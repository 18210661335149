import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Pagination, Row, Switch, Table} from "antd";
import {useLazyGetBrokerBranchComparisonQuery} from "../../../redux/api/brokersChartsApiSlice";
import {useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import {euro, numeric, percentage} from "../../../utils/formatters";
import {ColumnsType} from "antd/lib/table";
import {BrokerBranchComparisonResponse} from "../../../types/brokerCharts";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import BBBChartCardContainer from "../BBBChartCardContainer";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokerBranchComparison() {

    const {params} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = !!(params.budget_group && params.broker_uuid && typeof uwYear === 'number')
    const [calculationType, setCalculationType] = useState<1 | 2>(1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const options = useSelector(selectOptions)

    const [fetch, {data, isFetching, isUninitialized}] = useLazyGetBrokerBranchComparisonQuery()

    const getData = useCallback((page = 1) => {
        if (areParamsOk) {
            setCurrentPage(page)
            fetch({
                broker_uuid: params.broker_uuid,
                budget_group: params.budget_group,
                calculation_type: calculationType,
                uw_year: uwYear,
                page,
            })
        }
    }, [areParamsOk, calculationType, fetch, params.broker_uuid, params.budget_group, uwYear])

    useEffect(() => {
        getData()
    }, [getData]);

    const loading = isFetching || isUninitialized

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"}>
            <h3>Confronto Branch</h3>
            <Switch
                size={"small"}
                checked={calculationType === 1}
                onChange={() => setCalculationType(prevState => prevState === 1 ? 2 : 1)}
                checkedChildren={"Trattative"}
                unCheckedChildren={"Premi"}
                style={{width: "77px"}}
            />
        </Row>
    }, [calculationType])

    const render = useCallback((v: number) => !loading && (calculationType === 1 ? numeric(v) : euro(v)), [calculationType, loading])

    const columns: ColumnsType<BrokerBranchComparisonResponse> = useMemo(() => {
        return [
            {
                title: "Branch",
                key: 'branch',
                dataIndex: 'branch',
                render: (v: string) => {
                    const province = options.provinces.find(el => el.value === v)
                    if (province) {
                        return province.text
                    } else {
                        return v
                    }
                }
            },
            {
                title: <div style={{textAlign: 'right'}}>Submission ricevute</div>,
                key: 'submission',
                dataIndex: 'submission',
                align: 'right',
                render
            },
            {
                title: "Working",
                key: 'working',
                dataIndex: 'working',
                align: 'right',
                render
            },
            {
                title: <div style={{textAlign: 'right'}}>Quotate</div>,
                key: 'quoted',
                dataIndex: 'quoted',
                align: 'right',
                render
            },
            {
                title: <div style={{textAlign: 'right'}}>Bound</div>,
                key: 'bound',
                dataIndex: 'bound',
                align: 'right',
                render
            },
            {
                title: <div style={{textAlign: 'right'}}>Hit Ratio</div>,
                key: 'hitratio',
                dataIndex: 'hitratio',
                align: 'right',
                render: (v: number) => percentage(v)
            }
        ]
    }, [options.provinces, render])

    return <BBBChartCardContainer title={cardTitle}>
        <Table
            pagination={false}
            loading={loading}
            rowKey={'branch'}
            scroll={{x: true}}
            columns={columns}
            dataSource={data?.results}
            style={{marginTop: "10px"}}/>
        {!!data?.count && <Row justify={"end"} style={{marginTop: "1rem"}}>
            <Pagination
                disabled={loading}
                total={data?.count}
                current={currentPage}
                onChange={page => getData(page)}
                simple={true}
            />
        </Row>}
    </BBBChartCardContainer>
}