import React, {Key} from "react";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {Button, Checkbox, Col, ConfigProvider, Rate, Row, Slider, Space, Tag, Tooltip, Typography} from "antd";
import {FilterFilled} from "@ant-design/icons";
import DatePicker from "components/DatePicker";
import dayjs from "dayjs";
import {GeneralNegotiation, RenewsNegotiation,} from "types";
import {euro, numeric, percentage} from "utils/formatters";
import {NegotiationModel, NegotiationUserModel} from "types/negotiations";
import {ReinsurerBrokerModel} from "types/reinsurerBroker";
import {BrokerModel} from "types/broker";
import {LobModel} from "types/options";
import InstallmentTypeTag from "./generalTable/InstallmentTypeTag";
import itIT from "antd/es/locale/it_IT";
import BrokerFilter from "./BrokerFilter";
import ContractorFilter from "./ContractorFilter";
import ProvinceFilter from "./ProvinceFilter";
import ReinsurerBrokerFilter from "./ReinsurerBrokerFilter";
import DelegatedInsurerFilter from "./DelegatedInsurerFilter";
import {FilterConfirmProps, FilterDropdownProps} from "antd/lib/table/interface";
import FilterBySearch from "./generalTable/FilterBySearch";
import FilterContainer from "./FilterContainer";
import ReinsuranceNotesFilter from "./generalTable/ReinsuranceNotesFilter";
import ReinsurerFilter from "./ReinsurerFilter";
import BrokerBranchFilter from "./BrokerBranchFilter";
import {ReinsuranceType} from "../../../types/reinsuranceType";
import {ContractorModel} from "../../../types/contractors";
import BankSalesManagementFilter from "./BankSalesManagementFilter";
import BankBranchAreaFilter from "./BankBranchAreaFilter";
import BankBranchFilter from "./BankBranchFilter";
import SaleFilter from "./SaleFilter";
import BankReportingFilter from "./BankReportingFilter";
import BankSalesTypeFilter from "./BankSalesTypeFilter";
import BankBranchRegionFilter from "./BankBranchRegionFilter";

let timer: NodeJS.Timeout;
export const DELAY_TIMER_SLIDER_FILTER = 500
export const DELAY_TIMER_CHECKBOX_FILTER = 800
export const DELAY_TIMER_SEARCH_FILTER = 800

export const DEFAULT_CURRENT_PAGE_TABLE = 1;
export const DEFAULT_CURRENT_PAGE_SIZE_TABLE = 10;

export const endAndStartTimerFilter = (confirm: (param?: FilterConfirmProps) => void, delay: number = 800) => {
  window.clearTimeout(timer);
  timer = setTimeout(() => {
    confirm({closeDropdown: false});
  }, delay)
}

const getColumnNumberProps = (dataIndex: string, min: number = 0, max: number = 100, step: number = 0.1, unit: 'percentage' | 'star' | 'euro' | 'days' | 'none' = 'percentage'): ColumnType<any> => {
  let symbol: string = ""
  switch (unit) {
    case "euro":
      symbol = '€'
      break
    case "star":
      symbol = 'stelle'
      break
    case "percentage":
      symbol = '%'
      break
    case "days":
      symbol = 'gg'
      break
    case "none":
      symbol = ''
      break
  }

  return ({
    filterDropdown: (props: FilterDropdownProps) => {
      return <FilterContainer {...props}>
        {props.selectedKeys.length === 2 && typeof props.selectedKeys[0] === "number" && typeof props.selectedKeys[1] === "number" ?
            unit === 'euro' ? <p>{euro(props.selectedKeys[0])} / {euro(props.selectedKeys[1])}</p> :
                unit === 'percentage' ?
                    <p>{numeric(props.selectedKeys[0])}% / {numeric(props.selectedKeys[1])}% </p> :
                    <p>{numeric(props.selectedKeys[0])} {symbol} / {numeric(props.selectedKeys[1])} {symbol}</p> :
            <p>Nessun range impostato</p>}
        <Slider
            style={{width: '20rem'}}
            value={props.selectedKeys.length === 2 && typeof props.selectedKeys[0] === "number" && typeof props.selectedKeys[1] === "number" ? [props.selectedKeys[0], props.selectedKeys[1]] : undefined}
            range
            marks={unit === 'days' ? {0: '0 gg'} : {}}
            min={min}
            max={max}
            step={step}
            tooltip={{formatter: value => typeof value === "number" ? unit === 'percentage' ? `${numeric(value)}%` : unit === 'euro' ? euro(value) : numeric(value) : ""}}
            onChange={v => {
              v ? props.setSelectedKeys(v) : props.setSelectedKeys([])
              endAndStartTimerFilter(props.confirm, DELAY_TIMER_SLIDER_FILTER)
            }}
        />
      </FilterContainer>
    },
    filterIcon: (filtered: boolean) => {
      return <FilterFilled style={{color: filtered ? '#1890ff' : undefined}}/>
    },
    align: 'right',
    render: value => {
      if (value === null) {
        return ''
      }
      if (unit === 'euro') {
        return euro(value)
      }
      if (unit === 'percentage') {
        return percentage(value)
      }
      if (unit === 'none') {
        return numeric(value)
      }
      if (unit === 'days') {
        return numeric(value)
      }


      return value ? `${value} ${symbol}` : ""
    }
  })
}
export const getColumnDateProps = (dataIndex: string,
                                   unit: "day" | "month" | "year" = 'day'): ColumnType<any> => ({
  filterDropdown: (props: FilterDropdownProps) => {
    return <FilterContainer {...props}>
      <ConfigProvider locale={itIT}>
        <DatePicker.RangePicker
            format={unit === 'day' ? 'DD-MM-YYYY' : unit === 'year' ? 'YYYY' : 'YYYY-MM-DD'}
            style={{width: '20rem'}}
            className={'date-picker'}
            picker={unit !== 'day' ? unit : 'date'}
            placement={'topLeft'}
            value={props.selectedKeys.length === 2 && typeof props.selectedKeys[0] === "string" && typeof props.selectedKeys[1] === "string" ? [dayjs(props.selectedKeys[0]), dayjs(props.selectedKeys[1])] : undefined}
            allowClear={true}
            onChange={(dates, stringDates) => {
              if (dates) {
                switch (unit) {
                  case 'day' :
                    props.setSelectedKeys(dates.map(el => dayjs(el).format('YYYY-MM-DD')))
                    break
                  case 'year':
                    props.setSelectedKeys(dates.map(el => dayjs(el).format('YYYY')))
                    break
                  default:
                    props.setSelectedKeys(stringDates)
                    break
                }
              } else {
                props.setSelectedKeys([])
              }
              props.confirm({closeDropdown: false});
            }}/>
      </ConfigProvider>
    </FilterContainer>
  },
  filterIcon: (filtered: boolean) => {
    return <FilterFilled style={{color: filtered ? '#1890ff' : undefined}}/>
  },
  render: text => {
    if (text) {
      switch (unit) {
        case 'day':
          return dayjs(text).format('DD-MM-YYYY')
        case 'month':
          return dayjs(text).format('MMMM YYYY')
        case 'year':
          return text as string
      }
    }
  }
})

export const getStandardFilter = (filters: { text: string, value: string | number | boolean }[]): ColumnType<any> => {
  return {
    filterDropdown: (props: FilterDropdownProps) => {
      return <FilterContainer {...props}>
        <Checkbox.Group
            style={{maxHeight: '20rem', overflowY: 'auto'}}
            value={props.selectedKeys}
            onChange={(checkedValues) => {
              props.setSelectedKeys(checkedValues as React.Key[])
              endAndStartTimerFilter(props.confirm, DELAY_TIMER_CHECKBOX_FILTER)
            }}
        >
          <Row gutter={[16, 8]} style={{maxWidth: '10rem'}}>
            {filters.map(el => <Col key={el.value as React.Key} span={24}><Checkbox
                value={el.value}
            >
              {el.text}
            </Checkbox></Col>)}
          </Row>
        </Checkbox.Group>
      </FilterContainer>
    },
  }
}

let allTheColumns: ColumnsType<NegotiationModel> = [
  {
    title: 'Status',
    key: 'state',
  },
  {
    title: 'Nome contraente',
    key: 'contractor',
    render: ((contractor: ContractorModel) => {
      if (contractor) {
        return contractor.name
      }
    }),
    filterDropdown: (props) => {
      return ContractorFilter(props)
    },
  },
  {
    title: 'Provincia',
    key: 'province',
    render: (province) => {
      if (province)
        return province
    },
    filterDropdown: (props) => {
      return ProvinceFilter(props)
    }
  },
  {
    title: "Segnalazione banca",
    key: 'bank_reporting_type',
    filterDropdown: (props) => {
      return BankReportingFilter(props)
    }
  },
  {
    title: 'Tipologia direzione',
    key: 'bank_sales_type',
    filterDropdown: (props) => {
      return BankSalesTypeFilter(props)
    }
  },
  {
    title: 'Direzione regionale',
    key: 'bank_branch_region',
    filterDropdown: (props) => {
      return BankBranchRegionFilter(props)
    }
  },
  {
    title: 'Direzione commerciale',
    key: 'bank_sales_management',
    filterDropdown: (props) => {
      return BankSalesManagementFilter(props)
    }
  },
  {
    title: 'Area commerciale',
    key: 'bank_branch_area',
    filterDropdown: (props) => {
      return BankBranchAreaFilter(props)
    }
  },
  {
    title: 'Filiale in gestione',
    key: 'bank_branch',
    filterDropdown: (props) => {
      return BankBranchFilter(props)
    }
  },
  {
    title: 'Sale',
    key: 'sale',
    filterDropdown: (props) => {
      return SaleFilter(props)
    }
  },
  {
    title: 'Rating NZIA',
    key: 'emissions_rating'
  },
  {
    title: 'Qualità rapporto cliente',
    key: 'relationship_quality',
    ...getColumnNumberProps("relationship_quality", 0, 5, 1, 'star'),
    render: (value) => value ? <Rate value={value} disabled/> : null
  },
  {
    title: 'Scadenza prevalente programma assicurativo',
    key: 'prevailing_expiring_date',
    ...getColumnDateProps('prevailing_expiring_date'),

  },
  {
    title: 'Partita IVA',
    key: 'vat_number',
    filterDropdown: (props) => {
      return <FilterBySearch props={props} placeholder={'Filtra per partita IVA...'}/>
    },
  },
  {
    title: 'Sottoscrittore assegnato',
    key: 'underwriter',
    render: ((underwriter: NegotiationUserModel) => {
      if (underwriter) {
        return underwriter.user_full_name
      }
    })
  },
  {
    title: 'Portafoglio',
    key: 'portfolio_user',
    render: ((portfolioUser: NegotiationUserModel) => {
      if (portfolioUser) {
        return portfolioUser.user_full_name
      }
    })
  },
  {
    title: 'Gestita da portafoglio',
    key: 'is_portfolio_managed',
    render: ((value: boolean | null) => {
      if (value === true) {
        return <Tag color={'green'}>Gestito</Tag>
      } else if (value === false) {
        return <Tag color={'red'}>Non gestito</Tag>
      }
    }),
    ...getStandardFilter([{text: 'Gestito', value: true}, {text: 'Non gestito', value: false}])
  },
  {
    title: 'Broker',
    key: 'broker',
    render: ((broker: BrokerModel) => {
      if (broker) {
        return broker.name
      }
    }),
    filterDropdown: (props) => {
      return BrokerFilter(props)
    },
  },
  {
    title: 'Branch broker',
    key: 'broker_branch',
    filterDropdown: (props) => {
      return BrokerBranchFilter(props)
    },
  },
  {
    title: 'Registrazione',
    key: 'reception_date',
    ...getColumnDateProps('reception_date'),
  },
  {
    title: 'Numero Polizza',
    key: 'policy_number',
    filterDropdown: (props) => {
      return <FilterBySearch props={props} placeholder={'Filtra per il numero di polizza...'}/>
    },
  },
  {
    title: 'Regolazione premio',
    key: 'has_regulation_premium',
    render: ((value: boolean | null) => {
      if (value === true)
        return <Tag color={'green'}>Si</Tag>
      if (value === false)
        return <Tag color={'red'}>No</Tag>
    }),
    ...getStandardFilter([{text: 'Sì', value: true}, {text: 'No', value: false}])
  },
  {
    title: 'LPS',
    key: 'is_lps',
    render: ((value: boolean | null) => {
      if (value === true)
        return <Tag style={{width: '4rem', textAlign: 'center'}} color={'green'}>LPS</Tag>
      if (value === false)
        return <Tag style={{width: '4rem', textAlign: 'center'}} color={'red'}>NON LPS</Tag>
    }),
    ...getStandardFilter([{text: 'LPS', value: true}, {text: 'NON LPS', value: false}])
  },
  {
    title: 'Decorrenza',
    key: 'policy_effective_date',
    ...getColumnDateProps('policy_effective_date'),
  },
  {
    title: 'Giorni al termine di disdetta',
    key: 'days_to_dead_line',
    ...getColumnNumberProps("days_to_dead_line", -150, 150, 5, 'days'),
  },
  {
    title: 'Giorni alla scadenza',
    key: 'days_to_expiration',
    ...getColumnNumberProps("days_to_expiration", -150, 150, 5, 'days'),

  },
  {
    title: 'Scadenza',
    key: 'policy_expiring_date',
    ...getColumnDateProps('policy_expiring_date'),
  },
  {
    title: 'Premio imponibile quota ISA',
    key: 'isa_quoted_premium_share',
    ...getColumnNumberProps("isa_quoted_premium_share", 0, 1000000, 10000, 'euro'),
  },
  {
    title: 'Quota ISA',
    key: 'isa_share_perc',
    ...getColumnNumberProps('isa_share_perc')
  },
  {
    title: 'UW Year',
    key: 'uw_year',
    ...getColumnDateProps('uw_year', "year"),
  },
  {
    title: 'LOB',
    key: 'lob',
    render: ((lob: LobModel) => {
      if (lob) {
        return lob.name
      }
    }),
  },
  {
    title: 'ISIA',
    key: 'is_isia',
    render: ((value: boolean) => {
      if (value) {
        return <Tag color={'green'}>ISIA</Tag>
      }
    }),
    ...getStandardFilter([{text: 'ISIA', value: true}, {text: 'NON ISIA', value: false}])
  },
  {
    title: 'Segnalazione ISIA',
    key: 'isia_type',
    render: ((value: string) => {
      if (value) {
        return <Tag>{value}</Tag>
      }
    }),
  },
  {
    title: 'ISIA Sale',
    key: 'isia_sale',
    render: ((value: NegotiationUserModel) => {
      if (value) {
        return value.user_full_name
      }
    })
  },
  {
    title: 'Termini di disdetta',
    key: 'cancellation_terms_days',
    align: 'right'
  },
  {
    title: 'Tacito rinnovo',
    key: 'is_auto_renewal',
    render: ((value: boolean | null) => {
      if (value === true) {
        return <Tag color={'blue'}>TR</Tag>
      } else if (value === false) {
        return <Tag color={'cyan'}>STR</Tag>
      }
    }),
    ...getStandardFilter([{text: 'Tacito rinnovo', value: true}, {text: 'Senza tacito rinnovo', value: false}])
  },
  {
    title: 'Tipo di riassicurazione',
    key: 'reinsurance_type',
    render: (value: ReinsuranceType | null) => {
      let color: string = ''
      let label: string = ''
      switch (value) {
        case (ReinsuranceType.TRATTATO):
          color = 'green'
          label = 'Trattato'
          break;
        case (ReinsuranceType.SPECIALE):
          color = 'blue'
          label = 'Speciale'
          break;
        case (ReinsuranceType.FACOLTATIVO):
          color = 'red'
          label = 'Facoltativo'
          break;
        case (ReinsuranceType.FACILITY):
          color = 'volcano'
          label = 'Facility'
          break;

      }
      if (color && label) {
        return <Tag color={color} style={{width: '5rem', textAlign: 'center'}}>{label}</Tag>
      }
    }
  },
  {
    title: 'Riassicuratori',
    key: 'reinsurers_list',
    filterDropdown: (props) => {
      return ReinsurerFilter(props)
    },
    render: ((reinsurers: string[]) => {
      if (reinsurers && reinsurers.length > 0) {
        const list = reinsurers.map((el, idx) => <Tag
            style={{width: '6rem', height: '1.25rem', textAlign: 'center'}} key={idx}><Typography.Text
            ellipsis={{
              tooltip: true,
            }}>{el}</Typography.Text></Tag>)
        return <>{list}</>
      }
    }),
  },
  {
    title: 'Broker Riassicuratore',
    key: 'reinsurer_broker',
    filterDropdown: (props) => {
      return ReinsurerBrokerFilter(props)
    },
    render: ((reinsurerBroker: ReinsurerBrokerModel) => {
      if (reinsurerBroker) {
        return reinsurerBroker.name
      }
    }),
  },
  {
    title: '% Fee totali (Provvigioni diretto + overrider ISA)',
    key: 'reinsurer_broker_fee_perc',
    ...getColumnNumberProps('reinsurer_broker_fee_perc')
  },
  {
    title: '% Riassicurazione',
    key: 'reinsured_share_perc',
    ...getColumnNumberProps('reinsured_share_perc')
  },
  {
    title: 'Importo Fees ISA',
    key: 'isa_fee',
    ...getColumnNumberProps("isa_fee", 0, 1000000, 10000, 'euro'),
  },
  {
    title: 'Premio netto ceduto ai riassicuratori',
    key: 'net_premium_to_reinsurers',
    ...getColumnNumberProps("net_premium_to_reinsurers", 0, 1000000, 10000, 'euro'),
  },
  {
    title: 'Premio conservato ISA',
    key: 'saved_premium',
    ...getColumnNumberProps("saved_premium", 0, 1000000, 10000, 'euro'),
  },
  {
    title: "Attachment Point",
    key: "attachment_point",
    ...getColumnNumberProps("attachment_point", 0, 1000000, 10000, 'none'),
  },
  {
    title: 'Nota ai fini riassicurativi',
    key: 'reinsurance_notes',
    filterDropdown: (props) => {
      return ReinsuranceNotesFilter(props)
    },
    render: (text: string) => {
      return <Typography.Paragraph ellipsis={{rows: 3, tooltip: true}}>{text}</Typography.Paragraph>
    },
  },
  {
    title: 'Delegataria',
    key: 'delegated_insurer',
    filterDropdown: (props) => {
      return DelegatedInsurerFilter(props)
    },
    render: ((delegatedInsurer: { uuid: string, name: string }) => {
      if (delegatedInsurer) {
        return delegatedInsurer.name
      }
    }),
  },
  {
    title: 'Frazionamento',
    key: 'installment_type',
    render: ((type: string) => {
      if (type) {
        return <InstallmentTypeTag value={type}/>
      }
    }),
  },
  {
    title: 'Premium Payment Warranty (PPW)',
    key: 'ppw_days',
    align: 'right'
  },
  {
    title: 'Importo assicurato (nostra quota)',
    key: 'policy_insured_sum',
    ...getColumnNumberProps('policy_insured_sum', 0, 1000000000, 250000, 'euro')
  },
  {
    title: "New Business / Rinnovo",
    key: 'is_new_business',
    render: ((value: boolean | null) => {
      switch (value) {
        case true:
          return <Tag
              style={{width: '7rem', textAlign: 'center'}}
              color={'blue'}>New Business</Tag>
        case false:
          return <Tag
              style={{width: '7rem', textAlign: 'center'}}
              color={'grey'}>Rinnovo</Tag>
      }

    }),
    ...getStandardFilter([{text: 'New Business', value: true}, {text: 'Rinnovo', value: false}])
  },
  {
    title: "Convenzione",
    key: "is_framework_agreement",
    render: ((value: boolean) => {
      if (value)
        return <Tag color={'magenta'}>Convenzione</Tag>
      else
        return <Tag color={'orange'}>Singola Polizza</Tag>
    }),
    ...getStandardFilter([{text: 'Convenzione', value: true}, {text: 'Singola polizza', value: false}])
  },
  {
    title: "Nome convenzione",
    key: "framework_agreement",
    filterDropdown: (props) => {
      return <FilterBySearch props={props} placeholder={'Filtra per nome convenzione...'}/>
    },
  },
  {
    title: "Ateco",
    key: 'atecori',
    align: 'right',
    render: ((contractor) => {
      if (contractor && contractor.activity_full_description) {
        return <Tooltip title={contractor.activity_full_description}
                        placement={'topLeft'}>{contractor.activity_full_description.substring(0, 5)}</Tooltip>
      }
    })
  },
  {
    title: 'Ente Pubblico / Società Partecipata Pubblica',
    key: 'is_public',
    render: ((value: boolean | null) => {
      if (value === true) {
        return <Tag color={'blue'}>SI</Tag>
      } else if (value === false) {
        return <Tag color={'cyan'}>NO</Tag>
      }
    }),
    ...getStandardFilter([{text: 'Si', value: true}, {text: 'No', value: false}])
  },
  {
    title: '% Provvigioni segn. banca',
    key: 'fee_bank_perc',
    ...getColumnNumberProps('fee_bank_perc')
  }
]
allTheColumns = allTheColumns.map(c => {
  let dataIndex: Key | undefined | Key[] = "";
  switch (c.key) {
    case 'atecori':
      dataIndex = 'contractor';
      break;
    case 'vat_number':
      dataIndex = ['contractor', 'vat_number']
      break;
    case 'emissions_rating':
      dataIndex = ['contractor', 'emissions_rating']
      break;
    case 'relationship_quality':
      dataIndex = ['contractor', 'relationship_quality']
      break;
    case 'prevailing_expiring_date':
      dataIndex = ['contractor', 'prevailing_expiring_date']
      break;
    case 'province':
      dataIndex = ['contractor', 'province']
      break;
    case 'bank_reporting_type':
      dataIndex = ['contractor', 'bank_reporting_type_label']
      break
    case 'bank_sales_type':
      dataIndex = ['contractor', 'bank_branch_labels', 'bank_sales_type']
      break
    case 'bank_sales_management':
      dataIndex = ['contractor', 'bank_branch_labels', 'bank_sales_management']
      break;
    case 'bank_branch_region':
      dataIndex = ['contractor', 'bank_branch_labels', 'bank_branch_region']
      break;
    case 'bank_branch_area':
      dataIndex = ['contractor', 'bank_branch_labels', 'bank_branch_area']
      break;
    case 'bank_branch':
      dataIndex = ['contractor', 'bank_branch_labels', 'bank_branch']
      break;
    case 'sale':
      dataIndex = ['contractor', 'sale_label']
      break;
    case 'is_public':
      dataIndex = ['contractor', 'is_public']
      break;
    default:
      dataIndex = c.key
  }


  return {
    ...c,
    dataIndex: dataIndex,
    onFilter: () => true,
    filtered: false,
    filteredValue: [],
    sorter: c.key === 'reinsurers_list' || c.key === 'reinsurance_notes' ? false : {
      multiple: 0,
      sortOrder: undefined
    },
  }
})


allTheColumns = [...allTheColumns, {
  title: 'Azioni',
  key: 'renewsActions',
  dataIndex: 'renewsActions',
},
  {
    title: 'Azioni',
    key: 'robotReaderActions',
    dataIndex: 'robotReaderActions',
    render: (_, record) => (
        <Space size="middle">
          <Button type={'primary'}>Approva</Button>
        </Space>
    ),
  },]

//todo portfolio assignment
const generalColumns: ColumnsType<GeneralNegotiation> = allTheColumns.filter(col => ['state', 'contractor', 'is_public', 'emissions_rating', 'relationship_quality',
  'prevailing_expiring_date', 'province', 'bank_reporting_type', 'fee_bank_perc', 'bank_sales_type', 'bank_sales_management', 'bank_branch_region', 'bank_branch_area', 'bank_branch', 'sale',
  'vat_number', 'underwriter', /*'portfolio_user',*/, 'is_portfolio_managed', 'broker', 'broker_branch', 'lob',
  'reinsurance_type', 'reinsurers_list', 'reinsurer_broker', 'reinsurer_broker_fee_perc',
  'reinsured_share_perc', 'isa_fee', 'saved_premium', 'attachment_point', 'reinsurance_notes', 'net_premium_to_reinsurers',
  'delegated_insurer', 'is_isia', 'isia_type', 'isia_sale', 'reception_date', 'uw_year', 'is_new_business', 'isa_quoted_premium_share', 'installment_type',
  'policy_effective_date', 'policy_expiring_date', 'cancellation_terms_days', 'ppw_days', 'isa_share_perc',
  'policy_insured_sum', 'regulation_premium', 'is_framework_agreement', 'framework_agreement', 'atecori', 'has_regulation_premium', 'is_lps',
  'policy_number', 'is_auto_renewal'].includes(col.key as string)) as ColumnsType<GeneralNegotiation>
const renewsColumns: ColumnsType<RenewsNegotiation> = allTheColumns.filter(col => ['contractor', 'underwriter', 'lob', 'broker',
  'days_to_dead_line', 'days_to_expiration', 'policy_expiring_date', 'cancellation_terms_days', 'is_auto_renewal', 'isa_quoted_premium_share',
  'renewsActions'].includes(col.key as string)) as ColumnsType<RenewsNegotiation>

export {generalColumns, renewsColumns}