import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Affix, Col, Divider, message, Rate, Row, Space} from "antd";
import BudgetGroupsCards from "../../components/commercialDetail/BudgetGroupsCards";
import {LeftOutlined, StarFilled} from "@ant-design/icons";
import MeetingNotes from "../../components/commercialDetail/MeetingNotes";
import {useGetSingleBrokerQuery} from "../../redux/api/brokersApiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import HighchartsReact from "highcharts-react-official";
import ActiveAgreements from "../../components/commercialDetail/broker/ActiveAgreements";
import BrokerBranchComparison from "../../components/commercialDetail/broker/BrokerBranchComparison";
import NegotiationsDetail from "../../components/commercialDetail/NegotiationsDetail";
import BrokerNegotiationsPremiums from "../../components/commercialDetail/broker/BrokerNegotiationsPremiums";
import TotalBrokerPremiums from "../../components/commercialDetail/broker/TotalBrokerPremiums";
import TotalBrokerNegotiations from "../../components/commercialDetail/broker/TotalBrokerNegotiations";
import AnnualHitRatioGraph from "../../components/commercialDetail/AnnualHitRatioGraph";
import QuarterHitRatioGraph from "../../components/commercialDetail/QuarterHitRatioGraph";
import {ALL, useBrokerChartsParamsContext} from "../../context/BrokerChartsContext";
import {useGetAllBudgetGroupsQuery} from "redux/api/budgetsApiSlice";
import BrokerSubmissionRecevied from "../../components/commercialDetail/broker/BrokerSubmissionRecevied";
import BrokerSubmissionsTrend from "../../components/commercialDetail/broker/BrokerSubmissionsTrend";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import BrokerRenewRetention from "../../components/commercialDetail/broker/BrokerRenewRetention";
import SearchBroker from "../../components/commercialDetail/broker/SearchBroker";
import BrokerChartsBranchSelect from "../../components/commercialDetail/broker/BrokerChartsBranchSelect";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import UwYearDatePicker from "../../components/select/UwYearDatePicker";

export default function BrokerDetail() {

    const navigate = useNavigate()
    const {id: brokerId} = useParams()

    const renewRetentionChartRef = useRef<HighchartsReact.RefObject>(null);
    const {params, setParams} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = !!(params.broker_uuid && typeof uwYear === 'number')

    const [isAffix, setIsAffix] = useState(false)
    const {xs} = useBreakpoint()

    const {
        data: brokerData,
        isFetching: isFetchingBrokerData,
        isError: isErrorBrokerData,
        error,
    } = useGetSingleBrokerQuery(brokerId ? {uuid: brokerId} : skipToken)

    const {
        data: budgetGroupOptions,
        isFetching: isFetchingBudgetGroupOptions,
        isError: isErrorBudgetGroupOptions
    } = useGetAllBudgetGroupsQuery(areParamsOk ? {
        ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
        entity: 2,
        uw_year: uwYear,
        entity_uuid: params.broker_uuid
    } : skipToken)


    useEffect(() => {
            if (brokerId && !isFetchingBrokerData && !isErrorBrokerData) {
                setParams(prevState => ({
                    ...prevState,
                    broker_uuid: brokerId,
                    broker_branch: ALL,
                }))
            }
            if (isErrorBrokerData && "status" in error) {
                if (error?.status === 404) {
                    navigate('/brokers')
                    message.error('Broker non esistente')
                }
            }
        }, [brokerId, error, isErrorBrokerData, isFetchingBrokerData, navigate, setParams]
    );

    return <div style={{padding: xs ? 0 : "1rem"}}>
        <Link to={"/brokers"}>
            <Space>
                <LeftOutlined style={{color: '#1890FF'}}/>
                <p style={{color: '#1890FF'}}>Torna alla Dashboard generale</p>
            </Space>
        </Link>
        <Affix
            offsetTop={0}
            onChange={a => setIsAffix(!!a)}>
            <Row
                style={isAffix ? {
                    padding: '16px',
                    borderRadius: '12px',
                    boxShadow: '0px 10px 15px -10px #AAAAAA',
                    backgroundColor: '#ffffff',
                } : {}}
                justify={"end"}
                align={"middle"}
                gutter={[16, 16]}>
                <Col flex={1} xs={isAffix ? 0 : undefined}>
                    <Space wrap>
                        <h1>{isFetchingBrokerData ? '...' : brokerData?.name}</h1>
                        <SearchBroker/>
                        {typeof brokerData?.relationship_quality === 'number' && <div>
                            Valutazione: {!isFetchingBrokerData && <Rate
                            value={brokerData.relationship_quality}
                            disabled
                            character={(v) => {
                                return <StarFilled
                                    style={{
                                        color: (typeof brokerData?.relationship_quality === 'number' && typeof v?.index === 'number') ? v.index < brokerData.relationship_quality ? "#ffe300" : undefined : undefined,
                                        stroke: 'black',
                                        strokeWidth: '30px'
                                    }}
                                />
                            }}
                        />}
                        </div>}
                    </Space>
                </Col>
                <Col>
                    <UwYearDatePicker/>
                </Col>
                <Col>
                    <BrokerChartsBranchSelect key={params.broker_uuid}/>
                </Col>
                <Col xs={24}>
                    {params.broker_uuid && !isAffix && <ActiveAgreements broker={params.broker_uuid}/>}
                    <BudgetGroupsCards
                        loading={isFetchingBudgetGroupOptions}
                        options={(isErrorBudgetGroupOptions || !budgetGroupOptions) ? [] : budgetGroupOptions}
                        selectedOption={params.budget_group}
                        setSelectedOption={(value: string) => {
                            setParams(prevState => ({
                                ...prevState,
                                budget_group: value
                            }))
                        }}
                    />
                </Col>
            </Row>
        </Affix>
        <Divider/>
        <Row gutter={[16, 16]}>
            <Col xs={24}>
                <BrokerNegotiationsPremiums key={params.broker_uuid}/>
            </Col>
            <Col xs={24} lg={12}>
                <TotalBrokerPremiums key={params.broker_uuid}/>
            </Col>
            <Col xs={24} lg={12}>
                <BrokerRenewRetention key={params.broker_uuid} chartRef={renewRetentionChartRef}/>
            </Col>
            <Col xs={24} lg={12}>
                <TotalBrokerNegotiations key={params.broker_uuid}/>
            </Col>
            <Col xs={24} lg={12}>
                {areParamsOk && <QuarterHitRatioGraph
                    key={params.broker_uuid}
                    broker_branch={params.broker_branch !== ALL ? params.broker_branch : undefined}
                    uw_year={uwYear}
                    entity={2}
                    entity_uuid={params.broker_uuid}
                    budget_group={params.budget_group}
                />}
            </Col>
            <Col xs={24} lg={12}>
                {areParamsOk && <AnnualHitRatioGraph
                    key={params.broker_uuid}
                    broker_branch={params.broker_branch !== ALL ? params.broker_branch : undefined}
                    uw_year={uwYear}
                    entity={2}
                    entity_uuid={params.broker_uuid}
                    budget_group={params.budget_group}
                />}
            </Col>
            <Col xs={24} lg={12}>
                <BrokerSubmissionsTrend key={params.broker_uuid}/>
            </Col>
            <Col xs={24} lg={12}>
                <BrokerSubmissionRecevied key={params.broker_uuid}/>
            </Col>
            <Col xs={24} lg={12}>
                {areParamsOk && <MeetingNotes
                    key={params.broker_uuid}
                    params={{
                        broker: params.broker_uuid,
                        ...params.budget_group !== ALL && {budget_group: params.budget_group},
                        uw_year: uwYear,
                    }}/>}
            </Col>
        </Row>
        <Divider/>
        <BrokerBranchComparison/>
        <Divider/>
        {areParamsOk && <NegotiationsDetail
            key={params.broker_uuid}
            uuid={params.broker_uuid}
            broker_branch={params.broker_branch}
            filterKey={"broker"}
            budget_group={params.budget_group}
            uw_year={uwYear}
        />}
    </div>
}