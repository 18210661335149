import React, {Dispatch, useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Col, Input, Row, Select, Space, Table, TablePaginationConfig} from "antd";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {debounce} from "lodash";
import {useGetBrokerIstatDataQuery, useLazyGetBrokersListTableQuery} from "../../../redux/api/chartsApiSlice";
import {BrokersListTableResponse, LabelValue} from "../../../types/charts";
import {useBrokersChartsParamsContext} from "../../../context/BrokersChartsContext";
import {euro, numeric} from "../../../utils/formatters";
import {ColumnsType} from "antd/lib/table";
import BBBChartCardContainer from "../BBBChartCardContainer";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

const pageSize: number = 7;
export const ITALY = "ITALY"

export default function BrokersTable({
                                         options,
                                         selectedOption,
                                         setSelectedOption,
                                     }: {
    options: LabelValue[],
    selectedOption: string,
    setSelectedOption: Dispatch<string>,
}) {

    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState<string>('')
    const {params} = useBrokersChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year

    const debounceSetSearchTerm = debounce((value: string) => {
        setSearchTerm(value)
    }, 600)

    const [fetchData, {
        data: brokersData,
        originalArgs: brokersArgs,
        isFetching: isBrokerFetching,
        isUninitialized: isBrokerUninitialized,
    }] = useLazyGetBrokersListTableQuery()
    const loading = isBrokerUninitialized || isBrokerFetching

    const {data: istatData, isFetching: isFetchingIstatData, isError: isErrorIstatData} = useGetBrokerIstatDataQuery({
        ...selectedOption !== ITALY && {provinces: selectedOption},
    })
    const [page, setPage] = useState(1)

    useEffect(() => {
        if (typeof uwYear === 'number') {
            fetchData({
                budget_group: params.budget_group,
                uw_year: uwYear,
                ...selectedOption !== ITALY && {provinces: selectedOption},
                ...!!searchTerm && {broker_name: searchTerm},
                page_size: pageSize,
                page: 1
            })
            setPage(1)
        }

    }, [fetchData, params.budget_group, searchTerm, selectedOption, uwYear]);

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"} align={"middle"} gutter={[16, 16]}>
            <Col>
                <Select
                    value={selectedOption}
                    onChange={(v) => {
                        setSelectedOption(v)
                    }}
                    options={options}
                    style={{width: "200px"}}
                    showSearch={true}
                    virtual={false}
                    optionFilterProp={"label"}
                />
            </Col>
            <Col>
                {isFetchingIstatData && 'Numero aziende: ...'}
                {isErrorIstatData && 'Numero aziende: n.d.'}
                {!isFetchingIstatData && !isErrorIstatData && `Numero aziende (Istat - ${istatData?.year}): ${numeric(istatData?.companies || 0)}`}
            </Col>
        </Row>
    }, [isErrorIstatData, isFetchingIstatData, istatData?.companies, istatData?.year, options, selectedOption, setSelectedOption])

    const columns: ColumnsType<BrokersListTableResponse> = useMemo(() => {
        return [
            {
                title: "Broker",
                dataIndex: ["broker", "label"],
                width: 150
            },
            {
                title: <div style={{textAlign: 'right'}}>Trattative</div>,
                dataIndex: "negotiations_count",
                key: "negotiations_count",
                width: 150,
                align: 'right',
                render: (v) => numeric(v)
            },
            {
                title: <div style={{textAlign: 'right'}}>Totale Premi</div>,
                width: 150,
                dataIndex: "negotiations_premiums",
                key: "negotiations_premiums",
                align: 'right',
                render: (v) => euro(v)
            },
            {
                title: "Andamento",
                dataIndex: "growing_trend",
                width: 150,
                render: (trend: boolean) => trend ?
                    <ArrowUpOutlined style={{fontSize: "20px", color: "green", transform: "rotate(45deg)"}}/> :
                    <ArrowDownOutlined style={{fontSize: "20px", color: "red", transform: "rotate(-45deg)"}}/>
            },
        ]
    }, [])

    const onRow = useCallback((record: BrokersListTableResponse) => {
        return {
            onClick: () => {
                navigate(record.broker.value)
            },
            style: {
                cursor: 'pointer'
            }
        }
    }, [navigate])

    const pagination: TablePaginationConfig = useMemo(() => {
        return {
            size: "small",
            total: brokersData?.count,
            showSizeChanger: false,
            current: page,
            pageSize,
            onChange: (page) => {
                if (brokersArgs) {
                    setPage(page)
                    fetchData({
                        ...brokersArgs,
                        page
                    })
                }
            }
        }
    }, [brokersArgs, brokersData?.count, fetchData, page])

    return <BBBChartCardContainer title={cardTitle}>
        <Row
            style={{
                marginBottom: '0.5rem'
            }}
            align={'middle'}
            justify={'space-between'}>
            <Col>
                <Space direction={'vertical'}>
                    <div>
                        Totale
                        trattative: <strong>{loading ? '...' : typeof brokersData?.amount === 'number' && numeric(brokersData?.amount)}</strong>
                    </div>
                    <div>
                        Totale
                        premi: <strong>{loading ? '...' : typeof brokersData?.premiums === 'number' && euro(brokersData?.premiums)}</strong>
                    </div>
                </Space>
            </Col>
            <Col>
                <Input.Search
                    placeholder={'Filtra per nome...'}
                    style={{width: "20em", marginBottom: "1rem"}}
                    enterButton
                    defaultValue={searchTerm}
                    onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/>
            </Col>
        </Row>
        <div style={{overflowX: "scroll"}}>
            <Table
                size={'small'}
                columns={columns}
                tableLayout={"fixed"}
                loading={loading}
                dataSource={brokersData?.results}
                pagination={pagination}
                onRow={onRow}
                rowKey={record => record.broker.value}
            />
        </div>
    </BBBChartCardContainer>
}