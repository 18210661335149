import {Breadcrumb, Card, Col, Divider, Input, List, message, Pagination, Row, Spin} from "antd";
import React, {useCallback, useState} from "react";
import {
    useCreateBrokerMutation,
    useUpdateBrokerMutation, useGetPaginatedBrokersQuery
} from "../../redux/api/brokersApiSlice";
import {useNavigate} from "react-router-dom";
import CreateNewElement from "../../components/settings/CreateNewElement";
import {debounce} from "lodash";
import {LeftOutlined} from "@ant-design/icons";
import BrokerItem from "../../components/settings/BrokerItem";

const pageSize: number = 22;

export default function BrokersSettings() {

    const [params, setParams] = useState({
        currentPage: 1,
        searchTerm: ""
    })
    const debounceSetSearchTerm = debounce((value: string) => setParams({
        currentPage: 1,
        searchTerm: value,
    }), 600)

    const {
        data: brokersData,
        isLoading: isBrokerLoading,
        isFetching: isBrokerFetching,
        isError: isBrokerError
    } = useGetPaginatedBrokersQuery({
        ...!!params.searchTerm && {search: params.searchTerm},
        page_size: pageSize,
        page: params.currentPage
    })

    const [createBrokerRequest] = useCreateBrokerMutation()
    const [updateBrokerRequest] = useUpdateBrokerMutation()


    const navigate = useNavigate()

    const addBroker = useCallback((newBrokerName: string) => {
        createBrokerRequest({name: newBrokerName})
            .unwrap()
            .then(() => {
                message.success('Broker creato correttamente')
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiunta del broker')
                console.error('broker not created', error)
            })
    }, [createBrokerRequest])

    const updateBroker = useCallback((uuid: string, name: string, relationship_quality: number | null) => {
        return updateBrokerRequest({name, uuid, relationship_quality})
            .unwrap()
            .then(() => {
                message.success('Broker aggiornato correttamente')
                return true
            })
            .catch((error) => {
                message.error('Qualcosa è andato storto durante l\'aggiornamento del broker')
                console.error('broker not updated', error)
                return false
            })
    }, [updateBrokerRequest])

    return <Row justify={'space-evenly'}>
        <Col xs={24} sm={22} lg={23}>
            <div style={{paddingTop: '40px', paddingBottom: '70px', backgroundColor: 'transparent'}}>
                <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
                      title={<Breadcrumb>
                          <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                              style={{cursor: "pointer"}}><LeftOutlined/> Dati comuni</span></Breadcrumb.Item>
                          <Breadcrumb.Item>Brokers</Breadcrumb.Item>
                      </Breadcrumb>}
                >
                    <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
                        <Col style={{width: '20rem'}}><Input.Search placeholder={'Filtra per nome...'}
                                                                    enterButton
                                                                    defaultValue={params.searchTerm}
                                                                    onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/></Col>
                        <Col><CreateNewElement buttonContent={"Crea broker"} placeholder={"Nome broker"}
                                               modalTitle={"Creazione broker"} addElement={addBroker}/></Col>
                    </Row>
                    <Divider/>
                    <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        {isBrokerLoading ? <Spin/> :
                            <><List
                                grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                                loading={!brokersData || isBrokerFetching || isBrokerLoading}
                                dataSource={brokersData?.results}
                                renderItem={(item) => <List.Item
                                    style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
                                    <BrokerItem
                                        broker={item}
                                        updateBroker={(name, relationship_quality) => updateBroker(item.uuid, name || "", relationship_quality)}
                                    />
                                </List.Item>
                                }
                            />
                                <Row style={{marginTop: '2.5rem'}}
                                     justify={'end'}>
                                    <Pagination
                                        simple
                                        total={brokersData?.count}
                                        current={params.currentPage}
                                        pageSize={pageSize}
                                        onChange={page => {
                                            setParams(prevState => ({...prevState, currentPage: page}))
                                        }}
                                    />
                                </Row>
                            </>}
                        {brokersData?.count === 0 && params.searchTerm &&
                            <p>La ricerca con "<span style={{fontStyle: 'italic'}}>{params.searchTerm}</span>" come chiave di ricerca non ha prodotto risultati</p>}
                        {brokersData?.count === 0 && !params.searchTerm && <p>Non sono presenti broker</p>}
                    </div>
                </Card>
            </div>
        </Col>
    </Row>
}

