import {States} from "./index";

export type LabelValue = {
    label: string,
    value: string
}
export enum ZoneType {
    'REGION' = 1,
    'PROVINCE' = 2
}

export enum ChartEntity {
    'BANK_BRANCH' = 1,
    'BROKER' = 2
}

export enum CalculationType {
    'NEGOTIATIONS_COUNT' = 1,
    'PREMIUMS' = 2
}

export enum RatioType {
    BOUND_OVER_TOTAL = 1,
    BOUND_OVER_QUOTED = 2
}

export enum Subdivision {
    PER_YEAR = 1,
    PER_QUARTER = 2
}

export type RenewRetention = {
    percentage: number,
    amount: number,
    renewables: number,
    expired: number,
    average_renewables: number,
    to_be_renew: number
}

export type RenewRetentionParams = {
    uw_year: number,
    budget_group: string,
    isia?: string
}

export type BrokerRenewRetentionParams = {
    broker_branch?: string,
    broker_uuid: string,
    uw_year: number,
    budget_group: string,
}

export enum NegotiationsType {
    NEW_BUSINESS = 1,
    RENEWS = 2,
    ALL = 3,
    ACTIVE_PORTFOLIO = 4,
}

export type BrokerChartQueryParams = {
    uw_year: number,
    month?: number,
    year?: number,
    only_isia?: boolean,
    budget_group?: string,
    isia?: string,
    calculation_type?: CalculationType,
    negotiations_type?: NegotiationsType,
}

export type BBBSubmissionReceivedParams = {
    broker_branch?: string,
    entity: ChartEntity,
    entity_uuid?: string,
    uw_year: number,
    budget_group?: string,
}

export type BBBHitRatiosParams = {
    broker_branch?: string,
    budget_group: string,
    calculation_type: CalculationType,
    entity: ChartEntity,
    entity_uuid?: string,
    ratio_type: RatioType,
    subdivision: Subdivision,
    uw_year: number,
}

export type BBBHitRatiosResponse = {
    [period: string]: { label: string, amount: number }[]
}

export type BBBInBoundStatsParams = {
    budget_group: string,
    calculation_type?: CalculationType,
    entity: ChartEntity,
    uw_year: number,
}

export type BBBInBoundStatsResponse = {
    "new_business": number,
    "renewal": number,
    "regulation_premium": number,
    "total": number
}

export type TotalNegotiationsChartResponse = {
    history: { year: number, amount: number }[],
    result: { state: number, amount: number }[],
}

export type TotalNegotiationsChartParams = {
    broker_branch?: string,
    entity: ChartEntity,
    entity_uuid?: string,
    budget_group: string,
    uw_year: number,
    calculation_type?: CalculationType,
    negotiations_type?: NegotiationsType,
    percentage?: boolean,
}

export type SubmissionTrendParams = {
    broker_branch?: string,
    entity: ChartEntity,
    entity_uuid: string,
    budget_group: string,
    uw_year: number,
}

export type SubmissionTrendResponse = {
    [period: string]: { month: 'string', negotiations: number }[]
}


export type NegotiationDetailsParams = {
    broker_branch?: string,
    entity: ChartEntity,
    entity_uuid: string,
    budget_group: string,
    state?: States | string,
    uw_year: number,
    page?: number,
    page_size?: number
}

export type NegotiationDetailsResponse = {
    "contractor": {
        "value": string,
        "label": string
    },
    "negotiations": {
        "uuid": string,
        "lob": string,
        "state": States,
        "extended_premium": number
    }[],
    "penetration_index": number,
    "penetration_total": number,
}

export type BBBNegotiationsByZoneParams = {
    budget_group: string,
    entity: ChartEntity,
    entity_uuid?: string,
    provinces?: string,
    uw_year: number,
    zone_type?: ZoneType,
}

export type BBBNegotiationsByZoneResponse = {
    zone: LabelValue,
    "premium": number,
    "negotiations": number,
}

export type BBBlistTableParams = {
    bank_branch_area_name?: string,
    bank_branch_type_name?: string,
    bank_sales_management_name?: string,
    broker_name?: string,
    budget_group: string,
    city?: string,
    entity: ChartEntity,
    page?: number,
    page_size?: number
    provinces?: string,
    sales?: string,
    state?: States | string,
    uw_year: number,
}

export type BrokersListTableParams = Omit<BBBlistTableParams, 'bank_branch_area_name' | 'bank_branch_type_name' | 'bank_sales_management_name' | 'sales' | 'entity' | 'city'>
export type BankBranchesListTableParams = Omit<BBBlistTableParams, 'entity' | 'broker_name'>


export type BrokersListTableResponse = {
    "negotiations_count": number,
    "negotiations_premiums": number,
    "growing_trend": boolean,
    "broker": LabelValue
}

export type BankBranchesListTableResponse = {
    "negotiations_count": number,
    "negotiations_premiums": number,
    "growing_trend": boolean,
    "bank_branch": LabelValue,
    "bank_sales_management": LabelValue,
    "bank_branch_area": LabelValue,
    "bank_branch_type": LabelValue,
    "city": string,
    "sales": LabelValue
}

export type YearlyData = {
      "year": number,
      "companies": number,
      "persons_employed": number
    }

export type IstatData = {
  "yearly_data": YearlyData[],
  "branches": number,
  "reference_sale": string
}