import React, {useEffect, useState} from "react";
import {Button, Col, Divider, Form, Input, Radio, Rate, Row, Select, Tag} from "antd";
import {DebounceSelect} from "../debounceSelect";
import {useLazyGetAtecoCodesQuery, useLazyGetEmissionRatingOptionsQuery} from "../../redux/api/contractorsApiSlice";
import {ContractorDataFormModel, ContractorModel, CreateContractorModel} from "../../types/contractors";
import {AntCurrencyFormatInput} from "../inputNumber";
import {useForm} from "antd/lib/form/Form";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import ConnectContractorAndCustomer from "../contractorCustomer/ConnectContractorAndCustomer";
import {useCachedCountriesQuery} from "redux/api/countriesApiSlice";
import moment from "moment";

export default function EditContractorForm({
                                             contractor,
                                             onsubmit,
                                             onClose,
                                             onAlignContractor
                                           }: { contractor: ContractorModel, onsubmit: (values: CreateContractorModel) => void, onClose?: () => void, onAlignContractor: (c: ContractorModel) => void }) {

  const [fetchAtecos, {isLoading: isAtecosLoading}] = useLazyGetAtecoCodesQuery()
  const [fetchEmissionRating, {data: emissionRatingData}] = useLazyGetEmissionRatingOptionsQuery()
  const [isValuesChanged, setIsValuesChanged] = useState<boolean>(false)
  const [showSection, setShowSection] = useState(false)
  const [form] = useForm()
  const provinces = useSelector(selectOptions).provinces
  const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
  const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []

  const {
    uuid: id,
    name,
    activity,
    activity_full_description: activityFullDescription,
    province,
    yearly_revenues: yearlyRevenues,
    vat_number: vatNumber,
    country,
    customer_uuid,
    address,
    postcode,
    street_number: streetNumber,
    city,
    is_public: isPublic
  } = contractor
  const customerIsNotAlignedWithContractor = !customer_uuid

  async function searchAtecos(text: string): Promise<{ label: string, value: string }[]> {
    try {
      const atecosSearchResult = await fetchAtecos({search: text}).unwrap()
      return atecosSearchResult.results.map(ateco => (
          {label: ateco.full_description || ateco.uuid, value: ateco.uuid}
      ))
    } catch (e: any) {
      console.error('Ateco search', e)
      return []
    }
  }

  useEffect(() => {
    // @ts-ignore
    fetchEmissionRating()
  }, [fetchEmissionRating])

  function handleSubmit(values: ContractorDataFormModel) {
    if (isValuesChanged && values?.name && values.name.trim() !== "") {
      const parsedValues: CreateContractorModel = {...values, activity: values?.activity?.value}
      onsubmit(parsedValues)
    }
  }

  const onCountryChange = () => {
    const {province, country} = form.getFieldsValue()
    if (country === 'IT' && province === 'EE') {
      form.setFieldValue('province', null)
    }
    if (country && country !== 'IT' && province !== 'EE') {
      form.setFieldValue('province', 'EE')
    }
  }

  const onProvinceChange = () => {
    const {province, country} = form.getFieldsValue()
    if (province === 'EE' && country === 'IT') {
      form.setFieldValue('country', null)
    }
    if (province && province !== 'EE' && country !== 'IT') {
      form.setFieldValue('country', 'IT')
    }
  }

  return <>
    <Form
        form={form}
        initialValues={{
          name: name,
          yearly_revenues: yearlyRevenues,
          vat_number: vatNumber,
          activity: {value: activity, label: activityFullDescription},
          province: province,
          country: country,
          address: address,
          postcode: postcode,
          street_number: streetNumber,
          city: city,
          is_public: isPublic
        }}
        disabled={false}
        name='contractorData'
        layout="vertical"
        requiredMark={true}
        onFinish={(values) => handleSubmit(values)}
        onFieldsChange={(changedFields, allFields) => {
          setIsValuesChanged(allFields[0]?.value.trim() !== "")
        }}
    >
      <Row justify='space-between' gutter={[8, 8]}>
        <Col xs={24} md={17}>
          <Form.Item label="Nome contraente" required name='name'>
            <Input placeholder="Nome contraente" disabled/>
          </Form.Item>
        </Col>
        <Col xs={24} md={5} style={{display: "flex", flexDirection: "column"}}>
          <span style={{marginBottom: "8px"}}>Rating NZIA:</span>
          {contractor?.emissions_rating ? <div>
            <Tag color={contractor?.emissions_rating === "1" ? "green" : contractor?.emissions_rating === "2" ? "orange" : "red"}>
              {emissionRatingData?.find(el => el.value === contractor?.emissions_rating)?.text}
            </Tag>
          </div> : "-"}
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label={"Indirizzo"} name={"address"}>
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label={"Civico"} name={"street_number"}>
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label={"Città"} name={"city"}>
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label={"Cap"} name={"postcode"}>
            <Input/>
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label={"Nazione"} name={'country'}
                     rules={[{required: true, message: 'La nazione è obbligatoria'}]}>
            <Select
                optionFilterProp={'label'}
                showSearch
                options={countries}
                loading={isLoadingCountries}
                virtual={false}
                onChange={onCountryChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item
              label="Provincia"
              name='province'
              rules={[{required: true, message: 'La provincia è obbligatoria'}]}
          >
            <Select
                virtual={false}
                options={provinces.map(el => ({value: el.value, key: el.value, label: el.text}))}
                showSearch
                filterOption={(input, option) => {
                  return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
                }}
                onChange={onProvinceChange}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label="Fatturato annuo" name='yearly_revenues'>
            <AntCurrencyFormatInput/>
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item label="Partita IVA" name={'vat_number'}>
            <Input placeholder="Partita IVA" maxLength={16} disabled={!!contractor.customer_uuid}/>
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label="Descrizione attività" name={'activity'}>
            <DebounceSelect
                virtual={false}
                showSearch
                placeholder="Comincia a digitare per cercare un attività.. (nome o ateco) "
                fetchOptions={searchAtecos}
                style={{width: '100%'}}
            />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item label={"Ente Pubblico / Società Partecipata Pubblica"} name={"is_public"}>
              <Radio.Group>
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
        </Col>
      </Row>
      <Divider style={{margin: "12px 0"}}/>
      {contractor?.bank_reporting_type === "NO" || contractor?.bank_reporting_type === "ND" ?
          <>
            <Row align='middle' justify='space-between' gutter={[8, 8]}>
              <Col xs={24} md={11}>
                Segnalazione da banca <br/>
                <strong>{contractor?.bank_reporting_type_label}</strong>
              </Col>
              <Col xs={24} md={11}>
                Sale <br/>
                <strong>{contractor?.sale_label || "-"}</strong>
              </Col>
            </Row>
          </> :
          <Row align='middle' justify='space-between' gutter={[8, 8]}>
            <Col xs={24} md={11}>
                Segnalazione da banca <br/>
                <strong>{contractor?.bank_reporting_type_label}</strong>
              </Col>
            <Col xs={24}>
              Filiale in gestione <br/>
              <strong>{contractor?.bank_branch_labels?.bank_branch || "-"}</strong>
            </Col>
            <Col xs={24} md={11}>
              Sale <br/>
              <strong>{contractor?.sale_label || "-"}</strong>
            </Col>
            {contractor?.bank_reporting_type === "BDT" && <Col xs={24} md={11}>
              Tipologia <br/>
              <strong>{contractor?.bank_branch_labels?.bank_branch_type || "-"}</strong>
            </Col>}
            <Col xs={24} md={11}>
              Direzione commerciale <br/>
              <strong>{contractor?.bank_branch_labels?.bank_sales_management || "-"}</strong>
            </Col>
            <Col xs={24} md={11}>
              Area <br/>
              <strong>{contractor?.bank_branch_labels?.bank_branch_area || "-"}</strong>
            </Col>
          </Row>}
      <Divider/>
      <Row justify='space-between' gutter={[8, 8]}>
        <Col xs={24} md={11}>
          Scadenza prevalente programma assicurativo: <br/>
          <strong>{contractor?.prevailing_expiring_date ? moment(contractor.prevailing_expiring_date).format("D MMM YYYY") : "-"}</strong>
        </Col>
        <Col xs={24} md={11}>
          Qualità rapporto cliente: <br/>
          <strong>{contractor?.relationship_quality ? <Rate value={contractor.relationship_quality} disabled/> : "-"}</strong>
        </Col>
      </Row>
      <Divider/>
      <Row style={{margin: '1.5rem'}}><p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span
          style={{color: '#ff4d4f'}}>*</span> sono obbligatori</p></Row>
      <Row justify={'space-between'}>
        <Col>{!!onClose && <Button type={"default"} onClick={() => onClose()}>Annulla</Button>}</Col>
        <Col><Button htmlType={"submit"} disabled={!isValuesChanged}
                     type={'primary'}>Salva modifiche</Button></Col>
      </Row>
    </Form>
    {customerIsNotAlignedWithContractor && <>
        <Divider/>
        <Button
            style={{padding: 0}}
            type={'link'}
            onClick={() => setShowSection(prevState => !prevState)}
        >
          {showSection ? 'Nascondi caricamento...' : 'Carica i dati del contraente...'}
        </Button>
      {showSection && <ConnectContractorAndCustomer
          isFund={false}
          contractor={contractor}
          updateContractor={(c) => {
            form.setFieldsValue(c)
            onAlignContractor(c)
          }}/>}
    </>
    }
  </>
}