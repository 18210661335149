import {apiSlice} from "redux/api/baseApiSlice";
import {Country} from "../../types/countries";
import { useSelector } from "react-redux";
import {RootState} from "../store";
import { setCountries } from "redux/features/countriesSlice";

const countriesApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getCountries: builder.query<Country[], void>({
      query: () => {
        return {
          url: 'countries/',
          onSuccess: (response: { data: Country[]; }, {dispatch}: any) => {
            dispatch(setCountries(response.data));
          },
        };
      },
    }),
  })
})

export function useCachedCountriesQuery() {
  const { data, ...query } = countriesApiSlice.useGetCountriesQuery();
  const countries = useSelector((state: RootState) => state.countries);
  if (countries.length > 0) {
    return { data: countries, ...query };
  } else {
    return { data, ...query };
  }
}