import {Card, Divider, Table, TableProps} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {useEffect, useState} from "react";
import {
    useLazyGetBrokerDataDetailsChartQuery, useLazyGetBrokerSpecificDataDetailsChartQuery,
} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {TableDataType} from "../../../types/tableDataType";
import {euro, numeric} from "../../../utils/formatters";
import dayjs from "dayjs";
import {TableSorter} from "../../../types";
import _ from "lodash";
import {selectUser} from "../../../redux/features/userSlice";


function computeTotal(values: TableDataType | undefined): number {
    let total = 0
    if (values) {
        let keys: string[] = Object.keys(values)
        keys = keys.filter(itm => !["column0", "broker_uuid"].includes(itm))
        keys.forEach(k => {
            // @ts-ignore
            total += values[k as unknown as typeof values]
        })
    }
    return total
}

function formatData(values: TableDataType[] | undefined) {
    let newValues = values?.map((itm, index) => ({...itm, column6: computeTotal(itm), key: index.toString()}))
    return newValues
}


export default function BrokerDataTable({
                                            className,
                                            date,
                                            isDataLoading, calculationType, negotiationsType
                                        }: { className: string, date: dayjs.Dayjs, isDataLoading: boolean, calculationType: 1 | 2, negotiationsType: 1 | 2 | 3 | 4 }) {
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetBrokerDataDetailsChartQuery()
    const [columns, setColumns] = useState<ColumnsType<any>>([])
    const [getSpecificBrokerData, {
        isLoading: isLoadingGetSpecificBrokerData,
        isFetching: isFetchingGetSpecificBrokerData,
        data: specificBrokerData
    }] = useLazyGetBrokerSpecificDataDetailsChartQuery()
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                month: date.month(),
                year: date.year(),
                calculation_type: calculationType,
                negotiations_type: negotiationsType
            })
            setExpandedRowKeys([])
        }
    }, [budgetGroup, calculationType, date, getData, isia, negotiationsType, uwYear])

    useEffect(() => {
        if (data?.header) {
            const updateCols: ColumnsType<any> = data.header.map(col => {
                if (col.dataIndex === 'column0') {
                    return {
                        ...col,
                        width: '40%',
                        key: col.dataIndex,
                        sorter: {
                            compare: (a: any, b: any) => {
                                return a[col.dataIndex].localeCompare(b[col.dataIndex])
                            },
                            multiple: 0,
                            sortOrder: undefined
                        },
                        sortOrder: undefined
                    }
                } else {
                    return {
                        ...col,
                        width: '10%',
                        key: col.dataIndex,
                        align: 'right',
                        sorter: {
                            compare: (a: any, b: any) => {
                                return +a[col.dataIndex] - +b[col.dataIndex]
                            },
                            multiple: 0
                        },
                        sortOrder: undefined,
                        render: (value: number) => {
                            if (calculationType === 1) {
                                return numeric(value)
                            } else {
                                return euro(value)
                            }
                        }
                    }
                }
            })
            setColumns(updateCols)
        }
    }, [calculationType, data?.header])


    return <div className={className} style={{ overflow: 'scroll' }}>
        <Table
            style={{minWidth: '900px'}}
            tableLayout={'fixed'}
            rowClassName={(record) => expandedRowKeys.includes(record?.key) ? 'ant-table-row-selected ant-table-row-selected-header' : ""}
            loading={isDataLoading || isLoading || isFetching}
            columns={columns}
            dataSource={formatData(data?.values)}
            bordered={true}
            onChange={(pagination, filters, sorters, extra) => {
                if (extra.action === 'sort') {
                    let currentSorters: TableSorter[] = []
                    if (Array.isArray(sorters)) {
                        currentSorters = sorters.map(el => ({
                            key: el.field as string,
                            type: el.order as 'ascend' | 'descend'
                        }))

                    } else {
                        if (sorters.order === undefined) {
                            currentSorters = []
                        } else {
                            currentSorters = [{
                                key: sorters.field as string,
                                type: sorters.order as 'ascend' | 'descend'
                            }]
                        }

                    }
                    setColumns(prevState => {
                        const updatedCols = _.cloneDeep(prevState)
                        updatedCols.map(col => {
                            // @ts-ignore
                            col.sorter = {...col.sorter, multiple: 0, sortOrder: undefined}
                            col.sortOrder = undefined
                        })
                        currentSorters.forEach((curSor, idx) => {
                            const selectedColumn = updatedCols.find(col => col.key === curSor.key)
                            if (selectedColumn) {
                                // @ts-ignore
                                selectedColumn.sorter.sortOrder = curSor.type
                                selectedColumn.sortOrder = curSor.type
                                if (curSor.type) {
                                    // @ts-ignore
                                    selectedColumn.sorter.multiple = idx + 1
                                }
                            }
                        })
                        return updatedCols
                    })

                }
            }}
            expandable={{
                expandedRowRender: record => {
                    return <Table
                        className={'broker-data-specific--chart'}
                        rowClassName={'ant-table-row-selected'}
                        showHeader={false}
                        bordered={false}
                        loading={isLoadingGetSpecificBrokerData || isFetchingGetSpecificBrokerData}
                        columns={specificBrokerData?.header.map(el => {
                            if (el.dataIndex === 'column0') {
                                return {...el, width: '40%'}
                            } else {
                                return {
                                    ...el,
                                    width: '10%',
                                    align: 'right',
                                    render: (value) => {
                                        if (calculationType === 1) {
                                            return numeric(value)
                                        } else {
                                            return euro(value)
                                        }
                                    }
                                }
                            }
                        })}
                        dataSource={specificBrokerData?.values.map(el => ({...el, column6: computeTotal(el)}))}
                        pagination={false}
                    />
                },
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => {
                    if (!expanded) {
                        setExpandedRowKeys([])
                    } else {
                        setExpandedRowKeys([record.key])
                        if (budgetGroup && typeof uwYear === 'number' && isia) {
                            getSpecificBrokerData({
                                uw_year: uwYear,
                                budget_group: budgetGroup?.value,
                                month: date.month(),
                                year: date.year(),
                                isia: isia.value,
                                calculation_type: calculationType,
                                broker: record.broker_uuid,
                                negotiations_type: negotiationsType
                            })
                        }
                    }
                }
            }}
        />
    </div>
}