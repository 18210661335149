import React, {useEffect, useState} from "react";
import {useGetBankBranchRegionQuery} from "../../../redux/api/commercialDetailApiSlice";
import FilterContainer from "./FilterContainer";
import {Select} from "antd";
import {LabeledValue} from "antd/lib/select";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";
import {FilterDropdownProps} from "antd/lib/table/interface";

export default function BankBranchRegionFilter(props: FilterDropdownProps) {
    const {data: bankBranchRegionData} = useGetBankBranchRegionQuery()
    const [options, setOptions] = useState<{label: string, value: string}[]>([])

    useEffect(()=> {
        if (bankBranchRegionData)
            setOptions(bankBranchRegionData.map(el => ({label: el.name, value: el.uuid})))
    }, [bankBranchRegionData])

    return <FilterContainer {...props}>
        <Select
            labelInValue
            options={options}
            mode={"multiple"}
            showSearch={true}
            placeholder={"Seleziona una direzione regionale"}
            filterOption={(input, option) => {
                return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
            }}
            style={{width: "20rem"}}
            virtual={false}
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.reverse().map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            // @ts-ignore
            value={props.selectedKeys}
        />
    </FilterContainer>
}