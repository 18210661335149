import { apiSlice } from "redux/api/baseApiSlice";
import {GetDocumentsModel, TreeStructureModel, RequestToSaveDocumentModel} from "../../types/documents";

export const documentsAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFoldersStructure: builder.query<TreeStructureModel[], void>({
            query: () => {
                return {
                    url: '/negotiation/get_folders',
                    method: 'GET'
                }
            }
        }),
        getFolderLabel: builder.query<{label: string, value: string}[], void>({
            query: () => {
                return {
                    url: '/negotiation/get_folder_labels/',
                    method: 'GET'
                }
            }
        }),
        getGeneralFolders: builder.query<TreeStructureModel[], {document_path?: string}>({
            query: (args) => {
                return {
                    url: '/negotiation/negotiation_folders/',
                    method: 'GET',
                    params: {...args}
                }
            }
        }),
        getDocuments: builder.query<GetDocumentsModel[], {negotiation_id: string | undefined, path?: string, search?: string}>({
            query: (args) => {
                const params = {...args}
                return {
                    url: `negotiation/negotiations/${params.negotiation_id}/document/`,
                    method: 'GET',
                    params: {path: params.path, search: params.search}
                }
            },
        }),
        requestToSaveDocuments: builder.query<RequestToSaveDocumentModel, {path?: string, name?: string, negotiation: string | undefined, content_type: string}>({
            query: ( args) => {
                const params = {...args}
                return {
                    url: `negotiation/negotiations/${params.negotiation}/document/`,
                    body: {...params},
                    method: 'POST'
                }
            }
        }),
        getDocumentUrl: builder.query<{url: string}, {negotiation_uuid: string | undefined, uuid: string}>({
            query: (args) => {
                const {negotiation_uuid, uuid} = args
                return {
                    url: `negotiation/negotiations/${negotiation_uuid}/document/${uuid}/get_url/`,
                }
            }
        }),
        updateDocument: builder.mutation<GetDocumentsModel, { uuid: string, data: { is_valid?: boolean, path?: string, name?: string, negotiation: string | undefined } }>({
            query: (args) => {
                const params = {...args}
                return {
                    url: `negotiation/negotiations/${params.data.negotiation}/document/${params.uuid}/`,
                    method: 'PATCH',
                    body: { ...params.data }
                }
            }
        }),
        deleteDocument: builder.mutation<void, {negotiation_uuid: string | undefined, uuid: string | number}>({
            query: (args) => {
                const {negotiation_uuid, uuid} = args
                return {
                    url: `negotiation/negotiations/${negotiation_uuid}/document/${uuid}/`,
                    method: 'DELETE'
                }
            }
        }),
        getNegotiationHistory: builder.query<{ label: string, value: string }[], { negotiation: string }>({
            query: (args) => {
                const params = {...args}
                return {
                    url: `negotiation/negotiations/${params.negotiation}/history/`,
                    method: 'GET'
                }
            }
        })
    })
})

export const {useLazyGetFoldersStructureQuery, useLazyGetFolderLabelQuery, useLazyGetGeneralFoldersQuery, useLazyGetDocumentsQuery, useLazyRequestToSaveDocumentsQuery, useLazyGetDocumentUrlQuery, useUpdateDocumentMutation, useDeleteDocumentMutation, useLazyGetNegotiationHistoryQuery } = documentsAPiSlice