import {Card, Row, Col, Space, Divider} from "antd";
import React from "react";
import {PieContainerProps} from "../../../types/negotiations/components";
import Title from "antd/lib/typography/Title";
import {euro, numeric, percentage as p} from "../../../utils/formatters";
import InfoModal from "../../modals/InfoModal";

export default function PieChartContainer(props: PieContainerProps) {

    const titleCol = props.title ?
        <Row justify={"space-between"}>
            <Col>
                <Row align={'middle'}>
                    <Title ellipsis={true} level={4}>{props.title}</Title>
                    <InfoModal title={props.title} description={props.description}/>
                </Row>

            </Col>
        </Row> :
        <Col span={0}></Col>
    const buttonsCol = props.buttons ?
        <Col span={24} style={{textAlign: 'right'}}>
            <Space wrap={true}>{props.buttons}</Space>
        </Col> :
        <Col span={0}></Col>
    const rowCol = <Row className={'params'} justify={'end'}>
        {buttonsCol}
    </Row>

    const {
        buttons,
        pieChart,
        valueDescription,
        displayValue,
        sideData,
        colSpan,
        extraComponents,
        description,
        ...antProps
    } = props;

    return <Card className={props.className} style={{border: 'none'}} loading={props.loading}>
        <div className={'pie-chart-container'}>
            {rowCol}
            <div className={'pie-chart'}>{props.pieChart}</div>
            <div className={'info'}>
                {titleCol}
                <Row><Col style={{
                    textAlign: 'left',
                    fontSize: '14px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.45)'
                }}>{valueDescription}</Col>
                </Row>
                <Row style={{marginTop: '1em'}}>
                    <Col style={{
                        textAlign: 'left',
                        fontSize: '30px',
                        fontWeight: '500'
                    }}>{props.displayValue}</Col>
                </Row>
                <Divider type={'horizontal'}/>
                <div style={{margin: '2em 0 2em 0'}}>
                    {props.sideData?.map(data => {
                            return <Row justify={'space-between'} style={{width: '100%', display: 'flex'}}
                                        key={data.key}>
                                <Col style={{maxWidth: '50%'}}>{data.key}</Col>
                                <Col style={{fontWeight: 'bolder', textAlign: 'end'}}>{data.value}</Col>
                            </Row>
                    })
                    }
                </div>

                {props.extraComponents}

            </div>
        </div>

    </Card>


    // return <Card className={props.className} style={{border: 'none'}} loading={props.loading}>
    //
    //
    //
    //
    //     <Card.Grid style={{minWidth: '50%', padding: '0', boxShadow: 'none'}} hoverable={false}>
    //         {props.pieChart}
    //     </Card.Grid>
    //     <Card.Grid hoverable={false} style={{width: '50%', padding: '0', boxShadow: 'none'}}>
    //         <Card {...antProps} title={rowCol} style={{border: 'none'}}
    //               headStyle={{border: 'none', flexWrap: 'nowrap', paddingRight: '10px'}}
    //               bodyStyle={{paddingTop: 0, boxShadow: 'none', display: 'block'}}
    //         >
    //             {titleCol}
    //             <div style={{paddingRight: '16px'}}>
    //                 {<Row><Col style={{
    //                     textAlign: 'left',
    //                     fontSize: '14px',
    //                     fontWeight: '400',
    //                     color: 'rgba(0,0,0,0.45)'
    //                 }}>{valueDescription}</Col></Row>}
    //                 {<Row style={{marginTop: '1em'}}><Col style={{
    //                     textAlign: 'left',
    //                     fontSize: '30px',
    //                     fontWeight: '500'
    //                 }}>{props.displayValue}</Col></Row>}
    //                 <Col flex={1}><Divider type={'horizontal'}/></Col>
    //                 <div style={{margin: '2em 0 2em 0'}}>
    //                     {props.sideData?.map(data => {
    //                         if (data) {
    //                             return <Row justify={'space-between'} style={{width: '100%', display: 'flex'}}
    //                                         key={data.key}>
    //                                 <Col style={{maxWidth: '50%'}}>{data.key}</Col>
    //                                 <Col style={{fontWeight: 'bolder', textAlign: 'end'}}>{data.value}</Col>
    //                             </Row>
    //                         }
    //                     })
    //                     }
    //                 </div>
    //
    //                 {props.extraComponents}
    //             </div>
    //
    //         </Card>
    //     </Card.Grid>
    // </Card>


}