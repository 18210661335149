import {Select, SelectProps} from "antd";
import {Dispatch} from "react";

interface CustomSelect extends SelectProps {
    value: number;
    setValue: Dispatch<1 | 2 | 3 | 4>
}

export default function SelectNewBusinessRenewalAll(props: CustomSelect) {
    const {value, setValue, ...selectProps} = props

    return <Select
        options={[
            {label: 'New Business', value: 1},
            {label: 'Rinnovati con successo', value: 2},
            {label: 'New Business + Rinnovati con successo', value: 3},
            {label: 'Polizze in essere', value: 4},
            // {label: 'Portafoglio', value: 5, className: 'portfolio-option'}
        ]}
        style={{
            width: '11rem',
            textAlign: 'center'
        }}
        value={value}
        onSelect={(value: 1 | 2 | 3 | 4) => {
            props.setValue(value)
        }}
        {...selectProps}
    />
}