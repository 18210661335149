import {apiSlice} from "redux/api/baseApiSlice";
import {AllBudgetGroup, AllBudgetGroupParams, Budget, BudgetGroup} from "../../types/budgets";

export const budgetsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBudgetGroups: builder.query<BudgetGroup[], void>({
            query: () => '/negotiation/budget_groups/',
        }),
        getAllBudgetGroups: builder.query<
            AllBudgetGroup[], AllBudgetGroupParams>({
            query: (params) => {
                return {
                    url: '/negotiation/charts/bbb/negotiationsbudgetgroups/',
                    params
                }
            }
        }),
        getBudgets: builder.query<Budget[], { uw_year: number }>({
            query: (uw_year) => {
                return {
                    url: '/negotiation/budget/',
                    params: uw_year
                }
            },
        }),
        createBudget: builder.mutation<Budget, {
            budget_group: string,
            uw_year: number,
            total_goal: number,
            direct_isa_goal: number,
            isia_goal: number
        }>({
            query: (args) => {
                return {
                    url: '/negotiation/budget/',
                    method: 'POST',
                    body: args
                }
            }
        }),
        updateBudget: builder.mutation<Budget, Pick<Budget, 'total_goal' | 'direct_isa_goal' | 'isia_goal' | 'hit_ratio_from_quoted' | 'hit_ratio_from_submission' | 'regulation_premium_estimate'> & {
            uuid: string
        }>({
            query: (args) => {
                const {
                    uuid,
                    total_goal,
                    direct_isa_goal,
                    isia_goal,
                    hit_ratio_from_quoted,
                    hit_ratio_from_submission,
                    regulation_premium_estimate
                } = args
                return {
                    url: `/negotiation/budget/${uuid}/`,
                    method: 'PATCH',
                    body: {
                        total_goal,
                        direct_isa_goal,
                        isia_goal,
                        hit_ratio_from_quoted,
                        hit_ratio_from_submission,
                        regulation_premium_estimate
                    }
                }
            }
        })
    })
})

export const {
    useGetAllBudgetGroupsQuery,
    useLazyGetBudgetGroupsQuery,
    useLazyGetBudgetsQuery,
    useCreateBudgetMutation,
    useUpdateBudgetMutation
} = budgetsApiSlice