import React, {useMemo, useRef} from "react";
import {Row} from "antd";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import BBBChartCardContainer from "./BBBChartCardContainer";

export default function SubmissionReceivedGraph({months, submissions, meetings, loading}: {
    months: string[],
    submissions: number[],
    meetings: number[],
    loading?: boolean
}) {
    const ref = useRef<HighchartsReact.RefObject>(null);
    const chartOptions = useMemo(() => ({
                chart: {
                    type: 'column',
                },
                title: {
                    text: '',
                    align: 'left'
                },
                subtitle: {
                    text: ""
                },
                xAxis: {
                    categories: months,
                    accessibility: {
                        description: 'Months'
                    }
                },
                yAxis: [{
                    title: {
                        text: 'Submission',
                    },
                    allowDecimals: false,
                }, {
                    title: {
                        text: 'Meeting'
                    },
                    allowDecimals: false,
                    opposite: true
                }],
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        borderRadius: 5
                    }
                },
                series: [{
                    name: 'Submissions ricevute',
                    data: submissions,
                    yAxis: 0,
                    color: "#95CEFF"
                }, {
                    name: 'Meeting effettuati',
                    data: meetings,
                    yAxis: 1,
                    type: 'line',
                    color: "#E98A42"
                }],
                exporting: {
                    enabled: false
                },
                credits: undefined,
            }), [meetings, months, submissions])

    return <BBBChartCardContainer loading={loading}>
        <Row justify={"center"}>
            <h2>Submission ricevute</h2>
        </Row>
        <Row justify={"center"}>
            <h5 style={{color: "#b0aeae"}}>Come l'azione commerciale sta avendo effetti sulla ricezione submission</h5>
        </Row>
        <HighchartsReact
            highcharts={Highcharts}
            ref={ref}
            options={chartOptions}
        />
    </BBBChartCardContainer>
}