import LoadedPolicies from "../charts/ChartCards/LoadedPolicies";
import PoliciesToLoad from "../charts/ChartCards/PoliciesToLoad";
import DashboardHeader from "./DashboardHeader";
import LobPortfolioChart from "../charts/ChartCards/LobPortfolioChart";
import React, { useRef} from "react";
import HighchartsReact from "highcharts-react-official";
import BrokerPortfolioChart from "../charts/ChartCards/BrokerPortfolioChart";
import PoliciesStatus from "../charts/ChartCards/PoliciesStatus";
import BelatedHistoryCard from "../charts/ChartCards/belatedHistoryCard";
import BelatedEmittedBatchReportCard from "../charts/ChartCards/BelatedEmittedBatchReportCard";
import TitleExtractionCard from "components/charts/ChartCards/TitleExtractionCard";

export default function PortfolioDashboard() {

    const lobChartRef = useRef<HighchartsReact.RefObject>(null);
    const brokerChartRef = useRef<HighchartsReact.RefObject>(null);

    const policiesToLoadChartRef = useRef<HighchartsReact.RefObject>(null);
    const loadedPoliciesChartRef = useRef<HighchartsReact.RefObject>(null);
    const policiesStatusChartRef = useRef<HighchartsReact.RefObject>(null);

    return <>
        <DashboardHeader />
        <div className={'negotiation-overview--charts'} >
            <LoadedPolicies chartRef={loadedPoliciesChartRef}/>
            <PoliciesToLoad chartRef={policiesToLoadChartRef}/>
            <PoliciesStatus chartRef={policiesStatusChartRef} />
        </div>


        <div className={'dashboard-portfolio-container'} style={{marginTop: '1.5rem'}}>
            <BrokerPortfolioChart
                chartRef={brokerChartRef}
                className={'broker-portfolio--chart'}/>
            <LobPortfolioChart
                chartRef={lobChartRef}
                className={'lob-portfolio--chart'}/>
            <TitleExtractionCard className={'title-extraction--card'} />
            <BelatedHistoryCard className={'belated-history--table'}/>
            <BelatedEmittedBatchReportCard className={'belated-batch-report--table'} />
        </div>

    </>
}