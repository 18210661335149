import { apiSlice } from "redux/api/baseApiSlice";
import {SurveyModel} from "survey-react";

export type ChecklistType = 'ASSU' | 'CINT'

export const checklistApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createChecklist: builder.query<{ uuid: string, jsonSchema: SurveyModel, data: object }, {negotiation: string, checklist_type: ChecklistType}>({
            query: ({negotiation, checklist_type}) => {
                return {
                    url: `negotiation/negotiations/${negotiation}/checklist/`,
                    method: 'POST',
                    body: {checklist_type}
                }
            },
        }),

        getChecklist: builder.query<{ uuid: string, jsonSchema: SurveyModel, data: object }, {negotiation: string, checklist: string}>({
            query: ({negotiation, checklist}) => {
                return {
                    url: `negotiation/negotiations/${negotiation}/checklist/${checklist}/`,
                }
            },
        }),

        updateChecklist: builder.mutation<{}, {negotiation: string, checklist: string, data: object, visible_questions: {[key: string]: boolean}}>({
            query: ({negotiation, checklist, ...body}) => {
                return {
                    url: `negotiation/negotiations/${negotiation}/checklist/${checklist}/`,
                    method: 'PATCH',
                    body: body
                }
            }
        }),
    })
})

export const {useLazyCreateChecklistQuery, useLazyGetChecklistQuery, useUpdateChecklistMutation } = checklistApiSlice