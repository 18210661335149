import {Select, Spin, SelectProps} from "antd";
import React, {useCallback, useEffect} from "react";
import {debounce} from "lodash";
import { useLazyGetPaginatedReinsuresQuery } from "redux/api/reinsurersApiSlice";

export default function ReinsurerMultiSelect(props: SelectProps) {
    const [fetchReinsurers, { data: reinsurers, isFetching: isReinsurersFetching, isLoading: isReinsurersLoading, isError: isReinsurersError }] = useLazyGetPaginatedReinsuresQuery()
    const debounceFetcher = useCallback(debounce( (searchTerm) => fetchReinsurers({search: searchTerm}), 600 ), [])

    useEffect(() => {
        fetchReinsurers({})
    }, [fetchReinsurers])

    return <Select
        labelInValue
        mode={'multiple'}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={ isReinsurersLoading || isReinsurersFetching ? <Spin size="small" /> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
        options={reinsurers?.results.map(el => ({label: el.name, value: el.uuid})) || []}
        virtual={false}
        placeholder={'Digitare il nome del riassicuratore...'}
        {...props}
    />
}


