import { useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {BudgetGroup, UserTypes} from "../../types";
import PortfolioDashboard from "../../components/dashboard/PortfolioDashboard";
import React, {useState} from "react";
import UnderwriterDashboard from "components/dashboard/UnderwriterDashboard";
import {canSeePortfolioCharts, canSeeUWCharts} from "../../utils/permission";
import {Col, Row} from "antd";


export default function Dashboard() {

    const user = useSelector(selectUser)

    return <Row justify={'space-evenly'}>
            {!!user.usertypes && !!user.usertypes.length && canSeePortfolioCharts(user.usertypes.map(el=> el.code)) && <Col style={{paddingTop: '1.25rem', paddingBottom: '4.375rem'}} xs={24} sm={22} lg={23}><PortfolioDashboard /></Col>}
            {!!user.usertypes && !!user.usertypes.length && canSeeUWCharts(user.usertypes.map(el=> el.code)) && <Col style={{paddingTop: '1.25rem', paddingBottom: '4.375rem'}} xs={24} sm={22} lg={23}><UnderwriterDashboard /></Col>}
        </Row>
}