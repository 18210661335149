import ConfirmCancelModal from "components/modals/ConfirmCancelModal";
import {CustomDataNode} from "../../types/documents";

export default function RemoveDocumentModal({document, remove, visible, close}: {document: CustomDataNode[], remove: (uuid: CustomDataNode[]) => void, visible: boolean, close: () => void}) {
    return <ConfirmCancelModal
        title={'Elimina documento'}
        open={visible}
        onOk={() => {
            remove(document)
            close()
        }}
        width={700}
        destroyOnClose
        okText={'Elimina'}
        okButtonProps={{danger: true}}
        onCancel={close}
    >
        <p>Vuoi davvero eliminare questi documenti?</p>
        <ul>{document.map((e, index) => <li key={index} style={{marginTop: '0.75rem', marginLeft: "15px"}}><span>{e.title as string}</span></li>)}</ul>
    </ConfirmCancelModal>
}