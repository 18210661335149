import {Col, Row, Space, Typography} from "antd";
import dayjs from "dayjs";
import {AntCurrencyFormatInput} from "../../../inputNumber";
import {BelatedEmittedModel} from "../../../../types/negotiations";

export default function BelatedEmittedItem({belatedEmitted}: { belatedEmitted: BelatedEmittedModel }) {
  const {Text} = Typography

  return <Row justify={"center"}>
    <Col xxl={18} xl={24}>
      <Row wrap={true} justify={"space-between"}>
        <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
          <Space direction='vertical'>
            <Text>Decorrenza</Text>
            <Text strong>{dayjs(belatedEmitted.installment_effective_date).format('DD/MM/YYYY')}
            </Text>
          </Space>
        </Col>
        <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
          <Space direction='vertical'>
            <Text>Scadenza</Text>
            <strong>{belatedEmitted.installment_expiring_date ? dayjs(belatedEmitted.installment_expiring_date).format('DD/MM/YYYY') :
                <span>Non presente</span>}</strong>
          </Space>
        </Col>
        <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
          <Space direction='vertical'>
            <Text>Importo premio</Text>
            <strong> <AntCurrencyFormatInput displayType='text'
                                             value={belatedEmitted.installment_premium}/></strong>
          </Space>
        </Col>
        <Col span={6} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
          <Space direction='vertical'>
            <Text>Provvigioni</Text>
            <strong><AntCurrencyFormatInput displayType='text'
                                            value={belatedEmitted.installment_commission}/></strong>
          </Space>
        </Col>
        {belatedEmitted.is_unmounted &&
            <Col span={7} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                <Space direction='vertical'>
                    <Text>Data caricamento</Text>
                    <strong>{belatedEmitted.unmounting_date && dayjs(belatedEmitted.unmounting_date).format('DD/MM/YYYY')}</strong>
                </Space>
            </Col>}
        {belatedEmitted.is_unmounted &&
            <Col span={8} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                <Space direction='vertical'>
                    <Text>Importo premio effettivo</Text>
                    <strong> <AntCurrencyFormatInput displayType='text'
                                                     value={belatedEmitted.installment_premium_actual}/></strong>
                </Space>
            </Col>}
        {belatedEmitted.is_unmounted &&
            <Col span={7} style={{marginBottom: '1rem', padding: '0 0.5rem'}}>
                <Space direction='vertical'>
                    <Text>Numero certificato</Text>
                    <Text strong>{belatedEmitted.certificate_number ? belatedEmitted.certificate_number :
                        <span
                            style={{textDecoration: 'underline'}}>Non presente</span>}</Text>
                </Space>
            </Col>}
      </Row>
    </Col>
  </Row>
}