import React, {useState} from "react";
import {BrokerModel} from "../../types/broker";
import {Button, Col, Divider, Form, Input, InputNumber, Radio, Rate, Row} from "antd";
import {useForm} from "antd/lib/form/Form";

export default function EditBrokerForm({broker, onsubmit, onClose}: {broker: BrokerModel, onsubmit: (values: BrokerModel)=> void, onClose?: ()=> void}) {
    const [form] = useForm()
    const [isValuesChanged, setIsValuesChanged] = useState<boolean>(false)

    function handleSubmit(values: any) {
        if (isValuesChanged && values?.brokerName && values.brokerName.trim() !== "") {
            const parsedValues: BrokerModel = {...values}
            onsubmit(parsedValues)
        }
    }

    return <div>
        <Form
            form={form}
            initialValues={{
                brokerName: broker.name,
                relationshipQuality: broker.relationship_quality,
            }}
            name={"brokerData"}
            layout={"vertical"}
            onFinish={(values) => handleSubmit(values)}
            onFieldsChange={(changedFields, allFields) => {
                setIsValuesChanged(allFields[0]?.value.trim() !== "")
            }}
        >
            <Row justify='space-between' gutter={[8, 8]}>
                <Col xs={24} md={17}>
                    <Form.Item label="Nome broker" required name='brokerName'>
                        <Input placeholder="Nome broker"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={17}>
                    <Form.Item label="Valutazione broker" name='relationshipQuality'>
                        <Rate/>
                    </Form.Item>
                </Col>
            </Row>
            <Divider/>
      <Row justify={'space-between'}>
        <Col>{!!onClose && <Button type={"default"} onClick={() => onClose()}>Annulla</Button>}</Col>
        <Col><Button htmlType={"submit"} disabled={!isValuesChanged}
                     type={'primary'}>Salva modifiche</Button></Col>
      </Row>
        </Form>
    </div>
}