import SubmissionReceivedGraph from "../SubmissionReceivedGraph";
import dayjs from "dayjs";
import {skipToken} from "@reduxjs/toolkit/query";
import {capitalize} from "lodash";
import {useGetBBBSubmissionReceptionQuery} from "../../../redux/api/chartsApiSlice";
import {useBankBranchChartsParamsContext} from "../../../context/BankBranchChartsContext";
import {ChartEntity} from "../../../types/charts";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BankBranchSubmissionReceived() {

    const {params} = useBankBranchChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const paramsAreOk = !!(params.bank_branch_uuid && params.budget_group && typeof uwYear === 'number')

    const {data, isFetching, isUninitialized} = useGetBBBSubmissionReceptionQuery(paramsAreOk ? {
        entity: ChartEntity.BANK_BRANCH,
        entity_uuid: params.bank_branch_uuid,
        uw_year: uwYear,
        budget_group: params.budget_group,
    } : skipToken)

    return <SubmissionReceivedGraph
        loading={isFetching || isUninitialized}
        months={data?.map(el => capitalize(dayjs(el.month).format('MMM'))) || []}
        meetings={data?.map(el => el.meeting_notes) || []}
        submissions={data?.map(el => el.negotiations) || []}
    />
}