import {Col, Divider, Row} from "antd";
import 'antd/es/spin/style/css';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {euro} from "utils/formatters";
import HighchartsReact from "highcharts-react-official";
import ChartContainer from "./ChartContainer";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import {RenewRetention} from "../../../types/charts";
import {ContainerProps} from "../../../types/negotiations/components";

function Description() {
    return <>
        Il grafico mostra, sulle trattative in rinnovo, la percentuale dei bound (come sommatoria dei premi) rispetto il
        totale che ha già superato la data di decorrenza.
        <dl>
            <dt>Premi rinnovabili:</dt>
            <dd>rappresenta la sommatoria dei premi che possono essere rinnovati.</dd>
            <dt>Rinnovo medio (90%):</dt>
            <dd>rappresenta il rinnovo medio target.</dd>
            <dt>Premi rinnovati chiusi con successo:</dt>
            <dd>rappresenta la sommatoria dei premi già rinnovati con successo.</dd>
            <dt>Premi da rinnovare:</dt>
            <dd>rappresenta la differenza tra i premi rinnovabili e quelli rinnovati con successo.</dd>
        </dl>
    </>
}

export default function RenewRetentionCommon(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, data?: RenewRetention, loading: boolean, subtitle?: string, containerProps?: ContainerProps }) {
    const title = "Retention su rinnovi"
    const {data, loading, subtitle, containerProps} = props
    const percentageToDisplay = data?.percentage ? (data?.percentage * 100).toFixed(2) : '0'

    return <ChartContainer
        title={title}
        loading={loading}
        description={<Description />}
        /*buttons={<DownloadButton chartRef={props.chartRef}/>}*/
        {...containerProps}
    >
        <BarPercentageChart chartRef={props.chartRef} options={{
            exporting: {
                chartOptions: {
                    title: {
                        text: title,
                    },
                    subtitle: {
                        text: subtitle
                    },
                    chart: {
                        height: 150,
                        spacing: [10, 10, 15, 10],
                        // margin: [undefined, undefined, undefined, undefined]
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true,
                                x: 0,
                                y: -25
                            },
                        },
                    },

                },
            },
            series: [
                {
                    type: 'bar',
                    stacking: 'percent',
                    data: [Number(percentageToDisplay) > 100 ? 0 : 100 - Number(percentageToDisplay) ],
                    color: '#F5F5F5',
                    dataLabels: {
                        enabled: false,
                    },
                },
                {
                    type: 'bar',
                    stacking: 'percent',
                    data: Array.from({length: 1}, () => Number(percentageToDisplay)),
                    color: '#BEA8FB',
                    dataLabels: {
                        enabled: true,
                        format: `{y:.2f}%`
                    },
                },

            ]
        }}/>
        <Divider style={{marginTop: 0, marginBottom: '12px'}}/>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi rinnovabili:</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.renewables === 'number' && euro(data?.renewables)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Rinnovo medio (90%):</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.average_renewables === 'number' && euro(data?.average_renewables)}</Col>

        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi rinnovati chiusi con successo:</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.amount === 'number' && euro(data?.amount)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi non rinnovati:</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.expired === 'number' && euro(data?.expired)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi da rinnovare:</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.to_be_renew === 'number' && euro(data?.to_be_renew)}</Col>
        </Row>
    </ChartContainer>
}