import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from 'react';
import {Model} from "survey-core";
import {SurveyPageContextProvider} from "./SurveyPageContext";

export type SurveyContextType = [
  survey: Model | undefined,
  setSurvey: Dispatch<SetStateAction<Model | undefined>>
]

const surveyContext = createContext<SurveyContextType>([
  undefined,
  () => null,
]);

export const useSurveyContext = () => useContext(surveyContext);

export const SurveyContext = (props: PropsWithChildren & {isAdditionalInsuredVisible: boolean}) => {
  const [survey, setSurvey] = useState<Model | undefined>();

  return (
    <surveyContext.Provider value={[survey, setSurvey]}>
      <SurveyPageContextProvider isAdditionalInsuredVisible={props.isAdditionalInsuredVisible}>
        {props.children}
      </SurveyPageContextProvider>
    </surveyContext.Provider>
  );
};