import SubmissionReceivedGraph from "../SubmissionReceivedGraph";
import dayjs from "dayjs";
import {skipToken} from "@reduxjs/toolkit/query";
import {capitalize} from "lodash";
import {useBrokersChartsParamsContext} from "../../../context/BrokersChartsContext";
import {useGetBBBSubmissionReceptionQuery} from "../../../redux/api/chartsApiSlice";
import {ChartEntity} from "../../../types/charts";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokersSubmissionReceived() {

    const {params} = useBrokersChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const paramsAreOk = !!(typeof uwYear === 'number' && params.budget_group)

    const {data, isFetching, isUninitialized} = useGetBBBSubmissionReceptionQuery(paramsAreOk ? {
        entity: ChartEntity.BROKER,
        uw_year: uwYear,
        budget_group: params.budget_group,
    } : skipToken)

    return <SubmissionReceivedGraph
        loading={isFetching || isUninitialized}
        months={data?.map(el => capitalize(dayjs(el.month).format('MMM'))) || []}
        meetings={data?.map(el => el.meeting_notes) || []}
        submissions={data?.map(el => el.negotiations) || []}
    />
}