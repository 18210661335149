import React, {useEffect, useState} from "react";
import {useGetSingleBankBranchQuery} from "../../redux/api/commercialDetailApiSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";
import {LeftOutlined} from "@ant-design/icons";
import {Affix, Col, Divider, message, Row, Space} from "antd";
import BudgetGroupsCards from "../../components/commercialDetail/BudgetGroupsCards";
import NegotiationsDetail from "../../components/commercialDetail/NegotiationsDetail";
import MeetingNotes from "../../components/commercialDetail/MeetingNotes";
import QuarterHitRatioGraph from "../../components/commercialDetail/QuarterHitRatioGraph";
import AnnualHitRatioGraph from "../../components/commercialDetail/AnnualHitRatioGraph";
import TotalBankBranchesNegotiations from "../../components/commercialDetail/bankBranch/TotalBankBranchesNegotiations";
import TotalBankBranchesPremiums from "../../components/commercialDetail/bankBranch/TotalBankBranchesPremiums";
import {useGetAllBudgetGroupsQuery} from "../../redux/api/budgetsApiSlice";
import {useBankBranchChartsParamsContext} from "../../context/BankBranchChartsContext";
import BankBranchSubmissionReceived from "../../components/commercialDetail/bankBranch/BankBranchSubmissionReceived";
import BankBranchSubmissionsTrend from "../../components/commercialDetail/bankBranch/BankBranchSubmissionsTrend";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import SearchBankBranch from "../../components/commercialDetail/bankBranch/SearchBankBranch";
import {ALL} from "../../context/BrokerChartsContext";
import UwYearDatePicker from "../../components/select/UwYearDatePicker";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";

export default function BranchDetail() {
    const navigate = useNavigate()
    const {id: bankBranchId} = useParams()
    const {params, setParams} = useBankBranchChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = !!(params.bank_branch_uuid && typeof uwYear === 'number')

    const [isAffix, setIsAffix] = useState(false)
    const {xs} = useBreakpoint()

    const {
        data: branchData,
        isFetching: isFetchingBranchData,
        isError: isErrorBranchData,
        error: errorBranchData
    } = useGetSingleBankBranchQuery(bankBranchId ? {uuid: bankBranchId} : skipToken)
    const {
        data: budgetGroupOptions,
        isFetching: isFetchingBudgetGroupOptions,
        isError: isErrorBudgetGroupOptions
    } = useGetAllBudgetGroupsQuery(areParamsOk ? {
        entity: 1,
        uw_year: uwYear,
        entity_uuid: params.bank_branch_uuid
    } : skipToken)

    useEffect(() => {
            if (bankBranchId && !isFetchingBranchData && !isErrorBranchData) {
                setParams(prevState => ({
                    ...prevState,
                    bank_branch_uuid: bankBranchId
                }))
            }
            if (isErrorBranchData && "status" in errorBranchData) {
                if (errorBranchData?.status === 404) {
                    navigate('/branches')
                    message.error('Filiale non esistente')
                }
            }
        }, [bankBranchId, errorBranchData, isErrorBranchData, isFetchingBranchData, navigate, setParams]
    );

    return <div style={{padding: xs ? 0 : "1rem"}}>
        <Link to={"/branches"}>
            <Space>
                <LeftOutlined style={{color: '#1890FF'}}/>
                <p style={{color: '#1890FF'}}>Torna alla Dashboard generale</p>
            </Space>
        </Link>
        <Affix
            offsetTop={0}
            onChange={a => setIsAffix(!!a)}>
            <Row
                style={isAffix ? {
                    padding: '16px',
                    borderRadius: '12px',
                    boxShadow: '0px 10px 15px -10px #AAAAAA',
                    backgroundColor: '#ffffff',
                } : {}}
                justify={"end"}
                align={"middle"}
                gutter={[16, 16]}>
                <Col flex={1} xs={isAffix ? 0 : undefined}>
                    <Space wrap>
                        <h1>{isFetchingBranchData ? '' : branchData?.name}</h1>
                        <SearchBankBranch/>
                    </Space>
                </Col>
                <Col>
                    <UwYearDatePicker/>
                </Col>
                <Col xs={24}>
                    <BudgetGroupsCards
                        loading={isFetchingBudgetGroupOptions}
                        options={(isErrorBudgetGroupOptions || !budgetGroupOptions) ? [] : budgetGroupOptions}
                        selectedOption={params.budget_group}
                        setSelectedOption={(value: string) => {
                            setParams(prevState => ({
                                ...prevState,
                                budget_group: value
                            }))
                        }}
                    />
                </Col>
            </Row>
        </Affix>
        <Divider/>
        <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <BankBranchSubmissionReceived key={params.bank_branch_uuid}/>
            </Col>
            <Col xs={24} md={12}>
                {areParamsOk && <MeetingNotes
                    key={params.bank_branch_uuid}
                    params={{
                        bank_branch: params.bank_branch_uuid,
                        ...params.budget_group !== ALL && {budget_group: params.budget_group},
                        uw_year: uwYear,
                    }}
                />}
            </Col>
            <Col xs={24} md={12}>
                <TotalBankBranchesNegotiations key={params.bank_branch_uuid}/>
            </Col>
            <Col xs={24} md={12}>
                <BankBranchSubmissionsTrend key={params.bank_branch_uuid}/>
            </Col>
            <Col xs={24} md={12}>
                <TotalBankBranchesPremiums key={params.bank_branch_uuid}/>
            </Col>
            <Col xs={24} md={12}>
                {areParamsOk && <QuarterHitRatioGraph
                    key={params.bank_branch_uuid}
                    uw_year={uwYear}
                    entity={1}
                    entity_uuid={params.bank_branch_uuid}
                    budget_group={params.budget_group}
                />}
            </Col>
            <Col xs={24} md={12}>
                {areParamsOk && <AnnualHitRatioGraph
                    key={params.bank_branch_uuid}
                    uw_year={uwYear}
                    entity={1}
                    entity_uuid={params.bank_branch_uuid}
                    budget_group={params.budget_group}
                />}
            </Col>
        </Row>
        <Divider/>
        {areParamsOk && <NegotiationsDetail
            key={params.bank_branch_uuid}
            uuid={params.bank_branch_uuid}
            filterKey={"bank_branch"}
            budget_group={params.budget_group}
            uw_year={uwYear}
        />}
    </div>
}