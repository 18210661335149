import React, {useMemo, useRef} from "react";
import {Row} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import dayjs from "dayjs";
import {capitalize} from "lodash";
import {SubmissionTrendResponse} from "../../types/charts";
import BBBChartCardContainer from "./BBBChartCardContainer";

export default function SubmissionsTrend({data, loading}: {
    data?: SubmissionTrendResponse,
    loading?: boolean
}) {
    const ref = useRef<HighchartsReact.RefObject>(null);

    const chartOptions = useMemo(() => {
        const series = []
        let months
        if (data) {
            for (const [key, options] of Object.entries(data)) {
                months = options.map(el => capitalize(dayjs(el.month).format('MMM')))
                series.push({name: key, data: options.map(el => el.negotiations), yAxis: 0})
            }
        }
        return {
            chart: {
                type: 'column',
                height: "45%"
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: ""
            },
            xAxis: [{
                categories: months,
                accessibility: {
                    description: 'Months'
                }
            }],

            yAxis: [{
                min: 0,
                title: {
                    text: 'Submission',
                },
                allowDecimals: false,
            }],
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    borderRadius: 5
                }
            },
            series,
            exporting: {
                enabled: false
            },
            credits: undefined,
        }
    }, [data])

    return <BBBChartCardContainer loading={loading}>
        <Row justify={"center"}>
            <h2>Andamento submission</h2>
        </Row>
        <Row justify={"center"}>
            <h5 style={{color: "#b0aeae"}}>Ricezione Submission ultimi 3 anni</h5>
        </Row>
        <HighchartsReact
            highcharts={Highcharts}
            ref={ref}
            options={chartOptions}
        />
    </BBBChartCardContainer>
}