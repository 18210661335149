import {Button, Col, Modal, Row, Select} from "antd";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import React, {useEffect, useState} from "react";
import {NegotiationModel, UpdateNegotiationRequest} from "../../types/negotiations";
import {useUpdateNegotiationMutation} from "../../redux/api/negotiationsApiSlice";

type EditLobModalProps = {
  isOpen: boolean,
  onClose: () => void,
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
}

export default function EditLobModal({
                                       isOpen,
                                       onClose,
                                       negotiation,
                                       updateNegotiation
                                     }: EditLobModalProps) {


  const options = useSelector(selectOptions)
  const [lob, setLob] = useState<string>()
  const [selectLobOptions, setSelectLobOptions] = useState<{ label: string, value: string }[]>([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (options) {
      const selectOptions = options.lobs.map(el => ({
        label: el.name,
        value: el.uuid
      }))
      if (selectOptions.length) {
        setSelectLobOptions(selectOptions)
        setLob(negotiation.lob.uuid)
      }
    }
  }, [negotiation.lob.uuid, options])

  return <Modal
      title="Modifica LOB"
      centered
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={
        <Row align='middle' justify="space-between">
          <Col>
            <Button onClick={onClose}>
              Annulla
            </Button>
          </Col>
          <Col>
            <Button
                type='primary'
                loading={isLoading}
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true)
                  const resetFund = !!negotiation?.lob.has_funds && !options.lobs.find(l => l.uuid === lob)?.has_funds

                  updateNegotiation({
                    lob: lob,
                    // attention: uw assignment isn't possibile in RORE state
                    underwriter: null,
                    attorney_from: null,
                    product_type: null,
                    branches: {},
                    ...{...resetFund ? {fund: null, hasFund: false} : {}}
                  })
                      .finally(() => {
                        setIsLoading(false)
                        onClose();
                      })
                }}>
              Modifica
            </Button>
          </Col>
        </Row>
      }
  >
    <Select
        style={{width: '100%'}}
        options={selectLobOptions}
        value={lob}
        virtual={false}
        onSelect={(value: string) => {
          setLob(value)
        }}
    />
  </Modal>
}