import 'antd/es/spin/style/css';
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import HighchartsReact from "highcharts-react-official";
import {useLazyGetRenewRetentionChartQuery} from "../../../redux/api/chartsApiSlice";
import {selectUser} from "../../../redux/features/userSlice";
import RenewRetentionCommon from "./RenewRetentionCommon";

export default function UnderwriterDashboardRenewRetention(props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) {
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetRenewRetentionChartQuery()

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    return <RenewRetentionCommon
        chartRef={props.chartRef}
        data={data}
        loading={isLoading || isFetching}
        subtitle={`Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}, Premi rinnovabili: ${data?.renewables}, Rinnovo medio (90%): ${data?.average_renewables}, Premi rinnovati con successo: ${data?.amount}`}
    />
}