import {Table} from "antd";
import {useCallback, useEffect, useState} from "react";
import {dataQueryResult, TableDataType, TableHeader} from "../../../types/tableDataType";
import {euro, numeric} from "../../../utils/formatters";
import {TableSorter} from "../../../types";
import {ColumnType} from "antd/lib/table";


export default function NegotiationsDividedByStateTable(props: { negotiation: string, data: dataQueryResult | undefined, isLoading: boolean }) {

    const [antColumns, setAntColumns] = useState<ColumnType<any>[]>([])


    const setRecursiveColumns: (columns: TableHeader[], sorters?: TableSorter[]) => ColumnType<any>[] = useCallback((columns, sorters) => {
        if (columns.length === 0) {
            return []
        } else {
            return columns.map(col => {
                if (!col.children || col.children.length === 0) {
                    const sorter = sorters?.find(s => s.key === col.dataIndex) ? sorters?.find(s => s.key === col.dataIndex)?.type : undefined
                    const sorterPriority = sorters?.findIndex(s => s.key === col.dataIndex) || -1
                    if (col.dataIndex === 'column0') {
                        return {
                            ...col,
                            key: col.dataIndex,
                            sorter: {
                                compare: (a: any, b: any) => {
                                    return a[col.dataIndex].localeCompare(b[col.dataIndex])
                                },
                                sortOrder: sorter,
                                multiple: sorterPriority,
                            },
                            sortOrder: sorter,
                            children: []
                        }

                    } else {
                        return {
                            ...col,
                            key: col.dataIndex,
                            align: 'right',
                            sorter: {
                                compare: (a: any, b: any) => {
                                    return +a[col.dataIndex] - +b[col.dataIndex]
                                },
                                multiple: sorterPriority,
                                sortOrder: sorter,
                            },
                            sortOrder: sorter,
                            children: [],
                            render: (value) => {
                                if (props.negotiation === 'true') {
                                    return numeric(value)
                                } else {
                                    return euro(value)
                                }
                            }
                        }
                    }
                } else {
                    return {
                        ...col,
                        key: col.dataIndex,
                        align: 'center',
                        children: setRecursiveColumns(col.children, sorters)
                    }
                }
            })
        }
    }, [props.negotiation])


    useEffect(() => {
        if (props.data?.header) {
            setAntColumns(setRecursiveColumns(props.data?.header))
        }
    }, [props.data?.header, setRecursiveColumns])


    return <div className={'specific-broker-data--table'}><Table
        loading={props.isLoading}
        columns={antColumns}
        dataSource={props.data?.values.map((el, idx) => ({...el, key: idx}))}
        scroll={{x: true}}
        bordered={true}
        onChange={(pagination, filters, sorters, extra) => {
            if (extra.action === 'sort') {
                let currentSorters: TableSorter[] = []
                if (Array.isArray(sorters)) {
                    currentSorters = sorters.map(el => ({
                        key: el.field as string,
                        type: el.order as 'ascend' | 'descend'
                    }))

                } else {
                    if (sorters.order === undefined) {
                        currentSorters = []
                    } else {
                        currentSorters = [{
                            key: sorters.field as string,
                            type: sorters.order as 'ascend' | 'descend'
                        }]
                    }

                }
                if (props.data?.header)
                    setAntColumns(setRecursiveColumns(props.data?.header, currentSorters))

            }
        }}


    >
    </Table></div>
}