import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from "react";
import {ALL} from "./BrokerChartsContext";

type Params = {
    bank_branch_uuid: string,
    budget_group: string,
}

type BankBranchChartsParamsType = {
    params: Params,
    setParams: Dispatch<SetStateAction<Params>>
}

const defaultValues: Params = {
    bank_branch_uuid: "",
    budget_group: ALL,
}

const BankBranchCharts = createContext<BankBranchChartsParamsType>({
    params: defaultValues,
    setParams: () => undefined
})

export const useBankBranchChartsParamsContext = () => useContext(BankBranchCharts)

export default function BankBranchChartsParamsContext(props: PropsWithChildren) {

    const [params, setParams] = useState<Params>(defaultValues)

    return <BankBranchCharts.Provider
        value={{params, setParams}}
    >
        {props.children}
    </BankBranchCharts.Provider>
}