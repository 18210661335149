import React, {useMemo, useRef, useState} from "react";
import {Col, Row, Switch} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {skipToken} from "@reduxjs/toolkit/query";
import {ALL, useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import {euro} from "../../../utils/formatters";
import {CalculationType, ChartEntity, NegotiationsType} from "../../../types/charts";
import {useGetBBBTotalNegotiationsQuery} from "../../../redux/api/chartsApiSlice";
import BBBChartCardContainer from "../BBBChartCardContainer";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokerNegotiationsPremiums() {
    const ref1 = useRef<HighchartsReact.RefObject>(null);
    const ref2 = useRef<HighchartsReact.RefObject>(null);

    const [calculationType, setCalculationType] = useState<1 | 2>(1)

    const {params} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year

    const isCommonParamsOk = !!(typeof uwYear === 'number' && params.budget_group && params.broker_uuid)

    const {
        data: newBusinessData,
        isFetching: isFetchingNewBusinessData,
        isUninitialized: isUninitializedNewBusinessData
    } = useGetBBBTotalNegotiationsQuery(isCommonParamsOk ? {
        uw_year: uwYear,
        entity: ChartEntity.BROKER,
        entity_uuid: params.broker_uuid,
        budget_group: params.budget_group,
        calculation_type: calculationType,
        ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
        negotiations_type: NegotiationsType.NEW_BUSINESS
    } : skipToken)
    const {
        data: activePortfolioData,
        isFetching: isFetchingActivePortfolioData,
        isUninitialized: isUninitializedActivePortfolioData
    } = useGetBBBTotalNegotiationsQuery(isCommonParamsOk ? {
        uw_year: uwYear,
        entity: ChartEntity.BROKER,
        entity_uuid: params.broker_uuid,
        budget_group: params.budget_group,
        calculation_type: calculationType,
        ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
        negotiations_type: NegotiationsType.ACTIVE_PORTFOLIO
    } : skipToken)

    const loading = isFetchingActivePortfolioData || isFetchingNewBusinessData || isUninitializedActivePortfolioData || isUninitializedNewBusinessData

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"}>
            <h3>{calculationType === CalculationType.NEGOTIATIONS_COUNT ? 'Trattative' : 'Premi'}</h3>
            <Switch
                size={"small"}
                checked={calculationType === CalculationType.NEGOTIATIONS_COUNT}
                onChange={() => setCalculationType(prevState => prevState === CalculationType.NEGOTIATIONS_COUNT ? CalculationType.PREMIUMS : CalculationType.NEGOTIATIONS_COUNT)}
                checkedChildren={"Trattative"}
                unCheckedChildren={"Premi"}
                style={{width: "77px"}}
            />
        </Row>
    }, [calculationType])

    const optionsNewBusiness = useMemo(() => ({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ""
        },
        legend: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        credits: undefined,
        xAxis: {
            categories: newBusinessData?.history.map(el => el.year),
            crosshair: true,
            accessibility: {
                description: 'Anno'
            }
        },
        yAxis: {
            title: {
                text: ''
            },
            allowDecimals: calculationType === CalculationType.PREMIUMS,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                borderRadius: 5,
                colorByPoint: true,
                dataLabels: {
                    enabled: true,
                    formatter: function (): string {
                        // @ts-ignore
                        return calculationType === CalculationType.NEGOTIATIONS_COUNT ? this.y : (euro(this.y) || "")
                    }
                }
            }
        },
        series: [
            {
                name: 'New Business',
                data: newBusinessData?.history.map(el => el.amount),
                colors: ['#008652', '#E98A42', '#DEB630']
            }
        ],
        tooltip: {
            formatter: function (): string {
                // @ts-ignore
                const value = calculationType === CalculationType.PREMIUMS ? euro(this.y ? this.y : 0) : this.y
                // @ts-ignore
                return `<span>${this.x}</span>: <span style='font-weight: bolder'>${value}</span>`
            }
        },
    }), [calculationType, newBusinessData?.history])
    const optionsPortfolio = useMemo(() => ({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ""
        },
        legend: {
            enabled: false
        },
        exporting: {
            enabled: false
        },
        tooltip: {
            formatter: function (): string {
                // @ts-ignore
                const value = calculationType === CalculationType.PREMIUMS ? euro(this.y ? this.y : 0) : this.y
                // @ts-ignore
                return `<span>${this.x}</span>: <span style='font-weight: bolder'>${value}</span>`
            }
        },
        credits: undefined,
        xAxis: {
            categories: activePortfolioData?.history.map(el => el.year),
            crosshair: true,
            accessibility: {
                description: 'Anno'
            }
        },
        yAxis: {
            title: {
                text: ''
            },
            allowDecimals: calculationType === CalculationType.PREMIUMS,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                borderRadius: 5,
                colorByPoint: true,
                dataLabels: {
                    enabled: true,
                    formatter: function (): string {
                        // @ts-ignore
                        return calculationType === CalculationType.NEGOTIATIONS_COUNT ? this.y : (euro(this.y) || "")
                    }
                }
            }
        },
        series: [
            {
                name: 'Portafoglio in essere',
                data: activePortfolioData?.history.map(el => el.amount),
                colors: ['#008652', '#E98A42', '#DEB630']
            }
        ]
    }), [activePortfolioData?.history, calculationType])

    return <BBBChartCardContainer
        title={cardTitle}
        loading={loading}
    >
        <Row>
            <Col xs={24} lg={12}>
                <Row justify={"center"}>
                    <h3>New Business</h3>
                </Row>
                <HighchartsReact
                    highcharts={Highcharts}
                    ref={ref1}
                    options={optionsNewBusiness}
                />
            </Col>
            <Col xs={24} lg={12}>
                <Row justify={"center"}>
                    <h3>Portafoglio in essere</h3>
                </Row>
                <HighchartsReact
                    highcharts={Highcharts}
                    ref={ref2}
                    options={optionsPortfolio}
                />
            </Col>
        </Row>
    </BBBChartCardContainer>
}