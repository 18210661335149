import {useSurveyPage} from "../../context/SurveyPageContext";
import {Button, Col, Row} from "antd";

const SurveyButtons = () => {
  const {page, pagesCount, goNext, goBack} = useSurveyPage();

  return (
    <Row gutter={[16, 16]} style={{marginTop: '1rem'}}>
      {(pagesCount !== undefined && page > 0) && <Col>
        <Button onClick={goBack}>
          Indietro
        </Button>
      </Col>}
      {(pagesCount !== undefined && page < (pagesCount - 1)) && <Col>
        <Button type={'primary'} onClick={goNext}>
          Avanti
        </Button>
      </Col>}
    </Row>
  );
}

export default SurveyButtons;