import {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect, useMemo,
    useState
} from 'react';
import {useSurveyContext} from "./SurveyContext";

export type PageType = 'generalData' | 'additionalInsured' | 'surveyJs'

export type SurveyPageContextType = {
    page: number,
    setPage: Dispatch<SetStateAction<number>>,
    pagesCount: number | undefined,
    goBack: () => void,
    goNext: () => void,
    staticSurveyPagesNumber: number | undefined,
    pageType: PageType,
    isAdditionalInsuredVisible: boolean | undefined,
}

const surveyPageContext = createContext<SurveyPageContextType>({
    page: 0,
    setPage: () => {},
    pagesCount: undefined,
    goBack: () => {},
    goNext: () => {},
    staticSurveyPagesNumber: undefined,
    pageType: 'generalData',
    isAdditionalInsuredVisible: undefined,
});

export const useSurveyPage = () => useContext(surveyPageContext);

export const SurveyPageContextProvider = (props: PropsWithChildren & {isAdditionalInsuredVisible: boolean}) => {
    const [page, setPage] = useState<number>(0);
    const [pagesCount, setPagesCount] = useState<number>()
    const [survey,] = useSurveyContext();
    const staticSurveyPagesNumber = props.isAdditionalInsuredVisible ? 2 : 1
    const pageType: PageType = useMemo(() => {
        switch (page) {
            case 0:
                return 'generalData'
            case 1:
                return props.isAdditionalInsuredVisible ? 'additionalInsured' : 'surveyJs'
            default:
                return 'surveyJs'
        }
    }, [page, props.isAdditionalInsuredVisible])

    useEffect(() => {
        if (survey) {
            setPagesCount(survey.visiblePageCount + staticSurveyPagesNumber)
        }
    }, [staticSurveyPagesNumber, survey]);

    useEffect(() => {
        if (survey && page >= staticSurveyPagesNumber) {
            survey.visiblePages.forEach((el, idx) => {
                if (idx === (page - staticSurveyPagesNumber)) {
                    survey.currentPage = survey.getPageByName(el.name);
                }
            })
        }
    }, [page, staticSurveyPagesNumber, survey]);

    const handleNext = useCallback(() => {
        setPage(prevState => {
            return (pagesCount !== undefined && prevState < (pagesCount - 1)) ? prevState + 1 : prevState
        })
    }, [pagesCount])

    const handleBack = useCallback(() => {
        setPage(prevState => {
            return (pagesCount !== undefined && prevState > 0) ? prevState - 1 : prevState
        })
    }, [pagesCount])

    return (
        <surveyPageContext.Provider value={{
            page,
            setPage,
            pagesCount,
            staticSurveyPagesNumber,
            goBack: handleBack,
            goNext: handleNext,
            pageType,
            isAdditionalInsuredVisible: props.isAdditionalInsuredVisible
        }}>
            {props.children}
        </surveyPageContext.Provider>
    );
};