import {Select, SelectProps} from "antd";
import {useGetLegalFormsQuery} from "../../redux/api/additionalInsured";

export default function SelectLegalForm(selectProps?: SelectProps) {

    const {data: options} = useGetLegalFormsQuery()

    return <Select
        options={options}
        {...selectProps}
    />
}