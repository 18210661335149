import { apiSlice } from "redux/api/baseApiSlice";
import {GetPendingEmails, GetPendingEmailsParams} from "../../types/pendingEmails";

export const pendingEmailsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPendingEmails : builder.query<GetPendingEmails, GetPendingEmailsParams>({
            query: (args) => {
                return {
                    url: 'negotiation/pending_emails/',
                    method: 'GET',
                    params: args
                }

            }
        }),
        deletePendingEmail: builder.mutation<void, { pending_email_uuid: string }>({
            query: ({pending_email_uuid}) => {
                return {
                    url: `negotiation/pending_emails/${pending_email_uuid}/archive/`,
                    method: 'POST',
                }
            }
        }),
    })
})

export const {useLazyGetPendingEmailsQuery, useDeletePendingEmailMutation} = pendingEmailsApiSlice