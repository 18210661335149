import {CheckCircleOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {useState} from "react";
import {EditReferredModal} from "./state";
import {NegotiationModel} from "../../../../../types/negotiations";

const EditReferredModalButton = ({negotiation, handleCreateStateTransition, isStateTransitionInProgress}: {
  negotiation: NegotiationModel,
  handleCreateStateTransition: (data: {
    state_to: number,
    reason?: string,
    notes?: string
  }, newNegotiation?: NegotiationModel) => Promise<void>,
  isStateTransitionInProgress: boolean,
}) => {

  const [
    isReferred,
    setIsReferred
  ] = useState<boolean>(false);

  return (
    <>
      <EditReferredModal
        negotiationId={negotiation.uuid}
        isOpen={isReferred}
        onClose={() => setIsReferred(false)}
        handleCreateStateTransition={handleCreateStateTransition}
        isCreateStateTransitionLoading={isStateTransitionInProgress}/>
      <Button
        block
        onClick={() => setIsReferred(true)}
        disabled={isStateTransitionInProgress}
        icon={<CheckCircleOutlined/>}>
        Richiedi referral
      </Button>
    </>
  );
}

export default EditReferredModalButton;