//used for advanced search results, these are columns that have a range of values
//and/or needs a suffix like € or %
export const specialFields = [
    {
        system: "attachment_point",
        suffix: "€"
    },
    {
        system: "isa_fee",
        suffix: "€"
    },
    {
        system: "isa_quoted_premium_share",
        suffix: "€"
    },
    {
        system: "policy_insured_sum",
        suffix: "€"
    },
    {
        system: "saved_premium",
        suffix: "€"
    },
    {
        system: "reinsurer_broker_fee_perc",
        suffix: "%"
    },
    {
        system: "reinsured_share_perc",
        suffix: "%"
    },
    {
        system: "policy_effective_date",
        suffix: ""
    },
    {
        system: "policy_expiring_date",
        suffix: ""
    },
    {
        system: "reception_date",
        suffix: ""
    },
    {
        system: "uw_year",
        suffix: ""
    },
];
