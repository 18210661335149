import {useSurveyPage} from "../../context/SurveyPageContext";
import NegotiationAdditionalInsuredList from "../additionalInsureds/NegotiationAdditionalInsuredList";
import React from "react";
import {useParams} from "react-router-dom";
import {Form, message, Radio, Typography} from "antd";
import {useGetNegotiationDetailQuery, useUpdateNegotiationMutation} from "../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";

export default function AdditionalInsuredsCoverageConfirmation({disabled}: {disabled?: boolean}) {

    const {pageType} = useSurveyPage()
    const {id} = useParams()

    const {data: negotiation} = useGetNegotiationDetailQuery(id ?? skipToken)
    const [update] = useUpdateNegotiationMutation()

    return <>
        {pageType === 'additionalInsured' && <>
            <Typography.Title
                level={3}
                style={{marginBottom: '2rem'}}>
                Assicurati addizionali
            </Typography.Title>
            <Form.Item
                key={negotiation?.uuid}
                label="Gestione con certificati per gli assicurati addizionali">
                <Radio.Group
                    disabled={disabled}
                    defaultValue={negotiation?.policy_with_certificates}
                    onChange={(v) => {
                        if (negotiation?.uuid) {
                            update({id: negotiation?.uuid, data: {policy_with_certificates: v.target.value}})
                                .then(() => {
                                    message.success('Dati aggiornati')
                                })
                                .catch(() => {
                                    message.error('Impossibile aggiornare i dati')

                                })
                        }
                    }}
                >
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            {!!negotiation?.policy_with_certificates &&
                <NegotiationAdditionalInsuredList negotiationUuid={negotiation?.uuid} disabled={disabled}/>}
        </>}
    </>
}