import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {Result} from "antd";

export default function TransitionFromRiskApp() {

  let {token} = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      localStorage.setItem('accessToken', token)
      navigate('/')
    }
  }, [navigate, token])


  return <>
    {!token && <Result status={"error"} title={'Credenziali non corrette'}
                       subTitle={'Token di autenticazione non presente'}/>}
  </>
}