import {apiSlice} from "redux/api/baseApiSlice";
import {BrokerBranchComparisonParams, BrokerBranchComparisonResponse,} from "../../types/brokerCharts";
import {GenericPaginatedApi} from "../../types";

export const brokersChartsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBrokerBranchComparison: builder.query<GenericPaginatedApi<BrokerBranchComparisonResponse>, BrokerBranchComparisonParams>({
            query: (args) => {
                const {broker_uuid, ...params} = args
                return {
                    url: `/negotiation/brokers/${broker_uuid}/brokerbranchcomparison/`,
                    params
                }
            }
        }),
    })
})

export const {
    useGetBrokerBranchComparisonQuery,
    useLazyGetBrokerBranchComparisonQuery,
} = brokersChartsApiSlice