import {apiSlice} from "redux/api/baseApiSlice";
import {
  BelatedEmittedBatchReport,
  BelatedEmittedBatchReportResponse
} from "../../types/belatedEmittedBatchReport";
import handleDownloadFile from "../../utils/handleDownloadFile";

export const belatedEmittedBatchReportApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getBelatedBatchReports: builder.query<BelatedEmittedBatchReportResponse, { page: number, page_size: number, reference_date_from?: string, reference_date_to?: string, result?: string, ordering?: string }>({
      query: (args) => {
        return {url: `/negotiation/batch_report/`, params: args}
      }
    }),
    getBelatedBatchReportsDownload: builder.query<Blob, { reference_date_from?: string, reference_date_to?: string, result?: string, ordering?: string }>({
      query: (args) => {
        return {
          url: `/negotiation/batch_report/download/`,
          responseHandler: (response) => handleDownloadFile(response),
          params: args,
          cache: "no-cache",
        }
      },

    }),
    getReexecuteProcess: builder.query<BelatedEmittedBatchReport, { batch_report_uuid: string }>({
      query: (args) => {
        const {batch_report_uuid} = args
        return {url: `/negotiation/batch_report/${batch_report_uuid}/redo/`}
      },
    }),
  })
})


export const {
  useLazyGetBelatedBatchReportsQuery,
  useLazyGetBelatedBatchReportsDownloadQuery,
  useLazyGetReexecuteProcessQuery
} = belatedEmittedBatchReportApiSlice
