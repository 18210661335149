import React from "react";
import {BelatedEmittedREVISITED} from "../../../../../types/negotiations";
import dayjs from "dayjs";
import _ from "lodash";
import {AntCurrencyFormatInput} from "../../../../inputNumber";
import {Checkbox, Col, Row, Space, Table, Tag, Typography} from "antd";

export default function BelatedEmittedTableBeforeAlignment({belatedEmitted}: {
  belatedEmitted: BelatedEmittedREVISITED[]
}) {

  const {Title} = Typography;

  const totalTitles = belatedEmitted.length
  const unmountedTitles = belatedEmitted.reduce((pv, curr) => pv + (curr.is_unmounted ? 1 : 0), 0)

  return <>
    <Row justify={'space-between'} align={'middle'}>
      <Col>
        <Title level={5}>PRIMA</Title>
      </Col>
      <Col>
        <span style={{fontStyle: 'italic'}}>Smontati {unmountedTitles} titoli su {totalTitles}</span>
      </Col>
    </Row>
    <Table
        <BelatedEmittedREVISITED>
        dataSource={belatedEmitted}
        pagination={false}
        rowKey={'uuid'}
        scroll={{x: true}}
        columns={[
          {
            key: 'installment_class',
            dataIndex: 'installment_class',
            title: 'Titolo',
            align: 'right',
            render: (value, _, idx) => {
              return <Space>
                {value === 'PRT' && <Tag color={'gold'}>Pro-rata</Tag>}
                {idx + 1}
              </Space>
            }
          },
          {
            key: 'installment_effective_date',
            dataIndex: 'installment_effective_date',
            title: 'Decorrenza',
            align: 'right',
            render: (value, record) => {
              if (value) {
                return <Space>
                  {dayjs(value).format('DD/MM/YYYY')}
                </Space>
              }
            }
          },
          {
            key: 'installment_expiring_date',
            dataIndex: 'installment_expiring_date',
            title: 'Scadenza',
            align: 'right',
            render: value => {
              if (value) {
                return dayjs(value).format('DD/MM/YYYY')
              }
            }
          },
          {
            key: 'installment_premium',
            dataIndex: 'installment_premium',
            title: 'Importo premio',
            align: 'right',
            render: value => {
              if (!_.isNil(value)) {
                return <AntCurrencyFormatInput displayType='text' value={value}/>
              }
            }
          },
          {
            key: 'installment_commission',
            dataIndex: 'installment_commission',
            title: 'Provvigioni',
            align: 'right',
            render: value => {
              if (!_.isNil(value)) {
                return <AntCurrencyFormatInput displayType='text' value={value}/>
              }
            }
          },
          {
            key: 'is_unmounted',
            dataIndex: 'is_unmounted',
            title: 'Smontato',
            align: 'right',
            render: value => {
              return <Checkbox checked={value} disabled/>
            }
          },
          {
            key: 'unmounting_date',
            dataIndex: 'unmounting_date',
            title: 'Data caricamento',
            align: 'right',
            render: value => {
              if (value) {
                return dayjs(value).format('DD/MM/YYYY')
              }
            }
          },
          {
            key: 'installment_premium_actual',
            dataIndex: 'installment_premium_actual',
            title: 'Importo premio effettivo',
            align: 'right',
            render: value => {
              if (!_.isNil(value)) {
                return <AntCurrencyFormatInput
                    displayType='text'
                    value={value}/>
              }
            }
          },
          {
            key: 'certificate_number',
            dataIndex: 'certificate_number',
            title: 'Numero certificato',
            align: 'right',
          },
        ]}
    />
  </>;
}