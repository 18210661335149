import {NegotiationFormModel, NegotiationModel} from "types/negotiations"
import _, {cloneDeep} from "lodash"
import {normalizePercTo1} from "./dataParser"
import {LobModel} from "types/options"
import {roundNumber} from "utils/formatters"
import {ReinsuranceType} from "../../types/reinsuranceType";

//https://riskapp-it.atlassian.net/browse/ISATRA-295
export function calculateSubmissionFormSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if (values.policy_effective_date){
        updatedValues.uw_year = values.policy_effective_date
    } else {
        updatedValues.uw_year = null
    }
    if (values.policy_expiring_date && values.policy_effective_date && !values.policy_expiring_date.isAfter(values.policy_effective_date, 'day')) {
        updatedValues.policy_expiring_date = null
    }

    // lob fields is not present (it's in the sidebar) but the update logic is preserved
    if ('lob' in changedValues)
        updatedValues = {...updatedValues, underwriter: null, attorney_from: null, product_type: null, branches: {}}

    if (changedValues.is_isia === false)
        updatedValues = {...updatedValues, isia_type: null, fee_agency_perc: null}

    if (changedValues.has_prorata === false)
        updatedValues = {...updatedValues, prorata_effective_date: null, prorata_uw_year: null, prorata_isa_share: null}

    if ('prorata_effective_date' in changedValues) {
        updatedValues = {...updatedValues, prorata_uw_year: values.prorata_effective_date || null}
    }

    if ((values.is_calculated.fee_total_perc && changedValues.is_isia === false) ||  changedValues.is_calculated?.fee_total_perc || (('fee_agency_perc' in changedValues || 'fee_broker_perc' in changedValues) && values.is_calculated.fee_total_perc) ) {
        updatedValues.fee_total_perc = (updatedValues.fee_agency_perc || 0) + (updatedValues.fee_broker_perc || 0)
    }

    if (changedValues.is_calculated?.isa_quoted_premium_share || (('isa_share_perc' in changedValues || 'quoted_premium' in changedValues) && values.is_calculated.isa_quoted_premium_share)) {
        updatedValues.isa_quoted_premium_share = +((updatedValues.isa_share_perc || 0) * normalizePercTo1(updatedValues.quoted_premium || 0)).toFixed(2)
    }

    if (changedValues.is_calculated) {
        updatedValues.is_calculated = changedValues.is_calculated
    }

    return updatedValues

}


//https://riskapp-it.atlassian.net/browse/ISATRA-297
export function calculateReinsuranceFormSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    const updatedValues = cloneDeep(values)

    if (!(values.reinsurance_type === ReinsuranceType.FACOLTATIVO || values.policy_product_type === 'E')) {
        updatedValues.attachment_point = 0
    }

    if (values.reinsurance_type === ReinsuranceType.TRATTATO || values.reinsurance_type === ReinsuranceType.SPECIALE || values.reinsurance_type === ReinsuranceType.FACILITY) {
        return {
            ...updatedValues,
            policy_insured_sum_net_reins: null,
            reinsurer_broker: null,
            reinsured_share_perc: 0,
            reinsurer_broker_fee_perc: 0,
            isa_fee: 0,
            saved_premium: 0,
            reinsurance_notes: '',
            net_premium_to_reinsurers: 0,
            ppw_days: null
        }
    } else {
        const reinsuredSharePerc = normalizePercTo1(updatedValues.reinsured_share_perc || 0)
        const feeTotalPerc = normalizePercTo1(updatedValues.fee_total_perc || 0)
        const reinsurerBrokerFeePerc = normalizePercTo1(updatedValues.reinsurer_broker_fee_perc || 0)
        const isaQuotedPremiumShare = updatedValues.isa_quoted_premium_share || 0

        if ((changedValues.is_calculated?.policy_insured_sum_net_reins) || (values.is_calculated?.policy_insured_sum_net_reins)) {
            updatedValues.policy_insured_sum_net_reins = roundNumber((values.policy_insured_sum ?? 0) * (1 - reinsuredSharePerc))
        }

        if (changedValues.is_calculated?.saved_premium || values.is_calculated.saved_premium) {
            updatedValues.saved_premium = roundNumber(isaQuotedPremiumShare * (1 - reinsuredSharePerc) * (1 - feeTotalPerc))
        }

        if (changedValues.is_calculated?.isa_fee || values.is_calculated.isa_fee) {
            updatedValues.isa_fee = roundNumber(isaQuotedPremiumShare * reinsuredSharePerc * (reinsurerBrokerFeePerc - feeTotalPerc >= 0 ? reinsurerBrokerFeePerc - feeTotalPerc : 0 ))
        }

        if (changedValues.is_calculated?.net_premium_to_reinsurers || values.is_calculated.net_premium_to_reinsurers) {
            updatedValues.net_premium_to_reinsurers = roundNumber(isaQuotedPremiumShare * (1 - reinsurerBrokerFeePerc) * reinsuredSharePerc)
        }

        if (changedValues.is_calculated) {
            updatedValues.is_calculated = changedValues.is_calculated
        }

        return updatedValues
    }

}

//https://riskapp-it.atlassian.net/browse/ISATRA-298
export function calculateNegotiationFormSideEffect(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel, lob: LobModel | undefined, firstDelegate: { label: string, value: string } | null) {

    let updatedValues = cloneDeep(values)
    if (updatedValues.is_auto_renewal === false)
        updatedValues.cancellation_terms_days = null
    if (changedValues.isa_share_perc && changedValues.isa_share_perc >= 100 && firstDelegate) {
        updatedValues.delegated_insurer = firstDelegate.value
    }
    if (!lob || !(['property'].includes(lob.name.toLowerCase()))) {
        updatedValues.tiv = 0
        updatedValues.insured_sum = 0
    }
    if (changedValues.is_calculated?.reference_premium || values.is_calculated.reference_premium) {
        updatedValues.reference_premium = values.quoted_premium
    }
    if (changedValues.is_calculated?.regulation_premium || values.is_calculated.regulation_premium) {
        updatedValues.regulation_premium = values.quoted_premium
    }
    if (changedValues.hasOwnProperty("is_new_business") && !changedValues.is_new_business ) {
        updatedValues.fee_bank_perc = null
    }
    return updatedValues

}

//https://riskapp-it.atlassian.net/browse/ISATRA-298
export function calculatePortfolioFormSideEffect(negotiation: NegotiationModel | undefined, changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if ('product_type' in changedValues) {
        if (!changedValues.product_type) {
            updatedValues = {...updatedValues, branches: {}}
        }
        else {
            const branches = negotiation && negotiation.lob.products.find(product => product.product === changedValues.product_type)?.branches
            const defaultBranches: { [key: number | string]: number } = {}
            if (branches) {
                for (const key in branches)
                    defaultBranches[key] = branches[key as any].default * 100

            }
            updatedValues = {...updatedValues, branches: defaultBranches}
        }

    }
    return updatedValues

}

export function calculatePoliciesAndIssuesSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if (changedValues.is_framework_agreement === false)
        return {...updatedValues, framework_agreement: null}
    if (changedValues.is_calculated?.policy_premium || values.is_calculated.policy_premium) {
        updatedValues.policy_premium = values.quoted_premium
    }
    if (changedValues.is_calculated?.policy_insured_sum || values.is_calculated.policy_insured_sum) {
        updatedValues.policy_insured_sum = !_.isNil(updatedValues.insured_sum) && !_.isNil(updatedValues.isa_share_perc) ? +(updatedValues.insured_sum * normalizePercTo1(updatedValues.isa_share_perc)).toFixed(2) : null
    }
    if (changedValues.has_regulation_premium === false) {
        updatedValues.regulation_premium = null
    }
    return updatedValues
}

export function calculateContractorSideEffects(changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) {
    let updatedValues = cloneDeep(values)
    if (changedValues.has_fund === false)
        return {...updatedValues, fund: null}
    return updatedValues

}

