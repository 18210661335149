import { apiSlice } from "redux/api/baseApiSlice";
import handleDownloadFile from "../../utils/handleDownloadFile";

export const NEW_BUSINESS_EXTRACTION = 'new_business_extraction'
export const PORTFOLIO_EXTRACTION = 'portfolio_extraction'
export const UW_YEAR_FROM = 'uw_year_from'
export const UW_YEAR_TO = 'uw_year_to'

type ExtractionToXlsxParams = {
  [NEW_BUSINESS_EXTRACTION]: boolean,
  [PORTFOLIO_EXTRACTION]: boolean,
  [UW_YEAR_FROM]?: number,
  [UW_YEAR_TO]? : number
}

export const downloadXlsxApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getExtractionToXls: builder.query<Blob, ExtractionToXlsxParams >({
            query: (params) => {
                return {
                    url: `/negotiation/extraction_to_xlsx/`,
                    params: params,
                    responseHandler: (response) => handleDownloadFile(response),
                    cache: "no-cache",
                }
            },
        }),
        getSaleExtraction: builder.query<Blob, void>({
            query: () => {
                return {
                    url: `/negotiation/sale_extraction/`,
                    responseHandler: (response) => handleDownloadFile(response),
                    cache: "no-cache",
                }
            }
        })
    })
})

export const { useLazyGetExtractionToXlsQuery, useLazyGetSaleExtractionQuery } = downloadXlsxApiSlice