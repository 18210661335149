import {Table, Tag} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {useLazyGetNegotiationReferredQuery} from "redux/api/negotiationsApiSlice";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import ChartContainer from "../charts/ChartCards/ChartContainer";
import {ColumnsType} from "antd/lib/table";
import {NegotiationReferredTable} from "../../types/authorities";
import {getContrastYIQ} from "../../utils/formatters";

export default function NegotiationsToAuthorize(props: { className: string }) {
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const [triggerGetReferredNegotiations, {
        data: referredNegotiations,
        isLoading: isLoadingReferredNegotiations,
        isFetching: isFetchingReferredNegotiations
    }] = useLazyGetNegotiationReferredQuery();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const title = 'Trattative da autorizzare'

    useEffect(() => {
        triggerGetReferredNegotiations(
            {page_size: 5, page: currentPage},
        )
    }, [currentPage, triggerGetReferredNegotiations])

    const columns: ColumnsType<NegotiationReferredTable> = [
        {
            key: 'contractor',
            dataIndex: 'contractor',
            title: 'Contraente',
            ellipsis: true,
        }, {
            key: 'lob',
            dataIndex: 'lob',
            title: 'Lob',
            width: '10rem',
            responsive: ['lg'],
            render: (value: { label: string, color: string }) => {
                return <Tag
                    style={{
                        width: '8rem',
                        height: '1.5rem',
                        borderRadius: '100px',
                        textAlign: 'center',
                        color: getContrastYIQ(value.color)
                    }}
                    color={value.color}>
                    {value.label}
                </Tag>
            },

        }
        , {
            key: 'underwriter',
            dataIndex: 'underwriter',
            title: 'Underwriter',
            responsive: ['sm'],
        }, {
            key: 'responsible_user',
            dataIndex: 'responsible_user',
            title: 'Referral',
        },
        {
            key: 'referral_date',
            dataIndex: 'referral_date',
            title: 'Data di referral',
            responsive: ['sm'],
            render: (value) => dayjs(value).format('DD-MM-YYYY HH:mm'),
        }
    ]


    return <ChartContainer noInfo={true}
                           title={title}
                           className={props.className}
    >
        <Table
            <NegotiationReferredTable>
            columns={columns}
            dataSource={referredNegotiations?.results.map(el => ({key: el.uuid, ...el}))}
            className={"negotiation-table"}
            loading={isLoadingReferredNegotiations || isFetchingReferredNegotiations}
            onRow={(record) => {
                return {
                    onClick: () => {
                        navigate(`../negotiations/${record.key}`, {state: {previous_path: pathname}})
                    },
                };
            }}
            pagination={{
                simple: true,
                total: referredNegotiations?.count,
                current: currentPage,
                pageSize: 5,
                onChange: page => {
                    setCurrentPage(page)
                }
            }}/>
    </ChartContainer>
}