import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import {Country} from "../../types/countries";

const countriesSlice = createSlice({
    name: 'countries',
    initialState: [] as Country[],
    reducers: {
        setCountries: (state, action: PayloadAction<Country[]>) => {
            return action.payload
            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        }
    }
})

export const { setCountries } = countriesSlice.actions
export default countriesSlice.reducer
