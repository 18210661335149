import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useCallback, useEffect, useState} from 'react';

import {Col, Row, Button, Tooltip} from 'antd';

import { Layout, Menu, Typography, Divider} from 'antd';


import {useTranslation} from 'react-i18next';
import {LeftOutlined, RightOutlined, CloseOutlined, FileOutlined} from '@ant-design/icons';

import type {MenuProps} from 'antd';

import useScrollSpy from 'react-use-scrollspy'
import {NegotiationDetailSubForm} from "types/negotiations/components";

import {useSelector} from "react-redux";
import {selectUser} from "redux/features/userSlice";
import {canSeeDocuments} from "../../../utils/permission";

const {Title} = Typography


export function NegotiationDetailMenu({menuItems, hide, goToDocuments, isRore}: { menuItems: NegotiationDetailSubForm[], hide: () => void, goToDocuments?: () => void, isRore: boolean }) {

    // const scrollPosition = useScrollPosition()
    const user = useSelector(selectUser)
    const isPortfolio = user.usertypes.find(type => type.code === 'PO')

    const [selectedSection, setSelectedSection] = useState<string>(isPortfolio ? 'policiesAndIssues' : 'submission');
    const navigate = useNavigate()

    const location = useLocation()
    const state = location.state as {previous_path: string}

    const onMenuItemClick: MenuProps['onClick'] = useCallback(
        (e: any) => {
            // setSelectedSection(e.key)
            const menuItem = menuItems.find(item => item.key === e.key)
            if (menuItem?.ref.current)
                menuItem.ref.current.scrollIntoView()
        },
        [menuItems],
    )


    const activeSection = useScrollSpy({
        sectionElementRefs: menuItems.map(menuItem => menuItem.ref),
        offsetPx: -400,
    });

    useEffect(() => {
        setSelectedSection(menuItems[activeSection].key || '')
    }, [activeSection, menuItems])

    return (
            <aside
                style={{
                    backgroundColor: "white",
                    overflow: 'auto',
                    height: 'calc(100vh - 48px)',
                    position: 'sticky',
                    left: 0,
                    top: '24px',
                    bottom: 0,
                    padding: '24px 8px',
                    borderRadius: '12px',
                }}
            >
                <Row justify={'space-between'} align={"middle"}>
                    <Col>
                        <div className='btn-text' style={{gap: '4px', justifyContent: 'start'}}
                             onClick={() =>{
                                 state?.previous_path === '/authorities' ? navigate('/authorities') :
                                     state?.previous_path === '/dashboard' ? navigate('/dashboard') :
                                         navigate('/negotiations')
                             }}>
                            <LeftOutlined style={{color: '#1890FF'}}/>
                            <p style={{color: '#1890FF'}}>{state?.previous_path === '/authorities' ? "Torna alle authorities" : state?.previous_path === '/dashboard' ? "Torna alla dashboard" : "Torna alle trattative"}</p>
                        </div>
                    </Col>
                    <Col>
                        <Tooltip title={'Comprimi'} placement={'bottom'}>
                            <Button
                            onClick={hide}
                            style={{backgroundColor: 'transparent', width: '100%'}}
                            type={'text'}
                            icon={<LeftOutlined/>}/>
                        </Tooltip>
                    </Col>
                </Row>
                {/* collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}> */}
                <Row gutter={[24, 24]} >
                    <Col span={24}>
                        <Title level={5} style={{marginBottom: 0}}>Dettagli Trattativa</Title>

                    </Col>
                </Row>
                <Menu

                    onClick={onMenuItemClick}
                    style={{width: '100%', marginTop: '24px', marginLeft: '-8px', marginRight: '-8px'}}
                    selectedKeys={[selectedSection]}
                    mode="inline"
                    items={menuItems.map(item => ({key: item.key, icon: item.icon, label: item.label}))}
                />
                { user && user.usertypes.length && canSeeDocuments(user.usertypes.map(el=>el.code)) && <Row style={{marginTop: '15px'}} gutter={[8, 8]}>
                    {!isRore && <Col span={24}>
                        <Button
                            block
                            style={{width: '98%'}}
                            type="primary"
                            icon={<FileOutlined/>}
                            onClick={() => {
                                if (goToDocuments)
                                    goToDocuments()
                            }}>
                            Documenti
                        </Button>
                    </Col>}
            </Row>}


            </aside>
    )
}

