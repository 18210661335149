import {Button, ButtonProps, Modal} from "antd";
import {useSurveyContext} from "../../context/SurveyContext";

const SendCoverageConfirmationButton = (props: ButtonProps) => {
  const [survey] = useSurveyContext();

  return (
    <Button
      {...props}
      onClick={(e) => {
        if (survey && props.onClick) {
          if (survey.hasErrors(undefined, true, undefined)) {
            Modal.warning({
              title: 'Impossibile inviare la conferma di copertura',
              centered: true,
              content: 'Alcuni campi obbligatori non sono stati compilati. Controlla la conferma di copertura e riprova.',
              closable: true,
            })

          } else {
            props.onClick(e);
          }
        }
      }}
    />
  );
}

export default SendCoverageConfirmationButton;