import {useEffect, useState} from "react";
import {Button, Col, notification, Row} from "antd";

export const useCheckConnection = () => {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      notification.close("offlineNotification");
      notification.success(
        {
          message: "Stato connessione",
          description: (
            <>
              <Row justify={"space-between"} align={"middle"} wrap={false}>
                <Col flex={1}>Connessione nuovamente disponibile. Clicca per aggiornare la pagina.</Col>
              </Row>
              <Row justify={"end"}>
                <Col>
                  <Button type={"link"}
                          onClick={() => window.location.reload()}>
                    Clicca qui per ricaricare la pagina
                  </Button>
                </Col>
              </Row>
            </>),
          key: "onlineNotification"
        });
    };

    const handleOffline = () => {
      setIsOnline(false);
      notification.close("onlineNotification");

      notification.warning({
        message: "Stato connessione",
        description: "Connessione non disponibile. Si prega di controllare la connessione e riprovare.",
        duration: null,
        key: "offlineNotification"
      });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return isOnline;
};