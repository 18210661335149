import {Card, Col, Row} from "antd";
import MapChart from "../MapChart";
import {ChartEntity, ZoneType} from "../../../types/charts";
import {ITALY} from "../broker/BrokersTable";
import React, {useCallback, useMemo, useState} from "react";
import {useGetBBBNegotiationsByZoneQuery} from "../../../redux/api/chartsApiSlice";
import {BranchData} from "../bankBranch/BranchData";
import {BranchTable} from "../bankBranch/BranchTable";
import {useBankBranchesChartsParamsContext} from "../../../context/BankBranchesChartsContext";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";

export default function BankBranchesTableMap() {

    const [zoneType, setZoneType] = useState<ZoneType>(ZoneType.REGION)
    const [selectedOption, setSelectedOption] = useState<string>(ITALY)
    const {params} = useBankBranchesChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = typeof uwYear === 'number'

    const {
        data,
        isFetching,
        isUninitialized
    } = useGetBBBNegotiationsByZoneQuery(areParamsOk ? {
        budget_group: params.budget_group,
        entity: ChartEntity.BANK_BRANCH,
        uw_year: uwYear,
        zone_type: zoneType
    } : skipToken)

    const options = useMemo(() => [
            {
                label: "Tutta Italia",
                value: ITALY
            },
            {
                label: "Estero",
                value: "EE"
            },
            ...(data || []).map
            (el => ({value: el.zone.value, label: el.zone.label}))
                .sort((optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()))],
        [data])

    const handleZoneType = useCallback((v: ZoneType) => {
        setZoneType(v)
        if (selectedOption !== ITALY) {
            setSelectedOption(ITALY)
        }
    }, [selectedOption])

    return <>
        <Col xs={24}>
            <Card>
                <Row
                    gutter={[16, 16]}
                    justify={"space-between"}>
                    <Col xs={24} lg={12}>
                        <MapChart
                            data={data}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            loadingData={isFetching || isUninitialized}
                            zoneType={zoneType}
                            setZoneType={handleZoneType}
                            legendHeight={300}
                            chartHeight={350}
                        />
                    </Col>
                    <Col xs={24} lg={12}>
                        <BranchData
                            provinces={selectedOption === ITALY ? undefined : selectedOption}
                            target={options.find(el => el.value === selectedOption)?.label}
                        />
                    </Col>
                </Row>
            </Card>
        </Col>
        <Col xs={24}>
            <BranchTable
                zoneOptions={options || []}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
        </Col>
    </>
}