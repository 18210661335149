import {Button, Tooltip} from "antd";
import {UnorderedListOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {ChecklistFormDialog} from "./checklist";
import {NegotiationModel, UpdateNegotiationRequest} from "../../../../../types/negotiations";

const OpenAssumptionChecklistModalButton = ({negotiation, updateNegotiation}: {
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
}) => {

  const [
    isChecklistOpen,
    setIsChecklistOpen
  ] = useState<boolean>(false);

  return (
    <>
      <ChecklistFormDialog
        isOpen={isChecklistOpen}
        onClose={(isNegotiationToUpdate) => {
          setIsChecklistOpen(false)
          if (isNegotiationToUpdate)
            updateNegotiation({})
        }}
        negotiation={negotiation}
        updateNegotiation={updateNegotiation}/>
      <Tooltip title={'Checklist assuntiva'}>
        <Button
          className={'ant-btn'}
          style={{width: '100%'}}
          onClick={() => setIsChecklistOpen(true)}>
          <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
            <UnorderedListOutlined style={{marginRight: '0.5rem'}}/>
            Assuntiva
          </div>
        </Button>
      </Tooltip>
    </>
  );
}

export default OpenAssumptionChecklistModalButton;