import {FilterFilled} from "@ant-design/icons";
import {selectUser, setTablesPreferences} from "../../redux/features/userSlice";
import {Views} from "../../types";
import {Button, Popconfirm} from "antd";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSetUserTablesPreferencesMutation} from "../../redux/api/userPreferencesApiSlice";
import {DEFAULT_CURRENT_PAGE_SIZE_TABLE, DEFAULT_CURRENT_PAGE_TABLE} from "../tables/negotiationsTables/columnsUtils";

export default function ResetFiltersSortersButton({view}: { view: Views }) {

    const dispatch = useDispatch()
    const [updatePreferences] = useSetUserTablesPreferencesMutation()
    const [isSortersFiltersActivated, setIsSortersFiltersActivated] = useState<boolean>(false)
    const user = useSelector(selectUser)

    useEffect(() => {
        if (user) {
            setIsSortersFiltersActivated(!!user?.preferences?.tables[Views[view]].sorters?.length || !!user?.preferences?.tables[Views[view]].filters?.length)
        }
    }, [user, view])


    return <Popconfirm
        disabled={!isSortersFiltersActivated}
        title={'Eliminare i filtri e gli ordinamenti esistenti per la tabella corrente?'}
        onConfirm={() => {
        if (isSortersFiltersActivated) {
            dispatch(setTablesPreferences({
                [Views[view]]: {
                    sorters: [],
                    filters: [],
                    page: DEFAULT_CURRENT_PAGE_TABLE,
                    page_size: DEFAULT_CURRENT_PAGE_SIZE_TABLE
                }
            }))
            updatePreferences({
                [Views[view]]: {
                    sorters: [],
                    filters: []
                }
            })
        }
    }}>
        <Button size={'large'} icon={<FilterFilled
            className={isSortersFiltersActivated ? "ant-table-filter-trigger active" : "ant-table-filter-trigger"}/>}
                style={{borderColor: 'transparent', backgroundColor: 'transparent', boxShadow: "none"}}/>
    </Popconfirm>
}