import { Button, Card } from "antd";
import { SendOutlined } from "@ant-design/icons";

interface propHelp{
    show: boolean,
    handleInsertHelp: (value: string)=> void,
}

//change this array to modify examples of advanced search usage
const data = [
    'Estrai tutte le trattative della lob Liability con data di decorrenza compresa tra il 15 settembre 2022 e il 15 settembre 2023.',
    'Dammi le trattative del contraente Geico spa per l\'underwriting year 2023.',
    'Trattative che scadono tra 10 giorni.',
    'Impostami l\'importo assicurato da 50.000 € a 100.000 €, LPS in LPS e la decorrenza del giorno 22/09/2022.'
  ];

export default function Help({ show, handleInsertHelp }: propHelp) {
    return (
        <>
            <div style={show? { display: 'block', marginBottom:'1em' } : { display: 'none'}}>
                <Card size="small">
                    {data.map((element, index)=>{
                        return <Button
                                    key={index}
                                    onClick={()=>handleInsertHelp(element)} 
                                    style={{whiteSpace: 'normal',height:'auto', display:'flex', alignItems:'center',marginBottom: '4px'}}
                                >{element}<SendOutlined/></Button>
                    })}
                </Card>
            </div>
        </>
    );
}

