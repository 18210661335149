import {Card, Col, Divider, Row, Skeleton, Space, Steps, Typography} from "antd";
import {useSurveyContext} from "context/SurveyContext";
import {useSurveyPage} from "../../context/SurveyPageContext";
import PrintCoverageConfirmationButton from "../../pages/negotiations/PrintCoverageConfirmationButton";

const {Title} = Typography;
const {Step} = Steps;
const SelectSurveyPageCard = () => {

    const [survey,] = useSurveyContext();
    const {page, setPage, staticSurveyPagesNumber, isAdditionalInsuredVisible} = useSurveyPage();

    const handleChange = (page: number) => {
        setPage(page)
    };

    return (
        <>
            <Card>
                <Space direction={"vertical"} size={8} style={{width: '100%'}}>
                    <Title level={5}>
                        Indice pagine
                    </Title>
                    <Skeleton loading={!survey}>
                        <Steps
                            direction={"vertical"}
                            size={"small"}
                            onChange={handleChange}
                            current={page}>
                            <Step title={"1 - Dati generali"} key={0}/>
                            {isAdditionalInsuredVisible && <Step title={"2 - Assicurati addizionali"} key={1}/>}
                            {!!(staticSurveyPagesNumber && survey) && survey.visiblePages.map((page: any, index: number) => (
                                <Step title={`${index + staticSurveyPagesNumber + 1} - ${page.title}`}
                                      key={index + staticSurveyPagesNumber}/>
                            ))}
                        </Steps>
                    </Skeleton>
                </Space>
                <Divider style={{margin: "0 0 1rem 0"}}/>
                <Row justify={"end"}>
                    <Col>
                        <PrintCoverageConfirmationButton/>
                    </Col>
                </Row>
            </Card>
        </>
    );
}
export default SelectSurveyPageCard;