import React, {useCallback, useState} from "react";
import ContractorCandidatesList from "./ContractorCandidatesList";
import {useCachedCountriesQuery} from "../../redux/api/countriesApiSlice";
import { message, Modal, Switch} from "antd";
import {
  useCreateCustomerMutation,
  useLazyGetContractorsCandidatesQuery
} from "../../redux/api/contractorsApiSlice";
import {ContractorCandidateModel, ContractorModel} from "../../types/contractors";
import ManualContractorForm from "./ManualContractorForm";
import AutoPilotForm, {COUNTRY, FormType, NAME} from "./AutoPilotForm";


export default function ContractorCreation({
                                             isFund,
                                             searchTerm,
                                             isVisible,
                                             setIsVisible,
                                             onCreation
                                           }: { isFund: boolean, searchTerm: string, isVisible: boolean, setIsVisible: (visible: boolean) => void, onCreation?: (contractor: ContractorModel) => void }) {
  const [getCandidates, {
    data: candidates,
    isFetching: isFetchingCandidates,
    isLoading: isLoadingCandidates,
    isUninitialized: isUninitializedGetCandidates
  }] = useLazyGetContractorsCandidatesQuery()
  const [isManualInsertion, setIsManualInsertion] = useState(false)

  const handleFinish = useCallback((values: FormType) => {
    getCandidates({country: values[COUNTRY], q: values[NAME]})
  }, [getCandidates])

  const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
  const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []
  const [createContractorRequest, {isLoading: isCreateContractorLoading}] = useCreateCustomerMutation()

  const areAllButtonsDisabled = isCreateContractorLoading || isLoadingCountries || isCreateContractorLoading || isFetchingCandidates || isLoadingCandidates

  const onSelectedCandidate = useCallback((candidate: ContractorCandidateModel) => {
    return new Promise(async() => {
          try {
            const contractor = await createContractorRequest({...candidate, is_fund: false}).unwrap()
            message.success(`${contractor.name} (${contractor.vat_number}) creato correttamente.`)
            if (onCreation)
              onCreation(contractor)
            setIsVisible(false)
          } catch (err: any) {
            if (err?.data?.error) {
              message.error(err?.data?.error)
            } else {
              message.error('Errore nel recupero dei dati del contraente')
            }
          }
        }
    )
  }, [createContractorRequest, onCreation, setIsVisible])

  return (
      <Modal
          open={isVisible}
          onCancel={() => setIsVisible(false)}
          footer={false}
          title={isFund ? 'Creazione cassa assistenza' : 'Inserimento contraente'}
      >
        <div style={{textAlign: 'right', marginBottom: '1rem'}}>
          <Switch
              disabled={areAllButtonsDisabled}
              checked={isManualInsertion}
              onChange={value => setIsManualInsertion(value)}
              checkedChildren={'Manuale'} unCheckedChildren={'Automatico'}
          />
        </div>
        {!isManualInsertion && <>
          <AutoPilotForm handleFinish={handleFinish} disabled={areAllButtonsDisabled} searchTerm={searchTerm}/>
          {!isUninitializedGetCandidates && candidates && <>
              <span>Non trovi quello che stai cercando? </span>
              <button style={{ color: '#1890FF', backgroundColor: 'transparent', border: 'none', cursor: 'pointer'}} onClick={() => setIsManualInsertion(true)}>Aggiungi il contraente manualmente</button>
          </>}
            <ContractorCandidatesList
                contractorsCandidates={candidates || []}
                isLoading={isLoadingCandidates || isFetchingCandidates}
                btnLabel={"Seleziona"}
                isUninitialized={isUninitializedGetCandidates}
                disabled={areAllButtonsDisabled}
                onSelectedCandidate={onSelectedCandidate}
            />
        </>}

        {isManualInsertion && <ManualContractorForm
            contractor={{name: searchTerm}}
            isSubmit={isCreateContractorLoading}
            onSubmit={async(values) => {
              try {
                const contractor = await createContractorRequest({...values, is_fund: isFund}).unwrap()
                message.success(<span>{contractor.name} <br/> ({contractor.vat_number}) creato correttamente</span>)
                if (onCreation) {
                  onCreation(contractor)
                }
                setIsVisible(false)
              } catch (err: any) {
                if (err?.data?.error) {
                  message.error(err?.data?.error)
                } else {
                  message.error('Errore nella creazione del contraente')
                }
              }
        }

        }/>}
      </Modal>
  )
}