import ChartContainer from "./ChartContainer";
import React, {useEffect} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import {euro, numeric, percentage} from "../../../utils/formatters";
import {Col, Divider, Row} from "antd";
import {useLazyGetBoundRatiosChartQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function QuotedPremiumsOnReceivedChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {
    const title = 'Premi in bound'
    const [getBoundRatios, {isLoading, isFetching, isError, data: boundRatios}] = useLazyGetBoundRatiosChartQuery()
    const description = <>
        <dl>
            <dt>Il grafico mostra:</dt>
            <ul>
            <li style={{marginTop: '0.3rem'}}><span style={{fontWeight: 'bold'}}>Percentuale</span> calcolata come somma dei premi in bound su quotati</li>
            <li><span style={{fontWeight: 'bold'}}>Percentuale</span> calcolata come somma dei premi in bound su totale</li>
            <li><span style={{fontWeight: 'bold'}}>Bound:</span> somma dei premi in bound</li>
            <li><span style={{fontWeight: 'bold'}}>Quotate:</span> somma dei premi quotati</li>
            <li><span style={{fontWeight: 'bold'}}>Totale:</span> somma dei premi totali</li>
            </ul>
        </dl>
    </>


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getBoundRatios({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                calculation_type: 2

            })
        }
    }, [budgetGroup, getBoundRatios, isia, uwYear])

    return <ChartContainer
        loading={isLoading || isFetching}
        description={description}
        className={props.className}
        title={title}
        buttons={<DownloadButton chartRef={props.chartRef}/>}>
        <BarPercentageChart
            chartRef={props.chartRef}
            boundRatios={true}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                            // margin: [undefined, undefined, undefined, undefined]
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },

                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [boundRatios ? 1 - boundRatios.percentage_over_quoted : 1],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [boundRatios ? boundRatios.percentage_over_quoted : 1],
                        dataLabels: {
                            enabled: true,
                            style: {fontSize: '17px'},
                            format: `Bound su quotati: ${boundRatios ? percentage(boundRatios.percentage_over_quoted) : null}`
                        },
                        color: '#13C2C2',
                    },
                ],

            }}/>
        <BarPercentageChart
            chartRef={props.chartRef}
            boundRatios={true}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                            // margin: [undefined, undefined, undefined, undefined]
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },

                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [boundRatios ? 1 - boundRatios.percentage_over_total : 1],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [boundRatios ? boundRatios.percentage_over_total : 1],
                        dataLabels: {
                            enabled: true,
                            style: {fontSize: '17px'},
                            format: `Bound su totale: ${boundRatios ? percentage(boundRatios.percentage_over_total) : null}`
                        },
                        color: '#13C2C2',
                    },
                ],

            }}/>
        <Divider style={{marginTop: 0, marginBottom: '12px'}}/>
        <Row justify={'space-between'}><Col>Premi bound:</Col><Col
            style={{fontWeight: 'bolder'}}>{boundRatios ? euro(boundRatios.bound) : ""}</Col></Row>
        <Row justify={'space-between'}><Col>Premi quotati:</Col><Col
            style={{fontWeight: 'bolder'}}>{boundRatios ? euro(boundRatios.quoted) : ""}</Col></Row>
        <Row justify={'space-between'}><Col>Premi totali:</Col><Col
            style={{fontWeight: 'bolder'}}>{boundRatios ? euro(boundRatios.total) : ""}</Col></Row>

    </ChartContainer>
}