import React, {useMemo, useRef} from "react";
import {Row, Space} from "antd";
import HighchartsReact from 'highcharts-react-official';
import {ALL, useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import {skipToken} from "@reduxjs/toolkit/query";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import Highcharts from "highcharts";
import {useGetBBBTotalNegotiationsQuery} from "../../../redux/api/chartsApiSlice";
import BBBChartCardContainer from "../BBBChartCardContainer";
import {selectUser} from "../../../redux/features/userSlice";

export default function TotalBrokerNegotiations() {
    const ref = useRef<HighchartsReact.RefObject>(null);
    const {params} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isCommonParamsOk = !!(params.budget_group && params.broker_uuid && typeof uwYear === 'number')
    const {data, isFetching, isUninitialized} = useGetBBBTotalNegotiationsQuery(isCommonParamsOk ? {
            uw_year: uwYear,
            entity: 2,
            entity_uuid: params.broker_uuid,
            budget_group: params.budget_group,
            calculation_type: 1,
            negotiations_type: 3,
            ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
        }
        : skipToken)

    const lastIndex = data?.history.length ? data?.history.length - 1 : undefined
    const previousYearsData = data && typeof lastIndex === 'number' ? data?.history.slice(0, lastIndex) : []
    const currentYearData = data && typeof lastIndex === 'number' ? data.history[lastIndex] : undefined
    const options = useSelector(selectOptions)

    const values: number[] = []
    const colors: string[] = []
    const categories: string[] = []

    data?.result.forEach(el => {
        const state = options.states.find(state => state.value === el.state)
        if (state) {
            values.push(el.amount)
            colors.push(state.color)
            categories.push(state.text)
        }
    })

    const chartOptions = useMemo(() => ({
                chart: {
                    type: 'column',
                    height: "45%"
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ""
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                credits: undefined,
                xAxis: {
                    categories,
                    crosshair: true,
                    accessibility: {
                        description: 'Countries'
                    }
                },
                yAxis: {
                    title: {
                        text: ''
                    },
                    allowDecimals: false,
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        borderRadius: 5,
                        colorByPoint: true,
                        dataLabels: {
                            enabled: true,
                            format: '{point.y}',
                        }
                    }
                },
                series: [
                    {
                        name: 'Trattative',
                        data: values,
                        colors
                    }
                ]
            }), [categories, colors, values])

    return <BBBChartCardContainer loading={isFetching || isUninitialized}>
        <Row justify={"space-between"}>
            <h2>Trattative totali</h2>
            <Space>
                {previousYearsData.map(el => <div key={el.year}>
                    {el.year}: <strong>{el.amount}</strong>
                </div>)}
            </Space>
        </Row>
        {!!currentYearData && <Row justify={"end"}>
            <div style={{fontSize: "18px"}}>
                {currentYearData?.year}: <strong>{currentYearData?.amount}</strong>
            </div>
        </Row>}
        <HighchartsReact
            highcharts={Highcharts}
            ref={ref}
            options={chartOptions}
        />
    </BBBChartCardContainer>
}