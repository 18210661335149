import React, {useState} from "react";
import HighchartsReact from "highcharts-react-official";
import {Col, Row} from "antd";
import Title from "antd/lib/typography/Title";
import DatePicker from "../../DatePicker";
import NetPremiumOurShareSumChart from "./NetPremiumOurShareSumChart";
import AccountNegotiationsCountChart from "./AccountNegotiationsCountChart";
import dayjs from "dayjs";


export default function MonthlyChartsContainer(props: { chartRef: React.RefObject<HighchartsReact.RefObject>[] }) {

    const [date, setDate] = useState(dayjs())

    return <><Row className={'monthly-data-header'} style={{marginTop: '1rem'}} justify={'space-between'} >
        <Col xs={{push: 1}} sm={{push: 0}}>
            <Title level={3}>Dati per mese</Title>
        </Col>
        <Col xs={{ pull: 1}} sm={{pull: 0}}>
            <DatePicker.YearPicker value={date} onChange={(value) => {
                if (value) {
                    setDate(value)
                }
            }}/>

        </Col>
    </Row>
        <NetPremiumOurShareSumChart className={'net-premium-our-share-sum--chart'}
                                    chartRef={props.chartRef[0]}
                                    year={date.year()}
        />
        <AccountNegotiationsCountChart className={'account-negotiations-count--chart'}
                                       chartRef={props.chartRef[1]}
                                       year={date.year()}
        />
    </>
}