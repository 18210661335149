import React, {useState} from "react";
import {Button} from "antd";
import {EditOutlined} from "@ant-design/icons";
import EditUnderwriterModal from "../../../../modals/EditUnderwriterModal";
import {NegotiationModel} from "../../../../../types/negotiations";

const EditUnderwriterButton = (
  {negotiation, updateUnderwriter}:
    {
      negotiation: NegotiationModel,
      updateUnderwriter: (uwId: string) => Promise<NegotiationModel | undefined>
    }) => {

  const [
    isOpen,
    setIsOpen
  ] = useState(false);


  return (
    <>
      <EditUnderwriterModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        negotiation={negotiation}
        updateNegotiationUw={updateUnderwriter}/>
      <Button
        type={'link'}
        size={'small'}
        icon={<EditOutlined/>}
        onClick={() => setIsOpen(true)}/>
    </>
  );
}

export default EditUnderwriterButton;