import {ALL, useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import SubmissionReceivedGraph from "../SubmissionReceivedGraph";
import dayjs from "dayjs";
import {skipToken} from "@reduxjs/toolkit/query";
import {capitalize} from "lodash";
import {useGetBBBSubmissionReceptionQuery} from "../../../redux/api/chartsApiSlice";
import {ChartEntity} from "../../../types/charts";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokerSubmissionRecevied() {

    const {params} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const paramsAreOk = !!(params.broker_uuid && params.budget_group && typeof uwYear === 'number')

    const {data, isFetching, isUninitialized} = useGetBBBSubmissionReceptionQuery(paramsAreOk ? {
        entity: ChartEntity.BROKER,
        entity_uuid: params.broker_uuid,
        uw_year: uwYear,
        budget_group: params.budget_group,
        ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
    } : skipToken)

    return <SubmissionReceivedGraph
        loading={isFetching || isUninitialized}
        months={data?.map(el => capitalize(dayjs(el.month).format('MMM'))) || []}
        meetings={data?.map(el => el.meeting_notes) || []}
        submissions={data?.map(el => el.negotiations) || []}
    />
}