import {apiSlice} from "redux/api/baseApiSlice";
import {TableDataType, TableHeader} from "../../types/tableDataType";
import {
    BBBHitRatiosParams,
    BBBHitRatiosResponse,
    BBBInBoundStatsParams,
    BBBInBoundStatsResponse,
    BrokersListTableResponse,
    BBBNegotiationsByZoneParams,
    BBBNegotiationsByZoneResponse,
    BBBSubmissionReceivedParams,
    BrokerChartQueryParams,
    ChartEntity,
    NegotiationDetailsParams,
    NegotiationDetailsResponse,
    RenewRetention,
    RenewRetentionParams,
    SubmissionTrendParams,
    SubmissionTrendResponse,
    TotalNegotiationsChartParams,
    TotalNegotiationsChartResponse,
    BankBranchesListTableResponse,
    BrokersListTableParams,
    BankBranchesListTableParams,
    BrokerRenewRetentionParams, IstatData, YearlyData
} from "../../types/charts";
import handleDownloadFile from "../../utils/handleDownloadFile";
import {SubmissionReception,} from "../../types/brokerCharts";
import {GenericPaginatedApi} from "../../types";
import {GeoJSON, TopoJSON} from "highcharts";

const baseUrl = '/negotiation/charts'

export const chartsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNegotiationsStatusChart: builder.query<{ state: number, amount: number }[], {
            uw_year: number,
            budget_group: string,
            month: number,
            year: number,
            isia?: string /* underwriter: string,*/
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationsstatus`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getNegotiationsPremiumsChart: builder.query<{ state: number, amount: number }[], {
            uw_year: number,
            budget_group: string,
            month: number,
            year: number,
            isia?: string/* underwriter: string,*/
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationspremiums`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getSubmissionReceptionChart: builder.query<{ month: string, premium: number, negotiations: number }[], {
            uw_year: number,
            budget_group: string,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/submissionreception`,
                    params: params
                }
            },
        }),
        getQuotedPremiumsOnReceivedChart: builder.query<{
            premium: number,
            percentage_premium: number,
            negotiations: number,
            premium_goal: number
        }, { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/quotedpremiumsonreceived`,
                    params: params
                }
            },
        }),
        getBoundPremiumsOnTotalChart: builder.query<{
            premium: number,
            percentage_premium: number,
            negotiations: number,
            premium_goal: number
        }, { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/boundpremiumsontotal`,
                    params: params
                }
            },
        }),
        getBoundRatiosChart: builder.query<{
            percentage_over_quoted: number,
            percentage_over_total: number,
            bound: number,
            quoted: number,
            total: number
        }, { uw_year: number, budget_group: string, isia?: string, calculation_type: number }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/boundratios`,
                    params: params
                }
            },
        }),
        getNegotiationsResultsChart: builder.query<{ state: number, premium: number, negotiations: number }[], {
            uw_year: number,
            budget_group: string,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationsresults`,
                    params: params
                }
            },
        }),
        getMonthlyHitRatioChart: builder.query<{
            states: { state: number, premiums: number, negotiations: number }[],
            hit_ratio: number,
            total: number,
            isa_premium: number
        }, {
            uw_year: number,
            budget_group: string,
            month: number,
            year: number,
            isia?: string,
            calculation_type: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/monthlyhitratio`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, month: params.month + 1}
                }
            },
        }),
        getAnnualHitRatioChart: builder.query<{
            states: { state: number, premium: number, negotiations: number }[],
            hit_ratio: number,
            total: number,
            isa_premium: number
        }, { uw_year: number, budget_group: string, isia?: string, calculation_type: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/annualhitratio`,
                    params: params
                }
            },
        }),
        getNetPremiumOurShareChart: builder.query<{
            lobs: { lob: string, percentage: number }[],
            net_premium_our_share: number,
            account: number
        }, { uw_year: number, budget_group: string, isia?: string, calculation_type?: 1 | 2 }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumourshare`,
                    params: params
                }
            },
        }),
        getNetPremiumOurShareDetailsChart: builder.query<{ values: TableDataType[], header: TableHeader[] }, {
            uw_year: number,
            budget_group: string,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumoursharetable`,
                    params: params
                }
            }
        }),
        getNetPremiumOurShareTypeBusinessChart: builder.query<{
            new_business: number,
            renewal: number,
            total: number,
            regulation_premium: number
        }, { uw_year: number, budget_group: string, isia?: string, calculation_type?: 1 | 2 }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumoursharetypebusiness`,
                    params: params
                }
            }
        }),
        getBrokerDataTotalsChart: builder.query<{
            states: { state: number, amount: number }[],
            total: number
        }, BrokerChartQueryParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerdatatotals`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, ...(typeof params.month === 'number' ? {month: params.month + 1} : {})}
                }
            },
        }),
        getBrokerDataDownload: builder.query<Blob, BrokerChartQueryParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerdatadownload`,
                    params: {...params, ...(typeof params.month === 'number' ? {month: params.month + 1} : {})},
                    responseHandler: (response) => handleDownloadFile(response),
                    cache: "no-cache",
                }
            },
        }),
        getBrokerDataDetailsChart: builder.query<{
            values: TableDataType[],
            header: TableHeader[]
        }, BrokerChartQueryParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerdatatdetails`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, ...(typeof params.month === 'number' ? {month: params.month + 1} : {})}
                }
            },
        }),
        getBrokerSpecificDataDetailsChart: builder.query<{
            values: TableDataType[],
            header: TableHeader[]
        }, BrokerChartQueryParams & { broker: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/brokerspecificdatatdetails`,
                    // month in js 0-11 range in python 1-12
                    params: {...params, ...(typeof params.month === 'number' ? {month: params.month + 1} : {})}
                }
            },
        }),

        getAnnualGoalChart: builder.query<{
            state: string,
            percentage: number | null,
            premium: number,
            in_chart: boolean
        }[], { uw_year: number, scope: string, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/annualgoal`,
                    params: {...params, isia: 'all'}
                }
            },
        }),
        getTrendAgencyChart: builder.query<{ values: TableDataType[], header: TableHeader[] }, {
            uw_year: number,
            budget_group: string,
            is_negotiations: boolean,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/trendagency`,
                    params: params
                }
            },
        }),
        getUnderProcessingAgencyNegotiationsStatusChart: builder.query<{
            values: TableDataType[],
            header: TableHeader[]
        }, { uw_year: number, budget_group: string, is_negotiations: boolean, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/underprocessingagencynegotiationsstatus`,
                    params: params
                }
            },
        }),
        getGapToPipelineChart: builder.query<{ budget_group: string, percentage: number }[], {
            uw_year: number,
            budget_group: string,
            isia?: string /* underwriter: string,*/
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/gaptopipeline`,
                    params: params
                }
            },
        }),
        getGapToPlanChart: builder.query<{
            percentage: number,
            amount: number,
            goal: number,
            regulation: number,
            average_regulation: number,
            amount_new_business: number,
            amount_renews: number,
            regulation_premium_factor: string
        }, { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/gaptoplan`,
                    params: params
                }
            },
        }),
        getRenewRetentionChart: builder.query<RenewRetention, RenewRetentionParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/retention`,
                    params: params
                }
            },
        }),
        getBrokerRenewRetentionChart: builder.query<RenewRetention, BrokerRenewRetentionParams>({
            query: ({broker_uuid, ...params}) => {
                return {
                    url: `/negotiation/brokers/${broker_uuid}/brokerretention`,
                    params: params
                }
            },
        }),
        getNegotiationTotalChart: builder.query<{
            total_number: number,
            states_shares: { state: string, percentage: number }[]
        }, { uw_year: number, budget_group: string, isia?: string }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/negotiationshares`,
                    params: params
                }
            },
        }),
        getNetPremiumOurShareSumChart: builder.query<{ state: number, premiums: number[] }[], {
            uw_year: number,
            budget_group: string,
            year: number,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/netpremiumoursharesum`,
                    params: params
                }
            },
        }),
        getAccountNegotiationsCountChart: builder.query<{ state: number, premiums: number[] }[], {
            uw_year: number,
            budget_group: string,
            year: number,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/accountnegotiationscount`,
                    params: params
                }
            },
        }),
        getPortfolioStatusUpload: builder.query<{ name: string, percentage: number, amount: number }[], {
            uw_year: number,
            budget_group: string,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliostatusupload`,
                    params: params
                }
            },
        }),
        getPortfolioToBeUploaded: builder.query<{ to_be_uploaded: number, draft: number }, {
            uw_year: number,
            budget_group: string,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliotobeuploaded`,
                    params: params
                }
            },
        }),
        getPortfolioUploaded: builder.query<{ total: number, uploaded: number }, {
            uw_year: number,
            budget_group: string,
            isia?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliouploaded`,
                    params: params
                }
            },
        }),
        getPortfolioBrokers: builder.query<{ name: string, negotiations: number }[], {
            uw_year: number,
            budget_group: string,
            isia?: string,
            waiting_reason?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliobrokers`,
                    params: params
                }
            },
        }),
        getPortfolioLobs: builder.query<{ name: string, negotiations: number }[], {
            uw_year: number,
            budget_group: string,
            isia?: string,
            upload_reason?: string
        }>({
            query: (params) => {
                return {
                    url: `${baseUrl}/portfoliolobs`,
                    params: params
                }
            },
        }),
        getBBBSubmissionReception: builder.query<SubmissionReception[], BBBSubmissionReceivedParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/bbb/submissionreception/`,
                    params
                }
            }
        }),
        getBBBHitRatios: builder.query<BBBHitRatiosResponse, BBBHitRatiosParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/bbb/hitratios/`,
                    params
                }
            }
        }),
        getBBBInBoundStats: builder.query<BBBInBoundStatsResponse, BBBInBoundStatsParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/bbb/inboundstats/`,
                    params
                }
            }
        }),
        getBBBTotalNegotiations: builder.query<TotalNegotiationsChartResponse, TotalNegotiationsChartParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/bbb/totalnegotiations/`,
                    params
                }
            }
        }),
        getBBBSubmissionTrend: builder.query<SubmissionTrendResponse, SubmissionTrendParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/bbb/submissiontrend/`,
                    params
                }
            }
        }),
        getBBBNegotiationDetails: builder.query<GenericPaginatedApi<NegotiationDetailsResponse>, NegotiationDetailsParams>({
            query: (params) => {
                return {
                    url: `${baseUrl}/bbb/negotiationdetails/`,
                    params
                }
            }
        }),
        getProvincialBBBGeojson: builder.query<GeoJSON | TopoJSON, void>({
            query: () => {
                return {
                    url: `${baseUrl}/bbb/getjsons/get_provincial_geojson/`,
                }
            },
        }),
        getRegionalBBBGeojson: builder.query<GeoJSON | TopoJSON, void>({
            query: () => {
                return {
                    url: `negotiation/charts/bbb/getjsons/get_regional_geojson/`,
                }
            },
        }),
        getBBBNegotiationsByZone: builder.query<BBBNegotiationsByZoneResponse[], BBBNegotiationsByZoneParams>({
            query: (params) => {
                return {
                    url: `/negotiation/charts/bbb/negotiationsbyzone/`,
                    params,
                }
            },
        }),
        getBrokersListTable: builder.query<GenericPaginatedApi<BrokersListTableResponse> & { amount: number, premiums: number }, BrokersListTableParams>({
            query: (params) => {
                return {
                    url: `/negotiation/charts/bbb/listtable/`,
                    params: {
                        ...params,
                        entity: ChartEntity.BROKER
                    },
                }
            },
        }),
        getBankBranchesListTable: builder.query<GenericPaginatedApi<BankBranchesListTableResponse> & { amount: number, premiums: number }, BankBranchesListTableParams>({
            query: (params) => {
                return {
                    url: `/negotiation/charts/bbb/listtable/`,
                    params: {
                        ...params,
                        entity: ChartEntity.BANK_BRANCH
                    },
                }
            },
        }),
        getBankBranchesIstatData: builder.query<IstatData, { provinces?: string }>({
            query: (params) => {
                return {
                    url: `/negotiation/charts/bbb/istat_data/`,
                    params: {
                        ...params,
                        entity: ChartEntity.BANK_BRANCH
                    }
                }
            },
        }),
        getBrokerIstatData: builder.query<Omit<YearlyData, 'persons_employed'>, { provinces?: string }>({
            query: (params) => {
                return {
                    url: `/negotiation/charts/bbb/istat_data/`,
                    params: {
                        ...params,
                        entity: ChartEntity.BROKER
                    }
                }
            },
        }),
    })
})

export const {
    useLazyGetNegotiationsStatusChartQuery,
    useLazyGetNegotiationsPremiumsChartQuery,
    useLazyGetGapToPipelineChartQuery,
    useLazyGetSubmissionReceptionChartQuery,
    useLazyGetBoundRatiosChartQuery,
    useLazyGetNegotiationsResultsChartQuery,
    useLazyGetMonthlyHitRatioChartQuery,
    useLazyGetAnnualHitRatioChartQuery,
    useLazyGetNetPremiumOurShareChartQuery,
    useLazyGetNetPremiumOurShareTypeBusinessChartQuery,
    useLazyGetBrokerDataTotalsChartQuery,
    useLazyGetAnnualGoalChartQuery,
    useLazyGetNetPremiumOurShareSumChartQuery,
    useLazyGetNetPremiumOurShareDetailsChartQuery,
    useLazyGetAccountNegotiationsCountChartQuery,
    useLazyGetTrendAgencyChartQuery,
    useLazyGetUnderProcessingAgencyNegotiationsStatusChartQuery,
    useLazyGetBrokerDataDetailsChartQuery,
    useLazyGetGapToPlanChartQuery,
    useLazyGetRenewRetentionChartQuery,
    useLazyGetNegotiationTotalChartQuery,
    useLazyGetPortfolioToBeUploadedQuery,
    useLazyGetPortfolioUploadedQuery,
    useLazyGetPortfolioStatusUploadQuery,
    useLazyGetPortfolioBrokersQuery,
    useLazyGetPortfolioLobsQuery,
    useLazyGetBrokerSpecificDataDetailsChartQuery,
    useLazyGetBrokerDataDownloadQuery,
    useGetBBBSubmissionReceptionQuery,
    useGetBBBHitRatiosQuery,
    useGetBBBInBoundStatsQuery,
    useGetBBBTotalNegotiationsQuery,
    useGetBBBSubmissionTrendQuery,
    useLazyGetBBBNegotiationDetailsQuery,
    useGetProvincialBBBGeojsonQuery,
    useGetRegionalBBBGeojsonQuery,
    useGetBBBNegotiationsByZoneQuery,
    useLazyGetBrokersListTableQuery,
    useLazyGetBankBranchesListTableQuery,
    useGetBrokerRenewRetentionChartQuery,
    useGetBankBranchesIstatDataQuery,
    useGetBrokerIstatDataQuery,
} = chartsApiSlice