import React, {Dispatch, useMemo, useRef, useState} from "react";
import {Row, Select, Switch} from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import {useGetProvincialBBBGeojsonQuery, useGetRegionalBBBGeojsonQuery} from "../../redux/api/chartsApiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {BBBNegotiationsByZoneResponse, CalculationType, ZoneType} from "../../types/charts";
import BBBChartCardContainer from "./BBBChartCardContainer";
import {ITALY} from "./broker/BrokersTable";

highchartsMap(Highcharts);
Highcharts.addEvent(Highcharts.Axis, 'afterInit', function () {

    // @ts-ignore
    const that: any = this

    const logarithmic = that.logarithmic;

    if (logarithmic && that.options.allowNegativeLog) {

        // Avoid errors on negative numbers on a log axis
        that.positiveValuesOnly = false;

        // Override the converter functions
        logarithmic.log2lin = (num: number) => {
            const isNegative = num < 0;

            let adjustedNum = Math.abs(num);

            if (adjustedNum < 10) {
                adjustedNum += (10 - adjustedNum) / 10;
            }

            const result = Math.log(adjustedNum) / Math.LN10;
            return isNegative ? -result : result;
        };

        logarithmic.lin2log = (num: number) => {
            const isNegative = num < 0;

            let result = Math.pow(10, Math.abs(num));
            if (result < 10) {
                result = (10 * (result - 1)) / (10 - 1);
            }
            return isNegative ? -result : result;
        };
    }
});
export default function MapChart({selectedOption, setSelectedOption, data, zoneType, setZoneType, loadingData, legendHeight, chartHeight}: {
    data?: BBBNegotiationsByZoneResponse[],
    selectedOption: string,
    setSelectedOption: Dispatch<string>,
    zoneType: ZoneType,
    setZoneType: (v: ZoneType) => void,
    loadingData: boolean,
    legendHeight?: number,
    chartHeight?: number
}) {

    const chartRef = useRef<HighchartsReact.RefObject | null>(null);
    const [calculationType, setCalculationType] = useState<CalculationType>(CalculationType.NEGOTIATIONS_COUNT)

    const {
        data: regionalGeojson,
        isFetching: isFetchingRegionalGeojson,
        isUninitialized: isUninitializedRegionalGeojson
    } = useGetRegionalBBBGeojsonQuery(zoneType === ZoneType.REGION ? undefined : skipToken)
    const {
        data: provincialGeojson,
        isFetching: isFetchingProvincialGeojson,
        isUninitialized: isUninitializedProvincialGeojson
    } = useGetProvincialBBBGeojsonQuery(zoneType === ZoneType.PROVINCE ? undefined : skipToken)

    const geojson = useMemo(() => zoneType === ZoneType.REGION ? regionalGeojson : provincialGeojson, [provincialGeojson, regionalGeojson, zoneType])
    const loading = useMemo(() => loadingData || (zoneType === ZoneType.REGION ?
            (isFetchingRegionalGeojson || isUninitializedRegionalGeojson) :
            (isFetchingProvincialGeojson || isUninitializedProvincialGeojson)),
        [isFetchingProvincialGeojson, isFetchingRegionalGeojson, isUninitializedProvincialGeojson, isUninitializedRegionalGeojson, loadingData, zoneType])

    const options = useMemo(() => {
        return {
            chart: {
                animation: false,
                height: chartHeight
            },
            accessibility: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: null
            },
            exporting: {
                enabled: false,
            },
            legend: (data?.length) ? {
                layout: 'vertical',
                backgroundColor: 'rgba(255,255,255,0.85)',
                floating: false,
                verticalAlign: 'bottom',
                align: 'left',
                symbolHeight: legendHeight,
            } : false,
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom',
                    align: 'right',
                }
            },
            colorAxis: {
                min: 0,
                type: 'logarithmic',
                allowNegativeLog: true,
            },
            series: [
                {
                    name: calculationType === CalculationType.PREMIUMS ? 'Premi' : 'Numero trattative',
                    mapData: geojson,
                    data: data?.map(el => [
                        el.zone.value,
                        calculationType === CalculationType.PREMIUMS ? el.premium : el.negotiations,
                        el.zone.value === selectedOption,
                        el.zone.label
                    ]),
                    keys: zoneType === ZoneType.REGION ? ['provinces', 'value', 'selected', 'label'] : ['prov_acr', 'value', 'selected', 'label'],
                    joinBy: zoneType === ZoneType.REGION ? 'provinces' : 'prov_acr',
                    allowPointSelect: true,
                    dataLabels: {
                        enabled: false,
                    },
                    tooltip: {
                        pointFormat:
                            `{point.label}: ${calculationType === CalculationType.PREMIUMS ? "{point.value:,.2f}€" : "{point.value}"}`
                    },
                    states: {
                        hover: {
                            color: '#BADA55'
                        },
                        select: {
                            color: '#FF0000',
                            borderColor: '#000000',
                            borderWidth: 2
                        },
                    },
                    point: {
                        events: {
                            select: function (event: any) {
                                setSelectedOption(zoneType === ZoneType.REGION ? event.target.properties.provinces : event.target.properties.prov_acr)
                            },
                            unselect: function (event: any) {
                                if (event.target.selected) {
                                    setSelectedOption(ITALY)
                                }
                            },
                        }
                    }
                }
            ]
        }
    }, [calculationType, chartHeight, data, geojson, legendHeight, selectedOption, setSelectedOption, zoneType])

    const selectOptions = useMemo(() => {
        return [
            {
                label: "Per regione",
                value: ZoneType.REGION
            },
            {
                label: "Per provincia",
                value: ZoneType.PROVINCE
            }
        ]
    }, [])

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"} align={"middle"} gutter={[16, 16]}>
            <Select
                options={selectOptions}
                value={zoneType}
                onSelect={setZoneType}
                allowClear={false}
                style={{width: "150px"}}/>
            <Switch
                size={"small"}
                checked={calculationType === 1}
                onChange={() => setCalculationType(prevState => prevState === CalculationType.PREMIUMS ? CalculationType.NEGOTIATIONS_COUNT : CalculationType.PREMIUMS)}
                checkedChildren={"Trattative"}
                unCheckedChildren={"Premi"}
                style={{width: "77px"}}
            />
        </Row>
    }, [calculationType, selectOptions, setZoneType, zoneType])

    return <BBBChartCardContainer
        loading={loading}
        title={cardTitle}
        bodyStyle={{
            padding: !loading ? 0 : undefined
        }}>
        <HighchartsReact
            constructorType={'mapChart'}
            highcharts={Highcharts}
            ref={chartRef}
            options={options}
        />
    </BBBChartCardContainer>
}