import {Dropdown, Menu, MenuProps} from 'antd';
import {useGetNegotiationDetailQuery} from "../../redux/api/negotiationsApiSlice";
import {useParams} from "react-router-dom";
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {States} from "../../types";
import {useLazyGetLetterQuery} from "../../redux/api/coverageConfirmationApiSlice";

const PrintCoverageConfirmationButton = () => {
  const {id} = useParams();

  const {data: negotiation} = useGetNegotiationDetailQuery(id ?? skipToken);
  const [downloadLetter, {isLoading, isFetching}] = useLazyGetLetterQuery();

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case "quotation":
        if (id && negotiation) {
          downloadLetter({uuid: id, coverageconfirmation_uuid: negotiation.coverageconfirmation ?? undefined});
        }
        break;
      case "coverage_confirmation":
        if (id) {
          downloadLetter({uuid: id});
        }
        break;
      default:
        return;
    }
  };


  /*
    Referred,
    ReferralApproved,
    Rore,
    Reported,
    Assegnata,
    Working,
    Quoted = default stampa lettera di quotazione no conf cop,

    Declined = pulsante disabilitato,
    NotTakenUp,
    ReferralDeclined,
    Stornata,

    Bound = default stampa conferma di copertura possibilità di stampare lettera di quotazione,
    Draft,
    Issued,
    InRinnovo,
    DaRinnovare,


    NonRinnovata*/
  const isPrintButtonDisabled = negotiation ? [States.Declined, States.NotTakenUp, States.ReferralDeclined, States.Stornata].includes(negotiation.state) : false;
  const isQuotationLetterDefaultOption = negotiation ? [States.Rore, States.Reported, States.Assegnata, States.Working, States.Quoted, States.Referred, States.ReferralApproved].includes(negotiation.state) : false
  const isCoverageConfimationPrintAvailable = negotiation ? [States.Bound, States.Draft, States.Issued, States.InRinnovo, States.DaRinnovare].includes(negotiation.state) : false;

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key={"quotation"}>Lettera di quotazione</Menu.Item>
      {isCoverageConfimationPrintAvailable &&
        <Menu.Item key={"coverage_confirmation"}>Conferma di copertura</Menu.Item>}
    </Menu>
  );


  return (
    <>
      <Dropdown.Button
        overlay={menu} disabled={isPrintButtonDisabled || isLoading || isFetching}
        loading={isLoading || isFetching}
        onClick={() => {
          if (id) {
            if (isQuotationLetterDefaultOption) {
              /*stampa lettera di quotazione*/
              downloadLetter({uuid: id, coverageconfirmation_uuid: negotiation?.coverageconfirmation ?? undefined});
            } else {
              /*stampa conferma di copertura*/
              downloadLetter({uuid: id});
            }
          }
        }}>
        Stampa
      </Dropdown.Button>
    </>
  );
}

export default PrintCoverageConfirmationButton;