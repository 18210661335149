import React, {useCallback, useEffect, useState} from "react";
import {EditOutlined} from "@ant-design/icons";
import {Button, Col, Input, List, Row, Select, Space, Tag} from "antd";
import {MeetingNotesModel} from "../../types/commercialDetail";
import {LobModel} from "../../types/options";
import {useUpdateMeetingNoteMutation} from "../../redux/api/commercialDetailApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {canModifyCommercialDetail} from "../../utils/permission";
import DatePicker from "../DatePicker";
import dayjs from "dayjs";

export default function MeetingNoteItem({item, lobs, deleteNote, currentPage}: { item: MeetingNotesModel, lobs: LobModel[], deleteNote: () => void, currentPage: number }) {
    const user = useSelector(selectUser)

    const [edit, setEdit] = useState<boolean>(false)
    const [currentNote, setCurrentNote] = useState({meeting_date: dayjs(item.meeting_date), lob: item.lob, note: item.note})
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

    const [updateMeetingNoteRequest] = useUpdateMeetingNoteMutation()

    const updateMeetingNote = useCallback(()=> {
        updateMeetingNoteRequest({...currentNote, meeting_date: currentNote.meeting_date.format("YYYY-MM-DD"), uuid: item.uuid})
        setEdit(false)
    }, [currentNote, item.uuid, updateMeetingNoteRequest])

    useEffect(() => {
        setEdit(false)
    }, [currentPage])

    const renderEditNote = () => {
        return <div>
            <Row justify={"space-between"} style={{marginBottom: "1rem"}}>
                <Col span={13}>
                    LOB
                    <Select
                        value={currentNote.lob}
                        virtual={false}
                        mode={"multiple"}
                        showArrow
                        style={{width: "100%"}}
                        options={lobs.map(el => ({label: el.name, value: el.uuid}))}
                        onChange={value => setCurrentNote({...currentNote, lob: value})}
                    />
                </Col>
                <Col span={7}>
                    Data incontro
                    <DatePicker
                        style={{width: "100%"}}
                        value={currentNote.meeting_date}
                        format={'D MMM YYYY'}
                        onChange={(date) => setCurrentNote({...currentNote, meeting_date: date ? date : dayjs()})}
                    />
                </Col>
            </Row>
            <Input.TextArea
                value={currentNote.note}
                autoSize={{minRows: 3, maxRows: 3}}
                onChange={event => setCurrentNote({...currentNote, note: event.target.value})}
            />
            <Row justify={"space-between"} style={{marginTop: "1rem"}}>
                {!showConfirmDelete && <Button type={"primary"} danger onClick={() => setShowConfirmDelete(true)}>
                    Elimina nota
                </Button>}
                {showConfirmDelete && <div>
                    <Button type={"link"} size={"middle"} onClick={() => setShowConfirmDelete(false)}>
                        Annulla
                    </Button>
                    <Button danger type={"link"} size={"middle"} onClick={() => {
                        deleteNote()
                        setEdit(false)
                        setShowConfirmDelete(false)
                    }}>
                        Conferma
                    </Button>
                </div>}
                <Space>
                    <Button onClick={() => {
                        setCurrentNote({meeting_date: dayjs(item.meeting_date), lob: item.lob, note: item.note})
                        setEdit(false)
                    }}>Annulla</Button>
                    <Button type={"primary"} onClick={() => updateMeetingNote()} disabled={currentNote.note.trim() === ""}>
                        Salva
                    </Button>
                </Space>
            </Row>
        </div>
    }

    return <div>
        <List.Item key={item.uuid} style={{padding: "10px 0", borderBottom: "1px solid gainsboro"}}>
            <List.Item.Meta
                title={<div>
                    {!edit && <div>
                        <Row style={{marginBottom: "1rem"}}>
                            <Space>
                                {item.lob?.map(el => <Tag color={el.color} style={{borderRadius: "100px"}}>
                                    {el.label}
                                </Tag>)}
                            </Space>
                        </Row>
                        <Row justify={"space-between"}>
                              <span style={{color: "rgba(0, 0, 0, 0.45)", fontWeight: 500}}>
                                  {item.user_creator_name} - {dayjs(item.meeting_date).format("D MMM YYYY")}
                              </span>
                            {canModifyCommercialDetail(user.usertypes.map(el=>el.code)) && <Button type={"link"} size={"small"} icon={<EditOutlined/>} onClick={() => setEdit(true)}/>}
                        </Row>
                    </div>}
                </div>}
                description={<div style={{color: "black"}}>
                    {!edit && item.note}
                    {edit && renderEditNote()}
            </div>}
            />
        </List.Item>
    </div>
}