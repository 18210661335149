import React, {useState} from "react";
import {useGetNegotiationStatusTransitionsQuery} from "../../../../../redux/api/negotiationsApiSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {HistoryModal} from "./state";
import {RightOutlined} from "@ant-design/icons";

const HistoryModalButton = ({negotiationId}: { negotiationId: string | undefined }) => {

  const [
    isHistoryModalOpen,
    setIsHistoryModalOpen
  ] = useState(false);

  const {
    data: negotiationStatusHistory,
  } = useGetNegotiationStatusTransitionsQuery(negotiationId ?? skipToken);

  return (
    <>
      {negotiationStatusHistory && (
        <HistoryModal
          negotiationStatusHistory={negotiationStatusHistory}
          isOpen={isHistoryModalOpen}
          onClose={() => setIsHistoryModalOpen(false)}/>
      )}
      <div
        className={'btn-text'}
        onClick={() => setIsHistoryModalOpen(true)}>
        <p style={{color: 'inherit !important'}}>
          Storico
        </p>
        <RightOutlined style={{marginLeft: '4px'}}/>
      </div>
    </>

  );
}

export default HistoryModalButton