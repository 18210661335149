import {Button, List, Tag} from "antd";
import React, {useCallback, useState} from "react";
import {AdditionalInsuredWithIndex} from "../../types/additionalInsureds";

export default function AdditionalInsuredsCandidatesList({
                                                             candidates,
                                                             isLoading,
                                                             btnLabel,
                                                             isUninitialized,
                                                             disabled,
                                                             onSelectedCandidate
                                                         }: {
    isUninitialized: boolean,
    candidates: AdditionalInsuredWithIndex[],
    isLoading: boolean,
    btnLabel: string,
    disabled: boolean,
    onSelectedCandidate: (candidate: AdditionalInsuredWithIndex) => Promise<unknown>
}) {
    const [itemSelected, setItemSelected] = useState<number | null>(null)
    const [page, setPage] = useState(1)

    const handleClick = useCallback(async (candidate: AdditionalInsuredWithIndex) => {
        setItemSelected(candidate.idx)
        try {
            await onSelectedCandidate(candidate)
        } finally {
            setItemSelected(null)

        }
    }, [onSelectedCandidate])


    return <>
        {!isUninitialized && <List
            loading={isLoading && itemSelected === null}
            dataSource={candidates}
            pagination={candidates.length ? {
                current: page,
                onChange: p => setPage(p),
                pageSize: 5,
                simple: true
            } : false}
            renderItem={(item) => {
                const isButtonHidden = item.already_added

                return (
                    <List.Item
                        actions={[
                            !isButtonHidden ?
                                [
                                    <Button
                                        disabled={isLoading || disabled}
                                        loading={isLoading && item.idx === itemSelected}
                                        key={`${item.idx}_btn`}
                                        onClick={() => {
                                            handleClick(item)
                                        }}>
                                        {btnLabel}
                                    </Button>
                                ] :
                                [<Tag color={'green'}>GIÀ INSERITO</Tag>]
                        ]}
                    >
                        <List.Item.Meta
                            title={item.name}
                            description={<>
                                {item.vat_number ?? item.fiscal_code ?? 'Partita IVA / C.F. non presente'}
                                <br/>
                                {!!item.city && item.city} {!!item.province && '(' + item.province + ')'}
                            </>}
                        />
                    </List.Item>

                )
            }}
        />}
    </>
}