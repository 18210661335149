import {Col, Divider, message, Row, Select} from "antd";
import ConfirmCancelModal from "components/modals/ConfirmCancelModal";
import {useEffect, useState} from "react";
import {DataNode} from "antd/lib/tree";
import {useLazyGetFoldersStructureQuery} from "../../redux/api/documentsApiSlice";
import {FileOutlined} from "@ant-design/icons";

export default function MoveDocumentModal({
                                              document,
                                              move,
                                              visible,
                                              options,
                                              close
                                          }: { document: DataNode[], move: (dirType: string, documents: DataNode[]) => void, visible: boolean, options: {label: string, value: string}[], close: () => void }) {

    const [dirType, setDirType] = useState<string>("")

    return <ConfirmCancelModal
        title={'Sposta documenti'}
        open={visible}
        onOk={() => {
            move(dirType, document)
            setDirType("")
            close()
        }}
        width={700}
        okButtonProps={{disabled: dirType === ""}}
        destroyOnClose
        okText={'Sposta'}
        onCancel={() => {
            setDirType("")
            close()
        }}
    >
        <ul>{document.map((e, index) => <li key={index} style={{marginTop: '0.75rem', marginLeft: "40px"}}><span>{e.title as string}</span></li>)}</ul>
        <Divider/>
        <Row justify={'space-around'} align={"middle"} style={{marginTop: "20px"}}>
            <Col push={1} md={7} sm={24} xs={24}>
                <span>Seleziona cartella:</span>
            </Col>
            <Col flex={1} offset={4} pull={1}>
                <Select
                    virtual={false}
                    style={{width: '100%'}}
                    value={dirType}
                    options={options}
                    onChange={v => {setDirType(v)}}
                />
            </Col>
        </Row>
    </ConfirmCancelModal>
}