import {Col, Row, Space, Typography} from "antd";
import {NegotiationModel} from "../../../../types/negotiations";
import {StatusIndicator} from "./utils";
import StatusHelpModal from "../../../modals/StatusHelpModal";
import HistoryModalButton from "./modals/HistoryModalButton";
import React from "react";
import RestoreTransitionButton from "./modals/RestoreTransitionButton";

const {Paragraph} = Typography;


const NegotiationStatus = ({negotiation, handleStateTransition, isTransitionInProgress}: {
  negotiation: NegotiationModel,
  handleStateTransition?: (data: {
    state_to: number,
    reason?: string | undefined,
    notes?: string | undefined
  }) => void,
  isTransitionInProgress?: boolean
}) => {

  return (
    <>
      <Row
        justify={"space-between"}
        align={"middle"}
        gutter={[0, 8]}>
        <Col>
          <Space>
            <Paragraph>Stato Trattativa</Paragraph>
            {!!handleStateTransition && (
              <RestoreTransitionButton
                negotiation={negotiation}
                handleStateTransition={handleStateTransition}/>
            )}
          </Space>
        </Col>
        <Col>
          <HistoryModalButton negotiationId={negotiation.uuid}/>
        </Col>
      </Row>
      <Row style={{marginTop: '0.5rem'}}>
        <Col span={24}>
          <Space direction={"horizontal"} size={4}>
            <StatusIndicator
              state={negotiation.state}/>
            <StatusHelpModal/>
          </Space>
        </Col>
      </Row>
    </>
  );
}

export default NegotiationStatus;