import dayjs from "dayjs"
import { NegotiationFormModel, NegotiationModel, UpdateNegotiationRequest } from "types/negotiations"
import _, { cloneDeep, isNil } from "lodash"
import {joinTimeArray, splitTimeString} from "./timeUtils";

export const normalizePercTo1 = (num: number) => num / 100
export const normalizePercTo100 = (num: number) => num * 100

//map fetched negotiation into one structure usable by the global form (date -> dayjs, select with default value, perc 0-1 => 0-100.. )
export function transformNegotiationToFormFields(negotiation: NegotiationModel): NegotiationFormModel {
    //{...data} do not work! (data has nested fields, and spread operator do not create deep copies of them...)

    const negotiationCopy = cloneDeep(negotiation)
    if (negotiationCopy.branches)
        for (const key in negotiationCopy.branches)
            negotiationCopy.branches[key] *= 100

    return {
        ...negotiationCopy,
        lob: negotiation.lob.uuid,
        underwriter: negotiation.underwriter?.uuid || null,
        attorney_from: negotiation.attorney_from?.uuid || null,
        isia_sale: negotiation.isia_sale?.uuid || null,
        broker: negotiation.broker?.uuid || null,
        fee_total_perc: !isNil(negotiation.fee_total_perc) ? negotiation.fee_total_perc * 100 : null,
        fee_broker_perc: !isNil(negotiation.fee_broker_perc) ? negotiation.fee_broker_perc * 100 : null,
        fee_agency_perc: !isNil(negotiation.fee_agency_perc) ? negotiation.fee_agency_perc * 100 : null,
        fee_bank_perc: !isNil(negotiation.fee_bank_perc) ? negotiation.fee_bank_perc * 100 : null,
        reception_date: negotiation.reception_date ? dayjs(negotiation.reception_date) : undefined,
        policy_effective_date: negotiation.policy_effective_date ? dayjs(negotiation.policy_effective_date) : undefined,
        effect_hour: negotiation.effect_hour ? splitTimeString(negotiation.effect_hour) : undefined,
        policy_expiring_date: negotiation.policy_expiring_date ? dayjs(negotiation.policy_expiring_date) : undefined,
        uw_year: negotiation.uw_year ? dayjs().year(negotiation.uw_year) : undefined,
        prorata_effective_date: negotiation.prorata_effective_date ? dayjs(negotiation.prorata_effective_date) : undefined,
        prorata_expiring_date: negotiation.policy_effective_date ? dayjs(negotiation.policy_effective_date) : undefined,
        prorata_uw_year: negotiation.prorata_uw_year ? dayjs().year(negotiation.prorata_uw_year) : undefined,
        prorata_total: !isNil(negotiation.prorata_isa_share) && negotiation.isa_share_perc ? negotiation.prorata_isa_share / negotiation.isa_share_perc : undefined,
        isa_share_perc: !isNil(negotiation.isa_share_perc) ? negotiation.isa_share_perc * 100 : null,
        refreshment_commissions: !isNil(negotiation.refreshment_commissions) ? negotiation.refreshment_commissions * 100 : null,
        contractor: negotiation.contractor?.uuid || null,
        fund: negotiation.fund?.uuid || null,
        reinsurer_broker: negotiation.reinsurer_broker?.uuid || null,
        reinsured_share_perc: !isNil(negotiation.reinsured_share_perc) ? negotiation.reinsured_share_perc * 100 : null,
        reinsurer_broker_fee_perc: !isNil(negotiation.reinsurer_broker_fee_perc) ? negotiation.reinsurer_broker_fee_perc * 100 : null,
        portfolio_waiting_reason: negotiation.portfolio_waiting_reason ? negotiation.portfolio_waiting_reason : 'no_answer',
        ...(negotiation.delegated_insurer ? {
            delegated_insurer: negotiation.delegated_insurer.uuid
        } : { delegated_insurer: null }),
    } as NegotiationFormModel


}


export function transformNegotiationFormToUpdateRequest(data: NegotiationFormModel): UpdateNegotiationRequest {
    //{...data} do not work! (data has nested fields, and spread operator do not create deep copies of them...)
    let dataCopy = cloneDeep(data)

    if (dataCopy.branches)
        for (const key in dataCopy.branches)
            dataCopy.branches[key] /= 100

    const parsed = {
        ...dataCopy,
        isia_sale: dataCopy.isia_sale || null,
        reception_date: dataCopy.reception_date ? dayjs(dataCopy.reception_date).toDate() : null,
        policy_effective_date: dataCopy.policy_effective_date ? new Date(dataCopy.policy_effective_date.format('YYYY-MM-DD')) : null,
        effect_hour: dataCopy.effect_hour ? joinTimeArray(dataCopy.effect_hour) : "24:00",
        policy_expiring_date: dataCopy.policy_expiring_date ? new Date(dataCopy.policy_expiring_date.format('YYYY-MM-DD')) : null,
        uw_year: dataCopy.uw_year ? dataCopy.uw_year.year() : null,
        prorata_uw_year: dataCopy.prorata_uw_year ? dataCopy.prorata_uw_year.year() : null,
        prorata_effective_date: dataCopy.prorata_effective_date ? new Date(dataCopy.prorata_effective_date.format('YYYY-MM-DD')) : null,
        contractor: dataCopy.contractor || null,
        delegated_insurer: dataCopy.delegated_insurer || null,
        attorney_from: dataCopy.attorney_from || null,
        fee_total_perc: !isNil(dataCopy.fee_total_perc) ? dataCopy.fee_total_perc / 100 : 0,
        fee_broker_perc: !isNil(dataCopy.fee_broker_perc) ? dataCopy.fee_broker_perc / 100 : 0,
        fee_agency_perc: !isNil(dataCopy.fee_agency_perc) ? dataCopy.fee_agency_perc / 100 : 0,
        fee_bank_perc: !isNil(dataCopy.fee_bank_perc) ? dataCopy.fee_bank_perc / 100 : null,
        isa_share_perc: !isNil(dataCopy.isa_share_perc) ? dataCopy.isa_share_perc / 100 : 0,
        refreshment_commissions: !isNil(dataCopy.refreshment_commissions) ? dataCopy.refreshment_commissions / 100 : 0,
        reinsured_share_perc: !isNil(dataCopy.reinsured_share_perc) ? dataCopy.reinsured_share_perc / 100 : 0,
        reinsurer_broker_fee_perc: !isNil(dataCopy.reinsurer_broker_fee_perc) ? dataCopy.reinsurer_broker_fee_perc / 100 : 0,
        portfolio_waiting_reason: dataCopy.portfolio_waiting_reason !== 'no_answer' ? dataCopy.portfolio_waiting_reason : null,
    }

    return _.pickBy(parsed, (value, key) => {
        // delete fields present in FE but not necessary for BE
        if (key !== 'prorata_total'){
            return key in data
        }
    }) as UpdateNegotiationRequest
}