import React, {useCallback} from "react";
import { Modal} from "antd";
import { ContractorModel} from "../../types/contractors";
import ConnectContractorAndCustomer from "../contractorCustomer/ConnectContractorAndCustomer";

export default function ConnectContractorAndCustomerModal({
                                                       isFund,
                                                       isVisible,
                                                       setIsVisible,
                                                       contractor,
                                                       updateContractor
                                                     }: { isFund: boolean, isVisible?: boolean, setIsVisible: (visible: boolean) => void, updateContractor: (contractor: ContractorModel) => void, contractor: ContractorModel }) {


  const updateContractorAndCloseModal = useCallback((contractor: ContractorModel) => {
    updateContractor(contractor)
    setIsVisible(false)
  }, [setIsVisible, updateContractor])

  return (
      <Modal
          open={isVisible}
          onCancel={() => setIsVisible(false)}
          footer={false}
          title={'Caricamento dati del contraente'}
      >
        <ConnectContractorAndCustomer contractor={contractor} isFund={isFund} updateContractor={updateContractorAndCloseModal}/>
      </Modal>
  )
}