import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from "react";
import {ALL} from "./BrokerChartsContext";

type Params = {
    budget_group: string,
}

type BrokersChartsParamsType = {
    params: Params,
    setParams: Dispatch<SetStateAction<Params>>
}

const defaultValues: Params = {
    budget_group: ALL,
}


const BrokersChartsParams = createContext<BrokersChartsParamsType>({
    params: defaultValues,
    setParams: () => undefined

})

export const useBrokersChartsParamsContext = () => useContext(BrokersChartsParams)

export default function BrokersChartsParamsContext(props: PropsWithChildren) {

    const [params, setParams] = useState<Params>(defaultValues)

    return <BrokersChartsParams.Provider
        value={{params, setParams}}
    >
        {props.children}
    </BrokersChartsParams.Provider>
}