import PieChartContainer from "./PieChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import SelectMonth from "../../select/SelectMonth";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import PieChart from "../HighCharts/PieChart";
import {capitalizeFirstLetter, euro, numeric, percentage} from "../../../utils/formatters";
import dayjs from "dayjs";
import {
    useLazyGetMonthlyHitRatioChartQuery,
} from "../../../redux/api/chartsApiSlice";
import {PointOptionsObject} from "highcharts";
import {selectUser} from "../../../redux/features/userSlice";

export default function MonthlyHitRatioChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className?: string, calculationType: string }) {

    const [date, setDate] = useState(dayjs(new Date()))
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetMonthlyHitRatioChartQuery()
    const options = useSelector(selectOptions)
    const title = 'Hit ratio mensile'
    const sideData = [
        {key: 'Polizze totali al mese selezionato', value: numeric(data ? data.total : 0)},
        {key: 'Totale premi quota ISA', value: euro(data ? data.isa_premium : 0)}
    ]
    const [series, setSeries] = useState<PointOptionsObject[]>([])
    const description = <>
        Il grafico mostra la distribuzione delle trattative new business (limitate all’ultimo giorno del mese selezionato)
        nei diversi stati e l’Hit ratio, inteso come la percentuale della sommatoria dei premi (o il conteggio delle trattative in base alla selezione)
        in Bound sulla sommatoria dei premi (o il conteggio delle trattative in base alla selezione) di tutte le trattative ricevute.
        <dl>
            <dt>Totale Complessivo:</dt>
            <dd>è il numero delle trattative totali new business.</dd>
            <dt>Premio quota ISA complessivo:</dt>
            <dd>è la sommatoria dei premi new business in Bound.</dd>
        </dl>
    </>

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                year: date.year(),
                month: date.month(),
                budget_group: budgetGroup.value,
                isia: isia.value,
                calculation_type: props.calculationType
            })
        }
    }, [budgetGroup, date, getData, isia, props.calculationType, uwYear])

    useEffect(() => {
        if (data) {
            setSeries(data ? data.states.map(el => {
                const state = options.states.find(state => state.value === el.state)
                return {...el, stateName: state?.text, stateColor: state?.color}
            }).map(el => ({
                name: el.stateName,
                y: el.negotiations,
                color: el.stateColor,
            })) : [])
        }
    }, [data, options])

    const pieChart = <PieChart
        chartRef={props.chartRef}
        options={{
            chart: {
                borderRadius: 12
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: title
                    },
                    subtitle: {
                        text: `Parametri: ${capitalizeFirstLetter(date.format('MMMM YYYY'))} - UW Year:${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                    }
                }
            },
            plotOptions: {
                pie: {
                    showInLegend: true,
                    dataLabels: {
                        enabled: false,
                    },
                }
            },
            legend: {
                verticalAlign: 'bottom',
                align: 'center',
                labelFormat: `{name} - {percentage:.2f}%`
            },
            series: [
                {
                    type: 'pie',
                    data: series,
                },
            ]
        }}
    />

    return <PieChartContainer
        className={props.className}
        loading={isLoading || isFetching}
        description={description}
        valueDescription={'(Bound su totale)'}
        title={title}
        buttons={[
            <label htmlFor={'monthlyHitRatioMonthSelect'} key={'monthlyHitRatioMonthSelect'}
                   style={{fontWeight: '400'}}>Trattative al:</label>,
            <SelectMonth month={date} setMonth={setDate} key={'month-select'}
                         selectProps={{name: "monthlyHitRatioMonthSelect", allowClear: false}}/>,
            <DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}
        pieChart={pieChart}
        sideData={sideData}
        displayValue={percentage(data?.hit_ratio ? data.hit_ratio : 0)}>
    </PieChartContainer>;
}