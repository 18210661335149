import React from "react";
import {ReactElementFactory} from "survey-react-ui";
import {Question} from "survey-react";
import {useDeleteDocumentMutation, useLazyGetDocumentUrlQuery} from "../../redux/api/documentsApiSlice";
import {Button, Col, message, Popconfirm, Row, Space, Typography} from "antd";
import {DeleteOutlined, DownloadOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {GetDocumentsModel} from "../../types/documents";


interface FilePreviewComponentProps {
    question: Question;
}

const FilePreviewComponent: React.FC<FilePreviewComponentProps> = (props) => {
    const {question} = props
    const [getUrl] = useLazyGetDocumentUrlQuery()
    const [deleteDocument] = useDeleteDocumentMutation()
    const handleDownload = (fileItem: GetDocumentsModel) => {
        getUrl({negotiation_uuid: fileItem.negotiation, uuid: fileItem.uuid}).unwrap()
            .then((response) => {
                window.open(response.url)
            })
            .catch((e: any) => {
                message.error("Impossibile aprire il file")
                console.error("Open file", e)
            })
    }
    const handleDelete = (fileItem: GetDocumentsModel) => {
        question.removeFile(fileItem.name)
        deleteDocument({negotiation_uuid: fileItem.negotiation, uuid: fileItem.uuid}).unwrap()
            .then((response) => {
                message.success(fileItem.name + " eliminato")
            })
            .catch((e: any) => {
                message.error("Impossibile eliminare " + fileItem.name)
                console.error("Open file", e)
            })
    }

    if (!question || !question.value) {
        return null;
    }

    return (
        <div style={{
            position: 'absolute',
            top: "40%"
        }}>
            {question.value.map((fileItem: GetDocumentsModel) => (
                <Row gutter={32} style={{width: '100%'}} align={'middle'} key={fileItem.uuid}>
                    <Col flex={1}>
                        <Typography.Title style={{fontWeight: 'normal'}} level={4}>
                            {fileItem.name}
                        </Typography.Title>
                    </Col>
                    <Col>
                        <Space>
                            <Button
                                size={'large'}
                                type={'text'}
                                icon={<DownloadOutlined/>}
                                onClick={() => handleDownload(fileItem)}
                            />
                            <Popconfirm
                                title={"Eliminare il documento?"}
                                onConfirm={() => handleDelete(fileItem)}
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                disabled={question.isReadOnly}
                            >
                                <Button
                                    danger
                                    size={'large'}
                                    type={'text'}
                                    disabled={question.isReadOnly}
                                    icon={<DeleteOutlined/>}
                                />
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            ))}
        </div>
    );
};

ReactElementFactory.Instance.registerElement("sv-file-preview", (props) => {
    return <FilePreviewComponent {...props} />;
});

export default FilePreviewComponent;
