import ChartContainer from "./ChartContainer";
import React, { useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import SelectMonth from "../../select/SelectMonth";
import HighchartsReact from "highcharts-react-official";
import Histogram from "../HighCharts/Histogram";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {SeriesOptionsType} from "highcharts";
import dayjs from "dayjs";
import {capitalizeFirstLetter, euro, numeric} from "../../../utils/formatters";
import {useLazyGetNegotiationsStatusChartQuery} from "../../../redux/api/chartsApiSlice";
import {selectUser} from "../../../redux/features/userSlice";

export default function NegotiationsStatusChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {

    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetNegotiationsStatusChartQuery()
    const options = useSelector(selectOptions)
    const [series, setSeries] = useState<SeriesOptionsType[]>([])
    const [date, setDate] = useState(dayjs(new Date()))
    const description = 'Il grafico mostra il conteggio delle trattative che si trovano in ogni stato al mese di riferimento.'


    useEffect(() => {
        if (data)
            setSeries(data ?
                data.map(el => {
                    const state = options.states.find(state => state.value === el.state)
                    return {
                        type: 'column',
                        name: state?.text,
                        color: state?.color,
                        data: [el.amount],
                        dataLabels: {
                            enabled: true,
                            formatter: function () {
                                const value = this.y ? numeric(this.y) : ""
                                return value
                            }
                        }
                    }
                }) : [])
    }, [data, options])

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                year: date.year(),
                month: date.month(),
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, date, getData, isia, uwYear])


    return <ChartContainer
        loading={isLoading || isFetching}
        description={description}
        className={props.className}
        title={'Stato trattative'}
        buttons={[
            <label htmlFor={'negotiationStatusMonthSelect'} key={'negotiationStatusMonthSelect'}
                   style={{fontWeight: '400'}}>Trattative al:</label>,
            <SelectMonth month={date} setMonth={setDate} key={'month-select'}
                         selectProps={{name: "negotiationStatusMonthSelect", allowClear: false}}/>,
            /*<SelectUW setUw={setUw} key={'uw-select'} uw={uw}/>,*/
            <DownloadButton key={'download-button'} chartRef={props.chartRef}/>
        ]}>
        <Histogram
            chartRef={props.chartRef}
            options={{
                series: series,
                exporting: {
                    chartOptions: {
                        title: {
                            text: 'Stato trattative',
                        },
                        subtitle: {
                            // text: `Parametri: ${capitalizeFirstLetter(month.format('MMMM'))} ${props.year.year()} - ${uw.label}`
                            text: `Parametri: ${capitalizeFirstLetter(date.format('MMMM YYYY'))} - UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        }
                    }
                }
            }}
        />
    </ChartContainer>
}

