import {Budget} from "../../types/budgets";
import {Button, Col, Form, message, Row} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {useUpdateBudgetMutation} from "../../redux/api/budgetsApiSlice";
import {debounce} from "lodash";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {canModifyGapToPlan} from "../../utils/permission";
import {EditOutlined} from '@ant-design/icons';


type DataToUpdate = Pick<Budget,
    'total_goal' |
    'direct_isa_goal' |
    'isia_goal' |
    'hit_ratio_from_quoted' |
    'hit_ratio_from_submission' |
    'regulation_premium_estimate'>

export default function GapToPlanForm({budget}: { budget: Budget }) {

    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [updateBudget, { isLoading, isError}] = useUpdateBudgetMutation()
    const [data, setData] = useState(budget)

    useEffect(() => {
        if (isLoading) {
            message.success('Gap to plan aggiornata')
        }
    }, [isLoading])

    useEffect(() => {
        if (isError) {
            message.error('Gap to plan non aggiornata')
        }
    }, [isError])

    const updateDebounce = useCallback(debounce(
        (dataToUpdate: DataToUpdate) => updateBudget({uuid: budget.uuid, ...dataToUpdate}), 1000), [])

    const user = useSelector(selectUser)

    return <div style={{padding: '1rem'}}>
        {/* form useless only for styling purposes*/}
        <Form
            colon={false}
            labelAlign={'left'}
            labelCol={{span: 16}}
            className={'gap-to-plan-form'}
        >
            <Form.Item label={'Obiettivo direct ISA'}>
                <NumberFormat
                    className={!isDisabled ? `ant-input ant-item-form` : ''}
                    value={data.direct_isa_goal}
                    displayType={isDisabled ? "text" : "input"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    placeholder={"0.00 €"}
                    suffix={" €"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={isDisabled}
                    disabled={isDisabled}
                    isAllowed={(values) => {
                        return typeof values?.floatValue === 'number' && values.floatValue >= 0
                    }}
                    onValueChange={(values) => {
                        const directIsa = values.floatValue ? values.floatValue : 0
                        setData(prevState => {
                            updateDebounce({
                                direct_isa_goal: directIsa,
                                total_goal: prevState.isia_goal + directIsa
                            } as DataToUpdate)
                            return {
                                ...prevState,
                                direct_isa_goal: directIsa,
                                total_goal: prevState.isia_goal + directIsa
                            }
                        })
                    }}
                /></Form.Item>
            <Form.Item label={'Obiettivo ISIA'}>
                <NumberFormat
                    className={!isDisabled ? `ant-input ant-item-form` : ''}
                    value={data.isia_goal}
                    isAllowed={(values) => {
                        return typeof values?.floatValue === 'number' && values.floatValue >= 0
                    }}
                    displayType={isDisabled ? "text" : "input"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    placeholder={"0.00 €"}
                    suffix={" €"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={isDisabled}
                    disabled={isDisabled}
                    onValueChange={(values) => {
                        const isiaGoal = values.floatValue ? values.floatValue : 0
                        setData(prevState => {
                            updateDebounce({
                                isia_goal: isiaGoal,
                                total_goal: prevState.direct_isa_goal + isiaGoal
                            } as DataToUpdate)
                            return {
                                ...prevState,
                                isia_goal: isiaGoal,
                                total_goal: prevState.direct_isa_goal + isiaGoal
                            }
                        })
                    }}
                /></Form.Item>
            <Form.Item label={'Obiettivo totale'}>
                <NumberFormat
                    className={!isDisabled ? `ant-input ant-item-form` : ''}
                    value={data.direct_isa_goal + data.isia_goal}
                    displayType={isDisabled ? "text" : "input"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    placeholder={"0.00 €"}
                    suffix={" €"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={true}
                    disabled={true}
                />
            </Form.Item>
            <Form.Item label={'Hit ratio da quotato'}>
                <NumberFormat
                    className={!isDisabled ? `ant-input ant-item-form` : ''}
                    value={data.hit_ratio_from_quoted * 100}
                    isAllowed={(values) => {
                        return typeof values?.floatValue === 'number' && values.floatValue <= 100 && values.floatValue >= 0
                    }}
                    displayType={isDisabled ? "text" : "input"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    placeholder={"0 %"}
                    suffix={" %"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={isDisabled}
                    disabled={isDisabled}
                    onValueChange={(values) => {
                        const hitRatioFromQuoted = values.floatValue ? values.floatValue / 100 : 0
                        setData(prevState => {
                            updateDebounce({hit_ratio_from_quoted: hitRatioFromQuoted} as DataToUpdate)
                            return {
                                ...prevState,
                                hit_ratio_from_quoted: hitRatioFromQuoted,
                            }
                        })
                    }}
                /></Form.Item>
            <Form.Item label={'Hit ratio da submission'}>
                <NumberFormat
                    className={!isDisabled ? `ant-input ant-item-form` : ''}
                    value={data.hit_ratio_from_submission * 100}

                    displayType={isDisabled ? "text" : "input"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    placeholder={"0 %"}
                    suffix={" %"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={isDisabled}
                    disabled={isDisabled}
                    isAllowed={(values) => {
                        return typeof values?.floatValue === 'number' && values.floatValue <= 100 && values.floatValue >= 0
                    }}
                    onValueChange={(values) => {
                        const hitRatioFromSubmission = values.floatValue ? values.floatValue / 100 : 0
                        setData(prevState => {
                            updateDebounce({hit_ratio_from_submission: hitRatioFromSubmission} as DataToUpdate)
                            return {
                                ...prevState,
                                hit_ratio_from_submission: hitRatioFromSubmission,
                            }
                        })
                    }}
                /></Form.Item>
            <Form.Item label={'Stima premio di regolazione'}>
                <NumberFormat
                    className={!isDisabled ? `ant-input ant-item-form` : ''}
                    value={data.regulation_premium_estimate * 100}

                    displayType={isDisabled ? "text" : "input"}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    placeholder={"0 %"}
                    suffix={" %"}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    readOnly={isDisabled}
                    disabled={isDisabled}
                    isAllowed={(values) => {
                        return typeof values?.floatValue === 'number' && values.floatValue <= 100 && values.floatValue >= 0
                    }}
                    onValueChange={(values) => {
                        const regulationPremiumEstimate = values.floatValue ? values.floatValue / 100 : 0
                        setData(prevState => {
                            updateDebounce({regulation_premium_estimate: regulationPremiumEstimate} as DataToUpdate)
                            return {
                                ...prevState,
                                regulation_premium_estimate: regulationPremiumEstimate,
                            }
                        })
                    }}
                /></Form.Item>


        </Form>
        {!!user.usertypes.length && canModifyGapToPlan(user.usertypes.map(el => el.code)) &&
            <Row justify={'end'}>
              <Col>
                <Button
                    type={isDisabled ? 'default': 'primary'}
                    onClick={() => setIsDisabled(prevState => !prevState)}
                    shape={'circle'}
                    size={'large'}
                    icon={<EditOutlined />}
                />
              </Col></Row>}
    </div>
}