import {useLazyAlignBelatedEmittedQuery} from "redux/api/negotiationBelatedEmittedApiSlice"
import {Button, message, Modal} from "antd";
import {useParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import BelatedEmittedTableAfterAlignment from "./BelatedEmittedTable/BelatedEmittedTableAfterAlignment";
import BelatedEmittedTableBeforeAlignment from "./BelatedEmittedTable/BelatedEmittedTableBeforeAlignment";
import { NegotiationModel} from "../../../../types/negotiations";

export default function BelatedEmittedAlignment({negotiation, onConfirm}: {
  negotiation: NegotiationModel,
  onConfirm: () => void
}) {

  const [align, {error, data}] = useLazyAlignBelatedEmittedQuery()
  const {id} = useParams()
  const [alignmentFailedModalIsVisible, setIsAlignmentFailedModalIsVisible] = useState(false)
  const [alignmentFailedErrors, setAlignmentFailedErrors] = useState<string[]>([])
  const [alignmentComparisonModalIsVisible, setAlignmentComparisonModalIsVisible] = useState(false)

  const onAlignmentWithoutData = useCallback(async () => {
    if (id) {
      try {
        const data = await align({negotiationId: id}).unwrap()
        if (data) {
          if (data?.aligned) {
            // alignment succeed
            onConfirm()
            message.success('Allineamento correttamente effettuato')
          } else {
            // for finalizing alignment user must confirm the alignment
            setAlignmentComparisonModalIsVisible(true)
          }
        }
      } catch {}}
  }, [align, id, onConfirm])

    const onConfirmAlignmentWithData = useCallback(() => {
      onConfirm()
      setAlignmentComparisonModalIsVisible(false)
    }, [onConfirm])

    useEffect(() => {
      // show errors if alignment failed
      if (error) {
        if ('status' in error && error.status === 400) {
          setIsAlignmentFailedModalIsVisible(true)
          setAlignmentFailedErrors((error.data as { error: string }[]).map(obj => obj.error))
        } else {
          message.error("Errore nell'allineamento")
        }
      }
    }, [error])

    return <>

      <Button
          danger
          onClick={onAlignmentWithoutData}>
        Allinea
      </Button>


      <Modal open={alignmentFailedModalIsVisible}
             title={<p style={{color: "red"}}>Impossibile effettuare l'allineamento</p>}
             footer={null}
             onCancel={() => setIsAlignmentFailedModalIsVisible(false)}
      >
        <p style={{paddingBottom: "100px"}}>Per finalizzare l'allineamento risolvere i seguenti errori:</p>
        <ul> {alignmentFailedErrors?.map((err, index) => (
            <li key={index}
                style={{
                  marginTop: "0.75em",
                  marginLeft: "0.85em"
                }}>
              {err}
            </li>))}
        </ul>
      </Modal>
      <Modal
          open={alignmentComparisonModalIsVisible}
          title={<p style={{color: "red"}}>Conferma di allineamento</p>}
          footer={null}
          onCancel={() => setAlignmentComparisonModalIsVisible(false)}
          width={'90vw'}
      >
        <div style={{display: 'flex', flexDirection: 'column', gap: '1.5rem'}}>
          <p>
            Non è stato possibile allineare automaticamente i dati perché alcuni titoli sono stati smontati.
            Per finalizzare l'allineamento è necessario trasferire i dati nel nuovo schema proposto.
          </p>
          <BelatedEmittedTableBeforeAlignment belatedEmitted={data?.ante || []}/>
          <BelatedEmittedTableAfterAlignment
              belatedEmitted={data?.post || []}
              negotiation={negotiation}
              onConfirm={onConfirmAlignmentWithData}
          />
        </div>
      </Modal>
    </>
  }