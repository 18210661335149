import React from "react";
import {Card, Col, Row, Skeleton, Typography} from "antd";
import {AllBudgetGroup} from "../../types/budgets";
import {euro, numeric} from "../../utils/formatters";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function BudgetGroupsCards({options, selectedOption, setSelectedOption, loading}: {
    options: AllBudgetGroup[],
    selectedOption: string,
    setSelectedOption: (value: string) => void,
    loading?: boolean
}) {

    const {xxl} = useBreakpoint()


    return <Row
        style={{overflow: "scroll"}}
        gutter={[16, 16]}
        wrap={false}
        justify={'space-between'}
    >
        {loading && <Skeleton/>}
        {!loading && options.map((el) => <Col
            flex={(xxl) ? 1 : undefined}
            key={el.budget_group.value}>
            <Card
                bodyStyle={{
                    padding: "5px"
                }}
                style={{
                    minWidth: '200px',
                    cursor: "pointer",
                    height: "100%",
                    border: selectedOption === el.budget_group.value ? "1px solid #4FC7FF" : undefined,
                    backgroundColor: selectedOption === el.budget_group.value ? "#B3E7FF" : undefined
                }}
                onClick={() => setSelectedOption(el.budget_group.value)}>
                <div style={{
                    height: "100%",
                    fontSize: "18px",
                    fontWeight: 550,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    paddingBottom: '0.5rem'
                }}>
                    <Typography.Paragraph
                        ellipsis={{
                            tooltip: true,
                        }}
                        style={{padding: '1rem 0'}}
                    >
                        {el.budget_group.label}
                    </Typography.Paragraph>
                </div>
                <Row justify={'space-between'}>
                    <Col>Trattative:</Col>
                    <Col>{numeric(el.negotiations)}</Col>
                </Row>
                <Row justify={'space-between'}>
                    <Col>Premi:</Col>
                    <Col>{euro(el.premium)}</Col>
                </Row>
            </Card>
        </Col>)}
    </Row>
}