import {UploadDoc} from "../components/documents/UploadDocumentModal";
import {saveInAws} from "../utils/uploadFile";
import {useLazyRequestToSaveDocumentsQuery, useUpdateDocumentMutation} from "../redux/api/documentsApiSlice";
import {useCallback} from "react";

type UseUploadProps =
  {
    negotiationUuid?: string,
    coverageConfirmationUuid?: string,
  }

export const useUploadNegotiationFiles = ({coverageConfirmationUuid, negotiationUuid}: UseUploadProps) => {

  const [saveDocument] = useLazyRequestToSaveDocumentsQuery();
  const [updateDocument] = useUpdateDocumentMutation();

  const saveFiles = useCallback(async (file: File, fileName: string | undefined, destination: string, coverageConfirmationQuestionCode?: string) => {
    try {
      const responseSaveDocs = await saveDocument({
        path: destination,
        name: fileName,
        negotiation: negotiationUuid,
        content_type: file.type
      }).unwrap();
      await saveInAws(responseSaveDocs, file)
      return updateDocument({
        uuid: responseSaveDocs.uuid,
        data: {is_valid: true, negotiation: negotiationUuid}
      }).unwrap();
    } catch {
      return Promise.reject('Errore nel caricamento o nel salvataggio dei file.')
    }
  }, [negotiationUuid, saveDocument, updateDocument])

  const upload = useCallback(async (files: UploadDoc[], realFiles: File[][], destination: string, coverageConfirmationQuestionCode?: string) => {
    return Promise
      .all(realFiles
        .flat()
        .map((file, index) =>
          saveFiles(file, files[index].fileName, destination, coverageConfirmationQuestionCode)))
  }, [saveFiles])

  return {upload}

}