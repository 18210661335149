import React, {useState} from "react";
import {Button, List, Modal, Row} from "antd";
import {BankBranchModel} from "../../types/commercialDetail";
import MeetingNotes from "./MeetingNotes";

export default function BankBranchItem({item}: { item: BankBranchModel }) {
    const {name, uuid, soa_code} = item
    const [isMeetingNotesModalOpen, setIsMeetingNotesModalOpen] = useState<boolean>(false)

    return <div>
        <List.Item
            style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem'}}>
            <Row justify={"space-between"}>
                {name + (soa_code ? " - " + soa_code : "")}
                <Button
                    type={'link'}
                    size={'middle'}
                    onClick={() => setIsMeetingNotesModalOpen(true)}>
                    Meeting
                </Button>
            </Row>
        </List.Item>
        <Modal width={600} open={isMeetingNotesModalOpen} destroyOnClose footer={false} title={"Meeting Notes con: " + name}
               onCancel={() => setIsMeetingNotesModalOpen(false)}>
            <MeetingNotes bankBranch={uuid}/>
        </Modal>
    </div>
}