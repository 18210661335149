import {Card, Col, Form, Radio, Row} from "antd";
import {CommonFormComponentProps} from "../../../types/negotiations/components";
import { UsergroupAddOutlined} from "@ant-design/icons";
import React from "react";
import Title from "antd/lib/typography/Title";
import NegotiationAdditionalInsuredList from "../../additionalInsureds/NegotiationAdditionalInsuredList";

export default function AdditionalInsureds({
                                               forwaredRef,
                                               formInstance,
                                               onFormValuesChange,
                                               disabled,
                                               negotiation
                                           }: CommonFormComponentProps) {

    const watchPolicyWithCertificates = Form.useWatch('policy_with_certificates', formInstance)

    return <Card ref={forwaredRef} id="additionalInsureds" bordered={false} style={{
        // padding: '24px',
        borderRadius: '12px',
        width: '100%'
    }}>
        <Row style={{marginBottom: '24px'}}>
            <Col>
                <Title level={3}>
                    <UsergroupAddOutlined/>
                </Title>
            </Col>
            <Col style={{marginLeft: '12px'}}>
                <Title level={3}>Assicurati addizionali</Title>
            </Col>
        </Row>
        <Form
            form={formInstance}
            disabled={disabled}
            name='additionalInsureds'
            layout="vertical"
            requiredMark={false}
            onValuesChange={onFormValuesChange}
        >
            <Form.Item label="Gestione con certificati per gli assicurati addizionali" name={"policy_with_certificates"}>
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
        {!!watchPolicyWithCertificates && <NegotiationAdditionalInsuredList negotiationUuid={negotiation?.uuid} disabled={disabled}/>}
    </Card>
}