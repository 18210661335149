import {Button, List, message, Space, Tag} from "antd";
import {ContractorCandidateModel} from "../../types/contractors";
import {useCallback, useState} from "react";

export default function ContractorCandidatesList({
                                                   contractorsCandidates,
                                                   isLoading,
                                                   btnLabel,
                                                   isUninitialized,
                                                   disabled,
                                                   onSelectedCandidate
                                                 }: {
  isUninitialized: boolean,
  contractorsCandidates: ContractorCandidateModel[],
  isLoading: boolean,
  btnLabel: string,
  disabled: boolean,
  onSelectedCandidate: (candidate: ContractorCandidateModel) => Promise<unknown>
}) {
  const [itemCreation, setItemCreation] = useState<number | null>(null)

  const handleClick = useCallback(async (candidate: ContractorCandidateModel, idx: number) => {
    setItemCreation(idx)
    try {
      await onSelectedCandidate(candidate)
    } catch {
      message.error('Errore nella creazione del contraente')
    } finally {
      setItemCreation(null)

    }
  }, [onSelectedCandidate])


  return <>
    {!isUninitialized && <List
        loading={isLoading && !itemCreation}
        dataSource={contractorsCandidates}
        {...!!contractorsCandidates.length && {pagination: {pageSize: 5, simple: true}}}
        renderItem={(item, idx) => (
            <List.Item
                actions={[
                  <Button
                      disabled={isLoading || disabled || (!!item.contractor_uuid && !!item.customer_uuid)}
                      loading={isLoading && idx === itemCreation}
                      key={`${idx}_btn`}
                      onClick={() => {
                        if (!(!!item.contractor_uuid && !!item.customer_uuid)) {
                          handleClick(item, idx)
                        }
                      }}>
                    {btnLabel}
                  </Button>
                ]}
            >
              <List.Item.Meta
                  title={<Space>
                    {item.company_name}
                    {!!item.contractor_uuid && !!item.customer_uuid && <Tag color={'green'}>GIÀ INSERITO</Tag>}
              </Space>}
                  description={<>
                    {item.piva || 'Partita IVA presente'}
                    <br/>
                    {!!item.city && item.city} {!!item.province && '('+ item.province +')'}
                  </>}
              />
            </List.Item>

        )}
    />}
  </>
}