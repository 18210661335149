export const PREMIO = 'PREMIO'
export const COD_TRATTATIVA = "COD_TRATTATIVA"
export const COD_CONTRATTO = "COD_CONTRATTO"
export const NUM_VERSIONE = "NUM_VERSIONE"
export const FLG_ANNULLO = "FLG_ANNULLO"
export const DAT_FINE_COPERTURA_TIT = "DAT_FINE_COPERTURA_TIT"
export const DAT_INIZIO_COPERTURA_TIT = "DAT_INIZIO_COPERTURA_TIT"
export const DATA_INS_TITOLO = "DATA_INS_TITOLO"

export type PassProcessKey = typeof PREMIO | typeof COD_TRATTATIVA | typeof COD_CONTRATTO | typeof NUM_VERSIONE | typeof FLG_ANNULLO | typeof DAT_FINE_COPERTURA_TIT | typeof DAT_INIZIO_COPERTURA_TIT | typeof DATA_INS_TITOLO

export interface ProcessDescription {
  [PREMIO]?: number | string | null,
  [COD_TRATTATIVA]?: string | null,
  [COD_CONTRATTO]?: number | string | null,
  [NUM_VERSIONE]?: number | string | null,
  [FLG_ANNULLO]?: 0 | 1 | null,
  [DAT_FINE_COPERTURA_TIT]?: string | null,
  [DAT_INIZIO_COPERTURA_TIT]?: string | null,
  [DATA_INS_TITOLO]?: string | null
}

export interface BelatedEmittedBatchReport {
  uuid: string,
  reference_date: string,
  state: string,
  negotiation: string | null,
  process_description: ProcessDescription[]
}

export type BelatedEmittedBatchReportResponse = {
  count: number,
  next: string | null,
  previous: string | null,
  results: BelatedEmittedBatchReport[]
}

export interface BelatedEmittedBatchReportFilters {
  reference_date_from: string,
  reference_date_to: string,
  state: string
}