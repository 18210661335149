import ConfirmCancelModal from "./ConfirmCancelModal";
import React from "react";
import {Form, Row, Col, Checkbox} from "antd";
import {useForm} from "antd/lib/form/Form";
import dayjs, {Dayjs} from "dayjs";
import DatePicker from "../DatePicker";
import {
  NEW_BUSINESS_EXTRACTION,
  PORTFOLIO_EXTRACTION,
  useLazyGetExtractionToXlsQuery, UW_YEAR_FROM, UW_YEAR_TO
} from "../../redux/api/extractionToXlsx";


const UW_YEARS = 'uw_years'

type FormType = {
  [NEW_BUSINESS_EXTRACTION]: boolean,
  [PORTFOLIO_EXTRACTION]: boolean,
  [UW_YEARS]?: Dayjs[]
}

const initialValues: FormType = {
  [NEW_BUSINESS_EXTRACTION]: true,
  [PORTFOLIO_EXTRACTION]: true,
  [UW_YEARS]: [dayjs(), dayjs()]
}

export default function ReportDownloadModal({
                                              isModalVisible,
                                              closeModal
                                            }: { isModalVisible: boolean, closeModal: () => void }) {

  const [form] = useForm()
  const [download, {isLoading}] = useLazyGetExtractionToXlsQuery()
  const watchNewBusiness = !!Form.useWatch([NEW_BUSINESS_EXTRACTION], form)
  const watchPortfolio = !!Form.useWatch([PORTFOLIO_EXTRACTION], form)

  const onClose = () => {
    closeModal()
    form.resetFields()
  }

  const handleFinish = (values: FormType) => {
    if (values[NEW_BUSINESS_EXTRACTION]) {
      // is newBusiness is true - portfolio is false or true
      download({
        [PORTFOLIO_EXTRACTION]: values[PORTFOLIO_EXTRACTION],
        [NEW_BUSINESS_EXTRACTION]: true,
        [UW_YEAR_FROM]: values[UW_YEARS]?.length === 2 ? values[UW_YEARS][0].year() : dayjs().year(),
        [UW_YEAR_TO]: values[UW_YEARS]?.length === 2 ? values[UW_YEARS][1].year() : dayjs().year()
      })

      // is newBusiness is false and portfolio is true
    } else if (values[PORTFOLIO_EXTRACTION]) {
      download({
        [PORTFOLIO_EXTRACTION]: true,
        [NEW_BUSINESS_EXTRACTION]: false,
      })
    }
    onClose()
  }

  return (
      <ConfirmCancelModal
          title={'Download dei report'}
          open={isModalVisible}
          onCancel={onClose}
          onOk={form.submit}
          okButtonProps={{loading: isLoading, disabled: (isLoading || (!watchNewBusiness && !watchPortfolio))}}
          destroyOnClose
      >
        <p>Selezionare le opzioni di interesse per la generazione del file dei broker in formato .xlsx. Quindi
          premere il pulsante "Conferma" per effettuare il download del file.</p>
        <Form
            <FormType>
            form={form}
            initialValues={initialValues}
            onFinish={handleFinish}
            style={{marginTop: '1rem'}}
            colon={false}
            requiredMark={false}
        >
          <Row justify={'space-between'}>
            <Col xs={24} {...watchNewBusiness && {md: 11}}>
              <Form.Item
                         name={NEW_BUSINESS_EXTRACTION}
                         required
                         valuePropName="checked"
              >
                <Checkbox>New Business</Checkbox>
              </Form.Item>
            </Col>
            {watchNewBusiness && <Col xs={24} md={11}>
                <Form.Item
                    label={'UW Year'}
                    name={UW_YEARS}
                    rules={[
                      {
                        required: watchNewBusiness,
                        message: 'Inserire il periodo.',
                      },
                    ]}>

                    <DatePicker.RangePicker
                        clearIcon={false}
                        bordered={true}
                        picker={'year'}
                        format={'YYYY'}
                    />
                </Form.Item>
            </Col>}
          </Row>
          <Form.Item name={PORTFOLIO_EXTRACTION} required valuePropName="checked">
            <Checkbox>Portafoglio in essere</Checkbox>
          </Form.Item>
        </Form>

      </ConfirmCancelModal>
  )
}