import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from "react";

type Params = {
    broker_branch: string,
    broker_uuid: string,
    budget_group: string,
}

type BrokerChartsParamsType = {
    params: Params,
    setParams: Dispatch<SetStateAction<Params>>
}

export const ALL = "tutte"

const defaultValues: Params = {
    broker_branch: ALL,
    broker_uuid: "",
    budget_group: ALL,
}


const BrokerChartsParams = createContext<BrokerChartsParamsType>({
    params: defaultValues,
    setParams: () => undefined

})

export const useBrokerChartsParamsContext = () => useContext(BrokerChartsParams)

export default function BrokerChartsParamsContext(props: PropsWithChildren) {

    const [params, setParams] = useState<Params>(defaultValues)

    return <BrokerChartsParams.Provider
        value={{params, setParams}}
    >
        {props.children}
    </BrokerChartsParams.Provider>
}