import ConfirmCancelModal from "./ConfirmCancelModal";
import {Button, Checkbox, message} from 'antd';
import React, {useState} from "react";
import {DownloadOutlined} from "@ant-design/icons";
import {CheckboxValueType} from "antd/lib/checkbox/Group";
import {DownloadNegotiationsParam, useGetDownloadNegotiationsMutation} from "../../redux/api/negotiationsApiSlice";
import {Views} from "../../types";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {cloneDeep} from "lodash";

const options = [
    {label: <><span style={{fontWeight: 'bold'}}>Ordinamenti attivi</span><br/>Mantiene gli ordinamenti per colonna impostati in tabella</>, value: 'sorted'},
    {label: <><span style={{fontWeight: 'bold'}}>Filtri attivi</span><br/>Mantiene i filtri attivi per colonna impostati in tabella</>, value: 'filtered'},
    {label: <><span style={{fontWeight: 'bold'}}>Tutti i dati</span><br/>Vengono estratti tutti i campi delle trattative (anche quelli non visibili come colonna)</>, value: 'allFields'}]

export default function NegotiationTablesDownloadModal({isModalVisible, closeModal}: {isModalVisible: boolean, closeModal: () => void}) {

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(['sorted', 'filtered'])
    const [downloadXlsx, {isLoading}] = useGetDownloadNegotiationsMutation()
    const user = useSelector(selectUser)
    const currentView = user?.preferences.current_view || Views.general

    const handleDownloadXlsx = async () => {
        let contractorFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'contractor')
        let brokerFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'broker')
        let brokerBranchFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'broker_branch')
        let reinsurerBrokerFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'reinsurer_broker')
        let delegatedInsurerFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'delegated_insurer')
        let reinsurersFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'reinsurers_list')
        let provincesFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'province')
        let bankReportingFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'bank_reporting_type')
        let bankSalesTypeFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'bank_sales_type')
        let bankBranchRegionFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'bank_branch_region')
        let bankSalesManagementFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'bank_sales_management')
        let bankBranchAreaFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'bank_branch_area')
        let bankBranchFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'bank_branch')
        let salesFilter = user.preferences.tables[Views[currentView]].filters?.find(el => el.key === 'sale')

        let filters = user.preferences.tables[Views[currentView]].filters

        if (contractorFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'contractor'), {key: 'contractor', range: contractorFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }
        if (brokerFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'broker'), {key: 'broker', range: brokerFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (brokerBranchFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'broker_branch'), {key: 'broker_branch', range: brokerBranchFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (reinsurerBrokerFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'reinsurer_broker'), {key: 'reinsurer_broker', range: reinsurerBrokerFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }
        if (delegatedInsurerFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'delegated_insurer'), {key: 'delegated_insurer', range: delegatedInsurerFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (reinsurersFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'reinsurers_list'), {key: 'reinsurers_list', range: reinsurersFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (provincesFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'province'), {key: 'province', range: provincesFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (bankReportingFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'bank_reporting_type'), {key: 'bank_reporting_type', range: bankReportingFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (bankSalesTypeFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'bank_sales_type'), {key: 'bank_sales_type', range: bankSalesTypeFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (bankBranchRegionFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'bank_branch_region'), {key: 'bank_branch_region', range: bankBranchRegionFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (bankSalesManagementFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'bank_sales_management'), {key: 'bank_sales_management', range: bankSalesManagementFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (bankBranchAreaFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'bank_branch_area'), {key: 'bank_branch_area', range: bankBranchAreaFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (bankBranchFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'bank_branch'), {key: 'bank_branch', range: bankBranchFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        if (salesFilter?.range.length && filters) {
            filters = cloneDeep([...filters.filter(el => el.key !== 'sale'), {key: 'sale', range: salesFilter.range.map(el => (el as {label: string, value: string}).value)}])
        }

        const params: DownloadNegotiationsParam = {
            filters: checkedList.find(el => el === 'filtered') ? filters as { key: string, range: number[] | string[]}[] : [],
            sorters: checkedList.find(el => el === 'sorted') ? user.preferences.tables[Views[currentView]].sorters : [],
            all_fields: !!checkedList.find(el => el === 'allFields')
        }
        downloadXlsx(params)
            .then((payload) => {
                if ('error' in payload) {
                    message.error('Errore nel download')
                } else {
                    message.success('Download completato correttamente')
                }
            })
            .catch((error) => {
                message.error('Errore nel download')
            })
            .finally(() => closeModal())
    }
    return (
        <ConfirmCancelModal
            title={'Download delle trattative'}
            open={isModalVisible}
            onCancel={closeModal}
            onOk={handleDownloadXlsx}
            okButtonProps={{
                loading: isLoading,
            }}
        >
            <p>Selezionare le opzioni di interesse per la generazione del file delle trattative in formato .xlsx. Quindi
                premere il pulsante "Conferma" per effettuare il download del file.</p>
            <Checkbox.Group
                style={{marginTop: '1rem'}}
                options={options}
                value={checkedList}
                onChange={values => setCheckedList(values)}
            />
        </ConfirmCancelModal>
    )
}