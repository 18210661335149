import React, {useState} from "react";
import {Card, Col, List, Pagination, Row, Space, Tag} from "antd";
import {useGetMeetingNotesQuery} from "../../redux/api/commercialDetailApiSlice";
import moment from "moment";
import {getMeetingNotesParams} from "../../types/commercialDetail";
import {getContrastYIQ} from "../../utils/formatters";
import BBBChartCardContainer from "./BBBChartCardContainer";

const pageSize = 4

export default function MeetingNotes({params}: { params?: getMeetingNotesParams }) {

    const [currentPage, setCurrentPage] = useState<number>(1)

    const {data: meetingNotes, isFetching, isUninitialized} = useGetMeetingNotesQuery({
        ...params,
        page_size: pageSize,
        page: currentPage
    })

    return <BBBChartCardContainer>
        <h2>Meeting Notes</h2>
        {meetingNotes && meetingNotes.results && meetingNotes.results.length > 0 &&
            <div>
                <div style={{
                    border: "1px solid #dcdcdc",
                    borderRadius: "12px",
                    padding: "10px",
                    maxHeight: "500px",
                    overflowY: "scroll",
                    marginBottom: "10px"
                }}>
                    <List dataSource={meetingNotes.results}
                          loading={isFetching || isUninitialized}
                          renderItem={item => (
                              <List.Item style={{padding: "5px"}}>
                                  <List.Item.Meta
                                      title={<Row justify={"space-between"}>
                                          <Col>
                                          <span style={{color: "#676ACD", fontWeight: 500}}>
                                          {item.user_creator_name} - {moment(item.meeting_date).format("D MMM YYYY")}
                                      </span>
                                          </Col>
                                          <Col>
                                              {item.lob?.map(el => <Space key={el.value}>
                                                  <Tag
                                                      style={{
                                                          color: getContrastYIQ(el.color)
                                                      }}
                                                      color={el.color}>
                                                      {el.label}
                                                  </Tag>
                                              </Space>)}
                                          </Col>
                                      </Row>}
                                      description={<div style={{color: "black"}}>{item.note}</div>}
                                  />
                              </List.Item>
                          )}
                    />
                </div>
                <Row justify={"end"}>
                    <Pagination
                        total={meetingNotes.count}
                        simple
                        current={currentPage}
                        onChange={(page) => setCurrentPage(page)}
                        showSizeChanger={false}
                        pageSize={pageSize}/>
                </Row>
            </div>}
        {meetingNotes && meetingNotes.results && meetingNotes.results.length === 0 &&
            <Row justify={"center"}><p>Nessuna nota presente.</p></Row>}
    </BBBChartCardContainer>
}