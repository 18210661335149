import {Button, Col, Form, Row} from "antd";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {useLazyGetAppendixTotalQuery} from "../../redux/api/appendixApiSlice";
import AppendixModal from "../modals/AppendixModal";
import {AntCurrencyFormatInput} from "../inputNumber";

export default function AppendixFormItem({
                                             fee,
                                             negotiation,
                                         }: { fee: number, negotiation: string }) {

    const [isAppendixModalVisible, setIsAppendixModalVisible] = useState(false)
    const [getAppendixTotal, {data: totalAppendix}] = useLazyGetAppendixTotalQuery()

    useEffect(() => {
        if (!isAppendixModalVisible) {
            getAppendixTotal({negotiation: negotiation})
        }
    }, [getAppendixTotal, negotiation, isAppendixModalVisible])

    return <>
        <AppendixModal isVisible={isAppendixModalVisible} setIsVisible={setIsAppendixModalVisible} fee={fee}
                       negotiation={negotiation}/>
        <Form.Item label="Totale appendici a titolo oneroso (nostra quota)" name='total_appendix'>
            <Row justify={'space-between'} align={'middle'}>
                <Col flex={1}>
                        <span style={{fontWeight: 'bold'}}> {typeof totalAppendix?.total_appendix === 'number' ?
                            <AntCurrencyFormatInput displayType={'text'} readOnly
                                                    value={totalAppendix.total_appendix}/> :
                            <span style={{textDecoration: 'underline'}}>Non presente</span>}</span>
                </Col>
                <Col>
                    <Button
                        icon={typeof totalAppendix?.total_appendix === 'number' ? <EditOutlined/> : <PlusOutlined/>}
                        onClick={() => setIsAppendixModalVisible(true)}>
                        {typeof totalAppendix?.total_appendix === 'number' ? 'Modifica appendici' : 'Aggiungi appendici'}
                    </Button>
                </Col>
            </Row>
        </Form.Item>
    </>
}