import {Col, Row, Space, Affix, Button} from "antd";
import Title from "antd/lib/typography/Title";
import {LeftOutlined} from "@ant-design/icons";
import React from "react";
import {useNavigate} from "react-router-dom";
import UwYearDatePicker from "../select/UwYearDatePicker";
import BudgetGroupSelect from "../select/BudgetGroupSelect";
import IsiaSelect from "../select/IsiaSelect";

export default function DashboardHeader(props: { className?: string }) {

    const navigate = useNavigate()

    return <Row className={props.className} justify={'space-between'}>
        <Col xs={{push: 1}} sm={{push: 0}}>
            <Space direction={'vertical'} size={0}>
            <Button type={'text'} style={{color: '#1890FF'}} onClick={() => navigate('negotiations')} icon={<LeftOutlined style={{color: '#1890FF'}}/>}>Torna alle trattative</Button>
            <Title level={3}>Dashboard dati</Title>
            </Space>
        </Col>
        <Col>
            <Affix offsetTop={16}>
                <div style={{
                    padding: '16px',
                    borderRadius: '12px',
                    boxShadow: '0px 10px 15px -10px #AAAAAA',
                    backgroundColor: '#ffffff',
                }}>
                    <Space direction={'horizontal'} style={{alignContent: 'end'}} wrap={true}>
                        <div>
                            <UwYearDatePicker/>
                        </div>
                        <div>
                            <BudgetGroupSelect />
                        </div>
                        <div>
                            <IsiaSelect/>
                        </div>
                    </Space>

                </div>
            </Affix>
        </Col>
    </Row>
}