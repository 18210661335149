import {EditOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {useState} from "react";
import {MoveNegotiationToStatus} from "./state";
import {NegotiationModel} from "../../../../../types/negotiations";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../../../redux/features/optionsSlice";

const MoveNegotiationStatusModalButton = ({negotiation, handleCreateStateTransition, isStateTransitionInProgress}: {
  negotiation: NegotiationModel,
  handleCreateStateTransition: (data: {
    state_to: number,
    reason?: string,
    notes?: string
  }, newNegotiation?: NegotiationModel) => Promise<void>,
  isStateTransitionInProgress: boolean,
}) => {

  const {states} = useSelector(selectOptions);

  const {available_state_transitions} = negotiation;

  const [
    isMoveNegotiationToStatusModalOpen,
    setIsMoveNegotiationToStatusModalOpen
  ] = useState<boolean>(false);

  return (
    <>
      <MoveNegotiationToStatus
        handleCreateStateTransition={handleCreateStateTransition}
        isCreateStateTransitionLoading={isStateTransitionInProgress}
        state={negotiation.available_state_transitions[0].state}
        isOpen={isMoveNegotiationToStatusModalOpen}
        onClose={() => setIsMoveNegotiationToStatusModalOpen(false)}/>
      <Button
        block
        type='primary'
        icon={<EditOutlined/>}
        disabled={isStateTransitionInProgress}
        onClick={() => setIsMoveNegotiationToStatusModalOpen(true)}>
        {`Passa trattativa a ${states.find(state => state.value === available_state_transitions[0].state)?.text}`}
      </Button>
    </>
  );
}

export default MoveNegotiationStatusModalButton;