import React from "react";
import {TreeStructureModel} from "../../types/documents";
import {Col, Row} from "antd";

export default function TitleRender(props: any) {
    const node = props.n as TreeStructureModel
    const date = new Date(node.dateCreation as string)
    return <>{<Row justify={"space-between"} style={{padding: "0 0 4px 0"}} align={"middle"} tabIndex={-1} id={node.canUpload ? (node.isLeaf ? node.path : node.key) : undefined} data-idrow={node.isLeaf ? node.path : node.key}>
            <Col lg={12} xs={24} id={node.canUpload ? (node.isLeaf ? node.path : node.key) : undefined} data-idrow={node.isLeaf ? node.path : node.key}>{node.title}</Col>
            {node.isLeaf ? <Col style={{color: "grey"}} xl={4} xs={0} id={node.canUpload ? (node.isLeaf ? node.path : node.key) : undefined} data-idrow={node.isLeaf ? node.path : node.key}>{date.toLocaleString()}</Col> : null}
            {node.isLeaf ? <Col style={{color: "grey"}} lg={4} xs={0} id={node.canUpload ? (node.isLeaf ? node.path : node.key) : undefined} data-idrow={node.isLeaf ? node.path : node.key}>{node.userCreation}</Col> : null}
        </Row>}
    </>
}