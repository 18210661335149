//antd
import {Button, Divider, Form, Input, message, Select, Space, Typography} from "antd";
import Spin from "antd/es/spin";
import {PlusOutlined} from '@ant-design/icons';
import {Rule} from "antd/lib/form";

//react + redux
import {ReactNode, useCallback, useEffect, useState} from "react";

//types
import {ContractorModel} from "../../types/contractors";
import {InsurerModel} from "types/negotiations";
import {ReinsurerModel} from "../../types/reinsurer";
import {ReinsurerBrokerModel} from "../../types/reinsurerBroker";

//apis
import {
  useCreateBrokerMutation,
  useLazyGetPaginatedBrokersQuery,
} from "redux/api/brokersApiSlice";
import {
  useLazyGetContractorsQuery,
} from "redux/api/contractorsApiSlice";
import {
  useCreateReinsurerBrokerMutation,
  useLazyGetPaginatedReinsurerBrokersQuery
} from "../../redux/api/reinsurerBrokersApiSlice";
import {
  useCreateReinsurerMutation,
  useLazyGetPaginatedReinsuresQuery,
} from "../../redux/api/reinsurersApiSlice";
import {
  useCreateDelegatedInsurerMutation,
  useLazyGetDelegatedInsurerQuery
} from "../../redux/api/negotiationUsersApiSlice";
import debounce from "lodash/debounce";
import {BrokerModel} from "../../types/broker";
import ContractorCreation from "../contractorCustomer/ContractorCreation";

const {Option} = Select

export function BrokerSelect({fieldName, label, onCreation, rules, selectedBroker, disabled = false}:
                                 {
                                   fieldName: string,
                                   label: string,
                                   disabled?: boolean,
                                   rules?: Rule[],
                                   onCreation: (newBrokerId: string) => void,
                                   selectedBroker?: BrokerModel,
                                 }) {

  const [searchName, setSearchName] = useState<string | undefined>(undefined)
  const [brokerOptions, setBrokerOptions] = useState<BrokerModel[]>([])
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [showCreation, setShowCreation] = useState<boolean>(false)
  const [newBrokerName, setNewBrokerName] = useState<string | undefined>(undefined)

  const [createBrokerRequest, {isLoading: isCreateBrokerLoading}] = useCreateBrokerMutation()
  const [getBrokers, {
    data: brokers,
    isLoading: isBrokersLoading,
    isFetching: isBrokerFetching
  }] = useLazyGetPaginatedBrokersQuery()

  const debouncedCallback = debounce((value) => {
    setIsWaiting(true)
    setSearchName(undefined)
    setShowCreation(false)
    if (value !== undefined) {
      setSearchName(value)
    }
    setIsWaiting(false)
  }, 1000)

  useEffect(() => {
    searchName ? getBrokers({search: searchName}) : getBrokers({})
  }, [getBrokers, searchName])

    useEffect(() => {
        if (brokers) {
            if (selectedBroker) {
                setBrokerOptions([selectedBroker, ...brokers.results.filter(el => el.uuid !== selectedBroker.uuid)])
            } else {
                setBrokerOptions(brokers.results)
            }
        } else {
            if (selectedBroker) {
                setBrokerOptions([selectedBroker])
            }
        }
    }, [brokers, selectedBroker])

  const createBroker = async (newBroker: string) => {
    setShowCreation(false)
    if (newBroker) {
      try {
        const broker = await createBrokerRequest({name: newBroker}).unwrap()
        setBrokerOptions(prevState => [broker, ...prevState])
        onCreation(broker.uuid)
        message.success('Broker aggiunto con successo')
      } catch (e: any) {
        console.error('createBroker', e)
        message.error('Qualcosa è andato storto durante l\'aggiunta del broker')
      }
    }
  };

  return (
      <Form.Item label={label} name={fieldName} rules={rules} required>
        <Select
            loading={isBrokersLoading || isBrokerFetching || isCreateBrokerLoading || isWaiting}
            virtual={false}
            showSearch
            placeholder={disabled ? "" : "Comincia a digitare per cercare un broker..."}
            onSearch={(value) => {
              debouncedCallback(value)
            }}
            filterOption={(input, option) =>
                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
            }
            notFoundContent={isBrokersLoading || isBrokerFetching || isWaiting
                ? <span>Ricerca broker in corso...<Spin size={'small'}/></span>
                : searchName && searchName?.length === 0
                    ? null
                    : 'Nessun broker corrisponde alla ricerca'}
            dropdownRender={menu => (
                <>
                  {!showCreation && menu}
                  {searchName
                      && searchName?.length > 0
                      && !(isBrokersLoading || isBrokerFetching) && <>
                          <Divider style={{margin: '8px 0'}}/>
                        {!showCreation ? <Space align="center" style={{padding: '0 8px 4px'}}>
                              Non trovi quello che stai cercando?
                              <Typography.Link onClick={() => setShowCreation(true)}
                                               style={{whiteSpace: 'nowrap'}}>
                                <PlusOutlined/> Crea nuovo broker
                              </Typography.Link>
                            </Space> :
                            <div style={{padding: '0 8px 4px', justifyContent: 'space-between'}}>
                              <div style={{marginBottom: '0.5rem'}}>Creazione nuovo broker</div>
                              <div>
                                <Input size={"small"} style={{width: '70%'}} placeholder={"Nome broker"}
                                       defaultValue={searchName}
                                       onChange={e => setNewBrokerName(e.currentTarget.value)}></Input>
                                <Button size={"small"} style={{width: '25%', marginLeft: '1rem'}} type={"primary"}
                                        onClick={() => newBrokerName ? createBroker(newBrokerName) : createBroker(searchName)}>Crea</Button>
                              </div>
                            </div>
                        }
                      </>}
                </>
            )}
        >
          {brokerOptions.map(broker => <Option key={broker.uuid}
                                               value={broker.uuid}>{broker.name}</Option>)}
        </Select>
      </Form.Item>

  )
}


export function ContractorSelect({fieldName, label, onCreation, rules, disabled, selectedValue, isFund}:
                                     {
                                       disabled?: boolean,
                                       fieldName: string,
                                       label: ReactNode | string,
                                       rules?: Rule[],
                                       onCreation?: ({value, label}: { value: string, label: string }) => void,
                                       selectedValue?: ContractorModel,
                                       isFund?: boolean
                                     }) {

  const [searchName, setSearchName] = useState<string | undefined>(undefined)
  const [contractors, setContractors] = useState<ContractorModel[]>([])
  const [showCreation, setShowCreation] = useState<boolean>(false)
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [fetchContractors, {
    data: fetchContractorsResult,
    isLoading: isContractorsLoading,
    isFetching: isContractorsFetching
  }] = useLazyGetContractorsQuery()

  const debouncedCallback = useCallback( debounce((value) => setSearchName(value || undefined), 1000), [])

  const isCreationCondition = !!searchName && searchName?.length > 0 && !(isContractorsLoading || isContractorsFetching)

  useEffect(() => {
    searchName !== undefined ? fetchContractors({
      search: searchName,
      page_size: 20,
    }) : fetchContractors({})
  }, [fetchContractors, searchName])

    useEffect(() => {
        if (fetchContractorsResult) {
            if (selectedValue) {
                setContractors([selectedValue, ...fetchContractorsResult.results.filter(el => el.uuid !== selectedValue.uuid)])
            } else {
                setContractors(fetchContractorsResult.results)
            }
        } else {
            if (selectedValue) {
                setContractors([selectedValue])
            }
        }
    }, [fetchContractorsResult, selectedValue])

  return (
      <>
        <Form.Item label={label} name={fieldName} rules={rules}>
          <Select
              disabled={disabled}
              loading={isContractorsLoading || isContractorsFetching}
              virtual={false}
              showSearch
              onSearch={(value) => {
                if (value) {
                  debouncedCallback(value)
                }
              }}
              placeholder={disabled ? "" : isFund ? "Comincia a digitare per cercare una cassa di assistenza..." : "Comincia a digitare per cercare un contraente..."}
              filterOption={(input, option) =>
                  option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
              }
              style={{width: '100%'}}
              notFoundContent={isContractorsLoading || isContractorsFetching
                  ? <span>{`Ricerca ${isFund? 'cassa assistenza' : 'contraente'} in corso...`}<Spin size={"small"}/></span>
                  : searchName?.length === 0
                      ? null
                      : isFund ? 'Nessuna cassa di assistenza corrisponde alla ricerca' : 'Nessun contraente corrisponde alla ricerca'}
              open={isDropdownVisible}
              onDropdownVisibleChange={open => setIsDropdownVisible(open)}
              dropdownRender={menu => (
                  <>
                    {menu}
                    {isCreationCondition && <>
                        <Divider style={{margin: '8px 0'}}/>
                        <Space align="center" style={{padding: '0 8px 4px'}}>
                            Non trovi quello che stai cercando?
                            <Typography.Link
                                onClick={() => {
                                  setIsDropdownVisible(false)
                                  setTimeout(() => setShowCreation(true), 200)
                                }}
                                style={{whiteSpace: 'nowrap'}}>
                                <PlusOutlined/> {isFund ? "Crea nuova cassa di assistenza" : "Crea nuovo contraente"}
                            </Typography.Link>
                        </Space>
                    </>}
                  </>
              )}
          >
            {contractors.map(contractor => <Option key={contractor.uuid}
                                                   value={contractor.uuid}>{contractor.name}</Option>)}
          </Select>
        </Form.Item>
        {showCreation && <ContractorCreation searchTerm={searchName || ""} isFund={!!isFund} isVisible={showCreation}
                                             setIsVisible={(visible: boolean) => setShowCreation(visible)}
                                             {...!!onCreation && {
                                               onCreation: (contractor) => {
                                                 setContractors(prevContractors => [...prevContractors.filter(c => c.uuid !== contractor.uuid), contractor])
                                                 onCreation({value: contractor.uuid, label: contractor.name || ""})
                                                 setShowCreation(false)
                                               }
                                             }} />}
      </>

  )
}


export function ReinsurerSelect({
                                  fieldName,
                                  label,
                                  onCreation,
                                  rules,
                                  disabled,
                                  excludedReinsurers = [],
                                  selectedReinsurer
                                }:
                                    {
                                      disabled?: boolean,
                                      fieldName: string,
                                      label: string,
                                      rules?: Rule[],
                                      onCreation: (value: string) => void,
                                      excludedReinsurers?: string[],
                                      selectedReinsurer?: ReinsurerModel
                                    }) {

  const [searchName, setSearchName] = useState<string | undefined>(undefined)
  const [reinsurersOptions, setReinsurersOptions] = useState<ReinsurerModel[]>([])
  const [showCreation, setShowCreation] = useState<boolean>(false)
  const [newReinsurerName, setNewReinsurerName] = useState<string | undefined>(undefined)

  const [createReinsurerRequest, {isLoading: isCreateReinsurerLoading}] = useCreateReinsurerMutation()
  const [getReinsurer, {
    data: reinsurers,
    isLoading: areReinsuresLoading,
    isFetching: areReinsurersFetching
  }] = useLazyGetPaginatedReinsuresQuery()

  const debouncedCallback = debounce((value) => {
    setShowCreation(false)
    setSearchName(undefined)
    if (value !== undefined) {
      setSearchName(value)
    }
  }, 1000)

  useEffect(() => {
    getReinsurer({search: searchName})
  }, [getReinsurer, searchName])

  useEffect(() => {
    if (searchName === undefined)
      getReinsurer({})
  }, [getReinsurer, newReinsurerName])

  useEffect(() => {
    let options = reinsurers?.results || []
    if (selectedReinsurer) {
      options = [selectedReinsurer, ...options.filter(el => el.uuid !== selectedReinsurer.uuid)]
    }
    setReinsurersOptions(options)
  }, [reinsurers, selectedReinsurer])

  const createReinsurer = async (name: string) => {
    setShowCreation(false)
    try {
      const reinsurer = await createReinsurerRequest({name}).unwrap()
      onCreation(reinsurer.uuid)
      getReinsurer({search: name})
      message.success('Riassicuratore aggiunto con successo')
    } catch (e: any) {
      console.error('create reinsurer', e)
      message.error('Qualcosa è andato storto durante l\'aggiunta del riassicuratore')
    }
  };

  return (
      <Form.Item label={label} name={fieldName} rules={rules}>
        <Select
            disabled={disabled}
            loading={areReinsuresLoading || areReinsurersFetching || isCreateReinsurerLoading}
            virtual={false}
            showSearch
            onSearch={(value) => {
              debouncedCallback(value)
            }}
            placeholder={disabled ? "" : "Comincia a digitare per cercare un riassicuratore..."}
            filterOption={(input, option) =>
                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
            }
            style={{width: '100%'}}
            notFoundContent={areReinsuresLoading || areReinsurersFetching
                ? <span>Ricerca riassicuratore in corso...<Spin size={"small"}/></span>
                : searchName?.length === 0
                    ? null
                    : excludedReinsurers.length ? 'Nessun riassicuratore corrisponde alla ricerca o riassicuratore già presente' : 'Nessun riassicuratore corrisponde alla ricerca'}
            dropdownRender={menu => (
                <>
                  {!showCreation && menu}
                  {searchName
                      && searchName?.length > 0
                      && !(areReinsuresLoading || areReinsurersFetching) && <>
                          <Divider style={{margin: '8px 0'}}/>
                        {!showCreation ? <Space align="center" style={{padding: '0 8px 4px'}}>
                              Non trovi quello che stai cercando?
                              <Typography.Link
                                  onClick={() => setShowCreation(true)}
                                  style={{whiteSpace: 'nowrap'}}>
                                <PlusOutlined/> Crea nuovo riassicuratore
                              </Typography.Link>
                            </Space> :
                            <div style={{padding: '0 8px 4px', justifyContent: 'space-between'}}>
                              <div style={{marginBottom: '0.5rem'}}>Creazione nuovo riassicuratore</div>
                              <div>
                                <Input size={"small"} style={{width: '70%'}} placeholder={"Nome riassicuratore"}
                                       defaultValue={searchName}
                                       onChange={e => setNewReinsurerName(e.currentTarget.value)}></Input>
                                <Button size={"small"} style={{width: '25%', marginLeft: '1rem'}} type={"primary"}
                                        onClick={() => newReinsurerName ? createReinsurer(newReinsurerName) : createReinsurer(searchName)}>Crea</Button>
                              </div>
                            </div>}
                      </>}

                </>
            )}
        >
          {reinsurersOptions
              .filter(el => !excludedReinsurers.some(excRein => excRein === el.uuid))
              .map(reinsurer => <Option key={reinsurer.uuid}
                                        value={reinsurer.uuid}>{reinsurer.name}</Option>)
          }
        </Select>
      </Form.Item>

  )
}

export function ReinsurerBrokerSelect({fieldName, label, onCreation, rules, disabled}:
                                          {
                                            disabled?: boolean,
                                            fieldName: string,
                                            label: string,
                                            rules?: Rule[],
                                            onCreation: (value: string) => void
                                          }) {

  const [searchName, setSearchName] = useState<string | undefined>(undefined)
  const [reinsurerBrokersOptions, setReinsurerBrokersOptions] = useState<ReinsurerBrokerModel[]>([])
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [showCreation, setShowCreation] = useState<boolean>(false)
  const [newReinsurerBrokerName, setNewReinsurerBrokerName] = useState<string | undefined>(undefined)

  const [createReinsurerBrokerRequest, {isLoading: isCreateReinsurerBrokerLoading}] = useCreateReinsurerBrokerMutation()
  const [getReinsurerBrokers, {
    data: reinsurerBrokers,
    isLoading: areReinsureBrokersLoading,
    isFetching: areReinsurerBrokersFetching
  }] = useLazyGetPaginatedReinsurerBrokersQuery()

  const debouncedCallback = debounce((value) => {
    setIsWaiting(true)
    setShowCreation(false)
    setSearchName(undefined)
    if (value !== undefined) {
      setSearchName(value)
    }
    setIsWaiting(false)
  }, 1000)


  useEffect(() => {
    getReinsurerBrokers({search: searchName})
  }, [getReinsurerBrokers, searchName])

  useEffect(() => {
    if (searchName === undefined)
      getReinsurerBrokers({})
  }, [getReinsurerBrokers, newReinsurerBrokerName])

  useEffect(() => {
    if (reinsurerBrokers?.count)
      setReinsurerBrokersOptions(reinsurerBrokers.results)
  }, [reinsurerBrokers])

  const createReinsurer = async (name: string) => {
    setShowCreation(false)
    try {
      const reinsurer = await createReinsurerBrokerRequest({name}).unwrap()
      onCreation(reinsurer.uuid)
      getReinsurerBrokers({search: name})
      message.success('Broker riassicurativo aggiunto con successo')
    } catch (e: any) {
      console.error('create reinsurer', e)
      message.error('Qualcosa è andato storto durante l\'aggiunta del broker riassicurativo')
    }
  };

  return (
      <Form.Item label={label} name={fieldName} rules={rules}>
        <Select
            disabled={disabled}
            loading={areReinsureBrokersLoading || areReinsurerBrokersFetching || isCreateReinsurerBrokerLoading || isWaiting}
            virtual={false}
            showSearch
            onSearch={(value) => {
              debouncedCallback(value)
            }}
            placeholder={disabled ? "" : "Comincia a digitare per cercare un broker riassicurativo..."}
            filterOption={(input, option) =>
                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
            }
            style={{width: '100%'}}
            notFoundContent={areReinsureBrokersLoading || areReinsurerBrokersFetching || isWaiting
                ? <span>Ricerca broker riassicurativo in corso...<Spin size={"small"}/></span>
                : searchName?.length === 0
                    ? null
                    : 'Nessun broker riassicurativo corrisponde alla ricerca'}
            dropdownRender={menu => (
                <>
                  {!showCreation && menu}
                  {searchName
                      && searchName?.length > 0
                      && !(areReinsureBrokersLoading || areReinsurerBrokersFetching) && <>
                          <Divider style={{margin: '8px 0'}}/>
                        {!showCreation ? <Space align="center" style={{padding: '0 8px 4px'}}>
                              Non trovi quello che stai cercando?
                              <Typography.Link
                                  onClick={() => setShowCreation(true)}
                                  style={{whiteSpace: 'nowrap'}}>
                                <PlusOutlined/> Crea nuovo broker riassicurativo
                              </Typography.Link>
                            </Space> :
                            <div style={{padding: '0 8px 4px', justifyContent: 'space-between'}}>
                              <div style={{marginBottom: '0.5rem'}}>Creazione nuovo broker riassicurativo</div>
                              <div>
                                <Input size={"small"} style={{width: '70%'}} placeholder={"Nome broker riassicurativo"}
                                       defaultValue={searchName}
                                       onChange={e => setNewReinsurerBrokerName(e.currentTarget.value)}></Input>
                                <Button size={"small"} style={{width: '25%', marginLeft: '1rem'}} type={"primary"}
                                        onClick={() => newReinsurerBrokerName ? createReinsurer(newReinsurerBrokerName) : createReinsurer(searchName)}>Crea</Button>
                              </div>
                            </div>
                        }
                      </>}

                </>
            )}
        >
          {reinsurerBrokersOptions.map(reinsurer => <Option key={reinsurer.uuid}
                                                            value={reinsurer.uuid}>{reinsurer.name}</Option>)}
        </Select>
      </Form.Item>

  )
}


export function DelegatedInsurerSelect({fieldName, label, onCreation, rules, disabled}:
                                           {
                                             disabled?: boolean,
                                             fieldName: string,
                                             label: string,
                                             rules?: Rule[],
                                             onCreation: (newDelegatedInsurer: string) => void
                                           }) {

  const [searchName, setSearchName] = useState<string | undefined>(undefined)
  const [delegatedInsurersOptions, setDelegatedInsurersOptions] = useState<InsurerModel[]>([])
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [showCreation, setShowCreation] = useState<boolean>(false)
  const [newDelegatedInsurerName, setNewDelegatedInsurerName] = useState<string | undefined>(undefined)

  const [createDelegatedInsurerRequest, {isLoading: isCreateDelegatedInsurerLoading}] = useCreateDelegatedInsurerMutation()
  const [getDelegatedInsurers, {
    data: delegatedInsurers,
    isLoading: areDelegatedInsurersLoading,
    isFetching: areDelegatedInsurersFetching
  }] = useLazyGetDelegatedInsurerQuery()

  const debouncedCallback = debounce((value) => {
    setIsWaiting(true)
    setShowCreation(false)
    setSearchName(undefined)
    if (value !== undefined) {
      setSearchName(value)
    }
    setIsWaiting(false)
  }, 1000)

  useEffect(() => {
    getDelegatedInsurers({search: searchName})
  }, [searchName])

  useEffect(() => {
    if (searchName === undefined)
      getDelegatedInsurers({})
  }, [])

  useEffect(() => {
    if (delegatedInsurers)
      setDelegatedInsurersOptions(delegatedInsurers)
  }, [delegatedInsurers])

  const createDelegatedInsurer = async (name: string) => {
    setShowCreation(false)
    try {
      const reinsurer = await createDelegatedInsurerRequest({name}).unwrap()
      onCreation(reinsurer.uuid)
      getDelegatedInsurers({search: name})
      message.success('Delegataria aggiunta con successo')
    } catch (e: any) {
      console.error('create delegated insurer', e)
      message.error('Qualcosa è andato storto durante l\'aggiunta della delegataria')
    }
  };

  return (
      <Form.Item label={label} name={fieldName} rules={rules}>
        <Select
            disabled={disabled}
            loading={areDelegatedInsurersLoading || areDelegatedInsurersFetching || isCreateDelegatedInsurerLoading || isWaiting}
            virtual={false}
            showSearch
            onSearch={(value) => {
              debouncedCallback(value)
            }}
            //onChange={(value, option)=> {console.log('value', value); console.log('option', option); return {value, label: 'label_prova'}}}
            placeholder={disabled ? "" : "Comincia a digitare per cercare una delegataria..."}
            filterOption={(input, option) =>
                option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
            }
            style={{width: '100%'}}
            notFoundContent={areDelegatedInsurersLoading || areDelegatedInsurersFetching || isWaiting
                ? <span>Ricerca delegataria in corso...<Spin size={"small"}/></span>
                : searchName?.length === 0
                    ? null
                    : 'Nessuna delegataria corrisponde alla ricerca'}
            dropdownRender={menu => (
                <>
                  {!showCreation && menu}
                  {searchName
                      && searchName?.length > 0
                      && !(areDelegatedInsurersLoading || areDelegatedInsurersFetching) && <>
                          <Divider style={{margin: '8px 0'}}/>
                        {!showCreation ? <Space align="center" style={{padding: '0 8px 4px'}}>
                              Non trovi quello che stai cercando?
                              <Typography.Link
                                  onClick={() => setShowCreation(true)}
                                  style={{whiteSpace: 'nowrap'}}>
                                <PlusOutlined/> Crea una nuova delegataria
                              </Typography.Link>
                            </Space> :
                            <div style={{padding: '0 8px 4px', justifyContent: 'space-between'}}>
                              <div style={{marginBottom: '0.5rem'}}>Creazione nuova delegataria</div>
                              <div>
                                <Input size={"small"} style={{width: '70%'}} placeholder={"Nome delegataria"}
                                       defaultValue={searchName}
                                       onChange={e => setNewDelegatedInsurerName(e.currentTarget.value)}></Input>
                                <Button size={"small"} style={{width: '25%', marginLeft: '1rem'}} type={"primary"}
                                        onClick={() => newDelegatedInsurerName ? createDelegatedInsurer(newDelegatedInsurerName) : createDelegatedInsurer(searchName)}>Crea</Button>
                              </div>
                            </div>
                        }
                      </>}

                </>
            )}
        >
          {delegatedInsurersOptions.map(insurer => <Option key={insurer.uuid} value={insurer.uuid}>
            {insurer.name}
          </Option>)}
        </Select>
      </Form.Item>

  )
}