import {Button, Dropdown, Menu, MenuProps} from 'antd';
import React, {useCallback, useState} from "react";
import {DownloadOutlined, FileImageOutlined} from "@ant-design/icons";
import NegotiationTablesDownloadModal from "../modals/NegotiationTablesDownloadModal";
import ReportDownloadModal from "../modals/ReportDownloadModal";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {canDownloadBrokerDataXlsx, canDownloadFeeBank} from "../../utils/permission";
import {useLazyGetSaleExtractionQuery} from "../../redux/api/extractionToXlsx";

const NEGOTIATIONS = 'NEGOTIATIONS'
const REPORT = 'REPORT'
const SALEEXTRACTION = 'SALEEXTRACTION'

type PossibleModalValue = typeof NEGOTIATIONS | typeof REPORT | null

export default function NegotiationTablesDownloadButton() {
    const user = useSelector(selectUser)

    const [download, {isLoading}] = useLazyGetSaleExtractionQuery()

    const [showModal, setShowModal] = useState<PossibleModalValue>(null)
    const canDownloadBrokerData = canDownloadBrokerDataXlsx(user?.usertypes.map(el => el.code) || [])
    const canDownloadFeeBankPerc = canDownloadFeeBank(user?.usertypes.map(el => el.code) || [])

    const downloadSaleExtraction = useCallback(() => {
        download()
    }, [download])

    const handleMenuClick: MenuProps['onClick'] = e => {
        switch (e.key) {
            case NEGOTIATIONS:
                setShowModal(NEGOTIATIONS)
                break;
            case REPORT:
                setShowModal(REPORT)
                break;
            case SALEEXTRACTION:
                downloadSaleExtraction()
                break;
        }
    }

    const menuItems = () => {
        let items = [
            {
                label: 'Trattative',
                key: NEGOTIATIONS,
                icon: <FileImageOutlined/>,
            },
            {
                label: 'Reportistica',
                key: REPORT,
                icon: <FileImageOutlined/>,
            }
        ]
        if (canDownloadFeeBankPerc)
            items.push({
                label: 'Fee Banca',
                key: SALEEXTRACTION,
                icon: <FileImageOutlined/>,
            })
        return items
    }

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={menuItems()}
        />
    );

    return (
        <>
            {canDownloadBrokerData && <>
                <Dropdown overlay={menu} trigger={['click']}>
                    <Button
                        size='large'
                        icon={<DownloadOutlined/>}
                        style={{
                            borderColor: 'transparent',
                            backgroundColor: 'transparent',
                            boxShadow: "none"
                        }}
                    />
                </Dropdown>
                <ReportDownloadModal isModalVisible={showModal === REPORT} closeModal={() => setShowModal(null)}/>
                <NegotiationTablesDownloadModal isModalVisible={showModal === NEGOTIATIONS}
                                                closeModal={() => setShowModal(null)}/>
            </>}

            {!canDownloadBrokerData && <>
                <Button
                    size='large'
                    icon={<DownloadOutlined/>}
                    style={{
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                        boxShadow: "none"
                    }}
                    onClick={() => setShowModal(NEGOTIATIONS)}
                />
                <NegotiationTablesDownloadModal isModalVisible={showModal === NEGOTIATIONS}
                                                closeModal={() => setShowModal(null)}/>
            </>}
        </>
    );
}