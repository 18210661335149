import {AssignNegotiationModal} from "./state";
import React, {useState} from "react";
import {UserAddOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {NegotiationModel} from "../../../../../types/negotiations";

const AssignNegotiationModalButton = ({
                                        negotiation,
                                        updateNegotiationUnderwriter,
                                        handleCreateStateTransition,
                                        isStateTransitionInProgress
                                      }: {
  negotiation: NegotiationModel,
  updateNegotiationUnderwriter: (underwriterId: string) => Promise<NegotiationModel | undefined>
  handleCreateStateTransition: (data: {
    state_to: number,
    reason?: string,
    notes?: string
  }, newNegotiation?: NegotiationModel) => Promise<void>,
  isStateTransitionInProgress: boolean
}) => {

  const [
    isAssignNegotiationModalOpen,
    setIsAssignNegotiationModalOpen
  ] = useState<boolean>(false);

  return (
    <>
      <AssignNegotiationModal
        updateNegotiationUnderwriter={updateNegotiationUnderwriter}
        handleCreateStateTransition={handleCreateStateTransition}
        negotiation={negotiation}
        isOpen={isAssignNegotiationModalOpen}
        onClose={() => setIsAssignNegotiationModalOpen(false)}/>
      <Button
        block
        type='primary'
        icon={<UserAddOutlined/>}
        disabled={isStateTransitionInProgress}
        onClick={() => setIsAssignNegotiationModalOpen(true)}>
        Assegna trattativa ad underwriter
      </Button>
    </>
  );
}

export default AssignNegotiationModalButton;