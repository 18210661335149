import {useLocation, useNavigate} from "react-router-dom";
import {useState} from 'react';
import {Col, Row, Card, Form, Button, Select, Spin, Radio, Input, message, FormProps} from 'antd';
import {Typography} from 'antd';
import {LeftOutlined, FileAddOutlined} from '@ant-design/icons';
import {CreateNewNegotiationFormModel, DuplicateNegotiation} from "types/negotiations";
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import DatePicker from "components/DatePicker";
import dayjs from "dayjs";
import MakeTransition from "components/animator";
import {useCreateNegotiationMutation, useCheckDuplicatesNegotiationMutation} from "redux/api/negotiationsApiSlice";
import {BrokerSelect, ContractorSelect} from "components/negotiationForm/selects";
import DuplicatesModal from "../../components/modals/DuplicatesModal";

const {Title} = Typography
const {Option} = Select



export default function NewNegotiation() {
    const navigate = useNavigate()
    const {pathname} = useLocation()

    const [form] = Form.useForm<CreateNewNegotiationFormModel>();
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
    const [duplicates, setDuplicates] = useState<DuplicateNegotiation[]>([])
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [allData, setAllData] = useState<CreateNewNegotiationFormModel>()

    const options = useSelector(selectOptions)

    const [createNegotiation, {isLoading: isCreateNegotiationLoading}] = useCreateNegotiationMutation()
    const [checkDuplicatesInNegotiation] = useCheckDuplicatesNegotiationMutation()

    const onValuesChange = (changedValues: Partial<CreateNewNegotiationFormModel>, values: CreateNewNegotiationFormModel) => {
        if (values.policy_effective_date) {
            form.setFieldValue('uw_year', values.policy_effective_date.year())
        } else {
            form.resetFields(['uw_year'])
        }
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.broker && fields.contractor && fields.lob && fields.policy_effective_date && fields.reception_date && fields.is_isia !== undefined && fields.uw_year)
        setDisabledSubmit(!allFieldsOk);
    }

    const checkDuplicates = async (formData: CreateNewNegotiationFormModel) => {
        try {
            if (formData.contractor) {
                const fetchedDuplicates = await checkDuplicatesInNegotiation({
                    contractor: formData.contractor,
                    lob: formData.lob,
                    uw_year: formData.uw_year as number
                }).unwrap()
                if (fetchedDuplicates.length !== 0) {
                    setDuplicates(fetchedDuplicates)
                    setIsModalOpen(true)
                } else
                    createNewNegotiation(formData)
            }
        } catch (e: any) {
            message.error("Impossibile creare la trattativa")
            console.error('Create negotiation', e)
        }
    }

    const createNewNegotiation = async (formData: CreateNewNegotiationFormModel) => {
                try {
                    const negotiation = await createNegotiation(
                        {
                            ...formData,
                            policy_effective_date: new Date(formData.policy_effective_date.format('YYYY-MM-DD')),
                            reception_date: new Date(formData.reception_date.format('YYYY-MM-DD')),
                            contractor: formData.contractor,
                            is_isia: formData.is_isia.valueOf(),
                            uw_year: formData.uw_year,
                            has_prorata: false,
                        }).unwrap()
                    navigate(`/negotiations/${negotiation.uuid}`, {state: {previous_path: pathname}})
                } catch (e: any) {
                    message.error("Impossibile creare la trattativa")
                    console.error('Create negotiation', e)
                }
    }

    return (
        <MakeTransition forwardedKey={'new'}>
            <Row justify={'space-evenly'}>
                <Col xs={24} sm={22} lg={23}>
                    <div style={{
                        minHeight: '90vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Card style={{width: '100%',maxWidth: '50rem'}}>
                            <Row><Col><Title level={3}><FileAddOutlined/></Title></Col><Col style={{marginLeft: '12px'}}><Title
                                level={3}>Nuova trattativa</Title></Col></Row>
                            <Form
                                form={form}
                                layout="vertical"
                                requiredMark={false}
                                style={{marginTop: '20px'}}
                                onFinish={(values) => {
                                    setAllData(values)
                                    checkDuplicates(values)
                                }}
                                initialValues={{
                                    reception_date: dayjs()
                                }}
                                onValuesChange={onValuesChange}
                            >
                                <Form.Item label="LOB" name='lob' rules={[{required: true, message: 'La LOB è richiesta'}]}
                                >
                                    <Select
                                        virtual={false}
                                        showSearch
                                        placeholder="Seleziona una LOB"
                                        filterOption={(input, option) =>
                                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                                        }
                                    >
                                        {options.lobs.map(lob => <Option key={lob.uuid} value={lob.uuid}>{lob.name}</Option>)}
                                    </Select>
                                </Form.Item>
                                <ContractorSelect
                                    fieldName="contractor"
                                    label='Contraente'
                                    onCreation={({value}) => {
                                        form.setFieldsValue({contractor: value})
                                        onValuesChange({contractor: value}, form.getFieldsValue())
                                    }}
                                    rules={[{required: true, message: 'Il contraente è richiesto'}]}
                                />
                                <Form.Item label={"ISIA"} name={'is_isia'}
                                           rules={[{required: true, message: 'ISIA è richiesto'}]}>
                                    <Radio.Group>
                                        <Radio value={true}>Si</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <BrokerSelect
                                    fieldName="broker"
                                    label='Broker'
                                    onCreation={(newBrokerId: string) => {
                                        form.setFieldsValue({broker: newBrokerId})
                                    }}
                                    rules={[{required: true, message: 'Il broker è richiesto'}]}
                                />
                                <Row justify={"space-between"}>
                                    <Col span={11}>
                                        <Form.Item label="Data decorrenza" name='policy_effective_date'
                                                   rules={[{required: true, message: 'La data decorrenza è richiesta'}]}>
                                            <DatePicker
                                                style={{width: '100%'}}
                                                placeholder='Seleziona una data'
                                                format={'DD/MM/YYYY'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11}>
                                        <Form.Item label="UW Year" name='uw_year'
                                                   rules={[{required: true, message: 'L\'UW year è richiesto'}]}>
                                            <Input style={{width: '100%'}} maxLength={4} disabled/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item label="Data ricezione Submission" name='reception_date'
                                           rules={[{required: true, message: 'La data ricezione è richiesta'}]}
                                >
                                    <DatePicker
                                        style={{width: '100%'}}
                                        placeholder='Seleziona una data'
                                        format={'DD/MM/YYYY'}/>
                                </Form.Item>
                                <Row justify='space-between' align='middle'>
                                    <Col>

                                        <Button type='link' icon={<LeftOutlined/>} onClick={() => navigate('/negotiations')}>
                                            Torna alle trattative
                                        </Button>

                                    </Col>

                                    <Col>
                                        <Form.Item style={{marginBottom: 0}}>
                                            <Button icon={isCreateNegotiationLoading ? <Spin/> : <FileAddOutlined/>}
                                                    disabled={disabledSubmit} type="primary" htmlType="submit">
                                                Crea trattativa
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </div>
                </Col>
            </Row>
            <DuplicatesModal isOpen={isModalOpen} close={()=> setIsModalOpen(false)} source={duplicates} allData={allData} onOk={()=> allData ? createNewNegotiation(allData) : null}/>
        </MakeTransition>
    )


}
