import {apiSlice} from "redux/api/baseApiSlice";
import {AdditionalInsured, NegotiationAdditionalInsured} from "../../types/additionalInsureds";
import {GenericPaginatedApi} from "../../types";
import {LabelValue} from "../../types/charts";

export const additionalInsuredAPiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCandidatesAdditionalInsured: builder.query<AdditionalInsured[], {
            negotiationUuid: string,
            q: string,
            country?: string
        }>({
            query: ({negotiationUuid, ...params}) => {
                return {
                    url: `/negotiation/negotiations/${negotiationUuid}/additionalinsureds/search`,
                    params,
                };
            },
        }),
        addAdditionalInsured: builder.mutation<NegotiationAdditionalInsured, {
            negotiationUuid: string,
            data: AdditionalInsured
        }>({
            query: ({negotiationUuid, data}) => {
                return {
                    url: `negotiation/negotiations/${negotiationUuid}/additionalinsureds/`,
                    method: 'POST',
                    body: data
                }
            },
            invalidatesTags: (res, error, arg) => [{
                type: 'AdditionalInsureds',
                id: arg.negotiationUuid
            }, {type: 'CoverageConfirmation'}],
        }),
        updateAdditionalInsured: builder.mutation<NegotiationAdditionalInsured, {
            negotiationUuid: string,
            additionalInsuredUuid: string,
            data: Partial<Omit<NegotiationAdditionalInsured, 'uuid'>>
        }>({
            query: ({negotiationUuid, additionalInsuredUuid, data}) => {
                return {
                    url: `negotiation/negotiations/${negotiationUuid}/additionalinsureds/${additionalInsuredUuid}/`,
                    method: 'PATCH',
                    body: data
                }
            },
            invalidatesTags: (res, error, arg) => [{
                type: 'AdditionalInsureds',
                id: arg.negotiationUuid
            }, {type: 'CoverageConfirmation'}],
        }),
        getNegotiationAdditionalInsured: builder.query<GenericPaginatedApi<NegotiationAdditionalInsured>, {
            negotiationUuid: string,
            search?: string,
            page?: number,
            page_size?: number
        }>({
            query: ({negotiationUuid, ...params}) => {
                return {
                    url: `/negotiation/negotiations/${negotiationUuid}/additionalinsureds/`,
                    params,
                };
            },
            providesTags: (res, error, arg) => [{type: 'AdditionalInsureds', id: arg.negotiationUuid}],
        }),
        getLegalForms: builder.query<LabelValue[], void>({
            query: () => {
                return {
                    url: `/negotiation/legalforms/`,
                };
            },
        }),
    })
})

export const {
    useLazyGetCandidatesAdditionalInsuredQuery,
    useAddAdditionalInsuredMutation,
    useGetNegotiationAdditionalInsuredQuery,
    useUpdateAdditionalInsuredMutation,
    useGetLegalFormsQuery,
} = additionalInsuredAPiSlice