import {Select, SelectProps} from "antd";
import React from "react";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {useSelector} from "react-redux";

export default function BrokerBranchMultiSelect(props: SelectProps) {
    const provinces = useSelector(selectOptions).provinces

    return <Select
        virtual={false}
        mode={'multiple'}
        showSearch
        placeholder={"Seleziona un branch broker"}
        labelInValue
        filterOption={(input, option) => {
            return option?.label ? (option.label as string).toLowerCase().includes(input.toLowerCase()) || (option.value as string).toLowerCase() === (input.toLowerCase()) : false
        }}
        options={provinces.map(province => ({key: province.value, value: province.value, label: province.text}))}
        {...props}
    />
}


