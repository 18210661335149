import {Select, SelectProps, Spin} from "antd";
import {BankBranchModel} from "../../../types/commercialDetail";
import React, {useCallback, useEffect} from "react";
import {useLazyGetBankBranchQuery} from "../../../redux/api/commercialDetailApiSlice";
import {debounce} from "lodash";

export default function BankBranchMultiSelect(selectProps: SelectProps) {

    const [getBankBranch, {data: bankBranchData, isLoading: isBankBranchLoading, isFetching: isBankBranchFetching}] = useLazyGetBankBranchQuery()
    const debounceFetcher = useCallback(debounce( (searchTerm) => getBankBranch({search: searchTerm}), 600 ), [])

    useEffect(()=> {
        getBankBranch({})
    }, [getBankBranch])

    return <Select
            labelInValue
            options={bankBranchData?.results.map((el: BankBranchModel) => ({label: el.name, value: el.uuid})) || []}
            notFoundContent={ isBankBranchLoading || isBankBranchFetching ? <Spin size="small" /> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
            onSearch={debounceFetcher}
            mode={"multiple"}
            placeholder={"Seleziona una filiale"}
            filterOption={false}
            style={{width: "20rem"}}
            virtual={false}
            showSearch
            {...selectProps}
        />
}