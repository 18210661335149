import {apiSlice} from "./baseApiSlice";
import {
    BankBranchModel,
    BankBranchRegion,
    BankReportingOptionsModel,
    BankSalesManagementModel,
    BankSaleTypeModel,
    GetBankBranchAreaModel,
    getMeetingNotesModel,
    getMeetingNotesParams,
    MeetingNotesModel,
    SalesModel
} from "../../types/commercialDetail";
import {GenericPaginatedApi} from "../../types";

export const commercialDetailApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBankSalesType: builder.query<BankSaleTypeModel[], void>({
            query: () => {
                return {
                    url: '/negotiation/bank_sales_type/',
                    params: {
                        paginate: false
                    }
                }
            }
        }),
        getBankSalesManagement: builder.query<BankSalesManagementModel[], null>({
            query: () => {
                return {
                    url: '/negotiation/bank_sales_management/',
                    params: {
                        paginate: false
                    }
                }
            }
        }),
        getBankBranchRegion: builder.query<BankBranchRegion[], void>({
            query: () => {
                return {
                    url: '/negotiation/bank_branch_region/',
                    params: {
                        paginate: false
                    }
                }
            }
        }),
        getBankBranchArea: builder.query<GetBankBranchAreaModel, {
            page?: number,
            page_size?: number,
            search?: string
        }>({
            query: (args) => {
                return {
                    url: '/negotiation/bank_branch_area/',
                    params: {
                        ...args
                    }
                }
            }
        }),
        getBankBranch: builder.query<GenericPaginatedApi<BankBranchModel>, {
            paginate?: boolean,
            page?: number,
            page_size?: number,
            search?: string
        }>({
            query: (args) => {
                return {
                    url: '/negotiation/bank_branch/',
                    params: {
                        ...args
                    }
                }
            },
            providesTags: (res, error, arg) =>
                res
                    ? [
                        ...res?.results.map(({uuid}) => ({type: 'BankBranches' as const, id: uuid})),
                        {type: 'BankBranches', id: 'LIST'},
                    ]
                    : [{type: 'BankBranches', id: 'LIST'}],
        }),
        getSingleBankBranch: builder.query<BankBranchModel, { uuid: string }>({
            query: (args) => {
                return {
                    url: `/negotiation/bank_branch/${args.uuid}`,
                }
            },
            providesTags: (res, error, arg) => [{type: 'BankBranches', id: 'LIST'}]
        }),
        getSales: builder.query<SalesModel[], null>({
            query: () => {
                return {
                    url: '/negotiation/negotiation_userprofile/',
                    params: {
                        usertypes: ["SA"]
                    }
                }
            }
        }),
        getBankReportingOptions: builder.query<BankReportingOptionsModel[], null>({
            query: () => {
                return {
                    url: '/negotiation/bank_reporting_options/'
                }
            }
        }),
        getMeetingNotes: builder.query<getMeetingNotesModel, getMeetingNotesParams>({
            query: (args) => {
                return {
                    url: '/negotiation/meeting_notes/',
                    params: {...args}
                }
            },
            providesTags: (result, error, arg, meta) => ['MeetingNotes']
        }),
        createMeetingNote: builder.mutation<MeetingNotesModel, any>({
            query: (args) => {
                return {
                    url: '/negotiation/meeting_notes/',
                    method: 'POST',
                    body: {...args}
                }
            },
            invalidatesTags: (result, error, arg, meta) => ['MeetingNotes']
        }),
        updateMeetingNote: builder.mutation<MeetingNotesModel, any>({
            query: (args) => {
                return {
                    url: `/negotiation/meeting_notes/${args.uuid}/`,
                    method: 'PATCH',
                    body: {...args}
                }
            },
            invalidatesTags: (result, error, arg, meta) => ['MeetingNotes']
        }),
        deleteMeetingNotes: builder.mutation<void, { uuid: string }>({
            query: (args) => {
                return {
                    url: `/negotiation/meeting_notes/${args.uuid}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, arg, meta) => ['MeetingNotes']
        })
    })
})

export const {
    useLazyGetBankSalesManagementQuery,
    useLazyGetBankBranchAreaQuery,
    useLazyGetBankBranchQuery,
    useUpdateMeetingNoteMutation,
    useLazyGetSalesQuery,
    useGetBankSalesTypeQuery,
    useGetBankBranchRegionQuery,
    useLazyGetBankReportingOptionsQuery,
    useGetMeetingNotesQuery,
    useCreateMeetingNoteMutation,
    useDeleteMeetingNotesMutation,
    useLazyGetMeetingNotesQuery,
    useGetSingleBankBranchQuery
} = commercialDetailApiSlice