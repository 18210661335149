import { apiSlice } from "redux/api/baseApiSlice";
export type Appendix = {uuid: string, description: string, isa_amount_share: number}

export const appendixApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAppendix: builder.query<Appendix[], { negotiation: string }>({
            query: ({negotiation}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation}/appendix/`,
                    params: {
                        paginate: false
                    }
                };
            },
        }),
        getAppendixTotal: builder.query<{total_appendix: number | null}, { negotiation: string }>({
            query: ({negotiation}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation}/appendix/overall/`,
                };
            },
        }),
        createAppendix: builder.mutation<Appendix, { negotiation: string, description: string, isa_amount_share: number }>({
            query: (args) => {
                const { negotiation, ...params } = args;
                return {
                    url: `/negotiation/negotiations/${negotiation}/appendix/`,
                    method: 'POST',
                    body: { ...params }
                }
            }
        }),
        updateAppendix: builder.mutation<Appendix, { negotiation: string, appendix: string, description?: string, isa_amount_share?: number }>({
            query: (args) => {
                const { negotiation, appendix, ...params } = args;
                return {
                    url: `/negotiation/negotiations/${negotiation}/appendix/${appendix}/`,
                    method: 'PATCH',
                    body: { ...params }
                }
            }
        }),
        deleteAppendix: builder.mutation<Appendix, { negotiation: string, appendix: string }>({
            query: (args) => {
                const { negotiation, appendix } = args;
                return {
                    url: `/negotiation/negotiations/${negotiation}/appendix/${appendix}/`,
                    method: 'DELETE',
                }
            }
        })
    })
})

export const {useLazyGetAppendixQuery, useCreateAppendixMutation, useUpdateAppendixMutation, useDeleteAppendixMutation, useLazyGetAppendixTotalQuery } = appendixApiSlice