import {Button, Col, message, Row, Space} from "antd";
import Title from "antd/lib/typography/Title";
import InfoModal from "../../modals/InfoModal";
import React, {useEffect, useState} from "react";
import Card from "antd/lib/card/Card";
import DatePicker from "../../DatePicker";
import {Dayjs} from "dayjs";
import {useLazyGetTitleExtractionDownloadQuery} from "../../../redux/api/BelatedHistoryApiSlice";

export default function TitleExtractionCard({className}: { className: string }) {
    const [isFromAfterTo, setIsFromAfterTo] = useState(false)
    const [from, setFrom] = useState<Dayjs | null>()
    const [to, setTo] = useState<Dayjs | null>()

    const [download, {isError, isFetching, isLoading}] = useLazyGetTitleExtractionDownloadQuery()

    useEffect(() => {
        setIsFromAfterTo(!!from && !!to && from.isAfter(to))
    }, [from, to])

    return <div className={className}>
        <Row style={{marginTop: '1rem', marginBottom: '1rem'}}
             align={'middle'}>
            <Col xs={{offset: 1, flex: 1}} sm={{offset: 0}}>
                <div style={{display: "flex", flexWrap: 'nowrap'}}>
                    <Title level={3}>Estrazione stato emesso tardivo</Title>
                    <InfoModal title={"Estrazione stato emesso tardivo"}
                               description={<>Generazione di un .xlsx riepilogativo dello stato dell'emesso tardivo nel
                                   periodo scelto.</>}/></div>
            </Col>
        </Row>
        <Card>
            <Row justify={'space-between'}>
                <Col>
                    <Space size={16}>
                        <div>
                            <label style={{marginRight: '0.5rem'}}>Data di inizio:</label>
                            <DatePicker
                                value={from}
                                format={'DD/MM/YYYY'}
                                onChange={value => setFrom(value)}
                                disabledDate={value => !!to && !!value && value.isAfter(to)}
                            />
                        </div>
                        <div>
                            <label style={{marginRight: '0.5rem'}}>Data di fine:</label>
                            <DatePicker
                                value={to}
                                format={'DD/MM/YYYY'}
                                disabledDate={value => !!from && !!value && value.isBefore(from)}
                                onChange={value => setTo(value)}
                            />
                        </div>
                    </Space>
                </Col>
                <Col>
                    <Button
                        loading={isLoading || isFetching}
                        type={'primary'}
                        htmlType="submit"
                        disabled={!from || !to || isFromAfterTo}
                        onClick={() => {
                            if (from && to) {
                                download({date_from: from.format('YYYY-MM-DD'), date_to: to.format('YYYY-MM-DD')})
                                    .then(res => {
                                        if (isError) {
                                            message.error('Errore nella generazione del file .xlsx')
                                        } else {
                                            message.success('File scaricato correttamente')
                                        }
                                        setFrom(null)
                                        setTo(null)
                                    })
                                    .catch(err => message.error('Errore nella generazione del file .xlsx'))
                            }
                        }}>
                        Genera
                    </Button>
                </Col>
            </Row>
            {isFromAfterTo &&
                <p style={{color: 'red', marginTop: '1rem'}}>La data di fine deve essere uguale o successiva alla data
                  di inizio.</p>}
        </Card>
    </div>
}