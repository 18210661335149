import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Button, Row, Col, Modal, Divider, Spin} from 'antd';
import {Typography} from 'antd';
import {EditOutlined} from '@ant-design/icons';
import {ContractorModel, CreateContractorModel} from "types/contractors";
import EditContractorForm from "./EditContractorForm";

const {Title} = Typography

export default function ContractorsItem({
                                          contractor,
                                          updateContractors,
                                          isOneItemModifing,
                                          setIsOneItemModifing,
                                          onAlignContractor
                                        }: { contractor: ContractorModel, updateContractors: (data: CreateContractorModel) => void, isOneItemModifing: { status: boolean; key: string; }, setIsOneItemModifing: Dispatch<SetStateAction<{ status: boolean; key: string; }>>, onAlignContractor: (c: ContractorModel) => void }) {

  const {uuid: id, name} = contractor
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if ((isOneItemModifing.status && isOneItemModifing.key !== id) || isOneItemModifing.key === "")
      setIsModalOpen(false)
  }, [isOneItemModifing])

  const handleSaveClick = (values: CreateContractorModel) => {
    updateContractors(values)
    setIsModalOpen(false)
  }

  const handleModifyClick = () => {
    setIsOneItemModifing({status: true, key: id})
    setIsModalOpen(true)
  }

  return (
      <>
        <Row justify={'space-between'} style={{width: '100%'}}>
          <Col style={{paddingTop: '0.3rem', marginLeft: '0.3rem'}}><Title level={5}>{name}</Title></Col>
          <Col>
            <Button
                type={'link'}
                size={'middle'}
                onClick={handleModifyClick}
                icon={<EditOutlined/>}
            />
          </Col>
        </Row>
        <Modal open={isModalOpen} destroyOnClose footer={false} title={"Aggiornamento contraente"}
               onCancel={() => setIsModalOpen(false)}>
          <Spin spinning={contractor.is_autopilot_pending}>
            <EditContractorForm
                contractor={contractor}
                onsubmit={handleSaveClick}
                onAlignContractor={(c) => {
                  onAlignContractor(c)
                  setIsModalOpen(false)
                }}
                onClose={() => setIsModalOpen(false)}
            />
          </Spin>
        </Modal>
      </>
  )
}