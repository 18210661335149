import {SearchOutlined} from "@ant-design/icons";
import {Button, Modal, Space} from "antd";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {LabelValue} from "../../../types/charts";
import BankBranchMultiSelect from "../../select/MultiSelect/BankBranchMultiSelect";

export default function SearchBankBranch() {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedBankBranch, setSelectedBankBranch] = useState("")
    const navigate = useNavigate()

    return <>
        <Modal
            open={isModalOpen}
            title={'Ricerca filiale'}
            okText={"Conferma"}
            onOk={() => {
                setIsModalOpen(false)
                navigate(`/branches/${selectedBankBranch}`)
            }}
            okButtonProps={{
                disabled: !selectedBankBranch
            }}
            onCancel={() => setIsModalOpen(false)}
        >
            <Space
                direction={'vertical'}
            >
                Filiale
                <BankBranchMultiSelect
                    mode={undefined}
                    style={{width: '20rem'}}
                    onSelect={(v: LabelValue) => setSelectedBankBranch(v.value)}
                />
            </Space>
        </Modal>
        <Button
            icon={<SearchOutlined
                style={{
                    fontSize: '24px',
                    color: "#1890FF",
                }}
            />}
            type={'text'}
            onClick={() => setIsModalOpen(prevState => !prevState)}
        />
    </>
}