import React, {useRef} from "react";
import {Col, Divider, Row} from "antd";
import HighchartsReact from "highcharts-react-official";
import PieChart from "../charts/HighCharts/PieChart";
import {useGetBBBInBoundStatsQuery} from "../../redux/api/chartsApiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useBrokersChartsParamsContext} from "../../context/BrokersChartsContext";
import {useBankBranchesChartsParamsContext} from "../../context/BankBranchesChartsContext";
import {euro} from "../../utils/formatters";
import BBBChartCardContainer from "./BBBChartCardContainer";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";

export default function PremiumsInBoundGraph({entity}: { entity: 'broker' | 'bank_branch' }) {

    const ref = useRef<HighchartsReact.RefObject>(null);
    const {params: brokersParams} = useBrokersChartsParamsContext()
    const {params: bankBranchesParams} = useBankBranchesChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year

    const areParamsOk = !!(entity === 'broker' ? brokersParams.budget_group : bankBranchesParams.budget_group) && typeof uwYear === 'number'
    const {data, isFetching, isUninitialized} = useGetBBBInBoundStatsQuery(areParamsOk ?
        entity === 'broker' ?
            {
                budget_group: brokersParams.budget_group,
                calculation_type: 2,
                entity: 2,
                uw_year: uwYear,
            } :
            {
                budget_group: bankBranchesParams.budget_group,
                calculation_type: 2,
                entity: 1,
                uw_year: uwYear,
            }
        : skipToken)

    return <BBBChartCardContainer loading={isFetching || isUninitialized}>
        <Row>
            <Col span={14}>
                <PieChart
                    chartRef={ref}
                    options={{
                        chart: {
                            borderRadius: 12
                        },
                        exporting: {
                            chartOptions: {
                                title: {
                                    text: ""
                                },
                            }
                        },
                        legend: {
                            labelFormat: `{name} - {percentage:.2f}%`
                        },
                        responsive: {
                            rules: [{
                                condition: {
                                    maxWidth: 768
                                },
                                chartOptions: {
                                    legend: {
                                        align: 'center',
                                        verticalAlign: 'bottom',
                                        layout: 'horizontal'
                                    }
                                }
                            }]
                        },
                        plotOptions: {
                            pie: {
                                dataLabels: {
                                    enabled: false,
                                    format: '{point.name} - {percentage:.2f}%'
                                },
                                showInLegend: true
                            },
                        },
                        series: [
                            {
                                type: 'pie',
                                colorByPoint: true,
                                data: [
                                    {
                                        name: 'New business',
                                        y: data?.new_business
                                    },
                                    {
                                        name: 'Rinnovati con successo',
                                        y: data?.renewal
                                    },
                                    {
                                        name: 'Premi di regolazione',
                                        y: data?.regulation_premium
                                    }
                                ]
                            },
                        ]
                    }}/>
            </Col>
            <Col span={10}>
                <Row justify={"space-evenly"}>
                    <h3 style={{display: "flex", alignItems: "center"}}>Somma dei premi in Bound</h3>
                    <h2>{euro(data?.total || 0)}</h2>
                </Row>
                <Divider/>
                <div>
                    <Row justify={'space-between'}>
                        <Col>New Business:</Col>
                        <Col style={{fontWeight: 'bold'}}>{euro(data?.new_business || 0)}</Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <Col>Rinnovati con successo:</Col>
                        <Col style={{fontWeight: 'bold'}}>{euro(data?.renewal || 0)}</Col>
                    </Row>
                    <Row justify={'space-between'}>
                        <Col>Premi di regolazione:</Col>
                        <Col style={{fontWeight: 'bold'}}>{euro(data?.regulation_premium || 0)}</Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </BBBChartCardContainer>
}