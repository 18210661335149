import PieChartContainer from "./PieChartContainer";
import React, { useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import PieChart from "../HighCharts/PieChart";
import {euro, numeric} from "../../../utils/formatters";
import {useLazyGetNetPremiumOurShareChartQuery} from "../../../redux/api/chartsApiSlice";
import {PointOptionsObject} from "highcharts";
import SelectPremiumsOrNegotiationsCount from "../../select/SelectPremiumsOrNegotiationsCount";
import {Button, Col, Row} from "antd";
import NetPremiumOurShareForLobTable from "../../tables/chartTables/NetPremiumOurShareForLobTable";
import {selectUser} from "../../../redux/features/userSlice";

function Description({type}: {type: 1 | 2}) {
    return <>
        Il grafico mostra la distribuzione delle trattative per le LOB visibili.<br/> È possibile mostrare la suddivisione per il numero di trattative o la sommatoria dei premi.
        <dl>
            {type === 1 ?
                <>
                    <dt>Numero delle trattative in bound:</dt>
                    <dd>rappresenta la sommatoria delle trattative new business in Bound.</dd>
                </>
                :
                <>
                    <dt>Somma dei premi in bound:</dt>
                    <dd>rappresenta la sommatoria dei premi new business in Bound.</dd>
                </>
            }
            <dd>Premendo il tasto <span style={{fontWeight: 'bold'}}>Mostra dettaglio </span>, sarà visibile la
                tabella di dettaglio delle trattative in Bound suddivise per LOB.
            </dd>
        </dl>
    </>
}

function ExtraComponent({isModalVisible, setModalVisibility} : {isModalVisible: boolean, setModalVisibility:  React.Dispatch<React.SetStateAction<boolean>>}) {
    return <Row justify={'space-between'}>
        <Col>
            <Button key={'broker-details-btn'}
                    type={'primary'}
                    onClick={() => {
                        setModalVisibility(prevState => !prevState)
                    }}>
                {!isModalVisible ? 'Mostra dettaglio' : 'Nascondi dettaglio'}
            </Button>
        </Col>
    </Row>
}


export default function NetPremiumOurShareChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetNetPremiumOurShareChartQuery()
    const options = useSelector(selectOptions)
    const displayData = [{key: 'Conteggio di account complessivo', value: numeric(data ? data.account : 0)}]
    const [value, setValue] = useState<string>('')
    const [series, setSeries] = useState<PointOptionsObject[]>([])
    const [type, setType] = useState<1 | 2>(1)
    const [showDetail, setShowDetail] = useState<boolean>(false)
    const title = 'Net premium our share per LOB'

    useEffect(() => {
        if (data?.net_premium_our_share) {
            setValue(type === 1 ? numeric(data.net_premium_our_share) : euro(data.net_premium_our_share) )
        } else {
            setValue(type === 1 ? numeric(0) : euro(0) )
        }
    }, [data?.net_premium_our_share, type])

    useEffect(() => {
        if (data)
            setSeries(data ? data.lobs.map(el => {
                return {name: el?.lob, y: el?.percentage, color: options.lobs.find(itm => itm?.name === el.lob)?.color}
            }) : [])
    }, [data, options])


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                calculation_type: type
            })
        }
    }, [budgetGroup, getData, isia, type, uwYear])

    const pieChart = <PieChart
        chartRef={props.chartRef}
        options={{
            chart: {
                borderRadius: 12
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: title
                    },
                    subtitle: {
                        text: `Parametri: UW Year:${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                    }
                }
            },
            legend: {
                width: '50%',
                verticalAlign: 'middle',
                align: 'right',
                labelFormat: `{name} - {percentage:.2f}%`
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 768
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        }
                    }
                }]
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name} - {percentage:.2f}%'
                    },
                    showInLegend: true
                },
            },
            series: [
                {
                    type: 'pie',
                    data: series
                },
            ]
        }}
    />


    return <React.Fragment>
        <PieChartContainer
        description={<Description type={type} />}
        className={props.className}
        loading={isLoading || isFetching}
        title={title}
        buttons={[
            <SelectPremiumsOrNegotiationsCount key={'premium-negotiation-button'} type={type} setType={setType}/>,
            <DownloadButton key={'download'} chartRef={props.chartRef}/>
        ]}
        pieChart={pieChart}
        sideData={displayData}
        valueDescription={type === 1 ? 'Numero delle trattative in bound' : 'Somma dei premi in bound'}
        displayValue={value}
        colSpan={4}
        extraComponents={<ExtraComponent  isModalVisible={showDetail} setModalVisibility={setShowDetail} />}
    />
        {showDetail && <NetPremiumOurShareForLobTable className={'net-premium-our-share-sum--table'}/>}
    </React.Fragment>
}