import PieChartContainer from "./PieChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import {useSelector} from "react-redux";
import PieChart from "../HighCharts/PieChart";
import {euro, numeric} from "../../../utils/formatters";
import {
    useLazyGetNetPremiumOurShareTypeBusinessChartQuery
} from "../../../redux/api/chartsApiSlice";
import SelectPremiumsOrNegotiationsCount from "../../select/SelectPremiumsOrNegotiationsCount";
import {selectUser} from "../../../redux/features/userSlice";

function Description({type}: { type: 1 | 2 }) {
    return <>
        {type === 1 ? <>Il grafico mostra la distribuzione delle trattative in bound suddivise tra new business e
                rinnovati con successo.</> :
            <>Il grafico mostra la distribuzione delle trattative in bound suddivise tra new business, rinnovati con
                successo e premi di regolazione.</>}
        <br/> È possibile mostrare la suddivisione per il numero di trattative o la sommatoria dei premi.
        <dl>
            {type === 1 ?
                <>
                    <dt>Numero delle trattative in bound:</dt>
                    <dd>rappresenta la sommatoria delle trattative in Bound.</dd>
                </>
                :
                <>
                    <dt>Somma dei premi in bound:</dt>
                    <dd>rappresenta la sommatoria dei premi in Bound.</dd>
                </>
            }
        </dl>
    </>
}


export default function NetPremiumOurShareTypeBusinessChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {
    const [getData, {data, isLoading, isFetching, isError}] = useLazyGetNetPremiumOurShareTypeBusinessChartQuery()
    const [type, setType] = useState<1 | 2>(1)
    const title = 'Net premium our share per tipo'
    const displayNumberData = [{
        key: 'New business',
        value: numeric(data ? data.new_business : 0)
    }, {key: 'Rinnovati con successo', value: numeric(data ? data.renewal : 0)}, {
        key: 'Premi di regolazione',
        value: numeric(data ? data.regulation_premium : 0)
    }]
    const displayEuroData = [{
        key: 'New business',
        value: euro(data ? data.new_business : 0)
    }, {key: 'Rinnovati con successo', value: euro(data ? data.renewal : 0)}, {
        key: 'Premi di regolazione',
        value: euro(data ? data.regulation_premium : 0)
    }]

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                calculation_type: type
            })
        }
    }, [budgetGroup, getData, isia, type, uwYear])

    const total: string = type === 1 ? numeric(data ? data.total : 0) : euro(data ? data.total : 0)

    const dataSeries = () => {
        let _series = [
            {
                name: 'New business',
                y: data?.new_business
            },
            {
                name: 'Rinnovati con successo',
                y: data?.renewal
            },
            {
                name: 'Premi di regolazione',
                y: data?.regulation_premium
            }
        ]
        return _series
    }

    const pieChart = <PieChart
        chartRef={props.chartRef}
        options={{
            chart: {
                borderRadius: 12
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: title
                    },
                    subtitle: {
                        text: `Parametri: UW Year:${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                    }
                }
            },
            legend: {
                width: '50%',
                verticalAlign: 'middle',
                align: 'right',
                labelFormat: `{name} - {percentage:.2f}%`
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 768
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        }
                    }
                }]
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name} - {percentage:.2f}%'
                    },
                    showInLegend: true
                },
            },
            series: [
                {
                    type: 'pie',
                    colorByPoint: true,
                    data: dataSeries()
                },
            ]
        }}
    />


    return <React.Fragment>
        <PieChartContainer
            description={<Description type={type}/>}
            className={props.className}
            loading={isLoading || isFetching}
            title={title}
            buttons={[
                <SelectPremiumsOrNegotiationsCount key={'premium-negotiation-button'} type={type} setType={setType}/>,
                <DownloadButton key={'download'} chartRef={props.chartRef}/>
            ]}
            pieChart={pieChart}
            sideData={type === 1 ? displayNumberData : displayEuroData}
            valueDescription={type === 1 ? 'Numero delle trattative in bound' : 'Somma dei premi in bound'}
            displayValue={total}
            colSpan={4}
        />
    </React.Fragment>
}