import React, { useEffect } from 'react';
import Modal from 'antd/es/modal/Modal';
import Results from './Results';
import Help from './Help';
import Hint from './Hint';
import { Button, Input, Space, message, Typography} from 'antd';
import { SearchOutlined, AudioOutlined } from '@ant-design/icons';
import { advancedSearchData } from 'types/advancedSearchData';
import { useDispatch, useSelector } from 'react-redux';
import { setTablesPreferences } from 'redux/features/userSlice';
import { Views } from 'types';
import { selectUser } from 'redux/features/userSlice';
import { DEFAULT_CURRENT_PAGE_SIZE_TABLE, DEFAULT_CURRENT_PAGE_TABLE } from 'components/tables/negotiationsTables/columnsUtils';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useSetUserTablesPreferencesMutation } from 'redux/api/userPreferencesApiSlice';
import { useLazyGetAdvancedSearchQuery } from 'redux/api/advancedSearchApiRequest';
import { useLazyGetPaginatedReinsuresQuery } from 'redux/api/reinsurersApiSlice';
import { useLazyGetPaginatedReinsurerBrokersQuery } from 'redux/api/reinsurerBrokersApiSlice';
import { useLazyGetDelegatedInsurerQuery } from 'redux/api/negotiationUsersApiSlice';
import { useLazyGetContractorsQuery } from 'redux/api/contractorsApiSlice';
import { useLazyGetPaginatedBrokersQuery } from 'redux/api/brokersApiSlice';

export default function AdvancedSearchButton() {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [textAreaValue, setTextAreaValue] = React.useState("Estrai le trattative ");
    const [results, setResults] = React.useState(false);
    const [showHelp, setShowHelp] = React.useState(false);
    const [showHint, setShowHint] = React.useState(false);
    const [vocalButtonDisabled, setVocalButtonDisabled] = React.useState(false);
    const [isVoiceRecognitionActive, setIsVoiceRecognitionActive] = React.useState(false);

    //managing the response of AI services
    const [objects, setObjects] = React.useState<advancedSearchData[]>([]);

    //get the response from AI services
    const [getData, {isLoading, isFetching, error: errorGpt}]= useLazyGetAdvancedSearchQuery()
    const isError503= !!errorGpt &&  !! ('status' in errorGpt) && errorGpt.status === 503

    const user=useSelector(selectUser);
    const userSelectedColumns = user.preferences.tables.general.selected_columns;

    const dispatch = useDispatch();
    const [updatePreferences] = useSetUserTablesPreferencesMutation()
    const [fetchContractors] = useLazyGetContractorsQuery()
    const [fetchBrokers] = useLazyGetPaginatedBrokersQuery()
    const [fetchReinsurers] = useLazyGetPaginatedReinsuresQuery()
    const [fetchReinsurerBrokers] = useLazyGetPaginatedReinsurerBrokersQuery()
    const [fetchDelegatedInsurers] = useLazyGetDelegatedInsurerQuery()

    const {TextArea} = Input;

    //Voice recognition service
    const { transcript, listening: isListening, resetTranscript, browserSupportsSpeechRecognition, isMicrophoneAvailable } = useSpeechRecognition();

    //check if the browser has microphone permission and toggle on/off voice recognition
    function handleVoiceRecognition(){
      if(browserSupportsSpeechRecognition){   //check if the browser supports speech recognition
        if(isMicrophoneAvailable){            //check if the browser has microphone permission
          if(isVoiceRecognitionActive){       
            stopListening();
          } else {
            startListening();
          }
        }else{
          message.warning("Il microfono non è disponibile o i permessi sono stati negati.")
        }
      } else {
        message.warning("Il tuo browser non supporta tale servizio.")
      }
    }

    //start voice recognition
    async function startListening(){
      await SpeechRecognition.startListening({ continuous: true, language: "it-IT"});
      setIsVoiceRecognitionActive(true);
    }

    //stop voice recognition and save the transcript in the textarea
    async function stopListening(){
      await SpeechRecognition.stopListening();
      setIsVoiceRecognitionActive(false);
      setTextAreaValue(prevState => `${prevState} ${transcript}`)
      resetTranscript();
    }

    //check if service is available in the current browser
    useEffect(() => {
      if (!isMicrophoneAvailable) {
        setIsVoiceRecognitionActive(false); 
        SpeechRecognition.abortListening();
        message.warning("Il microfono non è disponibile o i permessi sono stati negati.");
      }
    }, [isMicrophoneAvailable]);

    //UIDS search for fields like contractors and brokers and if there is no results for a field, disable that field's checkbox
    async function searchUIDS(objects: advancedSearchData[]) {
      for (const o of objects) {
        const uids: { label: string, value: string }[] = [];
        let noMatch = ""; //used to replace the label of uids when there isn't a match for a field

        switch (o.systemLabel) {
          case 'broker':
            noMatch = o.userValue.join(", "); //convert an array(userValue) to a string, divided with ', '
            const brokerPromises = o.userValue.map((item) => { //for every item in the array
              return fetchBrokers({ search: item }).then((response) => { //search for brokers
                const data = response.data?.results.map((el) => ({ label: el.name, value: el.uuid })) || []; //get all results from the broker
                if (data.length > 0 && data[0].label && data[0].value) { //if found at least one broker
                  uids.push({ label: data[0]?.label, value: data[0]?.value}); //save the first broker found in uids
                }
              });
            });
            await Promise.all(brokerPromises); //wait for end of search of each userValue item
            if(uids.length===0) {              //if uids is empty then
              uids.push({ label: noMatch, value: "" }); 
              selectObjects(false,"broker",true); //set selected to false and disable to true in this way the user cant select this field to filter the table
            }
            setUID(uids, "broker"); //save uids to objects
            break;

          case 'contractor':
            noMatch = o.userValue.join(", ");
            const contractorPromises = o.userValue.map((item) => {
              return fetchContractors({ search: item }).then((response) => {
                const data = response.data?.results.map((el) => ({ label: el.name, value: el.uuid })) || [];
                if (data.length > 0 && data[0].label && data[0].value) {
                  uids.push({ label: data[0]?.label, value: data[0]?.value});
                }
              });
            });
            await Promise.all(contractorPromises);
            if(uids.length===0) {
              uids.push({ label: noMatch, value: "" }); 
              selectObjects(false,"contractor",true);
            }
            setUID(uids, "contractor");
            break;

          case 'delegated_insurer': 
            noMatch = o.userValue.join(", ");
            const delegatedInsurerPromises = o.userValue.map((item) => {
              return fetchDelegatedInsurers({ search: item }).then((response) => {
                const data = response.data?.map((el) => ({ label: el.name, value: el.uuid })) || [];
                if (data.length > 0 && data[0].label && data[0].value) {
                  uids.push({ label: data[0]?.label, value: data[0]?.value});
                }
              });
            });
            await Promise.all(delegatedInsurerPromises);
            if(uids.length===0) {
              uids.push({ label: noMatch, value: "" }); 
              selectObjects(false,"delegated_insurer",true);
            }
            setUID(uids, "delegated_insurer");
            break;

          case 'reinsurer_broker':
            noMatch = o.userValue.join(", ");
            const reinsurerBrokerPromises = o.userValue.map((item) => {
              return fetchReinsurerBrokers({ search: item }).then((response) => {
                const data = response.data?.results.map((el) => ({ label: el.name, value: el.uuid })) || [];
                if (data.length > 0 && data[0].label && data[0].value) {
                  uids.push({ label: data[0]?.label, value: data[0]?.value});
                }
              });
            });
            await Promise.all(reinsurerBrokerPromises);
            if(uids.length===0) {
              uids.push({ label: noMatch, value: "" }); 
              selectObjects(false,"reinsurer_broker",true);
            }
            setUID(uids, "reinsurer_broker");
            break;

          case 'reinsurers_list':
            noMatch = o.userValue.join(", ");
            const reinsurersListPromises = o.userValue.map((item) => {
              return fetchReinsurers({ search: item }).then((response) => {
                const data = response.data?.results.map((el) => ({ label: el.name, value: el.uuid })) || [];
                if (data.length > 0 && data[0].label && data[0].value) {
                  uids.push({ label: data[0]?.label, value: data[0]?.value});
                }
              });
            });
            await Promise.all(reinsurersListPromises);
            if(uids.length===0) {
              uids.push({ label: noMatch, value: "" }); 
              selectObjects(false,"reinsurers_list",true);
            }
            setUID(uids, "reinsurers_list");
            break;
        }
      }
    }

    //setup UIDS in the object
    function setUID(value:{label: string, value: string}[], target: string) {
      setObjects(prev => {
        return prev.map(p=> {
          if(p.systemLabel===target){
            return {...p,systemValue: value, userValue: value.map(v=> v.label)};
          }else{
            return p;
          }})
      })
    }

    //close the advanced search modal
    async function closeModal(){
      setIsModalOpen(false);  //set the modal open state
      if(isListening) 
        await stopListening();  //if voice recognition is active then close it 
      clearTextArea();        
      setResults(false);      //set show results component status
    }

    //clear the text area
    function clearTextArea() {
      resetTranscript();        //used to clear transcript variable of speech recognition service
      setTextAreaValue(prefix); 
      console.log("clearTextArea");
    }

    //send request to chat gpt, wait for response and show results
    function sendRequestAndShowResults() {
        getData({instruction: textAreaValue}).then((response) => {
          if(isError503){
              message.error("Errore servizio, riprovare più tardi")
          } else {
              const data=response.data; 
              setObjects(data || []);  //save response from AI services 
              searchUIDS(data || []);  //search for uids if required
              setShowHelp(false);      //hide Help component
              setVocalButtonDisabled(true); //disable the voice recognition button
              setResults(true);        //show results component
          }
        });
    };

    //close results component
    function goBack() {
      setResults(false); 
      setVocalButtonDisabled(false);
    };

    //success message if filters is successfully applied
    function showSuccessMessage() {
      setResults(false);
      setIsModalOpen(false);
      message.success("Filtraggio applicato");
    }

    //toggle on/off the checkbox of the results for filtering the table, can disable the checkbox if disable property is set
    function selectObjects(value: boolean, target: string, disable?: boolean) {
      setObjects(prev => {
        return prev.map(p=> {
          if(p.systemLabel===target){
            if(disable){
              return {...p,applyChanges: value, disabled: disable};
            } else {return {...p,applyChanges: value};}
          }else{
            return p;
          }})
      })
    }

    //insert examples into the textarea
    function insertExsample(value: string){
      setTextAreaValue(value);
    }

    //apply filters to the table
    function applyFilters() {
      //filters
      const filters = objects
        .filter(el => el.applyChanges && 
          el.systemValue.length!==0 && 
          el.systemLabel!=="ascending_ordering" && 
          el.systemLabel!=="descending_ordering" &&
          el.systemLabel!=="reset_filters")
        .map((item) => ({key: item.systemLabel, range: item.systemValue}));

      //ascend or descend ordering of columns
      const sorters: {key: string, type: "ascend" | "descend"}[] = objects
        .filter(el => (el.systemLabel==="ascending_ordering" || el.systemLabel==="descending_ordering") && el.applyChanges)
        .flatMap((item) => {
            return item.systemValue.map((column) => ({
              key: column.toString(), 
              type: item.userLabel==="Ordinazione crescente"? "ascend" : "descend"}))
        });
          
      //show columnns
      const selected_columns_set = [...(userSelectedColumns ?? []), ...objects.flatMap((item) => {
        if(item.systemLabel!=="order") return item.systemLabel;
        else return item.systemValue.map(column => column.toString())
      })];
      const selected_columns= selected_columns_set.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });

      //apply the selected columns, must be done separately to filters and sorters
      dispatch(setTablesPreferences({
        [Views[Views.general]]: {
          selected_columns: selected_columns,
        }
      }))

      dispatch(setTablesPreferences({
        [Views[Views.general]]: {
          sorters: sorters? sorters : [],
          filters: filters,
          page: DEFAULT_CURRENT_PAGE_TABLE,
          page_size: DEFAULT_CURRENT_PAGE_SIZE_TABLE
        }
      }))

      updatePreferences({
        [Views[Views.general]]: {
          sorters: sorters? sorters : [],
          filters: filters,
          selected_columns: selected_columns,
        }
      })
      showSuccessMessage();
      clearTextArea();
      setVocalButtonDisabled(false);
    }

    const footer=results? ( //footer of the modal when is showing results component
      <div>
        <Button key="cancel" onClick={goBack}>Indietro</Button>
        <Button key="conferma" disabled={objects.length===0? true:false} type="primary" onClick={applyFilters}>Applica Filtraggio</Button>
      </div>
    ) : ( //footer of the modal when is not showing the result component
      <div>
        <Button key="reset" disabled={isLoading || isFetching} onClick={clearTextArea}>Reset</Button>
        <Button key="apply" type="primary" onClick={sendRequestAndShowResults} loading={isLoading || isFetching} disabled={textAreaValue && !isListening && textAreaValue.trim() !== ""? false:true}>Conferma</Button>
      </div>
    );

    //initial value of the text area
    const prefix = "Estrai le trattative ";
    //placeholder of the text area
    const myPlaceholder = `Inserisci i dati per il filtraggio...
Es. Estrai le trattative con la data di registrazione dal 01/01/2023 a oggi con lo status rinnovata`;

    return (
        <>  
            <Button onClick={()=>setIsModalOpen(true)} icon={<SearchOutlined />}>Filtraggio Testuale</Button>
            <Modal 
              title={<div>Filtraggio testuale</div>}
              open={isModalOpen} 
              onCancel={() => {
                if (!isLoading && !isFetching) {
                  closeModal();
                }
              }}
              footer={footer}
              width={1000}
            >
                <Hint showHint={showHint} setShowHint={e => setShowHint(e)}/>

                <Button disabled={results} onClick={()=>setShowHelp(p=> !p)} type='link'><Typography.Link underline>Esempi di Utilizzo</Typography.Link></Button>
                <Help show={showHelp} handleInsertHelp={insertExsample}/>

                <TextArea 
                  placeholder={myPlaceholder}
                  autoSize={{minRows: 4}} 
                  value={isVoiceRecognitionActive? textAreaValue+transcript: textAreaValue}
                  onChange={(e)=> setTextAreaValue(e.target.value)}
                  readOnly={isVoiceRecognitionActive}
                  maxLength={4000} //1279 tokens
                  disabled={!!results || isVoiceRecognitionActive} />
                {textAreaValue.length===4000 && <div style={{color: 'red'}}>Lunghezza testo massimo raggiunto</div>}
                <br />

                <Space align="end" style={{display: "flex", justifyContent: "flex-end"}}>
                  <Button 
                    style={{marginTop: '1em'}}
                    disabled={vocalButtonDisabled} 
                    onClick={handleVoiceRecognition} 
                    icon={<AudioOutlined style={isVoiceRecognitionActive ? {color:'red', animation: 'blink 1.5s infinite' } : {}}/>}
                  >
                    {isVoiceRecognitionActive? "Spegni dettatura":"Avvia dettatura"}
                  </Button>
                </Space>

                <Results show={results} results={objects} handleIsSelected={selectObjects}/>
                
            </Modal>
        </>
    );
}
