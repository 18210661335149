import React, {useState} from "react";
import {Affix, Col, Divider, Row, Space} from "antd";
import PremiumsInBoundGraph from "../../components/commercialDetail/PremiumsInBoundGraph";
import AnnualHitRatioGraph from "../../components/commercialDetail/AnnualHitRatioGraph";
import NegotiationsInBoundGraph from "../../components/commercialDetail/NegotiationsInBoundGraph";
import BudgetGroupsCards from "../../components/commercialDetail/BudgetGroupsCards";
import {useGetAllBudgetGroupsQuery} from "../../redux/api/budgetsApiSlice";
import {useBrokersChartsParamsContext} from "../../context/BrokersChartsContext";
import BrokersSubmissionReceived from "../../components/commercialDetail/brokers/BrokersSubmissionReceived";
import NegotiationsInBoundTotal from "../../components/commercialDetail/NegotiationsInBoundTotal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import BrokersTableMap from "../../components/commercialDetail/brokers/BrokersTableMap";
import BBBChartColContainer from "../../components/commercialDetail/BBBChartColContainer";
import SearchBroker from "../../components/commercialDetail/broker/SearchBroker";
import UwYearDatePicker from "../../components/select/UwYearDatePicker";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";

export default function BrokerIndex() {

    const {params, setParams} = useBrokersChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = typeof uwYear === 'number'

    const {
        data: budgetGroupOptions,
        isFetching: isFetchingBudgetGroupOptions
    } = useGetAllBudgetGroupsQuery(areParamsOk ? {
        entity: 2,
        uw_year: uwYear,
    } : skipToken)

    const [isAffix, setIsAffix] = useState(false)
    const {xs} = useBreakpoint()

    return <div style={{padding: xs ? 0 : "1rem"}}>
        <Affix
            offsetTop={0}
            onChange={a => setIsAffix(!!a)}>
            <Row
                style={isAffix ? {
                    padding: '16px',
                    borderRadius: '12px',
                    boxShadow: '0px 10px 15px -10px #AAAAAA',
                    backgroundColor: '#ffffff',
                } : {}}
                justify={"end"}
                align={"middle"}
                gutter={[16, 16]}>
                <Col
                    flex={1}
                    xs={isAffix ? 0 : undefined}>
                    <Space align={'center'}>
                        <h1>Dashboard Broker</h1>
                        <SearchBroker/>
                    </Space>
                </Col>
                <Col>
                    <UwYearDatePicker/>
                </Col>
                <Col xs={24}>
                    <BudgetGroupsCards
                        loading={isFetchingBudgetGroupOptions}
                        options={budgetGroupOptions || []}
                        selectedOption={params.budget_group}
                        setSelectedOption={(value: string) => {
                            setParams(prevState => ({
                                ...prevState,
                                budget_group: value
                            }))
                        }}
                    />
                </Col>
            </Row>
        </Affix>
        <Divider/>

        <Row gutter={[16, 16]}>
            <BrokersTableMap/>
            <BBBChartColContainer>
                <PremiumsInBoundGraph entity={'broker'}/>
            </BBBChartColContainer>
            <BBBChartColContainer>
                {areParamsOk && <AnnualHitRatioGraph
                    uw_year={uwYear}
                    entity={2}
                    budget_group={params.budget_group}
                />}
            </BBBChartColContainer>
            <BBBChartColContainer>
                <NegotiationsInBoundGraph entity={'broker'}/>
            </BBBChartColContainer>
            <BBBChartColContainer>
                <BrokersSubmissionReceived/>
            </BBBChartColContainer>
            <BBBChartColContainer>
                <NegotiationsInBoundTotal entity={'broker'}/>
            </BBBChartColContainer>
        </Row>
    </div>
}