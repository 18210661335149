import {NegotiationFormModel} from "../../types/negotiations";

export const isDebounceUpdate = ( changedValues: Partial<NegotiationFormModel>) => {
    let debounceUpdate = false
      for (const field in changedValues || debounceUpdate) {
        // these fields don't require a debounced update (for example radio / select formItems)
        if (!(['attorney_from',
          'is_isia',
          "isia_type",
          "isia_sale",
          "broker",
          'broker_branch',
          'is_lps',
          'has_prorata',
          'policy_effective_date',
          'effect_hour',
          'policy_expiring_date',
          'uw_year',
          'is_calculated',
          'reinsurance_type',
          'reinsurer_broker',
          'delegated_insurer',
          'policy_product_type',
          'is_auto_renewal',
          'cancellation_terms_days',
          'is_new_business',
          'product_type',
          'is_framework_agreement',
          'installment_type',
          'has_fund',
          'fund',
          'ppw_days',
          'contractor',
          'policy_with_certificates',

          // portfolio formFields
          'portfolio_waiting_reason',
          'has_regulation_premium'
        ] as (keyof NegotiationFormModel)[]).includes(field as keyof NegotiationFormModel)) {
          debounceUpdate = true
        }
      }
      return debounceUpdate
}