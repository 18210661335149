import {Card, Col, Select, Space, Spin, Typography} from "antd";
import {StatusIndicator} from "../negotiationForm/sidebars/status/utils";
import StatusHelpModal from "../modals/StatusHelpModal";
import Title from "antd/lib/typography/Title";
import dayjs from "dayjs";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";

export default function DocumentSidebar(props: any) {
    const {Paragraph} = Typography
    const [uwYOptions, setUwYOptions] = useState<{label: string, value: string}[] | undefined>([])

    useEffect(()=> {
        setUwYOptions(props.uwYearOptions)
    }, [props.uwYearOptions])

    return <Spin spinning={!!props.loading}><Card title={"Dettagli trattativa"}>
        <Space direction='vertical' size='small' align='start' style={{width: '100%'}}>
            <Col span={24} style={{display: "flex", textAlign: 'end'}}>
                {(typeof props.status === "number") && <StatusIndicator state={props.status}/>}
                <StatusHelpModal/>
            </Col>
            <Space direction='horizontal' size='middle' align={'start'}>
                <Paragraph>Contraente</Paragraph>
                <Title underline={!props.contractor}
                       level={5}>{props.contractor || "Non presente"}</Title>
            </Space>
            <Col>
                <Space direction='horizontal' size='middle' align={'start'}>
                    <Paragraph>LOB</Paragraph>
                    <Title underline={!props.lob}
                           level={5}>{props.lob || "Non presente"}</Title>
                </Space>
            </Col>
            <Col>
                <Space direction='horizontal' size='middle' align={'center'} style={{width: '100%'}}
                       wrap={true}>
                    <Paragraph>Underwriter</Paragraph>
                    <Title underline={!props.underwriter}
                           level={5}>{props.underwriter || "Non presente"}</Title>
                </Space>
            </Col>
            <Col>
                <Space direction='horizontal' size='middle' align={'start'}>
                    <Paragraph>Data di ricezione</Paragraph>
                    <Title underline={!props.receptionDate}
                           level={5}>{props.receptionDate ? dayjs(props.receptionDate).format('DD/MM/YYYY') : "Non presente"}</Title>
                </Space>
            </Col>
            <Col>
                <Space direction='horizontal' size='middle' align={'start'}>
                    <Paragraph>Data di decorrenza</Paragraph>
                    <Title underline={!props.effectiveDate}
                           level={5}>{props.effectiveDate ? dayjs(props.effectiveDate).format('DD/MM/YYYY') : "Non presente"}</Title>
                </Space>
            </Col>
            <Col>
                <Space direction='horizontal' size='middle' align={'start'}>
                    <Paragraph>Data di scadenza</Paragraph>
                    <Title underline={!props?.expiringDate}
                           level={5}>{props.expiringDate ? dayjs(props.expiringDate).format('DD/MM/YYYY') : "Non presente"}</Title>
                </Space>
            </Col>
            <Col>
                <Space className={'ant-space-align-baseline'} direction='horizontal' size='large' align={'start'}>
                    <Paragraph>UW Year</Paragraph>
                    {uwYOptions && <Title underline={!props?.uwYear}
                            level={5}>
                        {uwYOptions.length !== 1 ?
                            <Select className={'uw-year-ant-select'} style={{width: "7rem"}} options={props.uwYearOptions} onChange={value => props.changeNegotiation(value)}
                                    defaultValue={{label: props.uwYear, value: props.uwYear}}></Select> : (props?.uwYear || "Non presente")}
                    </Title>}
                </Space>
            </Col>
            <Col>
                <Space direction='horizontal' size='middle' align={'start'}>
                    <Paragraph>Broker</Paragraph>
                    <Title underline={!props?.broker}
                           level={5}>{props?.broker || "Non presente"}</Title>
                </Space>
            </Col>
            <Col>
                <Space direction='horizontal' size='middle' align={'start'}>
                    <Paragraph>ISIA</Paragraph>
                    <Title level={5}>{!!props?.isia ? 'SI' : 'NO'}</Title>
                </Space>
            </Col>
            <Space direction='horizontal' size='middle' align={'start'}>
                <Paragraph>Numero polizza</Paragraph>
                <Title underline={!props.policyNumber}
                       level={5}>{props.policyNumber || "Non presente"}</Title>
            </Space>
        </Space>
    </Card></Spin>
}