import {Col, Row, Typography} from 'antd';
import {stateToColors} from 'utils/formatters';

const {Title} = Typography

export const StatusIndicator = ({state}: { state: number }) => {

  const enhancedState = stateToColors[state]

  return (
    <Row justify='start' align='middle' gutter={8}>
      <Col style={{
        width: '6px',
        height: '6px',
        backgroundColor: `var(${enhancedState.color}, black)`,
        borderRadius: '50px',
        padding: 0
      }}>
      </Col>
      <Col>
        <Title level={5} style={{marginBottom: 0}}>{enhancedState.label}</Title>
      </Col>
    </Row>
  )
}