import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import {Select} from "antd";
import {euro, percentage} from "utils/formatters";
import {Series, SeriesOptionsType} from "highcharts";
import CommonChart from "../HighCharts/CommonChart";
import {
    useLazyGetAnnualGoalChartQuery,
} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

function Description() {
    return <>
        Il grafico mostra le seguenti serie dati:
        <dl>
            <dt>Bound:</dt>
            <dd>la sommatoria dei premi new business in bound.</dd>
            <dt>Premi rinnovati:</dt>
            <dd>la sommatoria dei premi rinnovati in bound.</dd>
            <dt>Premi di regolazione:</dt>
            <dd>la sommatoria dei premi di regolazione delle trattative in bound con regolazione.</dd>
            <dt>Stima premi futuri rinnovati:</dt>
            <dd>la stima viene calcolata prendendo la sommatoria dei premi dell’uw year precedente in bound e con data
                di decorrenza tra la odierna dell’anno scorso e il 31/12 dell’anno scorso, a cui viene moltiplicato il
                retention rate di riferimento.
            </dd>
            <dt>Stima premi pipeline:</dt>
            <dd>la stima viene calcolata prendendo la sommatoria dei premi in stato quoted moltiplicata per l’Hit ratio
                (rispetto allo stato quoted).
            </dd>
            <dt>Gap to budget:</dt>
            <dd>la percentuale è la differenza tra 100 e la sommatoria delle percentuali delle serie precedenti;
                l'ammontare dei premi è calcolato moltiplicando la percentuale risultante della sottrazione e il budget.
            </dd>
            <dt>Totale:</dt>
            <dd>rappresenta il budget di riferimento.
            </dd>
        </dl>
    </>
}

export default function AnnualGoalChart (props: { className: string, chartRef: React.RefObject<HighchartsReact.RefObject>, lob: { label: string, value: string } }) {

    const title = 'Obiettivo annuo'
    const subtitle = 'GAP to budget based on actual hit ratio'
    const [agencyBroker, setAgencyBroker] = useState({label: 'Totale', value: "total"})
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetAnnualGoalChartQuery()
    const [series, setSeries] = useState<SeriesOptionsType[]>([])

    useEffect(() => {
        if (data)
            setSeries(data ? data?.map(el => {
                if (!el.in_chart){
                    return {type: 'bar', name: el.state.toString(), data: [0], showInLegend: true, color: 'transparent'}
                }
                else
                    return {type: 'bar', name: el.state.toString(), data: [el.percentage]}
            }) : [])
    }, [data])

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                scope: agencyBroker.value,
            })
        }
    }, [agencyBroker.value, budgetGroup, getData, isia, uwYear])

    return <ChartContainer
        description={<Description />}
        loading={isLoading || isFetching}
        className={props.className}
        title={title}
        buttons={[
            <Select
                style={{
                    width: '5.8rem',
                    textAlign: 'center'
                }}
                options={[
                    {label: 'Agenzia', value: "isia"},
                    {label: 'Broker', value: "brokers"},
                    {label: 'Totale', value: "total"},
                ]}
                value={agencyBroker}
                key={'select-agency-broker'}
                onSelect={(value: any, option: { label: string, value: string }) => {
                    setAgencyBroker(option)
                }}
            />,
            <DownloadButton key={'download-button'} chartRef={props.chartRef}/>
        ]}>
        <CommonChart
            chartRef={props.chartRef}
            options={{
                subtitle: {text: subtitle, align: "left", style: {fontSize: '14px'}},
                legend: {
                    title: {
                        text: "Composizione premi",
                        style: {
                            fontSize: '1rem'
                        },
                    },
                    labelFormatter: function () {
                        const series = this as Series
                        return `<div>
${series.name}: ${data?.find(el => el.state.toString() === series.name) ? euro(data.find(el => el.state.toString() === series.name)!.premium) : ''}
 ${typeof data?.find(el => el.state.toString() === series.name)?.percentage === 'number' ? '(' + percentage(data?.find(el => el.state.toString() === series.name)?.percentage as number) + ')' : ''}
 </div>`
                    },
                    itemMarginTop: 6,
                    layout: 'vertical',
                    verticalAlign: 'middle',
                    align: 'right',
                    navigation: {
                        enabled: false
                    },
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 768
                        },
                        chartOptions: {
                            chart: {
                                height: 350,
                                type: 'bar'
                            },
                            legend: {
                                width: '100%',
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            }
                        },
                    }]
                },
                yAxis: {
                    visible: false
                },
                xAxis: {
                    visible: false
                },
                plotOptions: {
                    series: {
                        stacking: 'percent',
                    }
                },
                chart: {
                    height: 300,
                    type: 'bar'
                },
                tooltip: {
                    headerFormat: '',
                    pointFormatter: function () {
                        const value = this.percentage ? percentage(this.percentage / 100) : percentage(0)
                        const name = this.series.name ? this.series.name : ''
                        const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                        return `${symbol} ${name}</br> <span style='font-weight: bolder'>${value}</span>`
                    },
                    useHTML: true
                },
                series: series,
                exporting: {
                    chartOptions: {
                        title: {
                            text: 'Obiettivo annuo'
                        },
                        chart: {
                            height: undefined,
                        },
                        subtitle: {
                            text: `Parametri: ${agencyBroker.label} - UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        legend: {
                            layout: 'horizontal',
                            verticalAlign: 'bottom',
                            align: 'center',
                            width: '100%',
                            itemDistance: 20,
                        }
                    }
                }
            }}

        />

    </ChartContainer>
}