import { List, Typography, Checkbox, Empty } from "antd";
import { specialFields } from "types/specialFields";
import { advancedSearchData } from "types/advancedSearchData";

interface propResult{
    show: boolean,
    results: advancedSearchData[],
    handleIsSelected: (value: boolean, target: string, disable?: boolean)=> void,
}

export default function Results({ show, results, handleIsSelected }: propResult) {
    return (
        <>
            
            <List
                style={show? { display: 'block' } : { display: 'none' }}
                header={<div>Risultati ottenuti:</div>}
                size="small"
                itemLayout="horizontal"
                dataSource={results}
                locale = {{emptyText: <Empty description="Nessun Risultato" />}}
                renderItem={item => 
                <List.Item key={item.systemLabel} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Checkbox 
                        style={{marginRight: '1em'}} 
                        checked={item.applyChanges} 
                        disabled={item.disabled} 
                        onChange={e=> {handleIsSelected(e.target.checked, item.systemLabel)}}
                    >
                    {/* name of column */}
                    <Typography.Text strong style={{marginLeft: '1em'}} >{item.userLabel}: </Typography.Text>
                    {/* show fields with a range of values */}
                    <Typography.Text>
                    {specialFields.map((field) => {
                        if (item.systemLabel === field.system) {
                            if(field.suffix) { //%,$,euro...
                                const formattedNumber= item.userValue.map(value => {
                                    return parseFloat(value).toLocaleString("it-IT",{//parse to decimal style (only for numbers)
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })
                                });
                                return ` da ${formattedNumber[0]}${field.suffix} a ${formattedNumber[1]}${field.suffix}`;
                            } else {
                            return item.userValue[1]? ` da ${item.userValue[0]} a ${item.userValue[1]}`: item.userValue;
                            }
                        }
                        return null;
                    })}
                    {/* show fields that have checkbox disabled */}
                    {item.disabled && (
                        <>
                            <Typography.Text>{item.userValue}</Typography.Text>
                            <div style={{marginLeft: '1em'}} ><Typography.Text type="danger">Non è stato possibile trovare: {item.userValue}</Typography.Text></div>
                        </>
                    )} 
                    {/* show fields without a range of values */}
                    {!specialFields.some((field) => item.systemLabel === field.system) && !item.disabled &&
                        item.userValue.map((field, index) => {
                        if (index === item.userValue.length - 1) {
                            return field;
                        } else {
                            return field + ", ";
                        }
                    })}
                    </Typography.Text>
                    </Checkbox>
                </List.Item>}
            />
        </>
    );
}