import HighchartsReact from 'highcharts-react-official';
import CommonChart from "./CommonChart";
import {percentage} from "../../../utils/formatters";
import React from "react";
import {Options} from "highcharts";

const PieChart = ({options, chartRef}: {options: Options, chartRef: React.RefObject<HighchartsReact.RefObject>}) => {

    return <>
        <CommonChart
            chartRef={chartRef}
            options={{
                tooltip: {
                    headerFormat: '',
                    pointFormatter: function () {
                        const value = this.percentage ? percentage(this.percentage / 100) : percentage(0)
                        const name = this.name ? this.name : ''
                        const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                        return `${symbol} ${name}: <span style='font-weight: bolder'>${value}</span>`
                    },
                    useHTML: true
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            format: '{point.name} - {percentage:.2f}%'
                        },
                    },
                },
                ...options
            }}
        />
    </>;
};

export default PieChart