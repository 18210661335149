import {ALL, useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {useGetBrokerBranchComparisonQuery} from "../../../redux/api/brokersChartsApiSlice";
import {CalculationType, LabelValue} from "../../../types/charts";
import {skipToken} from "@reduxjs/toolkit/query";
import {selectUser} from "../../../redux/features/userSlice";

const allLabelValue = {label: "Tutti", value: ALL}
export default function BrokerChartsBranchSelect() {

    const {params, setParams} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const provinces = useSelector(selectOptions).provinces
    const areParamsOk = !!(params.budget_group && params.broker_uuid && provinces.length && typeof uwYear === 'number')
    const [currentPage, setCurrentPage] = useState(1)

    const {data, isFetching, isUninitialized} = useGetBrokerBranchComparisonQuery(areParamsOk ? {
        broker_uuid: params.broker_uuid,
        budget_group: params.budget_group,
        calculation_type: CalculationType.NEGOTIATIONS_COUNT,
        uw_year: uwYear,
        page: currentPage,
    } : skipToken)
    const loading = isFetching || isUninitialized
    const [options, setOptions] = useState<LabelValue[]>([allLabelValue])
    const isToFetch = !!(!loading && data?.count && data?.count > options.length - 1)

    useEffect(() => {
        if (isToFetch) {
            setOptions(prevState => [
                ...prevState,
                ...data.results.reduce((result: LabelValue[], el) => {
                    if (!prevState.some(opt => el.branch === opt.value)) {
                        const province = provinces.find(pr => pr.value === el.branch)
                        result.push({
                            label: province ? province.text : el.branch,
                            value: el.branch
                        })
                    }
                    return result
                }, [])
            ])
        }
    }, [data?.results, isToFetch, provinces]);

    const handleChange = (v: string) => {
        setParams(prevState => ({
            ...prevState,
            broker_branch: v
        }))
    }

    const onScroll = (event: any) => {
        if (isToFetch) {
            setCurrentPage(page => page + 1)
        }
    };


    return <>
        <label
            htmlFor={'broker-branch'}
            style={{
                marginRight: '6px',
                fontSize: '14px',
                fontWeight: 'normal'
            }}>
            Branch:
        </label>
        <Select
            disabled={!areParamsOk}
            loading={loading}
            getPopupContainer={triggerNode => triggerNode.parentElement}
            onPopupScroll={onScroll}
            virtual={false}
            style={{width: '7rem'}}
            value={params.broker_branch}
            onChange={handleChange}
            options={options}
            filterOption={(input, option) => {
                return option?.label ? (option.label as string).toLowerCase().includes(input.toLowerCase()) || (option.value as string).toLowerCase() === (input.toLowerCase()) : false
            }}
            showSearch
            placeholder={"Seleziona un branch broker"}
        />
    </>
}