import {Card, CardProps} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function BBBChartCardContainer(cardProps: CardProps) {

    const {xs, sm} = useBreakpoint()

    return <Card
        style={{
            height: '100%',
            minHeight: (xs || sm) ? undefined : '30rem'
        }}
        {...cardProps}
    />
}