import {apiSlice} from "redux/api/baseApiSlice";
import {AgreementsModel, BrokerModel, CreateAgreementParams, UpdateAgreementParams} from "types/broker";
import {GenericPaginatedApi} from "../../types";

export const brokersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPaginatedBrokers: builder.query<GenericPaginatedApi<BrokerModel>, {
            search?: string,
            page?: number,
            page_size?: number
        }>({
            query: (args) => {
                return {
                    url: '/negotiation/brokers/',
                    params: {
                        paginate: true,
                        ...args
                    }
                };
            },
            providesTags: (res, error, arg) =>
                res
                    ? [
                        ...res?.results.map(({uuid}) => ({type: 'Brokers' as const, id: uuid})),
                        {type: 'Brokers', id: 'LIST'},
                    ]
                    : [{type: 'Brokers', id: 'LIST'}],
        }),
        getSingleBroker: builder.query<BrokerModel, { uuid: string }>({
            query: (args) => {
                const {uuid} = args
                return {
                    url: `/negotiation/brokers/${uuid}/`
                }
            },
            providesTags: (res, error, arg) => [{type: 'Brokers' as const, id: arg.uuid}]
        }),
        createBroker: builder.mutation<BrokerModel, { name: string }>({
            query: (args) => {
                const {name} = args;
                return {
                    url: `/negotiation/brokers/`,
                    method: 'POST',
                    body: {name}
                };
            },
            invalidatesTags: [{type: 'Brokers', id: 'LIST'}],
        }),
        updateBroker: builder.mutation<BrokerModel, {
            name: string | null,
            relationship_quality: number | null,
            uuid: string
        }>({
            query: (args) => {
                const {name, uuid, relationship_quality} = args;
                return {
                    url: `/negotiation/brokers/${uuid}/`,
                    method: 'PATCH',
                    body: {name, relationship_quality}
                };
            },
            invalidatesTags: (res, error, arg) => [{type: 'Brokers', id: 'LIST'}, {type: 'Brokers', id: arg.uuid}],
        }),
        getAgreements: builder.query<GenericPaginatedApi<AgreementsModel>, { broker?: string, page_size?: number, page?: number }>({
            query: (args) => {
                return {
                    url: `/negotiation/agreements/`,
                    params: {
                        ...args,
                    }
                }
            },
            providesTags: (res, error, arg) =>
                res
                    ? [
                        ...res?.results.map(({uuid}) => ({type: 'Agreements' as const, id: uuid})),
                        {type: 'Agreements', id: 'LIST'},
                    ]
                    : [{type: 'Agreements', id: 'LIST'}],
        }),
        createAgreements: builder.mutation<AgreementsModel, CreateAgreementParams>({
            query: (body) => {
                return {
                    url: `/negotiation/agreements/`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: [{type: 'Agreements', id: 'LIST'}],
        }),
        updateAgreements: builder.mutation<AgreementsModel, UpdateAgreementParams>({
            query: ({uuid, ...args}) => {
                return {
                    url: `/negotiation/agreements/${uuid}/`,
                    method: 'PATCH',
                    body: {...args}
                }
            },
            invalidatesTags: (res, error, arg) => [{type: 'Agreements', id: 'LIST'}, {type: 'Agreements', id: arg.uuid}],
        }),
        deleteAgreement: builder.mutation<void, { uuid: string }>({
            query: (args) => {
                return {
                    url: `/negotiation/agreements/${args.uuid}/`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: [{type: 'Agreements', id: 'LIST'}],
        })
    })
})

export const {
    useGetPaginatedBrokersQuery,
    useLazyGetPaginatedBrokersQuery,
    useCreateBrokerMutation,
    useUpdateBrokerMutation,
    useDeleteAgreementMutation,
    useGetAgreementsQuery,
    useUpdateAgreementsMutation,
    useCreateAgreementsMutation,
    useLazyGetAgreementsQuery,
    useGetSingleBrokerQuery
} = brokersApiSlice