import 'antd/es/spin/style/css';
import React from "react";
import HighchartsReact from "highcharts-react-official";
import {useGetBrokerRenewRetentionChartQuery} from "../../../redux/api/chartsApiSlice";
import {ALL, useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import RenewRetentionCommon from "../../charts/ChartCards/RenewRetentionCommon";
import {skipToken} from "@reduxjs/toolkit/query";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokerRenewRetention(props: {
    chartRef: React.RefObject<HighchartsReact.RefObject>
}) {
    const {params} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const paramsAreOk = !!(params.budget_group && params.broker_uuid && typeof uwYear === 'number')

    const {data, isLoading, isFetching} = useGetBrokerRenewRetentionChartQuery(paramsAreOk ? {
        broker_uuid: params.broker_uuid,
        budget_group: params.budget_group,
        uw_year: uwYear,
        ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
    } : skipToken)

    return <RenewRetentionCommon
        chartRef={props.chartRef}
        data={data}
        loading={isLoading || isFetching}
        containerProps={{
            style: {
                height: '100%'
            }
        }}
    />
}