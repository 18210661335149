import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Button, Col, Input, Row, Select, Space, UploadFile} from "antd";
import {CheckOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {UploadDoc} from "./UploadDocumentModal";
import _ from "lodash";

export default function DocListItem(props: {
  file: { name: string | number | readonly string[] | undefined; };
  index: number;
  fileList: UploadFile<any>[];
  setDisabledButton: Dispatch<SetStateAction<boolean>>;
  disabledButton: boolean;
  selectOptions: { label: string, value: string }[] | undefined;
  dirType: string | undefined;
  remove: React.MouseEventHandler<HTMLSpanElement> | undefined;
  setDocList: Dispatch<SetStateAction<UploadDoc[]>>;
}) {

  const [isDocumentNameDisabled, setDisabledDocumentName] = useState(true)
  const [docName, setDocName] = useState(props.file.name)
  const [isError, setIsError] = useState(false)
  const inputRef = useRef<any>(null)

  useEffect(() => {
    inputRef.current.focus({cursor: 'all'})
  }, [isDocumentNameDisabled])

  useEffect(() => {
    if (!isDocumentNameDisabled)
      props.setDisabledButton(true)
    else
      props.setDisabledButton(false)
  }, [isDocumentNameDisabled, props])

  const handleChangedSelect = (value: string) => {
    props.setDocList((prevState) => {
      const updatedDocList = _.cloneDeep(prevState)
      updatedDocList[props.index].dirType = value
      return updatedDocList
    })
  }

  const renameDocItem = () => {
    if (docName) {
      props.setDocList((prevState) => {
        const updatedDocList = _.cloneDeep(prevState)
        updatedDocList[props.index].fileName = updatedDocList[props.index].name = docName as string
        return updatedDocList
      })
    }
  }

  return (
    <Row style={{marginTop: '0.75rem'}} gutter={10} justify={"space-between"}>
      <Col>
        <Input ref={inputRef} value={docName}
               status={isError ? "error" : ""}
               placeholder={"Inserisci il nome"}
               bordered={!isDocumentNameDisabled}
               disabled={isDocumentNameDisabled}
               onChange={e => {
                 if (isError)
                   setIsError(false)
                 setDocName(e.currentTarget.value)
               }}
               onPressEnter={(e) => {
                 if (e.currentTarget.value.trim() === "")
                   setIsError(true)
                 else {
                   setDisabledDocumentName(!isDocumentNameDisabled)
                   renameDocItem()
                 }
               }}>
        </Input>
      </Col>
      {props.selectOptions && (
        <Col>
          <Select
            options={props.selectOptions}
            virtual={false}
            style={{width: "100%"}}
            defaultValue={props.dirType}
            onChange={handleChangedSelect}/>
        </Col>
      )}
      <Col>
        <Space direction="horizontal" size={4}>
          {isDocumentNameDisabled &&
            <Button
              type='link'
              size='middle'
              onClick={() => setDisabledDocumentName(!isDocumentNameDisabled)}
              icon={<EditOutlined/>}
            />
          }
          {!isDocumentNameDisabled && docName !== "" &&
            <Button
              type='link'
              size='middle'
              onClick={() => {
                setDisabledDocumentName(!isDocumentNameDisabled)
                renameDocItem()
              }}
              icon={<CheckOutlined/>}
            />
          }
          <Button
            type={'text'}
            danger={true}
            onClick={props.remove}
            icon={<DeleteOutlined style={{color: "red"}}/>}
          />
        </Space>

      </Col>
      {isError && <p style={{color: "red"}}>Il nome del documento non può essere vuoto</p>}
    </Row>
  )
}