import React, {useCallback, useEffect, useState} from "react";
import {useLazyGetBankBranchQuery} from "../../redux/api/commercialDetailApiSlice";
import {Col, Row, Card, Breadcrumb, Input, Divider, Spin, List, Pagination, message} from "antd";
import {LeftOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import {BankBranchModel} from "../../types/commercialDetail";
import BankBranchItem from "../../components/settings/BankBranchItem";

const pageSize: number = 22;

export default function Branches() {
    const [fetchBankBranch, {data: bankBranchData, isLoading: isBankBranchLoading, isFetching: isBankBranchFetching}] = useLazyGetBankBranchQuery()

    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)

    const navigate = useNavigate()

    const fetchBankBranchData = useCallback((pageParam?: number, searchParam?: string) => {
        try {
            fetchBankBranch({page: pageParam, page_size: pageSize, search: searchParam})
        } catch (e: any) {
            message.error('Impossibile scaricare le filiali')
            console.error('fetchBankBranchData', e)
        }

    }, [fetchBankBranch])

    useEffect(() => {
        searchTerm ? fetchBankBranchData(1, searchTerm) : fetchBankBranchData()
        setCurrentPage(1)
    }, [fetchBankBranchData, searchTerm])

    return <Row justify={"space-evenly"}>
        <Col xs={24} sm={22} lg={23}>
            <div style={{paddingTop: '40px', paddingBottom: '70px', backgroundColor: 'transparent'}}>
                <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
                      title={<Breadcrumb>
                          <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                              style={{cursor: "pointer"}}><LeftOutlined/> Dati comuni</span></Breadcrumb.Item>
                          <Breadcrumb.Item>Filiali</Breadcrumb.Item>
                      </Breadcrumb>}
                >
                    <Col style={{width: '20rem'}}>
                        <Input.Search
                            placeholder={'Filtra per nome...'}
                            enterButton
                            defaultValue={searchTerm}
                            onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}/>
                    </Col>
                    <Divider/>
                    <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        {isBankBranchLoading ? <Spin/> : <>
                            <List
                                grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                                loading={!bankBranchData || isBankBranchLoading || isBankBranchFetching}
                                dataSource={bankBranchData?.results}
                                renderItem={(item: BankBranchModel) => <BankBranchItem item={item}/>}
                            />
                            <Row style={{marginTop: '2.5rem'}}
                                 justify={'end'}>
                                <Pagination
                                    simple
                                    total={bankBranchData?.count}
                                    current={currentPage}
                                    pageSize={pageSize}
                                    onChange={page => {
                                        setCurrentPage(page)
                                        searchTerm ? fetchBankBranchData(page, searchTerm) : fetchBankBranchData(page)
                                    }}
                                />
                            </Row>
                        </>
                        }
                    </div>
                </Card>
            </div>
        </Col>
    </Row>
}