import {createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState} from "react";
import {ALL} from "./BrokerChartsContext";

type Params = {
    budget_group: string,
}

type BankBranchesChartsParamsType = {
    params: Params,
    setParams: Dispatch<SetStateAction<Params>>
}

const defaultValues: Params = {
    budget_group: ALL,
}

const BankBranchesCharts = createContext<BankBranchesChartsParamsType>({
    params: defaultValues,
    setParams: () => undefined
})

export const useBankBranchesChartsParamsContext = () => useContext(BankBranchesCharts)

export default function BankBranchesChartsParamsContext(props: PropsWithChildren) {

    const [params, setParams] = useState<Params>(defaultValues)

    return <BankBranchesCharts.Provider
        value={{params, setParams}}
    >
        {props.children}
    </BankBranchesCharts.Provider>
}