export default async function handleDownloadFile(response: any) {
    if (response.status === 200)
        await response.blob()
            .then((payload: any) => {
                const url = URL.createObjectURL(payload)
                let filename = '';
                const disposition = response.headers.get('content-disposition')
                if (disposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const a = document.createElement('a');
                a.href = url;
                a.download = filename
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url)
            })
            .catch((error: any) => console.error('Blob Error', error))
    else
        console.error('Download csv error - status: ', response.status)
}