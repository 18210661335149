import React from "react";
import {Divider, Skeleton, Space, Typography} from "antd";
import {useGetBankBranchesIstatDataQuery} from "../../../redux/api/chartsApiSlice";
import {YearlyData} from "../../../types/charts";
import {numeric} from "../../../utils/formatters";

function DataItem({year, persons_employed, companies}: YearlyData) {
    return <div style={{display: 'flex', flexDirection: 'column'}}>
        <h2>Dati Istat ({year})</h2>
        <Space>
            <label>Numero imprese attive:</label>
            <span>{numeric(companies)}</span>
        </Space>
        <Space>
            <label>Numero addetti delle imprese attive:</label>
            <span>{numeric(persons_employed)}</span>
        </Space>
    </div>
}

export function BranchData({provinces, target}: { provinces?: string, target?: string }) {

    const {data, isFetching, isError} = useGetBankBranchesIstatDataQuery({provinces})

    return <Skeleton loading={isFetching}>
        <Divider>{target}</Divider>
        {!isError && <div
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {data?.yearly_data.map(el => <DataItem {...el} key={el.year}/>)}
            <Divider/>
            <Space>
                <label>Filiali presenti:</label>
                <span>{numeric(data?.branches || 0)}</span>
            </Space>
            <Space>
                <label>Sale di riferimento:</label>
                <span>{data?.reference_sale || "Non presente"}</span>
            </Space>
        </div>}
        {isError && <Typography.Paragraph italic>Dati non presenti</Typography.Paragraph>}
    </Skeleton>
}