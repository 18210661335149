import {Button, Col, Divider, Space} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import {generalColumns, getStandardFilter} from "../columnsUtils";
import {ColumnsType} from "antd/lib/table";
import {GeneralNegotiation, States, UserTypes, Views} from "types";
import {InsertRowLeftOutlined} from "@ant-design/icons";
import CustomTable from "../CustomTable";
import {useDispatch, useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {useLazyGetNegotiationsQuery} from "redux/api/negotiationsApiSlice";
import Tag from "antd/es/tag";
import {selectUser, setTablesPreferences} from "../../../../redux/features/userSlice";
import {useSetUserTablesPreferencesMutation} from "../../../../redux/api/userPreferencesApiSlice";
import ResetFiltersSortersButton from "../../../buttons/ResetFiltersSortersButton";
import {selectNegotiationUsers} from "../../../../redux/features/negotiationUsersSlice";
import ColumnSelectionGeneralTableFilter from "../../../modals/ColumnSelectionGeneralTableFilter";
import {LobModel} from "../../../../types/options";
import {getContrastYIQ} from "../../../../utils/formatters";
import {canSeeOtherTablesViews, canUseAdvancedSearchGeneralTable} from "../../../../utils/permission";
import AdvancedSearchButton from "./advancedSearch/AdvancedSearchButton";
import {useGetEmissionRatingOptionsQuery} from "../../../../redux/api/contractorsApiSlice";

const GeneralTable = () => {
    const [showColumnsSelection, setShowColumnsSelections] = useState<boolean>(false)
    const [defaultColumns, setDefaultColumns] = useState<ColumnsType<GeneralNegotiation>>(generalColumns)
    const [currentColumns, setCurrentColumns] = useState<ColumnsType<GeneralNegotiation>>([])
    const options = useSelector(selectOptions)

    const negotiationUsers = useSelector(selectNegotiationUsers)
    const [triggerGetNegotiations, {data: negotiations, isLoading, isFetching, error}] = useLazyGetNegotiationsQuery()
    const {data: emissionRatingData} = useGetEmissionRatingOptionsQuery()


    const user = useSelector(selectUser)
    const isOtherViewsAvailable = canSeeOtherTablesViews(user.usertypes?.map(el => el.code) || [])
    const dispatch = useDispatch()
    const [updatePreferences] = useSetUserTablesPreferencesMutation()

    useEffect(() => {
        setDefaultColumns(prevState => prevState.map(el => {
            switch (el.key) {
                case 'state':
                    return {
                        ...el,
                        ...getStandardFilter(options.states.filter(el => el.value !== States.ReferralApproved)),
                        render: (value: number) => {

                            const foundState = options.states.find(el => el.value === value)
                            if (foundState) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '7rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundState.color)
                                    }}
                                    color={foundState.color}
                                >
                                    {foundState.text}
                                </Tag>
                            }
                        }
                    }
                case 'lob':
                    return {
                        ...el,
                        render: (lob: LobModel) => {
                            const foundLob = options.lobs.find(el => el.uuid === lob.uuid)
                            if (foundLob) {
                                return <Tag
                                    style={{
                                        borderRadius: '100px',
                                        width: '7rem',
                                        height: '1.25rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 0.5rem',
                                        color: getContrastYIQ(foundLob.color)
                                    }}
                                    color={foundLob.color}
                                >
                                    {foundLob.name}
                                </Tag>
                            }
                        },
                        ...getStandardFilter(options.lobs.map(el => ({text: el.name, value: el.uuid})))
                    }
                case 'underwriter':
                    return {
                        ...el, ...getStandardFilter(options.underwriters.map(el => ({
                            text: el.user_full_name,
                            value: el.uuid
                        })))
                    }
                case 'portfolio_user':
                    return {
                        ...el, ...getStandardFilter(negotiationUsers.filter(el => el.usertypes.some(usertype => usertype.code === UserTypes.PO)).map(el => ({
                            text: el.user_full_name,
                            value: el.uuid
                        })))
                    }
                case 'installment_type':
                    return {
                        ...el, ...getStandardFilter(options.installmentTypes.map(el => ({
                            text: el.text,
                            value: el.value
                        })))
                    }
                case 'atecori':
                    return {
                        ...el, ...getStandardFilter(options.atecori.map(el => ({
                            text: el.full_description,
                            value: el.nace
                        })))
                    }
                case 'cancellation_terms_days':
                    return {...el, ...getStandardFilter(options.cancellationDays)}
                case 'ppw_days':
                    return {...el, ...getStandardFilter(options.ppw_days)}
                case 'isia_type':
                    return {...el, ...getStandardFilter(options.isiaTypes)}
                case 'reinsurance_type':
                    return {...el, ...getStandardFilter(options.reinsuranceTypes)}
                case 'isia_sale':
                    return {
                        ...el,
                        ...getStandardFilter(negotiationUsers.filter(el => el.usertypes.some(usertype => usertype.code === 'AA' || usertype.code === 'SA' || usertype.code === 'AM')).map(el => ({
                            text: el.user_full_name,
                            value: el.uuid
                        })))
                    }
                case 'broker_branch':
                    return {
                        ...el,
                        render: (value: string | null) => {
                            if (value) {
                                const province = options.provinces.find(p => p.value === value)?.text
                                return province ? `${value} (${province})` : value
                            }
                        }
                    }
                case 'emissions_rating':
                    return {...el,
                        ...getStandardFilter(emissionRatingData || []),
                        render: (value: string | null) => value ? <Tag color={value === "1" ? "green" : value === "2" ? "orange" : "red"}>{emissionRatingData?.find(el => el.value === value)?.text}</Tag> : null
                    }
                default:
                    return el
            }
        }))
    }, [options, negotiationUsers, emissionRatingData])

    const addRemoveColumnButton = useMemo(() => (title: 'uw_year' | 'lob' | 'policy_effective_date' | 'is_isia' | 'reinsurers_list') => {
        return <Button
            disabled={currentColumns.length < 2 && currentColumns.map(el => el.key).includes(title)}
            onClick={() => {
                let preferredCols: string[] = currentColumns.map(el => el.key as string)
                if (preferredCols.includes(title)) {
                    preferredCols = preferredCols.filter(el => el !== title)
                    const colToRemoveSorter = user.preferences.tables.general.sorters?.find(el => el.key === title)
                    const colToRemoveFilter = user.preferences.tables.general.filters?.find(el => el.key === title)
                    if (colToRemoveFilter || colToRemoveSorter) {
                        updatePreferences({
                            general: {
                                sorters: user.preferences.tables.general.sorters ? user.preferences.tables.general.sorters.filter(el => el.key !== title) : [],
                                filters: user.preferences.tables.general.filters ? user.preferences.tables.general.filters.filter(el => el.key !== title) : []
                            }
                        })
                    }
                } else {
                    preferredCols.push(title)
                }
                dispatch(setTablesPreferences({[Views[Views.general]]: {selected_columns: preferredCols}}))
                updatePreferences({[Views[Views.general]]: {selected_columns: preferredCols}})
            }}
            type={currentColumns.find(col => col.key === title) ? 'primary' : undefined}
        >
            {defaultColumns.find(c => c.key === title)?.title as string}
        </Button>
    }, [currentColumns, defaultColumns, dispatch, updatePreferences, user.preferences.tables.general.filters, user.preferences.tables.general.sorters])

    return <>
        <ColumnSelectionGeneralTableFilter
            currentColumns={currentColumns}
            setCurrentColumns={setCurrentColumns}
            setShowColumnsSelections={setShowColumnsSelections}
            showColumnsSelection={showColumnsSelection}
            defaultColumns={defaultColumns}
        />

        <Col xs={{order: 4, offset: 1}} sm={{order: 4, offset: 0}} lg={{order: 2}} flex={1}>
            {isOtherViewsAvailable && <Divider type={'vertical'}/>}
            <Space wrap={true}>
                {addRemoveColumnButton('uw_year')}
                {addRemoveColumnButton('lob')}
                {addRemoveColumnButton('policy_effective_date')}
                {addRemoveColumnButton('is_isia')}
                {addRemoveColumnButton('reinsurers_list')}
            </Space>
            <ResetFiltersSortersButton view={Views.general}/>
            <Button size={'large'} icon={<InsertRowLeftOutlined/>}
                    style={{borderColor: 'transparent', backgroundColor: 'transparent', boxShadow: "none"}}
                    onClick={() => setShowColumnsSelections(true)}/>
            {canUseAdvancedSearchGeneralTable(user.usertypes.map(el => el.code)) && <AdvancedSearchButton/>}
        </Col>
        <Col order={4} span={24}>
            <CustomTable view={Views.general} cols={currentColumns}
                         defaultCols={defaultColumns}
                         setCols={setCurrentColumns} data={negotiations} getData={triggerGetNegotiations}
                         isLoading={isLoading || isFetching} error={error}
            />
        </Col>
    </>
}

export default GeneralTable