import {apiSlice} from "redux/api/baseApiSlice";
import {
  BelatedEmittedModel,
  BelatedEmittedREVISITED,
  MountBelatedEmittedRequest,
  UnmountBelatedEmittedRequest
} from "types/negotiations";


export const belatedEmittedApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getNegotiationBelatedEmitted: builder.query<{
      count: number,
      next: number | null,
      previous: number | null,
      results: BelatedEmittedModel[],
      total_belated_emitted: number | null
    }, { negotiationId: string, page?: number, page_size?: number, ordering?: string }>({
      query: (args) => {
        const {negotiationId, page, page_size} = args;
        return {
          url: `/negotiation/negotiations/${negotiationId}/belated_emitted/`,
          //   params: { start, end },
          // params: args
        };
      },
    }),
    mountNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel, {
      id: string,
      negotiation_id: string,
      data: MountBelatedEmittedRequest
    }>({
      query: ({id, negotiation_id, data}) => {
        return {
          url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/mount/`,
          method: 'POST',
          body: data
        };
      },
    }),
    unmountNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel[], {
      id: string,
      negotiation_id: string,
      data: UnmountBelatedEmittedRequest
    }>({
      query: ({id, negotiation_id, data}) => {
        return {
          url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/unmount/`,
          method: 'POST',
          body: data
        };
      },
    }),
    unmountPartialNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel[], {
      id: string,
      negotiation_id: string,
      data: UnmountBelatedEmittedRequest
    }>({
      query: ({id, negotiation_id, data}) => {
        return {
          url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/unmount_partial/`,
          method: 'POST',
          body: data
        };
      },
    }),
    updateNegotiationBelatedEmitted: builder.mutation<BelatedEmittedModel, {
      id: string,
      negotiation_id: string,
      data: Partial<BelatedEmittedModel>
    }>({
      query: ({id, negotiation_id, data}) => {
        return {
          url: `/negotiation/negotiations/${negotiation_id}/belated_emitted/${id}/`,
          method: 'PATCH',
          body: {...data}
        };
      },
    }),
    alignBelatedEmitted: builder.query<{ aligned: boolean, ante: BelatedEmittedREVISITED[], post: BelatedEmittedREVISITED[], "message": "string" }, { negotiationId: string }>({
      query: (args) => {
        const {negotiationId} = args;
        return {
          url: `/negotiation/negotiations/${negotiationId}/belated_emitted_align/`
        };
      },
    }),
    alignBelatedEmittedWithData: builder.mutation<BelatedEmittedREVISITED[], { negotiationId: string, data: BelatedEmittedREVISITED[] }>({
      query: (args) => {
        const {negotiationId, data} = args;
        return {
          url: `/negotiation/negotiations/${negotiationId}/belated_emitted_align/`,
          method: 'PATCH',
          body: data
        };
      },
    })
  }),
})

export const {
  useLazyGetNegotiationBelatedEmittedQuery,
  useMountNegotiationBelatedEmittedMutation,
  useUnmountNegotiationBelatedEmittedMutation,
  useUnmountPartialNegotiationBelatedEmittedMutation,
  useUpdateNegotiationBelatedEmittedMutation,
  useLazyAlignBelatedEmittedQuery,
  useAlignBelatedEmittedWithDataMutation
} = belatedEmittedApiSlice
