import React, {useMemo, useRef, useState} from "react";
import {Row, Select, Switch} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {useGetBBBHitRatiosQuery} from "../../redux/api/chartsApiSlice";
import {BBBHitRatiosParams, CalculationType, RatioType} from "../../types/charts";
import {skipToken} from "@reduxjs/toolkit/query";
import {roundNumber} from "../../utils/formatters";
import BBBChartCardContainer from "./BBBChartCardContainer";

export const boundSelectOptions = [
    {
        label: "Bound su totale",
        value: 1
    },
    {
        label: "Bound su quotate",
        value: 2
    }
]

export default function AnnualHitRatioGraph(params: Pick<BBBHitRatiosParams, 'entity' | 'entity_uuid' | 'uw_year' | 'budget_group' | 'broker_branch'>) {

    const ref = useRef<HighchartsReact.RefObject>(null);

    const [calculationType, setCalculationType] = useState<CalculationType>(CalculationType.NEGOTIATIONS_COUNT)
    const [ratioType, setRatioType] = useState<RatioType>(RatioType.BOUND_OVER_TOTAL)
    const areParamsOk = params.entity && ('entity_uuid' in params ? params.entity_uuid : true)

    const {data, isFetching, isUninitialized} = useGetBBBHitRatiosQuery(areParamsOk ? {
        ...params,
        subdivision: 1,
        calculation_type: calculationType,
        ratio_type: ratioType,
    } : skipToken)

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"} align={"middle"}>
            <Select
                onSelect={(v: RatioType) => setRatioType(v)}
                value={ratioType}
                options={boundSelectOptions}
                style={{width: "200px"}}/>
            <Switch
                size={"small"}
                checked={calculationType === CalculationType.NEGOTIATIONS_COUNT}
                onChange={() => setCalculationType(prevState => prevState === CalculationType.NEGOTIATIONS_COUNT ? CalculationType.PREMIUMS : CalculationType.NEGOTIATIONS_COUNT)}
                checkedChildren={"Trattative"}
                unCheckedChildren={"Premi"}
                style={{width: "77px"}}
            />
        </Row>
    }, [calculationType, ratioType])


    const chartOptions = useMemo(() => {
        const series = []
        if (data) {
            for (const [key, options] of Object.entries(data)) {
                series.push(...options.map(el => ({name: key, data: [el.amount * 100], yAxis: 0})))
            }
        }
        return {
            chart: {
                type: 'column',
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: ""
            },
            yAxis: [{
                min: 0,
                max: 100,
                title: {
                    text: 'Percentuale (%)',
                }
            }],
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    borderRadius: 5,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}%',
                    }
                }
            },
            series,
            tooltip: {
                // @ts-ignore
                formatter: function () {
                    // @ts-ignore
                    const value = roundNumber(this.y || 0)
                    // @ts-ignore
                    const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                    // @ts-ignore
                    return `${symbol} <span>${this.series.name}</span>: <span style='font-weight: bolder'>${(value)}%</span>`
                }
            },
            exporting: {
                enabled: false
            },
            credits: undefined,
        }
    }, [data])

    return <BBBChartCardContainer
        loading={isFetching || isUninitialized}
        title={cardTitle}
    >
        <Row justify={"center"}>
            <h2>Hit Ratio Annuale</h2>
        </Row>
        <HighchartsReact
            highcharts={Highcharts}
            ref={ref}
            options={chartOptions}
        />
    </BBBChartCardContainer>
}