import {apiSlice} from "redux/api/baseApiSlice";
import {ChartsParams, UserSortersFiltersColumnsTablePreferences, Views} from "types";

export const userPreferencesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        setUserTablesPreferences: builder.mutation<void, Omit<UserSortersFiltersColumnsTablePreferences, 'page' | 'page_size'>>({
            query: (settings) => ({
                url: '/negotiation/user_negotiation_settings/',
                method: 'PUT',
                body: {preferences: {tables: {...settings}}}
            }),
        }),
        setTablesViewSelection: builder.mutation<void, Views>({
            query: (updatedView: Views) => ({
                url: '/negotiation/user_negotiation_settings/',
                method: 'PUT',
                body: {preferences: {current_view: updatedView}}
            }),
        }),
        setChartsParams: builder.mutation<void, Partial<ChartsParams>>({
            query: (params: ChartsParams) => ({
                url: '/negotiation/user_negotiation_settings/',
                method: 'PUT',
                body: {preferences: {charts_params: {...params}}}
            }),
        }),

    })
})

export const {useSetUserTablesPreferencesMutation, useSetTablesViewSelectionMutation, useSetChartsParamsMutation} = userPreferencesApiSlice
