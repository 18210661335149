import {FilterDropdownProps} from "antd/lib/table/interface";
import FilterContainer from "./FilterContainer";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {Select} from "antd";
import {LabeledValue} from "antd/lib/select";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";
import {useLazyGetSalesQuery} from "../../../redux/api/commercialDetailApiSlice";
import {useEffect, useState} from "react";

export default function SaleFilter(props: FilterDropdownProps) {
    const [getSales, {data: salesData}] = useLazyGetSalesQuery()
    const [options, setOptions] = useState<{label: string, value: string}[]>([])

    useEffect(()=> {
        getSales(null)
    }, [getSales])

    useEffect(()=> {
        if (salesData)
            setOptions(salesData.map(el => ({label: el.user_full_name, value: el.uuid})))
    }, [salesData])
    // @ts-ignore
    // @ts-ignore
    return <FilterContainer {...props}>
        <Select
            labelInValue
            options={options}
            mode={"multiple"}
            showSearch={true}
            placeholder={"Seleziona un sale"}
            filterOption={(input, option) => {
                return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
            }}
            style={{width: "20rem"}}
            virtual={false}
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.reverse().map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            // @ts-ignore
            value={props.selectedKeys}
        />
    </FilterContainer>
}