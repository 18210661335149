import {FilterDropdownProps} from "antd/lib/table/interface";
import FilterContainer from "./FilterContainer";
import {LabeledValue} from "antd/lib/select";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";
import React from "react";
import BankBranchMultiSelect from "../../select/MultiSelect/BankBranchMultiSelect";

export default function BankBranchFilter(props: FilterDropdownProps) {

    return <FilterContainer {...props}>
        <BankBranchMultiSelect
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.reverse().map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            value={props.selectedKeys}
        />
    </FilterContainer>
}