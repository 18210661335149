import PieChartContainer from "./PieChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import SelectMonth from "../../select/SelectMonth";
import {Button, Col, message, Row} from "antd";
import BrokerDataTable from "../../tables/chartTables/BrokerDataTable";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import PieChart from "../HighCharts/PieChart";
import {capitalizeFirstLetter, euro, numeric} from "../../../utils/formatters";
import dayjs from "dayjs";
import {
    useLazyGetBrokerDataDownloadQuery,
    useLazyGetBrokerDataTotalsChartQuery
} from "../../../redux/api/chartsApiSlice";
import {PointOptionsObject} from "highcharts";
import SelectPremiumsOrNegotiationsCount from "../../select/SelectPremiumsOrNegotiationsCount";
import {selectUser} from "../../../redux/features/userSlice";
import SelectNewBusinessRenewalAll from "../../select/SelectNewBusinessRenewalAll";


function Description() {
    return <>
        <dd>Il grafico, in base alla selezione, mostra il numero delle trattative in bound o l'ammontare dei premi in
            bound che arrivano da
            broker. I dati rappresentati possono fare riferimento a:
        </dd>
        <dl>
            <dt>New business</dt>
            <dd> tutte le trattative new business per l'UW year selezionato</dd>
            <dt>Rinnovati con successo</dt>
            <dd> tutte le trattative rinnovate per l'UW year selezionato</dd>

            <dt>Polizze in essere</dt>
            <dd>tutte le polizze attive per l'UW year selezionato composte da: le trattative in Bound per l'UW year e le
                trattive in Bound per l'UW year precedente non ancora rinnovate
            </dd>
        </dl>
        <dd>Premendo il tasto Mostra dettaglio broker, sarà visibile la tabella di dettaglio dei broker.</dd>
        <dd>Cliccando sul tasto + di ogni riga, sarà visibile la suddivisione sulle LOB visibili dei dati del broker.
        </dd>
    </>
}

function ExtraComponent({
                            isModalVisible,
                            setModalVisibility,
                            isDownloadButtonVisible,
                            disableDownloadButton,
                            loadingDownloadButton,
                            download
                        }: { isModalVisible: boolean, setModalVisibility: React.Dispatch<React.SetStateAction<boolean>>, isDownloadButtonVisible: boolean, disableDownloadButton: boolean, loadingDownloadButton: boolean, download: () => void }) {
    return <Row justify={'space-between'} gutter={[16, 16]}>
        <Col>
            <Button key={'broker-details-btn'}
                    type={'primary'}
                    onClick={() => {
                        setModalVisibility(prevState => !prevState)
                    }}>
                {!isModalVisible ? 'Mostra dettaglio broker' : 'Nascondi dettaglio broker'}
            </Button>
        </Col>
        {isDownloadButtonVisible && <Col>
          <Button
              key={'broker-data-download-btn'}
              onClick={download}
              disabled={disableDownloadButton}
              loading={loadingDownloadButton}
          >
            Download dati
          </Button>
        </Col>}
    </Row>
}

export default function BrokerDataTotalsChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string, lob: { label: string, value: string } }) {
    const [isVisibleBrokerDetails, setIsVisibleBrokerDetails] = useState<boolean>(false)
    const title = 'Dati broker totali'
    const [date, setDate] = useState(dayjs(new Date()))
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetBrokerDataTotalsChartQuery()
    const [download, {
        isLoading: isLoadingDownload,
        isFetching: isFetchingDownload,
        isError: isErrorDownload
    }] = useLazyGetBrokerDataDownloadQuery()
    const [premiumsOrNegotiationsCount, setPremiumsOrNegotiationsCount] = useState<1 | 2>(1)
    const options = useSelector(selectOptions)
    const [series, setSeries] = useState<PointOptionsObject[]>([])
    const [total, setTotal] = useState<string>('')
    const [newBusinessRenewallAll, setNewBusinessRenewallAll] = useState<1 | 2 | 3 | 4>(1)

    useEffect(() => {
        if (data) {
            setTotal(premiumsOrNegotiationsCount === 1 ? numeric(data.total) : euro(data.total))
        }
    }, [data, premiumsOrNegotiationsCount])


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                year: date.year(),
                month: date.month(),
                budget_group: budgetGroup.value,
                isia: isia.value,
                calculation_type: premiumsOrNegotiationsCount,
                negotiations_type: newBusinessRenewallAll
            })
        }
    }, [budgetGroup, date, getData, isia, newBusinessRenewallAll, premiumsOrNegotiationsCount, uwYear])

    useEffect(() => {
        if (data)
            setSeries(data ? data.states.map(el => {
                const state = options.states.find(state => state.value === el.state)
                return {...el, stateName: state?.text, stateColor: state?.color}
            }).map(el => ({
                name: el.stateName,
                y: el.amount,
                color: el.stateColor,
            })) : [])
    }, [data, options])

    const pieChart = <PieChart
        chartRef={props.chartRef}
        options={{
            chart: {
                borderRadius: 12
            },
            exporting: {
                chartOptions: {
                    title: {
                        text: title
                    },
                    subtitle: {
                        text: `Parametri: ${capitalizeFirstLetter(date.format('MMMM YYYY'))} - UW Year:${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                    }
                }
            },
            legend: {
                width: '50%',
                verticalAlign: 'middle',
                align: 'right',
                labelFormat: '{name} - {percentage:.2f}%'
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 768
                    },
                    chartOptions: {
                        legend: {
                            width: '100%',
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        }
                    }
                }]
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        format: '{point.name} - {percentage:.2f}%'
                    },
                    showInLegend: true
                },
            },
            series: [
                {
                    type: 'pie',
                    data: series
                },
            ]
        }}
    />

    return <React.Fragment>
        <PieChartContainer
            description={<Description/>}
            loading={isLoading || isFetching}
            className={props.className}
            title={title}
            buttons={[
                <label htmlFor={'brokerDataTotalsMonthSelect'} key={'brokerDataTotalsMonthSelect'}
                       style={{fontWeight: '400'}}>Trattative al:</label>,
                <SelectMonth month={date} setMonth={setDate} key={'month-select'}
                             selectProps={{name: "brokerDataTotalsMonthSelect", allowClear: false}}/>,
                <SelectPremiumsOrNegotiationsCount key={'premium-negotiation-select'} type={premiumsOrNegotiationsCount}
                                                   setType={setPremiumsOrNegotiationsCount}/>,
                <SelectNewBusinessRenewalAll key={'new-business-renewal-select'} setValue={setNewBusinessRenewallAll}
                                             value={newBusinessRenewallAll}/>,
                <DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}
            pieChart={pieChart}
            valueDescription={premiumsOrNegotiationsCount === 1 ? 'Numero delle trattative in bound' : 'Totale complessivo dei premi in bound'}
            displayValue={total}
            colSpan={4}
            extraComponents={<
                ExtraComponent
                isModalVisible={isVisibleBrokerDetails}
                setModalVisibility={setIsVisibleBrokerDetails}
                download={() => {
                    if (budgetGroup && typeof uwYear === 'number' && isia) {
                        download({
                            uw_year: uwYear,
                            year: date.year(),
                            month: date.month(),
                            budget_group: budgetGroup.value,
                            isia: isia.value,
                            calculation_type: premiumsOrNegotiationsCount,
                            negotiations_type: newBusinessRenewallAll
                        })
                            .then((payload) => {
                            message.success('Download completato correttamente')
                        })
                            .catch((error) => {
                                message.error('Errore nel download')
                            })
                    }
                }}
                isDownloadButtonVisible={newBusinessRenewallAll === 4}
                loadingDownloadButton={isFetchingDownload || isLoadingDownload}
                disableDownloadButton={isLoadingDownload || isFetchingDownload || newBusinessRenewallAll !== 4}
            />}/>
        {isVisibleBrokerDetails && <BrokerDataTable className={'broker-data--table'} date={date}
                                                    isDataLoading={isLoading || isFetching}
                                                    calculationType={premiumsOrNegotiationsCount}
                                                    negotiationsType={newBusinessRenewallAll}/>}

    </React.Fragment>
}