import React from "react"
import {Button, Typography} from "antd"
import {InfoCircleOutlined} from '@ant-design/icons';

interface propsHint {
  setShowHint: (value: boolean) => void;
  showHint: boolean
}

//change this array to modify suggestions
const hint = [
  'Specificare la colonna da filtrare seguito dai valori',
  'Per i campi con un range si consiglia di specificare "da [valore] a [valore]"'
];

export default function Hint({setShowHint, showHint}: propsHint) {

  return (
      <div style={{marginBottom: "5px"}}>
        Spiega il filtraggio che vuoi ottenere dalla tabella
        <Button type="text" onClick={() => setShowHint(!showHint)} style={{height: 'auto', textAlign: 'left'}}>
          <Typography.Text type='secondary'>
            <InfoCircleOutlined/> Per avere una ricerca più accurata{!showHint ? '...' : ': '}<br/>
            {showHint && hint.map((row, index) => (
                <p key={index} style={{whiteSpace: 'normal'}}>{'\u2003\u2003- ' + row}</p>
            ))}
          </Typography.Text>
        </Button>
      </div>
  )
}