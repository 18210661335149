import React, {useCallback, useEffect, useState} from "react";
import {AgreementsModel, UpdateAgreementParams} from "../../types/broker";
import {Button, Col, Input, InputNumber, List, Radio, Row, Select, Space, Tag} from "antd";
import {useUpdateAgreementsMutation} from "../../redux/api/brokersApiSlice";
import {LobModel} from "../../types/options";
import {EditOutlined} from "@ant-design/icons";
import {AntCurrencyFormatInput} from "../inputNumber";


export default function AgreementItem({
                                          item,
                                          lobs,
                                          deleteAgreement,
                                          currentPage
                                      }: {
    item: AgreementsModel,
    lobs: LobModel[],
    deleteAgreement: () => void,
    currentPage: number
}) {

    const {lob, ...otherItemKeys} = item
    const initialValues = {
        ...otherItemKeys,
        ...!!lob ? {lob: lob.value} : {}
    }

    const [edit, setEdit] = useState<boolean>(false)
    const [currentAgreement, setCurrentAgreement] = useState<UpdateAgreementParams>(initialValues)
    const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)

    const [updateAgreementRequest] = useUpdateAgreementsMutation()

    const updateAgreement = useCallback(() => {
        updateAgreementRequest(currentAgreement)
        setEdit(false)
    }, [currentAgreement, updateAgreementRequest])

    useEffect(() => {
        setEdit(false)
    }, [currentPage])

    const renderEditAgreement = () => {
        return <div>
            <Row justify={"space-between"} style={{marginBottom: "1rem"}} gutter={[8, 16]}>
                <Col span={10}>
                    Nome
                    <Input
                        value={currentAgreement.name || ""}
                        style={{width: "100%"}}
                        onChange={(event) => setCurrentAgreement({...currentAgreement, name: event.target.value})}
                    />
                </Col>
                <Col span={10}>
                    LOB
                    <Select
                        value={currentAgreement.lob}
                        virtual={false}
                        showArrow
                        style={{width: "100%"}}
                        options={lobs.map(el => ({label: el.name, value: el.uuid}))}
                        onChange={value => setCurrentAgreement({...currentAgreement, lob: value})}
                    />
                </Col>
                <Col span={10}>
                    Commissioni
                    <AntCurrencyFormatInput
                        suffix={' %'}
                        onChange={v => setCurrentAgreement({...currentAgreement, commissions: typeof v === 'number' ? v : null})}
                        value={typeof currentAgreement.commissions === 'number' ? currentAgreement.commissions : undefined}
                        max={100}
                        min={0}
                        decimalScale={0}
                        placeholder={'0 %'}
                    />
                </Col>
                <Col span={10}>
                    Wording dedicato <br/>
                    <Radio.Group
                        value={currentAgreement.dedicated_wording}
                        onChange={(event) => setCurrentAgreement({
                            ...currentAgreement,
                            dedicated_wording: event.target.value
                        })}
                    >
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Row justify={"space-between"} style={{marginTop: "1rem"}}>
                {!showConfirmDelete && <Button type={"primary"} danger onClick={() => setShowConfirmDelete(true)}>
                    Elimina convenzione
                </Button>}
                {showConfirmDelete && <div>
                    <Button type={"link"} size={"middle"} onClick={() => setShowConfirmDelete(false)}>
                        Annulla
                    </Button>
                    <Button danger type={"link"} size={"middle"} onClick={() => {
                        deleteAgreement()
                        setEdit(false)
                        setShowConfirmDelete(false)
                    }}>
                        Conferma
                    </Button>
                </div>}
                <Space>
                    <Button onClick={() => {
                        setCurrentAgreement(initialValues)
                        setEdit(false)
                    }}>Annulla</Button>
                    <Button type={"primary"} onClick={updateAgreement}>
                        Salva
                    </Button>
                </Space>
            </Row>
        </div>
    }

    return <div>
        <List.Item style={{padding: "10px 0", borderBottom: "1px solid gainsboro"}}>
            <List.Item.Meta
                title={<div>
                    {!edit && <div>
                        <Row style={{marginBottom: "1rem"}} justify={"space-between"}>
                            <Space>
                                <Tag color={item.lob?.color} style={{borderRadius: "100px"}}>
                                    {item.lob?.label}
                                </Tag>
                            </Space>
                            <Button type={"link"} size={"small"} icon={<EditOutlined/>} onClick={() => setEdit(true)}/>
                        </Row>
                        <Row justify={"space-between"} gutter={[8, 8]}>
                            <Col xs={7}>
                                Nome: {" "}{item.name}
                            </Col>
                            <Col xs={7}>
                                Commissioni: {" "}{item.commissions}%
                            </Col>
                            <Col xs={7}>
                                Wording dedicato: {" "}{item.dedicated_wording ? "SI" : "NO"}
                            </Col>
                        </Row>
                    </div>}
                </div>}
                description={<div style={{color: "black"}}>
                    {edit && renderEditAgreement()}
                </div>}
            />
        </List.Item>
    </div>
}