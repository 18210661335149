import SubmissionsTrend from "../SubmissionsTrend";
import React from "react";
import {ALL, useBrokerChartsParamsContext} from "../../../context/BrokerChartsContext";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetBBBSubmissionTrendQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokerSubmissionsTrend() {

    const {params} = useBrokerChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year

    const areParamsOk = !!(params.broker_uuid && typeof uwYear === 'number' && params.budget_group)
    const {data, isFetching, isUninitialized} = useGetBBBSubmissionTrendQuery(areParamsOk ? {
        entity: 2,
        entity_uuid: params.broker_uuid,
        budget_group: params.budget_group,
        uw_year: uwYear,
        ...params.broker_branch !== ALL && {broker_branch: params.broker_branch},
    } : skipToken)

    return <SubmissionsTrend data={data} loading={isUninitialized || isFetching}/>
}