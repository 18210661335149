import React, {useCallback, useEffect, useState} from "react";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Divider,
    message, Modal,
    Pagination,
    Row,
    Space,
    Spin,
    Table,
    Typography
} from "antd";
import DatePicker from "components/DatePicker";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {
    useCreateBelatedReportsMutation,
    useLazyGetBelatedReportsDownloadQuery, useLazyGetBelatedReportsOptionsQuery,
    useLazyGetBelatedReportsQuery
} from "../../../redux/api/BelatedHistoryApiSlice";
import dayjs from "dayjs";
import {BelatedReports, BelatedReportsOptions, BelatedReportsResponse} from "../../../types/negotiations";
import {ColumnsType} from "antd/es/table";
import {getColumnDateProps, getStandardFilter} from "../../tables/negotiationsTables/columnsUtils";
import Title from "antd/lib/typography/Title";
import InfoModal from "../../modals/InfoModal";

interface TypologyFormProps {
    options: BelatedReportsOptionsToDisplay[],
    uuid: string,
    date: dayjs.Dayjs,
    expanded: boolean
}

interface BelatedReportsOptionsToDisplay {
    value: string,
    label: string
}

const {Text} = Typography;

const PAGE_SIZE = 7

export function PrintTypologyForm(props: TypologyFormProps) {

    const [value, setValue] = useState<CheckboxValueType[]>();
    const [downloadBelated, {isLoading, isFetching, isError, isSuccess}] = useLazyGetBelatedReportsDownloadQuery()
    const [errorsOccourred, setErrorsOccourred] = useState<string[]>([])
    const [isSuccessMessageVisible, setSuccessMessageVisibility] = useState<boolean>(false)
    const [isMessageVisibile, setIsMessageVisible] = useState<boolean>(false)

    useEffect(() => {
        if (!props.expanded && isSuccessMessageVisible) {
            setSuccessMessageVisibility(false)
        }
    }, [isSuccessMessageVisible, props.expanded])

    function onButtonClick() {
        let errors: string[] = []
        setErrorsOccourred([])
        if (value) {
            setSuccessMessageVisibility(true)
            value.forEach(async (value) => {
                const csv = await downloadBelated({
                    uuid: props.uuid,
                    type: value.toString(),
                })
                if (!isSuccess || isError) {
                    const error = props.options.find(itm => itm.value === value)
                    if (error) {
                        errors = errors.concat([error.label])
                    }
                    setErrorsOccourred(errors)
                }
            })

        }
        setValue([])
        setIsMessageVisible(false)
    }

    const onChange = (checkedValues: CheckboxValueType[]) => {
        if (!isMessageVisibile)
            setIsMessageVisible(true)
        setValue(checkedValues)
        setSuccessMessageVisibility(false)
    };


    function displayMessage() {
        if (value && isMessageVisibile) {
            if (value.length === 0) return <Text type={'secondary'}>Seleziona almeno una tipologia di report da
                scaricare</Text>
            else if (value.length === 1) return <Text type={'secondary'}> Verrà scaricato 1 file</Text>
            if (value.length > 1) return <Text type={'secondary'}>Verranno scaricati {value.length} file</Text>
        }
    }

    function displaySuccesMessage() {
        if (isSuccessMessageVisible) {
            if (isSuccess) {
                return <Alert
                    style={{margin: '0.5rem auto'}}
                    message={value?.length === 1 ? 'Report scaricato con successo' : 'Report scaricati con successo'}
                    type="success"
                />
            }
            if (isError) {
                return <Alert
                    style={{margin: '0.5rem auto'}}
                    message="Qualcosa è andato storto:"
                    description={errorsOccourred.length === 1 ? "Impossibile scaricare il report: " + errorsOccourred.toString() : "Impossibile scaricare i report: " + errorsOccourred.toString()}
                    type="error"
                />
            }
        }

    }

    const checkbox = <Checkbox.Group options={props.options} value={value}
                                     onChange={onChange} className={'historyModalCheckbox'}/>

    return (<>
            <Row style={{margin: '0.5rem'}}>
                <Text strong/*level={5}*/>Lista dei report disponibili:</Text>
            </Row>
            <Row>
                {checkbox}
            </Row>
            <Row style={{marginTop: '0.5rem'}}>
                {displayMessage()}
            </Row>
            <Row>
                {displaySuccesMessage()}
            </Row>
            <Row>
                <Col style={{margin: 'auto'}}>
                    <Button type='primary' onClick={onButtonClick} disabled={value ? value.length < 1 : true}>
                        {isLoading || isFetching ? <Spin/> : 'Scarica'}
                    </Button>
                </Col>
            </Row>
        </>

    );
}

const BelatedHistoryDescription = () => <p>La tabella mostra tutti i tracciati tardivi creati alla data odierna.<br/>
    È possibile creare un nuovo tracciato tardivo premendo sul pulsante <span style={{fontWeight: 'bold'}}>Crea tracciato tardivo.</span>
    <br/> Premendo sul pulsante <span style={{fontWeight: 'bold'}}>+ </span>
    alla destra di ogni riga è possibile scegliere i report da scaricare tra quelli disponibili.
</p>

const BelatedHistoryModalDescription = () =>
    <>
        <p>Per creare un nuovo tracciato tardivo è necessario selezionare la
            data di riferimento.<br/> In base alla data selezionata si genera un tipo diverso di report:
        </p>
        <ul style={{marginTop: '0.5rem', marginLeft: '12px'}}>
            <li><span style={{fontWeight: 'bold'}}>Standard:</span> se la data selezionata coincide con quella corrente
                e con il primo giorno del mese
            </li>
            <li><span style={{fontWeight: 'bold'}}>Storico:</span> se la data selezionata è antecedente a quella
                corrente
            </li>
            <li><span style={{fontWeight: 'bold'}}>Proiezione:</span> se la data selezionata è conseguente a quella
                corrente
            </li>
        </ul>
    </>


export default function BelatedHistoryCard({className}: { className: string }) {
    const [getBelatedReports, {
        data: belatedData,
        isLoading: isBelatedLoading,
        isFetching: isBelatedFetching
    }] = useLazyGetBelatedReportsQuery()
    const [createReports, {
        isLoading: isCreationLoading,
        isError: hasCreationFailed
    }] = useCreateBelatedReportsMutation()
    const [getBelatedReportsOptions, {isLoading: isBelatedLoadingOptions}] = useLazyGetBelatedReportsOptionsQuery()

    const [belatedReports, setBelatedReports] = useState<BelatedReports[]>([])
    const [belatedReportsOptions, setBelatedReportsOptions] = useState<BelatedReportsOptions[]>([])
    const [totalCount, setTotalCount] = useState(0)
    const [pageIndex, setPageIndex] = useState(1)
    const [historyDate, setHistoryDate] = useState<string>("")
    const [reportType, setReportType] = useState<string>("")
    const [creationDate, setCreationDate] = useState<[string?, string?]>([])
    const [referenceDate, setReferenceDate] = useState<[string?, string?]>([])
    const [sortBy, setSortBy] = useState<string>('')
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const options = belatedReportsOptions.map((itm) => ({label: itm.text, value: itm.value}))

    useEffect(() => {
        async function fetchBelatedReportsOptions() {
            try {
                const res: BelatedReportsOptions[] = await getBelatedReportsOptions().unwrap()
                setBelatedReportsOptions(res)
            } catch (e: any) {
                message.error('Impossibile scaricare le opzioni di stampa')
            }
        }

        fetchBelatedReportsOptions()
    }, [getBelatedReportsOptions])

    useEffect(() => {
        async function fetchBelatedReports() {
            try {
                const res = await getBelatedReports(
                    {
                        page: pageIndex,
                        page_size: PAGE_SIZE,
                        base_report_type: reportType,
                        reference_date_from: referenceDate[0],
                        reference_date_to: referenceDate[1],
                        date_creation_from: creationDate[0],
                        date_creation_to: creationDate[1],
                        ordering: sortBy
                    }
                ).unwrap()
                setBelatedReports(res.results)
                setTotalCount(res.count)
            } catch (e: any) {
                message.error('Impossibile scaricare i report')
            }
        }

        fetchBelatedReports()
    }, [creationDate, getBelatedReports, pageIndex, referenceDate, reportType, sortBy])

    const columns: ColumnsType<BelatedReports> = [
        {
            title: 'Data di riferimento',
            dataIndex: 'reference_date',
            key: 'reference',
            ...getColumnDateProps('reference_date'),
            sorter: true,
            render: text => dayjs(text).format('DD-MM-YYYY')
        },
        {
            title: 'Tipo report',
            dataIndex: 'base_report_type',
            key: 'type',
            sorter: true,
            ...getStandardFilter([
                {text: 'Standard', value: 0},
                {text: 'Proiezione', value: 1},
                {text: 'Storico', value: 2}
            ]),
            render: (text, record) => record?.base_report_type === "0" ? "Standard" : record?.base_report_type === "1" ? "Proiezione" : "Storico"

        },
        {
            title: 'Data creazione',
            dataIndex: 'date_creation',
            key: 'creation',
            ...getColumnDateProps('date_creation'),
            sorter: true,
            render: (text) => dayjs(text).format('DD-MM-YYYY')
            ,
        },
        Table.EXPAND_COLUMN
    ];

    const createBelatedReports = useCallback(async () => {
        try {
            await createReports(historyDate === "" ? {} : {reference_date: historyDate}).unwrap()
            const res: BelatedReportsResponse = await getBelatedReports({
                page: pageIndex,
                page_size: PAGE_SIZE
            }).unwrap()
            setBelatedReports(res.results)
        } catch (e: any) {
            message.error('Impossibile caricare i report')
        }
    }, [createReports, getBelatedReports, historyDate, pageIndex])

    return <div className={className}>
        <Row style={{marginTop: '1rem', marginBottom: '1rem'}} justify={"space-between"}
             align={'middle'}>
            <Col xs={{offset: 1, flex: 1}} sm={{offset: 0}}>
                <div style={{display: "flex", flexWrap: 'nowrap'}}><Title level={3}>Tracciati
                    tardivi</Title><InfoModal title={"Tracciati tardivi"} description={<BelatedHistoryDescription/>}/>
                </div>
            </Col>
            <Col xs={{offset: 1, flex: 1}} sm={{offset: 0, flex: 0}}>

                <Button type='primary' onClick={() => setIsModalOpen(true)}>
                    Crea tracciato tardivo
                </Button>
            </Col>
        </Row>
        <Table
            loading={isBelatedLoading || isBelatedFetching}
            columns={columns}
            rowKey={'uuid'}
            locale={{
                filterConfirm: 'Applica filtro',
                filterReset: 'Reset filtro',
            }}
            scroll={{x: true}}
            onChange={(pagination, filters, sorter: any) => {
                if (filters.type) {
                    let repType: string = ""
                    filters.type?.forEach((el, index) => index === 0 ? repType = el as string : repType = repType + "," + el)
                    setReportType(repType)
                } else
                    setReportType("")
                filters.creation ?
                    setCreationDate([filters.creation[0] as string, filters.creation[1] + " 23:59:59"]) : setCreationDate(['', ''])
                filters.reference ?
                    setReferenceDate([filters.reference[0] as string, filters.reference[1] + " 23:59:59"]) : setReferenceDate(['', ''])
                if (sorter.order) {
                    if (sorter.order === 'ascend')
                        setSortBy(sorter.field)
                    else if (sorter.order === 'descend')
                        setSortBy("-" + sorter.field)
                    else
                        setSortBy("")
                }
            }}
            expandable={{
                expandedRowRender: (record, index, indent, expanded) => <PrintTypologyForm
                    expanded={expanded}
                    options={options}
                    uuid={record.uuid} date={record.reference_date}/>,
            }}
            dataSource={belatedReports}
            pagination={false}
        />
        <Row justify={"end"} style={{marginTop: '1rem', marginBottom: '1rem'}}>
            <Pagination current={pageIndex}
                        total={totalCount}
                        showSizeChanger={false}
                        pageSizeOptions={[PAGE_SIZE]}
                        onChange={(page) => {
                            setPageIndex(page)
                        }}
            />
        </Row>
        <Modal open={isModalOpen} destroyOnClose={true} onCancel={() => setIsModalOpen(false)}
               title={"Creazione nuovo tracciato tardivo"}
               footer={false}>
            <BelatedHistoryModalDescription/>
            <Divider/>
            <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                <Space wrap={true}>
                    <p>Data di riferimento: </p>
                    <DatePicker
                        defaultValue={dayjs()}
                        format={"DD-MM-YYYY"}
                        onChange={(value: any) => setHistoryDate(dayjs(value).format("YYYY-MM-DD") + "T00:00:00Z")}/>
                </Space>
                <Button type='primary' style={{width: '11rem'}} onClick={() => {
                    createBelatedReports();
                    setIsModalOpen(false)
                }}>
                    {isCreationLoading ? <Spin/> : 'Genera tracciato tardivo'}
                </Button>
            </Row>

        </Modal>
    </div>
}