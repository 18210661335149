import {Cascader, Col, Form, Input, message, Radio, Row, Select, Space, Tooltip, Typography} from "antd";
import {useGetNegotiationDetailQuery, useUpdateNegotiationMutation} from "../../redux/api/negotiationsApiSlice";
import {useParams} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {useForm} from "antd/lib/form/Form";
import React, {useCallback, useMemo, useState} from "react";
import DatePicker from "../DatePicker";
import {
    CalculatedField,
    FieldsCalculated,
    NegotiationFormModel,
    NegotiationModel,
    UpdateNegotiationRequest
} from "../../types/negotiations";
import {BrokerSelect} from "../negotiationForm/selects";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import {AntCurrencyFormatInput} from "../inputNumber";
import _, {cloneDeep} from "lodash";
import WarningTitleButton from "../buttons/WarningTitleButton";
import {checkStateCorrectForWarningPolicyFields, percentage} from "../../utils/formatters";
import {EffectiveDateInfo, ProRataError} from "../negotiationForm/form/submission";
import {WarningOutlined} from "@ant-design/icons";
import {transformNegotiationFormToUpdateRequest, transformNegotiationToFormFields} from "../../utils/form/dataParser";
import {
    calculateContractorSideEffects,
    calculateNegotiationFormSideEffect,
    calculatePoliciesAndIssuesSideEffects,
    calculatePortfolioFormSideEffect,
    calculateReinsuranceFormSideEffects,
    calculateSubmissionFormSideEffects
} from "../../utils/form/handleFormSideEffects";
import debounce from "lodash.debounce";
import {useSurveyPage} from "../../context/SurveyPageContext";
import {isDebounceUpdate} from "../../utils/form/debouncedFields";
import {HOURS} from "../../utils/form/timeUtils";

export default function CoverageConfirmationGeneralData({disabled}: {disabled?: boolean}) {

    const {id} = useParams()
    const {pageType} = useSurveyPage();
    const {
        installmentTypes,
        cancellationDays,
        policy_types,
        reinsuranceTypes,
        isiaTypes
    } = useSelector(selectOptions)
    const options = useSelector(selectOptions);

    const {
        data: negotiation,
        isSuccess: isSuccessFetchingNegotiation,
    } = useGetNegotiationDetailQuery(id ? id : skipToken)
    const [updateNegotiation] = useUpdateNegotiationMutation()

    const [form] = useForm()
    const {setFieldsValue, getFieldValue, getFieldsValue} = form
    const watchIsAutoRenewal = Form.useWatch('is_auto_renewal', form)
    const watchIsia = Form.useWatch('is_isia', form);
    const watchProrata = Form.useWatch('has_prorata', form)
    const watchEffectiveDate = Form.useWatch('policy_effective_date', form)
    const watchProrataEffectiveDate = Form.useWatch('prorata_effective_date', form)

    const isStateCorrectForWarningPolicyFields = isSuccessFetchingNegotiation ? checkStateCorrectForWarningPolicyFields(negotiation.state) : undefined
    const [disabledPolicyFields, setDisabledPolicyFields] = useState({
        policy_effective_date: true,
        policy_expiring_date: true,
        prorata_effective_date: true,
        quoted_premium: true,
        isa_share_perc: true,
        has_prorata: true,
        installment_type: true
    })

    const saveData = useCallback(async (data: Partial<UpdateNegotiationRequest>): Promise<NegotiationModel | undefined> => {
        //saveData
        try {
            if (id === negotiation?.uuid && id !== undefined) {
                const updateResult = await updateNegotiation({id, data}).unwrap()
                setFieldsValue(transformNegotiationToFormFields(updateResult))
                message.success('Dati aggiornati')
                return updateResult
            } else
                message.error('Trattativa non impostata')
            return undefined
        } catch (e: any) {
            //in case of update error, rollback to previous negotitation save
            if (negotiation) {
                const oldFormNegotiation = transformNegotiationToFormFields(negotiation)
                setFieldsValue(oldFormNegotiation)
            }
            console.error('debounce', e)
            message.error('Impossibile aggiornare i dati')
            return undefined
        }
    }, [id, negotiation, setFieldsValue, updateNegotiation])

    const debounceInputChange = useMemo(
        () => debounce((data: Partial<UpdateNegotiationRequest>) => saveData(data), 1500)
        , [saveData]);


    const onValuesChange = useCallback((changedValues: Partial<NegotiationFormModel>, values: NegotiationFormModel) => {
        if (negotiation && !_.isMatch(transformNegotiationToFormFields(negotiation), changedValues)) {

            let updatedValues = cloneDeep(values)
            const lob = options.lobs.find(lob => lob.uuid === values.lob)

            updatedValues = calculateSubmissionFormSideEffects(changedValues, updatedValues)
            updatedValues = calculateContractorSideEffects(changedValues, updatedValues)
            updatedValues = calculateReinsuranceFormSideEffects(changedValues, updatedValues)
            updatedValues = calculateNegotiationFormSideEffect(changedValues, updatedValues, lob, options.firstDelegated)
            updatedValues = calculatePortfolioFormSideEffect(negotiation, changedValues, updatedValues)
            updatedValues = calculatePoliciesAndIssuesSideEffects(changedValues, updatedValues)

            const oldNegotiation = transformNegotiationToFormFields(negotiation)
            const fieldsToUpdate = _.pickBy(updatedValues, (value, key) => {
                // todo avoid this necessary check (the dates are different) having form field with only the year
                if (key === 'uw_year') {
                    return oldNegotiation.uw_year?.year() !== updatedValues.uw_year?.year()
                } else if (key === 'prorata_uw_year') {
                    return oldNegotiation.prorata_uw_year?.year() !== updatedValues.prorata_uw_year?.year()
                } else {
                    return !_.isEqual(oldNegotiation[key as keyof NegotiationFormModel], value)
                }
            }) as NegotiationFormModel
            form.setFieldsValue(fieldsToUpdate)

            // for resetting values (now only for policy_insured_sum) -> todo: add other fields
            const fieldsToReset = _.pickBy(fieldsToUpdate, (value, key) => value === null && key === 'policy_insured_sum')
            form.resetFields(Object.keys(fieldsToReset))

            const parsedFormValues = transformNegotiationFormToUpdateRequest(fieldsToUpdate)

            let debounceUpdate = isDebounceUpdate(changedValues)

            if (debounceUpdate) {
                void debounceInputChange(parsedFormValues)
            } else {
                void saveData(parsedFormValues)
            }
        }

    }, [debounceInputChange, form, negotiation, options.firstDelegated, options.lobs, saveData])

    const setCalculateField = useCallback((field: FieldsCalculated) => {
        if (getFieldValue('is_calculated') && negotiation) {
            const updatedCalculateFields: CalculatedField = {...getFieldValue('is_calculated')}
            updatedCalculateFields[field] = !updatedCalculateFields[field]
            onValuesChange({is_calculated: updatedCalculateFields}, {...transformNegotiationToFormFields(negotiation), ...getFieldsValue()})
        }
    }, [getFieldValue, getFieldsValue, negotiation, onValuesChange]);

    if (pageType !== 'generalData') {
        return null
    }

    return <>
        <Typography.Title level={3}>Dati generali</Typography.Title>
        <Form
            disabled={disabled}
            key={negotiation?.uuid}
            style={{marginTop: '2rem'}}
            form={form}
            onValuesChange={(changedValues, values) => negotiation && onValuesChange(changedValues, {...transformNegotiationToFormFields(negotiation), ...values})}
            layout={'vertical'}
            title={"Dati generali"}
            initialValues={negotiation && transformNegotiationToFormFields(negotiation)}
        >
            <Row justify={'space-between'}>
                <Col xs={24} md={7}>
                    <Form.Item
                        name='policy_effective_date'
                        label={
                            isStateCorrectForWarningPolicyFields ?
                                <WarningTitleButton
                                    title={<Space>Data decorrenza{!!watchProrata && <EffectiveDateInfo/>}</Space>}
                                    warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                    onConfirm={() => setDisabledPolicyFields(prevState => ({
                                        ...prevState,
                                        policy_effective_date: !prevState.policy_effective_date
                                    }))}
                                    isEditing={!disabledPolicyFields.policy_effective_date}
                                />
                                :
                                <Space>Data decorrenza{!!watchProrata && <EffectiveDateInfo/>}</Space>
                        }>
                        <DatePicker
                            clearIcon={false}
                            defaultPickerValue={undefined}
                            defaultValue={undefined}
                            style={{width: '100%'}}
                            placeholder={'Seleziona una data'}
                            format={'DD/MM/YYYY'}
                            disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.policy_effective_date}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={7}>
                    <Form.Item
                        name={"effect_hour"}
                        label={"Ora effetto"}
                    >
                        <Cascader
                            allowClear={false}
                            options={
                                HOURS.map(hour => ({
                                    value: hour,
                                    label: hour,
                                    children: [{label: "00", value: "00"}]
                                }))}
                            displayRender={label => label.join(":")}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={7}>
                    <Form.Item label="UW Year" name='uw_year'>
                        <DatePicker style={{width: '100%'}} placeholder={"Seleziona un anno"}
                                    picker='year' disabled/>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        name='policy_expiring_date'
                        label={
                            isStateCorrectForWarningPolicyFields ?
                                <WarningTitleButton
                                    title={"Data scadenza"}
                                    warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                    onConfirm={() => setDisabledPolicyFields(prevState => ({
                                        ...prevState,
                                        policy_expiring_date: !prevState.policy_expiring_date
                                    }))}
                                    isEditing={!disabledPolicyFields.policy_expiring_date}
                                />
                                : "Data scadenza"
                        }
                    >
                        <DatePicker
                            defaultPickerValue={undefined}
                            defaultValue={undefined}
                            style={{width: '100%'}}
                            placeholder={'Seleziona una data'}
                            format={'DD/MM/YYYY'}
                            disabled={!watchEffectiveDate || (isStateCorrectForWarningPolicyFields && disabledPolicyFields.policy_expiring_date)}
                            disabledDate={(current) => !!(!watchEffectiveDate || !current.isAfter(watchEffectiveDate, 'day'))}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Form.Item
                        name='quoted_premium'
                        label={
                            isStateCorrectForWarningPolicyFields ?
                                <WarningTitleButton
                                    title={'Premio imponibile 100%'}
                                    warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                    onConfirm={() => setDisabledPolicyFields(prevState => ({
                                        ...prevState,
                                        quoted_premium: !prevState.quoted_premium
                                    }))}
                                    isEditing={!disabledPolicyFields.quoted_premium}
                                />
                                :
                                'Premio imponibile 100%'
                        }
                    >
                        <AntCurrencyFormatInput
                            disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.quoted_premium}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Form.Item
                        name='isa_share_perc'
                        label={
                            isStateCorrectForWarningPolicyFields ?
                                <WarningTitleButton
                                    title={'Quota ISA'}
                                    warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                    onConfirm={() => setDisabledPolicyFields(prevState => ({
                                        ...prevState,
                                        isa_share_perc: !prevState.isa_share_perc
                                    }))}
                                    isEditing={!disabledPolicyFields.isa_share_perc}
                                />
                                :
                                'Quota ISA'
                        }
                    >
                        <AntCurrencyFormatInput
                            disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.isa_share_perc}
                            suffix=" %"
                        />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Tooltip placement='top'
                             title={form.getFieldValue('is_calculated')?.isa_quoted_premium_share ? 'Premio imponibile 100% * Quota ISA' : null}>
                        <Form.Item
                            shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.isa_quoted_premium_share !== curValues.is_calculated?.isa_quoted_premium_share}
                            style={{margin: 0, padding: 0}}>
                            {() => <Form.Item
                                name='isa_quoted_premium_share'
                                label={form.getFieldValue('is_calculated')?.isa_quoted_premium_share ?
                                    'Premio imponibile quota ISA' :
                                    <Tooltip
                                        title={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}>
                                        <Space>
                                            Premio imponibile quota ISA
                                            <WarningOutlined style={{color: 'red'}}/>
                                        </Space>
                                    </Tooltip>}
                            >
                                <AntCurrencyFormatInput
                                    disabled={form.getFieldValue('is_calculated')?.isa_quoted_premium_share}
                                    calculate={() => setCalculateField('isa_quoted_premium_share')}
                                />
                            </Form.Item>}
                        </Form.Item>
                    </Tooltip>
                </Col>

            </Row>


            <Row justify={'space-between'} wrap={true}>
                <Col xs={24}>
                    <Form.Item
                        name={'has_prorata'}
                        label={
                            isStateCorrectForWarningPolicyFields ?
                                <WarningTitleButton
                                    title={'Pro-rata'}
                                    warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                    onConfirm={() => setDisabledPolicyFields(prevState => ({
                                        ...prevState,
                                        has_prorata: !prevState.has_prorata
                                    }))}
                                    isEditing={!disabledPolicyFields.has_prorata}
                                />
                                : "Pro-rata"
                        }
                    >
                        <Radio.Group
                            disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.has_prorata}>
                            <Radio value={true}>Si</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Form.Item
                        name='prorata_effective_date'
                        label={
                            isStateCorrectForWarningPolicyFields ?
                                <WarningTitleButton
                                    title={<>Decorrenza pro-rata<EffectiveDateInfo/></>}
                                    warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                    onConfirm={() => setDisabledPolicyFields(prevState => ({
                                        ...prevState,
                                        prorata_effective_date: !prevState.prorata_effective_date
                                    }))}
                                    isEditing={!disabledPolicyFields.prorata_effective_date}
                                />
                                :
                                <Space>Decorrenza pro-rata<EffectiveDateInfo/></Space>
                        }
                        hidden={!watchProrata}
                        extra={<ProRataError
                            effectiveDate={watchEffectiveDate}
                            prorataEffectiveDate={watchProrataEffectiveDate}
                        />}
                    >
                        <DatePicker
                            clearIcon={false}
                            defaultPickerValue={undefined}
                            defaultValue={undefined}
                            style={{width: '100%'}}
                            placeholder='Seleziona una data'
                            format={'DD/MM/YYYY'}
                            disabledDate={(current) => watchEffectiveDate ?
                                (current.startOf('day') >= watchEffectiveDate.startOf('day') ||
                                    watchEffectiveDate.startOf('day').diff(current, 'year', true) > 1)
                                : true}
                            disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.prorata_effective_date}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Form.Item name={'prorata_expiring_date'} label="Scadenza pro-rata" hidden={!watchProrata}>
                        <DatePicker
                            disabled
                            style={{width: '100%'}}
                            placeholder=''
                            format={'DD/MM/YYYY'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Form.Item name='prorata_uw_year' label="UW Year pro-rata" hidden={!watchProrata}>
                        <DatePicker
                            placeholder={''}
                            disabled
                            style={{width: '100%'}}
                            picker='year'
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Form.Item label="Premio pro-rata nostra quota" name='prorata_isa_share' hidden={!watchProrata}>
                        <AntCurrencyFormatInput/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={11}>
                    <Tooltip placement='top'
                             title={`Pro-rata ns. quota / Quota ISA${negotiation?.isa_share_perc ? '(' + percentage(negotiation.isa_share_perc) + ')' : ""}`}>
                        <Form.Item label="Pro-rata 100%" hidden={!watchProrata} name={'prorata_total'}>
                            <AntCurrencyFormatInput disabled/>
                        </Form.Item>
                    </Tooltip>
                </Col>
            </Row>


            <Form.Item
                name='installment_type'
                label={
                    isStateCorrectForWarningPolicyFields ?
                        <WarningTitleButton
                            title={"Frazionamento premio"}
                            warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                            onConfirm={() => setDisabledPolicyFields(prevState => ({
                                ...prevState,
                                installment_type: !prevState.installment_type
                            }))}
                            isEditing={!disabledPolicyFields.installment_type}
                        />
                        :
                        "Frazionamento premio"
                }
            >
                <Radio.Group disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.installment_type}>
                    {installmentTypes.map(type => <Radio key={type.value}
                                                         value={type.value}>{type.text} </Radio>)}
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Tacito rinnovo" name='is_auto_renewal'>
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="Termini di disdetta" name='cancellation_terms_days' hidden={!watchIsAutoRenewal}>
                <Select
                    virtual={false}
                    showSearch
                    placeholder={"Seleziona il termine di disdetta"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                    }
                >
                    {cancellationDays.map(ct => <Select.Option key={ct.value}
                                                               value={ct.value}>{ct.text}</Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item name='has_regulation_premium' label="Regolazione premio"
                       required>
                <Radio.Group>
                    <Radio value={true}>Sì </Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Segnalazione ISIA" name={'isia_type'} hidden={!watchIsia}>
                <Select
                    virtual={false}
                    showSearch
                    placeholder={"Seleziona un tipo di segnalazione"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                    }
                >
                    {isiaTypes.map(type => <Select.Option
                        key={type.value}
                        value={type.value}>
                        {type.text}
                    </Select.Option>)}
                </Select>
            </Form.Item>
            <Form.Item label="Tipo polizza" name='policy_product_type'>
                <Select
                    virtual={false}
                    showSearch
                    placeholder={"Seleziona il tipo polizza"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                    }
                >
                    {policy_types.map(policy => <Select.Option
                        key={policy.value}
                        value={policy.value}>
                        {policy.text}
                    </Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item label="LPS" name='is_lps'>
                <Radio.Group>
                    <Radio value={true}>Si</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>
            <BrokerSelect
                fieldName="broker"
                label='Broker'
                onCreation={(newBrokerId: string) => saveData({broker: newBrokerId})}
                {...!!negotiation?.broker && {selectedBroker: negotiation?.broker}}
            />
            <Form.Item name='fee_broker_perc' label="Provvigioni broker">
                <AntCurrencyFormatInput suffix={' %'} decimalScale={6}/>
            </Form.Item>
            <Form.Item label="Branch" name='broker_branch'>
                <Select
                    virtual={false}
                    showSearch
                    placeholder={"Seleziona un broker branch"}
                    filterOption={(input, option) => {
                        return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
                    }}
                    options={options.provinces.map(province => ({
                        key: province.value,
                        value: province.value,
                        label: province.text
                    }))}
                />
            </Form.Item>
            <Form.Item label="Riassicurazione" name={'reinsurance_type'}>
                <Select
                    virtual={false}
                    showSearch
                    placeholder={"Seleziona un tipo di riassicurazione"}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                    }
                >
                    {reinsuranceTypes.map(type => <Select.Option
                        key={type.value}
                        value={type.value}>
                        {type.text}
                    </Select.Option>)}
                </Select>
            </Form.Item>

            {/* HIDDEN FORM ITEM */}
            <Form.Item name={'is_calculated'} hidden>
                <Input disabled/>
            </Form.Item>
        </Form>
    </>
}