import {Popover} from "antd";
import {FolderOpenOutlined, InfoCircleOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {useMemo} from "react";

export default function DocumentHelpPopover() {

    const popoverText = useMemo(() => <div style={{paddingLeft: "0.625rem", width: "700px"}}>
        <h3>Gestione dei documenti caricati</h3>
        <h4>Visualizzazione a cartelle</h4>
        <ul style={{marginLeft: "17px"}}>
            <li>Cliccare due volte su una cartella oppure una volta sulla relativa freccia per espanderla e vedere il contenuto</li>
            <li>Cliccare due volte su un documento per aprirlo
            </li>
            <li>Cliccare su un documento per selezionarlo, tenere premuto Control (Command su Mac)
                e cliccare su altri documenti per una selezione multipla
            </li>
            <li>Selezionando un solo file e premendo il tasto destro è possibile aprirlo (verrà scaricato se
                in formato non supportato dal browser), rinominarlo, spostarlo di cartella o eliminarlo
            </li>
            <li>Selezionando più file sarà possibile scaricarli, eliminarli o spostarli di cartella
            </li>
            <li>Premere <strong>CANC</strong> per eliminare i file selezionati</li>
            <li>Premere <strong>ESC</strong> per deselezionare i file selezionati</li>
            <li>Cliccando il pulsante <UnorderedListOutlined/> è possibile passare alla visualizzazione di tutti i file</li>
        </ul>
        <h4>Visualizzazione solo file</h4>
        <ul style={{marginLeft: "17px"}}>
            <li>In questa visualizzazione è possibile eseguire le stesse azioni della visualizzione a cartelle</li>
            <li>Cliccando il pulsante <FolderOpenOutlined/> è possibile tornare alla visualizzazione a cartelle
            </li>
        </ul>
        <h3> Caricare documenti</h3>
        <p>I documenti che superano la grandezza di 20 MB non verranno caricati </p>
        <h4>Tramite pulsante</h4>
        <ul style={{marginLeft: "17px"}}>
            <li>Premere sul pulsante <strong>Carica</strong> e dopo aver selezionato la cartella di destinazione, sceglierli dal pc
            </li>
            <li>Una volta scelti verrà visualizzata la lista di questi documenti dalla quale è possibile
                rimuovere alcuni documenti, cambiare il nome di un singolo documento o la relativa destinazione
            </li>
            <li>Cliccare su <strong>Upload</strong> per confermare il caricamento</li>
        </ul>
        <h4>Tramite trascinamento</h4>
         <ul style={{marginLeft: "17px"}}>
            <li>Trascinare i documenti direttamente sopra la cartella dove si desidera inserire il documento
            </li>
            <li>Rilasciare i documenti per finalizzare il caricamento
            </li>
        </ul>
        <h3> Cercare documenti</h3>
        <ul style={{marginLeft: "17px"}}>
            <li>Premere sul pulsante <strong>Cerca</strong> e digitare il nome del documento che si vuole cercare. Cliccare il pulsante Cerca o premere invio per effettuare la ricerca
            </li>
            <li>Se la ricerca andrà a buon fine verrà visualizzata una lista dei documenti trovati e sarà possibile aprirli
                o vedere dove si trovano all'interno della cartella
            </li>
        </ul>
        <h3> Navigare tra UW year</h3>
        <ul style={{marginLeft: "17px"}}>
            <li> Se la trattativa è un rinnovo: è possibile navigare tra i documenti di UW year differenti selezionando quello corretto nei dettagli della trattativa (è presente in tal caso una tendina alla voce UW Year)
            </li>
        </ul>
    </div>, [])

    return <Popover
        placement={"leftTop"}
        title={<h3>Guida</h3>}
        trigger={"click"}
        content={popoverText}>
        <InfoCircleOutlined style={{fontSize: "18px", cursor: "pointer", color: "rgb(129, 138, 148)"}}/>
    </Popover>
}