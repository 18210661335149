import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {
    Alert,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Tag,
    Tooltip,
    Typography
} from 'antd';

import {CommonFormComponentProps} from "types/negotiations/components";


import {
    CheckCircleFilled,
    CloseOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    InfoCircleOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {
    BelatedEmittedModel,
    NegotiationModel,
    UnmountBelatedEmittedForm,
    UpdateBelatedEmittedForm
} from "types/negotiations";
import dayjs from "dayjs";
import {
    useLazyGetNegotiationBelatedEmittedQuery,
    useUnmountNegotiationBelatedEmittedMutation,
    useUnmountPartialNegotiationBelatedEmittedMutation,
    useUpdateNegotiationBelatedEmittedMutation
} from "redux/api/negotiationBelatedEmittedApiSlice";
import DatePicker from "components/DatePicker";
import {AntCurrencyFormatInput} from "components/inputNumber";
import {checkStateCorrectForWarningPolicyFields, euro, percentage} from "../../../../utils/formatters";
import AppendixFormItem from "../../AppendixFormItem";
import CanceledPolicyOverview from "./CanceledPolicyOverview";
import TextArea from "antd/lib/input/TextArea";
import WarningTitleButton from "../../../buttons/WarningTitleButton";
import BelatedEmittedAlignment from "./BelatedEmittedAlignment";
import BelatedEmittedItem from "./BelatedEmittedItem";
import {ReinsuranceType} from "../../../../types/reinsuranceType";

const {Title, Text} = Typography
const {Option} = Select;


export default function PoliciesAndIssues({
                                              forwaredRef,
                                              formInstance,
                                              negotiation,
                                              setNegotiation,
                                              onFormValuesChange,
                                              disabled,
                                              setCalculateField
                                          }: CommonFormComponentProps) {

    const [isUpdateFractionamentOpen, setIsUpdateFractionamentOpen] = useState<boolean>(false)
    const [belatedEmitted, setBelatedEmitted] = useState<BelatedEmittedModel>()
    const [belatedEmittedList, setBelatedEmittedList] = useState<BelatedEmittedModel[]>([])
    const [totalBelatedEmitted, setTotalBelatedEmitted] = useState<number | null>(null)
    const [disabledPolicyFields, setDisabledPolicyFields] = useState({installment_type: true})

    const [getBelatedEmitted, {isLoading: isBelatedEmittedLoading}] = useLazyGetNegotiationBelatedEmittedQuery()
    const {waiting_reasons, installmentTypes} = useSelector(selectOptions)

    const watchHasRegulationPremium = Form.useWatch('has_regulation_premium', formInstance)
    const watchReinsuranceType = Form.useWatch('reinsurance_type', formInstance)
    const isPolicyCanceled = negotiation.is_policy_cancelled
    const isStateCorrectForWarningPolicyFields = checkStateCorrectForWarningPolicyFields(negotiation.state)

    const fetchBelatedEmitted = useCallback(async () => {
        try {
            const res = await getBelatedEmitted({negotiationId: negotiation.uuid}).unwrap()
            setBelatedEmittedList(res.results)
            setTotalBelatedEmitted(res.total_belated_emitted)
        } catch (e: any) {
            message.error('Emesso tardivo: Impossibile scaricare i frazionamenti')
        }
    }, [getBelatedEmitted, negotiation.uuid])

    useEffect(() => {
        fetchBelatedEmitted()
        // if negotiation.fee_total_perc changes => update belatedEmitted
    }, [fetchBelatedEmitted, negotiation.fee_total_perc])


    return (
        <Card ref={forwaredRef} id="policiesAndIssues" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            {belatedEmitted !== undefined ?
                <UpdateFractionamentModal isOpen={isUpdateFractionamentOpen}
                                          onClose={() => {
                                              setIsUpdateFractionamentOpen(false);
                                              setBelatedEmitted(undefined)
                                          }}
                                          fetchBelatedEmitted={fetchBelatedEmitted}
                                          negotiation={negotiation}
                                          belatedEmitted={belatedEmitted}/> : null}
            <Row>
                <Col>
                    <Title level={3}>
                        <SolutionOutlined/>
                    </Title>
                </Col>
                <Col style={{marginLeft: '12px'}}>
                    <Title level={3}>Polizze ed emissioni</Title>
                </Col>
            </Row>
            <Form
                form={formInstance}
                disabled={disabled}
                name='portfolioPoliciesAndIssues'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
                style={{marginTop: '24px', marginBottom: '36px'}}
            >
                <Row justify='space-between' style={{marginTop: "24px"}}>
                    <Col xs={24}>
                        <Form.Item name='is_portfolio_managed' label="Gestita da portafoglio">
                            <Radio.Group>
                                <Radio value={true}>Si</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align='top' justify='space-between'>
                    <Col xs={24} md={7}>
                        <Form.Item label={<Text>Polizza</Text>}>
                            <Text strong>{negotiation.is_framework_agreement ? 'Convenzione' : 'Singola'}</Text>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={7}>
                        <Form.Item
                            name='installment_type'
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={"Frazionamento premio"}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            installment_type: !prevState.installment_type
                                        }))}
                                        isEditing={!disabledPolicyFields.installment_type}
                                    />
                                    :
                                    "Frazionamento premio"
                            }
                            required>
                            <Select
                                virtual={false}
                                options={installmentTypes.map(type => ({
                                    key: type.value,
                                    value: type.value,
                                    label: type.text
                                }))}
                                disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.installment_type}
                            />
                        </Form.Item>
                    </Col>
                    {negotiation.installment_type === 'O' && <Col xs={24} md={7}>
                        <Form.Item label="Numero frazionamenti premio" name='installments_number'
                                   required help={'Valori permessi: 1, 2, 3, 4, 6, 12'}>
                            <AntCurrencyFormatInput suffix={''}
                                                    hasPlaceholder={!disabled}
                                                    isAllowed={(values) => [1, 2, 3, 4, 6, 12].includes(+values.value)}
                                                    decimalScale={0} placeholder={""}/>
                        </Form.Item>
                    </Col>}
                    <Col xs={24} md={7}>
                        <Form.Item label="Motivazione attesa" name='portfolio_waiting_reason' required>
                            <Select
                                virtual={false}
                                showSearch
                                placeholder={disabled ? "" : "Selezionare la causa"}
                                optionFilterProp="children"
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                                }
                            >
                                <Option key={'no_answer'} value={'no_answer'}>Nessuna attesa</Option>
                                {waiting_reasons.map(reason => <Option key={reason.value}
                                                                       value={reason.value}>{reason.text}</Option>)}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' align='top'>
                    <Col xs={24} md={11}>
                        <Form.Item label={'Premio imponibile polizza (Quota ISA)'}>
                            <AntCurrencyFormatInput displayType='text'
                                                    hasPlaceholder={!disabled}
                                                    value={negotiation.isa_quoted_premium_share}
                                                    style={{fontWeight: 'bold'}}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item label={'Importo Assicurato (nostra quota)'}>
                            <AntCurrencyFormatInput displayType='text'
                                                    hasPlaceholder={!disabled}
                                                    value={negotiation.insured_sum}
                                                    style={{fontWeight: 'bold'}}
                            />
                        </Form.Item>
                    </Col>

                    {watchReinsuranceType === ReinsuranceType.FACOLTATIVO && (
                        <Col xs={24} md={11}>
                            <Tooltip
                                placement='top'
                                title={formInstance.getFieldValue('is_calculated')?.policy_insured_sum_net_reins ? 'Importo assicurato (nostra quota) * (1 - % Riassicurazione)' : null}>
                                <Form.Item
                                    shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.policy_insured_sum_net_reins !== curValues.is_calculated?.policy_insured_sum_net_reins}
                                    style={{margin: 0, padding: 0}}>
                                    {() => <Form.Item
                                        label="Importo Assicurato (nostra quota) al netto della riassicurazione"
                                        name='policy_insured_sum_net_reins'>
                                        <AntCurrencyFormatInput
                                            disabled={!!formInstance.getFieldValue('is_calculated')?.policy_insured_sum_net_reins}
                                            hasPlaceholder={!disabled}
                                            calculate={() => setCalculateField('policy_insured_sum_net_reins')}
                                        />
                                    </Form.Item>}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
                <Row justify='space-between' align='top'>
                    <Col xs={24} md={7}>
                        <Form.Item label={'Data decorrenza polizza'}>
                            {!negotiation.has_prorata &&
                                (<Text strong underline={!negotiation.policy_effective_date}>
                                    {negotiation.policy_effective_date ? dayjs(negotiation.policy_effective_date).format('DD/MM/YYYY') : 'Non presente'}
                                </Text>)}
                            {negotiation.has_prorata && (
                                <Space direction='horizontal' size='middle' align={'start'}>
                                    {negotiation.prorata_effective_date ?
                                        <div style={{
                                            display: 'flex',
                                            alignContent: 'center',
                                            gap: '0.5rem'
                                        }}>
                                            <Text strong>
                                                {dayjs(negotiation.prorata_effective_date).format('DD/MM/YYYY')}
                                            </Text>
                                            <Tag color={'gold'}>
                                                Pro-rata
                                            </Tag>
                                        </div>
                                        :
                                        <Text strong underline>Non presente</Text>}
                                </Space>)}
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={7}>
                        <Form.Item label={'Data scadenza polizza'}>
                            <Text strong
                                  underline={!negotiation.policy_expiring_date}>{negotiation.policy_expiring_date ? dayjs(negotiation.policy_expiring_date).format('DD/MM/YYYY') : 'Non presente'}</Text>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={7}>
                        <Form.Item label='Numero polizza' name='policy_number'>
                            <Input placeholder={disabled ? "" : 'Numero polizza'}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify='space-between' align='top'>
                    <Col xs={24} md={11}>
                        <Form.Item name='has_regulation_premium' label="Regolazione premio"
                                   required>
                            <Radio.Group>
                                <Radio value={true}>Sì </Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    {watchHasRegulationPremium &&
                        <Col xs={24} md={11}>
                            <Form.Item label="Premio di regolazione (nostra quota)" name='regulation_premium'
                                       required>
                                <AntCurrencyFormatInput hasPlaceholder={!disabled}/>
                            </Form.Item></Col>
                    }
                </Row>
                <AppendixFormItem fee={negotiation.isa_share_perc || 0} negotiation={negotiation.uuid}/>
            </Form>
            {
                !isPolicyCanceled && <>
                    <BelatedIssuedManager negotiation={negotiation}
                                          setNegotiation={setNegotiation}
                                          fetchBelatedEmitted={fetchBelatedEmitted}
                                          belatedEmittedList={belatedEmittedList}
                                          setBelatedEmittedList={setBelatedEmittedList}
                                          updateFractionament={(be: BelatedEmittedModel) => {
                                              setBelatedEmitted(be)
                                              setIsUpdateFractionamentOpen(true)
                                          }}
                    />
                    <Row justify={"space-around"} style={{marginTop: '24px'}}>
                        <Col>
                            <Space direction={"horizontal"}>
                                {belatedEmittedList.length > 0 ?
                                    <>Totale emesso tardivo:
                                        <Text strong>
                                            {totalBelatedEmitted ? euro(totalBelatedEmitted) : euro(0)}
                                        </Text></>
                                    : 'Nessun titolo presente al momento'}
                            </Space>
                        </Col>
                    </Row>
                </>
            }
            {
                isPolicyCanceled && <CanceledPolicyOverview belatedEmittedList={belatedEmittedList}/>
            }
            <Form
                form={formInstance}
                disabled={disabled}
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
                style={{marginTop: '1rem'}}
            >
                <Form.Item label="Note" name='policy_notes'>
                    <TextArea autoSize={{minRows: 3}} placeholder={disabled ? "" : "Note"}/>
                </Form.Item>
            </Form>
        </Card>

    )
}


type UpdateFractionamentProps = {
    negotiation: NegotiationModel,
    belatedEmitted: BelatedEmittedModel
    //setBelatedEmittedList: (be: BelatedEmittedModel[]) => void
    //fetchBelatedEmittedList: () => BelatedEmittedModel[]
    isOpen: boolean
    onClose: () => void
    fetchBelatedEmitted: () => Promise<void>
}

export function UpdateFractionamentModal({
                                             isOpen,
                                             onClose,
                                             negotiation,
                                             belatedEmitted,
                                             fetchBelatedEmitted
                                         }: UpdateFractionamentProps) {

    const [form] = Form.useForm<UpdateBelatedEmittedForm>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const [updateFractionament, {isLoading, isError}] = useUpdateNegotiationBelatedEmittedMutation()
    const watchInstallmentPremium = Form.useWatch('installment_premium', form)
    const watchInstallmentEffectiveDate = Form.useWatch('installment_effective_date', form)
    const watchActualPremium = Form.useWatch('installment_premium_actual', form)

    useEffect(() => {
        const commission = +(watchInstallmentPremium * (negotiation.fee_total_perc || 0)).toFixed(2)
        const residualPremiumAmount = +((watchInstallmentPremium - watchActualPremium) < 0 ? 0 : (watchInstallmentPremium - watchActualPremium)).toFixed(2)
        const residualCommissionAmount = +(residualPremiumAmount * (negotiation.fee_total_perc || 0)).toFixed(2)
        form.setFieldsValue({
            installment_commission: commission,
            installment_premium_residual: residualPremiumAmount,
            installment_commission_residual: residualCommissionAmount
        })
    }, [form, negotiation.fee_total_perc, watchActualPremium, watchInstallmentPremium])

    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        let allFieldsOk = !!(
            fields.installment_class &&
            fields.installment_effective_date &&
            fields.installment_premium &&
            fields.installment_expiring_date &&
            fields.installment_expiring_date >= fields.installment_effective_date
        )
        if (belatedEmitted.is_unmounted) {
            allFieldsOk = allFieldsOk && typeof (fields.installment_commission) === 'number'
                && !!fields.certificate_number
                && typeof fields.installment_premium_actual === 'number'
                && typeof fields.installment_premium_residual === 'number'
                && typeof fields.installment_commission_residual === 'number'
                && !!fields.unmounting_date
        }
        setDisabledSubmit(hasErrors || !allFieldsOk);
    }


    async function onFinish(values: UpdateBelatedEmittedForm) {
        if (belatedEmitted) {
            const newInstallmentNumber = Math.max(belatedEmitted.installment_num, 0);

            let parsedData: Partial<BelatedEmittedModel> = {
                ...values,
                installment_num: newInstallmentNumber,
                installment_effective_date: new Date(values.installment_effective_date.format('YYYY-MM-DD')),
                installment_expiring_date: new Date(values.installment_expiring_date.format('YYYY-MM-DD')),
                unmounting_date: values.unmounting_date ? new Date(values.unmounting_date.format('YYYY-MM-DD')) : null
            }

            try {
                await updateFractionament({
                    id: belatedEmitted.uuid,
                    negotiation_id: negotiation.uuid,
                    data: parsedData
                }).unwrap()
                message.success('Frazionamento aggiornato')
                onClose()
                fetchBelatedEmitted()
            } catch (e: any) {
                message.error('Impossibile aggiornare il frazionamento')
                console.error('Fractionament update failed', e)
            }
        }
    }

    const disabledDate = (current: dayjs.Dayjs) => {
        if (negotiation.has_prorata) {
            let policy_date = dayjs(negotiation.prorata_effective_date)
            return current && !(current.isAfter(policy_date, 'day') || current.isSame(policy_date, 'day'));
        } else if (negotiation.policy_effective_date) {
            let policy_date = dayjs(negotiation.policy_effective_date)
            return current && !(current.isAfter(policy_date, 'day') || current.isSame(policy_date, 'day'));
        } else
            return true
    }

    const invalidDate = (date: Date) => {
        const policyDate = dayjs(negotiation.has_prorata ? negotiation.prorata_effective_date : negotiation.policy_effective_date)
        const titleDate = dayjs(date)
        return policyDate.isAfter(titleDate, "day")
    }

    return (
        <Modal
            title={<Row align='middle' justify='space-between'>
                <Col>
                    <Title level={5}>Modifica Titolo</Title>
                </Col>
                <Col>
                    <Space direction='horizontal' align='center' size='middle'>
                        <Tag
                            color={negotiation.fee_total_perc !== null ? 'blue' : 'red'}>Provvigioni: {negotiation.fee_total_perc !== null ?
                            <AntCurrencyFormatInput displayType='text'
                                                    value={percentage(negotiation.fee_total_perc)}
                                                    suffix=' %'/> : 'Non impostate'}</Tag>
                        <CloseOutlined style={{cursor: 'pointer'}} onClick={onClose}/>
                    </Space>
                </Col>
            </Row>}
            closable={false}
            centered
            open={isOpen}
            onOk={() => {
                onClose()
                fetchBelatedEmitted()
            }}
            onCancel={onClose}
            footer={<>
                <Alert style={{textAlign: 'left'}} type={"warning"}
                       message={"Attenzione, la modifica del titolo aumenterà l'emesso tardivo se la data di decorrenza è già passata"}
                       banner/>
                <Row align='middle' justify="space-between" style={{marginTop: '0.75rem'}}>
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Space>
                            <Button disabled={disabledSubmit} type='primary'
                                    onClick={() => onFinish(form.getFieldsValue())}>
                                Modifica
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </>
            }
        >
            <Form
                form={form}
                name="updateBelatedEmitted"
                onValuesChange={onValuesChange}
                onFinish={onFinish}
                layout={'vertical'}
                requiredMark={false}
                initialValues={{
                    installment_class: belatedEmitted.installment_class,
                    installment_effective_date: dayjs(belatedEmitted.installment_effective_date),
                    installment_expiring_date: dayjs(belatedEmitted.installment_expiring_date),
                    installment_premium: belatedEmitted.installment_premium,
                    installment_commission: belatedEmitted.installment_commission,
                    unmounting_date: dayjs(belatedEmitted.unmounting_date),
                    installment_premium_actual: belatedEmitted.installment_premium_actual || 0,
                    certificate_number: belatedEmitted.certificate_number,
                    installment_premium_residual: belatedEmitted.installment_premium_residual || 0,
                    installment_commission_residual: belatedEmitted.installment_commission_residual || 0
                }}
            >
                <Form.Item label="Tipologia" name='installment_class' required>
                    <Select disabled options={[{label: 'Normale', value: 'STD'}, {
                        label: 'Pro-rata',
                        value: 'PRT'
                    }]}/>
                </Form.Item>
                <Form.Item label="Decorrenza" name='installment_effective_date'
                           help={invalidDate(belatedEmitted.installment_effective_date) &&
                               <Tag color={"red"}><ExclamationCircleFilled/> La data di decorrenza del titolo è
                                   inferiore alla data di decorrenza della polizza</Tag>}>
                    <DatePicker
                        disabled
                        style={{width: '100%'}}
                        placeholder='Seleziona una data'
                        format={'DD/MM/YYYY'}
                        disabledDate={disabledDate}/>
                </Form.Item>
                <Form.Item label="Scadenza" name='installment_expiring_date' required
                           help={((form.getFieldValue("installment_expiring_date") < form.getFieldValue("installment_effective_date"))) &&
                               <Tag color={"red"}><ExclamationCircleFilled/> La data di scadenza del titolo è
                                   inferiore
                                   alla data di decorrenza del titolo</Tag>}>
                    <DatePicker
                        style={{width: '100%'}}
                        placeholder='Seleziona una data'
                        format={'DD/MM/YYYY'}
                        disabled={true}
                        disabledDate={(current) => !watchInstallmentEffectiveDate || watchInstallmentEffectiveDate >= current}
                    />
                </Form.Item>
                <Form.Item name='installment_premium' label='Importo premio'>
                    <AntCurrencyFormatInput disabled/>
                </Form.Item>
                <Tooltip placement='top' title='Provvigioni totali * importo premio'>
                    <Form.Item name='installment_commission' label='Importo provvigioni'>
                        <AntCurrencyFormatInput disabled/>
                    </Form.Item>
                </Tooltip>
                {belatedEmitted.is_unmounted && <>
                    <Form.Item
                        name='unmounting_date'
                        label='Data caricamento su portafoglio'>
                        <DatePicker
                            style={{width: '100%'}}
                            placeholder='Seleziona una data'
                            format={'DD/MM/YYYY'}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'installment_premium_actual'}
                        label={'Importo premio effettivo'}>
                        <AntCurrencyFormatInput/>
                    </Form.Item>
                    <Form.Item name='certificate_number' label='Numero certificato'>
                        <Input placeholder="Numero certificato/Polizza"/>
                    </Form.Item>
                    <Tooltip placement='top' title='Importo premio - importo effettivo'>
                        <Form.Item
                            name='installment_premium_residual'
                            label='Importo premio residuo'
                        >
                            <AntCurrencyFormatInput
                                allowNegative={true}
                                disabled
                            />
                        </Form.Item>
                    </Tooltip>
                    <Tooltip placement='top' title='Provvigioni * importo residuo'>
                        <Form.Item
                            name='installment_commission_residual'
                            label='Importo provvigioni residuo'
                        >
                            <AntCurrencyFormatInput
                                disabled
                            />
                        </Form.Item>
                    </Tooltip>
                </>}

            </Form>
        </Modal>
    )
}


type BelatedIssuedManagerProps = {
    negotiation: NegotiationModel,
    setNegotiation: Dispatch<SetStateAction<NegotiationModel | undefined>>,
    belatedEmittedList: BelatedEmittedModel[]
    setBelatedEmittedList: (be: BelatedEmittedModel[]) => void
    updateFractionament: (be: BelatedEmittedModel) => void
    fetchBelatedEmitted: () => Promise<void>
}

export function BelatedIssuedManager(
    {
        negotiation,
        belatedEmittedList,
        setBelatedEmittedList,
        updateFractionament,
        fetchBelatedEmitted,
        setNegotiation
    }
        : BelatedIssuedManagerProps) {

    const [selectedFractionament, setSelectedFractionament] = useState<BelatedEmittedModel | undefined>(undefined)
    const [belatedEmittedByInstallmentNumber, setBelatedEmittedByInstallmentNumber] = useState<BelatedEmittedModel[][]>([])
    useEffect(() => {

        const uniqueInstallmentNumber: number[] = []
        belatedEmittedList.forEach(be => !(uniqueInstallmentNumber.includes(be.installment_num)) && uniqueInstallmentNumber.push(be.installment_num))
        setBelatedEmittedByInstallmentNumber(uniqueInstallmentNumber.map(inst_num => belatedEmittedList.filter(be => inst_num === be.installment_num)))
    }, [belatedEmittedList])

    return (
        <Space direction='vertical' style={{width: '100%'}} size='large'>
            {selectedFractionament &&
                <UnmountFractionamentModal isOpen={true}
                                           onClose={() => setSelectedFractionament(undefined)}
                                           belatedEmittedList={belatedEmittedList}
                                           setBelatedEmittedList={setBelatedEmittedList}
                                           fetchBelatedEmitted={fetchBelatedEmitted}
                                           belatedEmitted={selectedFractionament}
                                           negotiation={negotiation}/>}
            <Title level={5}>Caricamento in emesso tardivo</Title>
            {negotiation.be_titles_mismatch && <><Row align='middle' justify='space-between' wrap={false}>
                <Col>
                    <p style={{color: 'red'}}>I dati dei titoli non sono allineati con la trattativa. Premere il
                        pulsante
                        'Allinea' per correggere
                        l'incoerenza.
                    </p>
                </Col>
                <Col>
                    <BelatedEmittedAlignment
                        negotiation={negotiation}
                        onConfirm={() => {
                            fetchBelatedEmitted()
                            setNegotiation(prevNegotiation => {
                                return prevNegotiation ? ({
                                    ...prevNegotiation,
                                    be_titles_mismatch: false
                                }) : prevNegotiation
                            })
                        }}
                    />
                </Col>
            </Row>
            </>}

            {belatedEmittedByInstallmentNumber.map((beList, idx) => {
                return (
                    <BelatedIssuedFractionament key={idx}
                                                negotiation={negotiation}
                                                index={idx}
                                                belatedEmittedList={beList}
                                                unmountFractionament={(f: BelatedEmittedModel) => setSelectedFractionament(f)}
                                                updateFractionament={(be: BelatedEmittedModel) => {
                                                    updateFractionament(be)
                                                }}/>
                )
            })}
        </Space>

    )
}


export function BelatedIssuedFractionament({
                                               belatedEmittedList,
                                               index,
                                               unmountFractionament,
                                               updateFractionament,
                                               negotiation
                                           }: {
    belatedEmittedList: BelatedEmittedModel[],
    index: number,
    unmountFractionament: (f: BelatedEmittedModel) => void,
    updateFractionament: (be: BelatedEmittedModel) => void,
    negotiation: NegotiationModel
}) {


    const invalidDate = (date: Date) => {
        const policyDate = dayjs(negotiation.has_prorata ? negotiation.prorata_effective_date : negotiation.policy_effective_date)
        const titleDate = dayjs(date)
        return policyDate.isAfter(titleDate, "day")
    }

    return (
        <Card style={{background: '#fafafa'}}
              bodyStyle={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}><Title level={5}>{index + 1}° Titolo</Title>
            </div>
            {belatedEmittedList.map((be, idx) => {
                return (<div key={idx}>
                        <Divider style={{marginBottom: '0.5rem'}}/>
                        {
                            be.installment_class === 'PRT' &&
                            <div style={{textAlign: 'right'}}><Tag style={{textAlign: 'right'}}
                                                                   color={'gold'}>Pro-rata</Tag></div>
                        }
                        <BelatedEmittedItem belatedEmitted={be}/>
                        <Row justify={"center"}>
                            <Col flex={20}>
                                {be.is_unmounted ?
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        marginTop: '0.5rem'
                                    }}>
                                        {!be.is_batch && <Button onClick={() => {
                                            updateFractionament(be)
                                        }}>
                                            <EditOutlined/>Modifica titolo
                                        </Button>}
                                        <Space direction='vertical' align='center' size={'small'}>
                                            <div style={{textAlign: 'center', color: 'green', fontWeight: '500'}}>
                                                <CheckCircleFilled
                                                    style={{fontSize: '16px', color: 'green'}}/> Caricato su
                                                portafoglio {be.is_batch &&
                                                <Tag style={{textAlign: 'right'}} color={'#1B8D1B'}>Pass</Tag>}
                                            </div>
                                            <Text>{be.unmounting_date && dayjs(be.unmounting_date).format('DD/MM/YYYY')}</Text>
                                        </Space>
                                    </div>
                                    : !be.is_unmounted && be.is_mounted ?
                                        <div style={{width: '100%'}}>
                                            <Space direction='vertical' align={"center"} style={{width: '100%'}}>
                                                <Space direction='vertical' align='center'
                                                       style={{marginTop: '0.5rem'}}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        color: 'orange',
                                                        fontWeight: '500'
                                                    }}>
                                                        <ExclamationCircleFilled
                                                            style={{fontSize: '16px', color: 'orange'}}/> Caricato
                                                        in
                                                        emesso tardivo {be.is_batch &&
                                                        <Tag style={{textAlign: 'right'}}
                                                             color={'#e5ac44'}>Pass</Tag>}
                                                    </div>
                                                    <AntCurrencyFormatInput displayType='text'
                                                                            value={be.installment_premium}/>
                                                </Space>
                                                <Button type='primary' onClick={() => unmountFractionament(be)}>
                                                    Carica su portafoglio
                                                </Button>
                                            </Space>
                                        </div>
                                        : <div style={{width: '100%'}}>
                                            <Space direction='vertical' align={"center"} style={{width: '100%'}}>
                                                <div style={{
                                                    textAlign: 'center',
                                                    marginBottom: '0.5rem',
                                                    marginTop: '0.5rem'
                                                }}>
                                                    <InfoCircleOutlined
                                                        style={{fontSize: '16px', color: '#3FA9FF'}}/> Non genera
                                                    emesso
                                                    tardivo
                                                </div>
                                                <Button type='primary' onClick={() => unmountFractionament(be)}>
                                                    Carica su portafoglio
                                                </Button>
                                            </Space>
                                        </div>
                                }
                            </Col>
                        </Row>
                        {invalidDate(be.installment_effective_date) &&
                            <Row justify={"center"} style={{marginTop: "1rem"}}><Tag
                                color={"red"}><ExclamationCircleFilled/> La data di decorrenza del titolo è
                                inferiore
                                alla data di
                                decorrenza della polizza</Tag></Row>}
                    </div>
                )
            })}
        </Card>

    )
}

type UnmountFractionamentProps = {
    negotiation: NegotiationModel,
    belatedEmitted: BelatedEmittedModel
    belatedEmittedList: BelatedEmittedModel[]
    setBelatedEmittedList: (be: BelatedEmittedModel[]) => void
    isOpen: boolean
    onClose: () => void,
    fetchBelatedEmitted: () => Promise<void>
}

export function UnmountFractionamentModal(
    {
        isOpen,
        onClose,
        negotiation,
        belatedEmitted,
        belatedEmittedList,
        setBelatedEmittedList,
        fetchBelatedEmitted
    }
        : UnmountFractionamentProps) {

    const [form] = Form.useForm<UnmountBelatedEmittedForm>()
    const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);

    const [unmountRequest, {isLoading: isUnmountLoading}] = useUnmountNegotiationBelatedEmittedMutation()
    const [partialUnmountRequest, {isLoading: isPartialUnmountLoading}] = useUnmountPartialNegotiationBelatedEmittedMutation()

    const onValuesChange = () => {
        const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
        const fields = form.getFieldsValue()
        const allFieldsOk = !!(fields.certificate_number && fields.installment_premium_actual && fields.unmounting_date && fields.installment_premium_residual !== undefined)
        setDisabledSubmit(hasErrors || !allFieldsOk);

    }

    async function unmountFractionament(partial?: boolean) {
        const {unmounting_date, ...values} = form.getFieldsValue()
        try {
            if (partial) {
                const unmountResult = await partialUnmountRequest({
                    id: belatedEmitted.uuid,
                    negotiation_id: negotiation.uuid,
                    data: {...values, unmounting_date: new Date(unmounting_date.format('YYYY-MM-DD'))}
                }).unwrap()
                //setBelatedEmittedList(unmountResult)
                await fetchBelatedEmitted()
                message.success('Frazionamento smontato parzialmente')
            } else {
                const unmountResult = await unmountRequest({
                    id: belatedEmitted.uuid,
                    negotiation_id: negotiation.uuid,
                    data: {...values, unmounting_date: new Date(unmounting_date.format('YYYY-MM-DD'))}
                }).unwrap()
                //setBelatedEmittedList(unmountResult)
                await fetchBelatedEmitted()
                message.success('Frazionamento smontato')
            }
            onClose()
        } catch (e: any) {
            console.error(e)
        }
    }

    const watchActualPremium = Form.useWatch('installment_premium_actual', form)

    useEffect(() => {
        let residualPremiumAmount = belatedEmitted.installment_premium
        let residualCommissionAmount = belatedEmitted.installment_premium
        const actualPremium = watchActualPremium || 0

        residualPremiumAmount = residualPremiumAmount - actualPremium >= 0 ? residualPremiumAmount - actualPremium : 0
        if (negotiation.fee_total_perc !== null)
            residualCommissionAmount = residualPremiumAmount * negotiation.fee_total_perc
        form.setFieldsValue({installment_premium_residual: residualPremiumAmount})
        form.setFieldsValue({installment_commission_residual: residualCommissionAmount})

    }, [belatedEmitted.installment_premium, form, negotiation.fee_total_perc, watchActualPremium])

    return (


        <Modal
            title={<Row align='middle' justify='space-between'>
                <Col>
                    <Title level={5}>Smonta frazionamento</Title>
                </Col>
                <Col>
                    <Space direction='horizontal' align='center' size='middle'>
                        <Tag
                            color={negotiation.fee_total_perc !== null ? 'blue' : 'red'}>Provvigioni: {negotiation.fee_total_perc !== null ?
                            <AntCurrencyFormatInput displayType='text'
                                                    value={percentage(negotiation.fee_total_perc)}
                                                    suffix=' %'/> : 'Non impostate'}</Tag>
                        <CloseOutlined style={{cursor: 'pointer'}} onClick={onClose}/>
                    </Space>
                </Col>
            </Row>}
            closable={false}
            centered
            open={isOpen}
            onOk={onClose}
            width={'40%'}
            onCancel={onClose}
            footer={
                <Row align='middle' justify="space-between">
                    <Col>
                        <Button onClick={onClose}>
                            Annulla
                        </Button>
                    </Col>
                    <Col>
                        <Button disabled={disabledSubmit} type='primary' onClick={() => unmountFractionament()}>
                            Smonta tutto
                        </Button>
                    </Col>
                </Row>
            }
        >
            {/* <Space size='small' direction="vertical" style={{ width: '100%' }}> */}
            <div style={{display: 'flex', justifyContent: 'center'}}><Title level={5}>Frazionamento
                selezionato</Title>
            </div>
            <Row style={{
                background: '#fafafa',
                borderRadius: '12px',
                border: '1px solid #f0f0f0',
                padding: '8px 12px'
            }}
                 justify='space-between' align='middle'>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Decorrenza</Text>
                        <Text strong>{dayjs(belatedEmitted.installment_effective_date).format('DD/MM/YYYY')}</Text>
                    </Space>
                </Col>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Scadenza</Text>
                        <Text strong>{dayjs(belatedEmitted.installment_expiring_date).format('DD/MM/YYYY')}</Text>
                    </Space>
                </Col>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Importo premio</Text>
                        <strong> <AntCurrencyFormatInput displayType='text'
                                                         value={belatedEmitted.installment_premium}/></strong>
                    </Space>
                </Col>
                <Col span={5}>
                    <Space direction='vertical'>
                        <Text>Provvigioni</Text>
                        <strong><AntCurrencyFormatInput displayType='text'
                                                        value={belatedEmitted.installment_commission}/></strong>
                    </Space>
                </Col>
            </Row>
            <Divider/>
            <Form form={form} name="unmountBelatedEmitted" layout={'vertical'} onValuesChange={onValuesChange}
                  style={{width: '100%'}} requiredMark={false}>
                <Row align='bottom' justify='space-between'>
                    <Col span={7}>
                        <Form.Item name='certificate_number' label='Numero certificato / Polizza' required>
                            <Input placeholder="Numero certificato/Polizza"/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name='installment_premium_actual' label='Importo premio effettivo' required>
                            <AntCurrencyFormatInput/>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item label="Data caricamento su portafoglio" name='unmounting_date' required>
                            <DatePicker
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder='Seleziona una data'
                                format={'DD/MM/YYYY'}
                                disabledDate={(current) => current > dayjs()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align='bottom' justify='space-between'>
                    <Col span={7}>
                        <Tooltip placement='top' title='Importo premio - importo effettivo'>
                            <Form.Item name='installment_premium_residual' label='Importo premio residuo' required>
                                <AntCurrencyFormatInput disabled allowNegative={true}/>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={7}>
                        <Tooltip placement='top' title='Provvigioni * importo residuo'>
                            <Form.Item name='installment_commission_residual' label='Importo provvigioni residuo'
                                       required>
                                <AntCurrencyFormatInput disabled/>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
            {/* </Space> */}
        </Modal>
    )
}