import React, {useMemo, useRef, useState} from "react";
import {Col, Row, Switch} from "antd";
import HighchartsReact from "highcharts-react-official";
import PieChart from "../charts/HighCharts/PieChart";
import {useBrokersChartsParamsContext} from "../../context/BrokersChartsContext";
import {useBankBranchesChartsParamsContext} from "../../context/BankBranchesChartsContext";
import {useGetBBBTotalNegotiationsQuery} from "../../redux/api/chartsApiSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {euro, numeric} from "../../utils/formatters";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import SelectNewBusinessRenewalAll from "../select/SelectNewBusinessRenewalAll";
import BBBChartCardContainer from "./BBBChartCardContainer";
import {selectUser} from "../../redux/features/userSlice";

export default function NegotiationsInBoundTotal({entity}: { entity: 'broker' | 'bank_branch' }) {

    const ref = useRef<HighchartsReact.RefObject>(null);
    const [calculationType, setCalculationType] = useState<1 | 2>(1)
    const [negotiationType, setNegotiationType] = useState<1 | 2 | 3 | 4>(1)

    const {params: sharedBrokersParams} = useBrokersChartsParamsContext()
    const {params: sharedBankBranchesParams} = useBankBranchesChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year

    const areParamsOk = !!((sharedBrokersParams.budget_group || sharedBankBranchesParams.budget_group) && typeof uwYear === 'number')
    const {data, isFetching, isUninitialized} = useGetBBBTotalNegotiationsQuery(areParamsOk ? {
        entity: entity === 'broker' ? 2 : 1,
        budget_group: entity === 'broker' ? sharedBrokersParams.budget_group : sharedBankBranchesParams.budget_group,
        calculation_type: calculationType,
        uw_year: uwYear,
        percentage: true,
        negotiations_type: negotiationType,
    } : skipToken)

    const lastIndex = data?.history.length ? data?.history.length - 1 : undefined
    const currentYearData = data && typeof lastIndex === 'number' ? data.history[lastIndex] : undefined
    const options = useSelector(selectOptions)

    const values: { name: string, y: number }[] = []
    const colors: string[] = []

    data?.result.forEach(el => {
        const state = options.states.find(state => state.value === el.state)
        if (state) {
            values.push({name: state.text, y: el.amount})
            colors.push(state.color)
        }
    })

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"} align={"middle"}>
            <Col>
                <SelectNewBusinessRenewalAll
                    options={[
                        {label: 'New Business', value: 1},
                        {label: 'Rinnovati con successo', value: 2},
                        {label: 'Polizze in essere', value: 4},
                    ]}
                    setValue={setNegotiationType}
                    value={negotiationType}
                />
            </Col>
            <Col>
                <Switch
                    checked={calculationType === 1}
                    onChange={() => setCalculationType(prevState => prevState === 1 ? 2 : 1)}
                    size={"small"}
                    checkedChildren={"Trattative"}
                    unCheckedChildren={"Premi"}
                    style={{width: "77px"}}
                />
            </Col>
        </Row>
    }, [calculationType, negotiationType])

    return <BBBChartCardContainer
        title={cardTitle}
        loading={isFetching || isUninitialized}>
        <Row align={'middle'} gutter={16}>
            <Col>
                <h3 style={{display: "flex", alignItems: "center"}}>Totale
                    complessivo {calculationType === 1 ? 'delle trattative' : 'dei premi'} in Bound</h3>
            </Col>
            <Col>
                <h2>{calculationType === 1 ? numeric(currentYearData?.amount || 0) : euro(currentYearData?.amount || 0)}</h2>
            </Col>
        </Row>

        <PieChart
            chartRef={ref}
            options={{
                chart: {
                    borderRadius: 12
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: ""
                        },
                    }
                },
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 768
                        },
                        chartOptions: {
                            legend: {
                                labelFormat: `{name} - {percentage:.2f}%`,
                            }
                        }
                    }]
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false,
                            format: '{point.name} - {percentage:.2f}%'
                        },
                        showInLegend: true
                    },
                },
                series: [
                    {
                        type: 'pie',
                        colorByPoint: true,
                        data: values,
                        colors
                    },
                ]
            }}/>
    </BBBChartCardContainer>
}