import {Button, Col, message, Row, Table, Tag} from "antd";
import {ColumnsType} from "antd/lib/table";
import {
  BelatedEmittedBatchReport,
  BelatedEmittedBatchReportFilters,
  DAT_INIZIO_COPERTURA_TIT,
  DATA_INS_TITOLO,
  DAT_FINE_COPERTURA_TIT,
  FLG_ANNULLO,
  COD_TRATTATIVA,
  COD_CONTRATTO,
  NUM_VERSIONE, PassProcessKey,
  PREMIO,
  ProcessDescription
} from "../../../types/belatedEmittedBatchReport";
import {getColumnDateProps, getStandardFilter} from "../../tables/negotiationsTables/columnsUtils";
import dayjs from "dayjs";
import Title from "antd/lib/typography/Title";
import InfoModal from "../../modals/InfoModal";
import React, {useEffect, useState} from "react";
import {
  useLazyGetBelatedBatchReportsDownloadQuery,
  useLazyGetBelatedBatchReportsQuery, useLazyGetReexecuteProcessQuery,
} from "../../../redux/api/belatedEmittedBatchReportApiSlice";
import _, {cloneDeep, isNil} from "lodash";
import {useLocation, useNavigate} from "react-router-dom";
import {euro, getTitleFromKeyPassProcess} from "../../../utils/formatters";

const ToNegotiationButton = ({uuid}: { uuid: string }) => {
  const navigate = useNavigate()
  const {pathname} = useLocation()

  return <Button
      type={"default"}
      size={"small"}
      onClick={() => navigate(`/negotiations/${uuid}`, {state: {previous_path: pathname}})}>
    Vai alla trattativa
  </Button>
}

const Info = () => (
    <>
      <p>La tabella mostra l'esito delle operazioni di acquisizione dei dati provenienti da Pass.</p>
      <span>Cliccando sul pulsante</span><span style={{fontWeight: 'bold'}}> +</span><span> è possibile vedere il dettaglio dell'operazione.</span>
      <p>È possibile scaricare un file .xlsx con il dettaglio dell'acquisizione tramite il pulsante 'Scarica' situato a
        destra. Il file verrà generato considerando i filtri e gli ordinamenti eventualmente settati sulla tabella.</p>
    </>
)

const ReexecuteUnmountButton = ({belatedEmitted, updateBelatedEmitted} : {belatedEmitted: string, updateBelatedEmitted: ( be: BelatedEmittedBatchReport) => void}) => {
  const [reExecute, {isLoading, isFetching}] = useLazyGetReexecuteProcessQuery()
  return <Button
      loading={isLoading || isFetching}
      type={"default"}
      size={"small"}
      onClick={async() => {
        try {
          const updatedBe = await reExecute({batch_report_uuid: belatedEmitted}).unwrap()
          updateBelatedEmitted(updatedBe)
          message.success('Esecuzione del processo completata.')
        } catch {
          message.error('Errore di esecuzione nel processo')
        }
      }}
  >
    Riesegui processo
  </Button>
}

const PAGE_SIZE = 7;
const DEFAULT_COLUMNS: ColumnsType<BelatedEmittedBatchReport> = [
  {
    title: 'Data di esecuzione',
    dataIndex: 'reference_date',
    key: 'reference_date',
    ...getColumnDateProps('reference_date'),
    sorter: {
      multiple: 0,
    }, render: (text: string) => {
      return text ? dayjs(text).format('DD-MM-YYYY') : ""
    },
    width: '30%'
  },
  {
    title: 'Esito',
    dataIndex: 'state',
    key: 'state',
    sorter: {
      multiple: 0,
    },
    ...getStandardFilter([
      {text: 'Errore', value: 'error'},
      {text: 'Completato', value: 'success'},
      {text: 'Warning', value: 'warning'}]),
    width: '35%',
    render: (value, record) => {
      if (!record.negotiation) {
        return <span style={{color: 'red'}}>{value}</span>
      } else {
        return value
      }
    }
  },
  {
    title: 'Azioni',
    dataIndex: 'negotiation',
    key: 'negotiation',
    render: (uuid, record) => {
      if (uuid) {
        return <ToNegotiationButton uuid={uuid}/>
      } else {
        return <ReexecuteUnmountButton belatedEmitted={record.uuid}  updateBelatedEmitted={(be) => {}}/>
      }
    },
    width: '35%',
  },
  Table.EXPAND_COLUMN
]

const EXPANDABLE_COLUMNS: ColumnsType<ProcessDescription> = ([
  COD_TRATTATIVA,
  DATA_INS_TITOLO,
  COD_CONTRATTO,
  DAT_INIZIO_COPERTURA_TIT,
  DAT_FINE_COPERTURA_TIT,
  PREMIO,
  FLG_ANNULLO,
  NUM_VERSIONE
] as PassProcessKey[]).map(key => ({
  key,
  dataIndex: key,
  title: getTitleFromKeyPassProcess(key),
  render: (value) => {
    switch (key) {
      case (DAT_FINE_COPERTURA_TIT):
      case (DAT_INIZIO_COPERTURA_TIT):
      case (DATA_INS_TITOLO):
        if (value) {
          return dayjs(value).format('DD-MM-YYYY')
        }
        break;
      case (PREMIO):
        if (!_.isNil(value)) {
          return euro(value)
        }
        break
      case (FLG_ANNULLO):
        if (!_.isNil(value)) {
          return <Tag color={value ? 'red' : 'green'}>{value ? 'Annullamento' : 'Emissione'}</Tag>
        }
        break;
      default:
        return value
    }
  }
}))


export default function BelatedEmittedBatchReportCard({className}: { className: string }) {
  const [getBelatedBatchReports, {data, originalArgs}] = useLazyGetBelatedBatchReportsQuery()
  const [filters, setFilters] = useState<Partial<BelatedEmittedBatchReportFilters>>({})
  const [sorters, setSorters] = useState<string>("")
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [downloadBatchReport, {isFetching}] = useLazyGetBelatedBatchReportsDownloadQuery()

  useEffect(() => {
    getBelatedBatchReports({page: 1, page_size: PAGE_SIZE})
  }, [getBelatedBatchReports])

  return <div className={className}>
    <Row style={{marginTop: '1rem', marginBottom: '1rem'}} justify={"space-between"} align={'middle'}>
      <Col xs={{offset: 1, flex: 1}} sm={{offset: 0}}>
        <div style={{display: "flex", flexWrap: 'nowrap'}}><Title level={3}>Storico importazione
          Pass</Title><InfoModal title={"Storico importazione Pass"} description={<Info/>}/></div>
      </Col>
      <Col xs={{offset: 1, flex: 1}} sm={{offset: 0, flex: 0}}>
        <Button disabled={isFetching} loading={isFetching} type='primary'
                onClick={() => {
                  downloadBatchReport({...filters, ...sorters && {ordering: sorters}})
                      .unwrap()
                      .then(() => message.success('Report scaricato correttamente.'))
                      .catch(() => message.error('Errore nel download del report.'))
                }}>
          Scarica
        </Button>
      </Col>
    </Row>
    <Table
        <BelatedEmittedBatchReport>
        columns={DEFAULT_COLUMNS.map(col => {
          if (col.key === 'negotiation') {
            return {...col,
              render: (uuid, record) => {
                if (uuid) {
                  return <ToNegotiationButton uuid={uuid}/>
                } else {
                  return <ReexecuteUnmountButton belatedEmitted={record.uuid} updateBelatedEmitted={(be) => {
                    if (originalArgs)
                      getBelatedBatchReports(originalArgs)
                  }}/>
                }
              },
            }} else {
              return col
            }
        })
        }
        rowKey={'uuid'}
        scroll={{x: true, scrollToFirstRowOnChange: true}}
        onChange={(pagination, filters, sorters, extra) => {
          const updatedCols = cloneDeep(DEFAULT_COLUMNS)
          const page = pagination.current || 1;
          let requestFilters: Partial<BelatedEmittedBatchReportFilters> = {}
          let requestSorter: string[] = []

          for (const [key, value] of Object.entries(filters)) {
            if (!isNil(value)) {
              switch (key) {
                case 'reference_date':
                  requestFilters.reference_date_from = value[0] as string
                  requestFilters.reference_date_to = value[1] as string
                  break;
                case 'state':
                  requestFilters[key] = value.join(',')
                  break;
              }
            }
          }

          if (Array.isArray(sorters)) {
            sorters.reverse().forEach((sorter) => {
              if (!isNil(sorter.order)) {
                let colToUpdate = updatedCols.find(col => col.key === sorter.columnKey)
                if (colToUpdate) {
                  if (sorter.order === 'ascend') {
                    requestSorter.push(sorter.field as string)
                  } else {
                    requestSorter.push('-' + sorter.field)
                  }
                }
              }


            })
          } else {
            if (sorters.order !== undefined) {
              if (sorters.order === 'ascend') {
                requestSorter.push(sorters.field as string)
              } else {
                requestSorter.push('-' + sorters.field)
              }
            }
          }
          setFilters(requestFilters)
          setSorters(requestSorter.join(','))
          getBelatedBatchReports({
            page,
            page_size: PAGE_SIZE,
            ordering: requestSorter.join(','), ...requestFilters
          })
        }}
        dataSource={data?.results}
        pagination={{total: data?.count, pageSize: PAGE_SIZE, simple: true}}
        expandable={{
          expandedRowRender: record => <Table
              <ProcessDescription>
              columns={EXPANDABLE_COLUMNS}
              dataSource={record.process_description?.map((pd, idx) => ({key: idx, ...pd})) || []}
              pagination={false}
          />,
          expandedRowKeys: expandedRowKeys,
          onExpand: (expanded, record) => {
            if (!expanded) {
              setExpandedRowKeys([])
            } else {
              setExpandedRowKeys([record.uuid])
            }
          }
        }}
    />
  </div>
}