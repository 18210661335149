import {Select, Spin, SelectProps} from "antd";
import React, {useCallback, useEffect} from "react";
import {debounce} from "lodash";
import {useLazyGetPaginatedBrokersQuery} from "../../../redux/api/brokersApiSlice";

export default function BrokerMultiSelect(props: SelectProps) {
    const [fetchBrokers, { data: brokers, isFetching: isBrokersFetching, isLoading: isBrokersLoading, isError: isBrokersError }] = useLazyGetPaginatedBrokersQuery()
    const debounceFetcher = useCallback(debounce( (searchTerm) => fetchBrokers({search: searchTerm}), 600 ), [])

    useEffect(() => {
        fetchBrokers({})
    }, [fetchBrokers])

    return <Select
        labelInValue
        mode={'multiple'}
        filterOption={false}
        showSearch
        onSearch={debounceFetcher}
        notFoundContent={ isBrokersLoading || isBrokersFetching ? <Spin size="small" /> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
        options={brokers?.results.map(el => ({label: el.name, value: el.uuid})) || []}
        virtual={false}
        placeholder={'Digitare il nome del broker...'}
        {...props}
    />
}


