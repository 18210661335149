import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Form, message, Modal, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {
    useAddAdditionalInsuredMutation,
    useLazyGetCandidatesAdditionalInsuredQuery
} from "../../redux/api/additionalInsured";
import AdditionalInsuredsCandidatesList from "./AdditionalInsuredsCandidatesList";
import {AdditionalInsured, AdditionalInsuredWithIndex} from "../../types/additionalInsureds";
import AutoPilotFormRevisited, {COUNTRY, NAME} from "./AutoPilotFormRevisited";
import ManualAdditionalInsuredForm from "./ManualAdditionalInsuredForm";

export default function ConnectAdditionalInsuredAndNegotiation({negotiationUuid, disabled}: {
    negotiationUuid?: string,
    disabled?: boolean
}) {
    const [getCandidates, {
        data: candidates,
        isFetching: isFetchingCandidates,
        isLoading: isLoadingCandidates,
        isUninitialized: isUninitializedGetCandidates,
    }] = useLazyGetCandidatesAdditionalInsuredQuery()

    const [isManualInsertion, setIsManualInsertion] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [associate, {isLoading: isLoadingAssociation}] = useAddAdditionalInsuredMutation()
    const [candidatesAssociated, setCandidatesAssociated] = useState<number[]>([])

    useEffect(() => {
        if (candidates) {
            setCandidatesAssociated([])
        }
    }, [candidates]);

    const handleAssociate = useCallback((candidate: AdditionalInsuredWithIndex | AdditionalInsured) => {
        return new Promise<void>(async (resolve, reject) => {
            if (negotiationUuid) {
                try {
                    await associate({negotiationUuid, data: candidate}).unwrap();
                    if ('idx' in candidate) {
                        setCandidatesAssociated(prevState => [...prevState, candidate.idx])
                    }
                    message.success('Assicurato addizionale associato alla trattativa');
                    resolve();
                } catch (err: any) {
                    console.log(err)
                    if ('status' in err && err.status === 400) {
                        let errorText: string[] = []
                        for (const [key, value] of Object.entries(err.data)) {
                            errorText.push(`${key}: ${value}`)
                        }
                        message.error(<div style={{textAlign: 'left'}}>
                            <p style={{fontWeight: 'bold'}}>Errore nell'inserimento dell'assicurato addizionale:</p>
                            <div style={{marginRight: '1rem'}}>
                                <ol style={{listStylePosition: 'inside'}}>
                                    {errorText.map((el, idx) => <li key={idx}>{el}</li>)}
                                </ol>
                            </div>
                        </div>)
                    } else {
                        message.error("Errore nell'inserimento dell'assicurato addizionale");
                    }
                    reject("Errore nell'inserimento dell'assicurato addizionale");
                }
            } else {
                reject("No negotiationUuid provided");
            }
        });
    }, [associate, negotiationUuid])

    const [autoPilotForm] = Form.useForm()
    const [manualForm] = Form.useForm()

    return <>
        <Modal
            open={isModalVisible}
            onCancel={() => {
                setIsManualInsertion(false)
                autoPilotForm.resetFields()
                manualForm.resetFields()
                setIsModalVisible(false)
            }}
            footer={false}
            title={"Inserimento assicurato addizionale"}
        >
            {!isManualInsertion && <>
                <AutoPilotFormRevisited
                    inputPlaceholder={"Ragione sociale / P.IVA assicurato addizionale"}
                    formProps={{
                        form: autoPilotForm,
                        disabled: isFetchingCandidates,
                        onFinish: (values) => negotiationUuid && getCandidates({
                            negotiationUuid,
                            country: values.country,
                            q: values.name
                        })
                    }}
                />
                <AdditionalInsuredsCandidatesList
                    candidates={candidates?.map((el, idx) => ({
                        ...el,
                        already_added: el.already_added || candidatesAssociated.some(candidate => candidate === idx),
                        idx
                    })) || []}
                    isLoading={
                        isLoadingCandidates ||
                        isFetchingCandidates ||
                        isLoadingAssociation
                    }
                    isUninitialized={isUninitializedGetCandidates}
                    btnLabel={"Seleziona"}
                    disabled={false}
                    onSelectedCandidate={handleAssociate}
                />
            </>}
            {isManualInsertion && (
                <ManualAdditionalInsuredForm
                    formProps={{
                        form: manualForm,
                        disabled: isLoadingAssociation,
                        onFinish: values => {
                            handleAssociate(values)
                                .then(() => setIsModalVisible(false))
                        }
                    }}
                />
            )}
            <Button
                disabled={isFetchingCandidates || isLoadingCandidates}
                type={'link'}
                style={{padding: 0, margin: '1rem 0'}}
                onClick={() => setIsManualInsertion(prevState => {
                    if (!prevState) {
                        manualForm.setFieldsValue({
                            [COUNTRY]: autoPilotForm.getFieldValue(COUNTRY) || 'IT',
                            ...!!(autoPilotForm.getFieldValue(COUNTRY) && autoPilotForm.getFieldValue(COUNTRY) !== 'IT') && {province: 'EE'},
                            [NAME]: autoPilotForm.getFieldValue(NAME),
                            vat_number: autoPilotForm.getFieldValue(NAME)
                        })
                    }
                    return !prevState
                })}
            >
                {isManualInsertion ? "Passa all'inserimento automatico" : "Non trovi quello che stai cercando? Passa all'inserimento manuale"}
            </Button>
        </Modal>
        <Row justify={'end'}>
            <Col>
                <Button
                    disabled={disabled}
                    onClick={() => {
                        setIsModalVisible(true)
                    }}
                    icon={<PlusOutlined/>}
                >
                    Aggiungi assicurato addizionale
                </Button>
            </Col>
        </Row>
    </>
}