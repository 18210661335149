import {AuditOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {ConflictOfInterestModal} from "./ConflictOfInterestModal";
import {NegotiationModel, UpdateNegotiationRequest} from "../../../../../types/negotiations";
import {Tooltip} from "antd";

export function ConflictOfInterest({negotiation, updateNegotiation}: {
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
}) {

  const [
    isOpen,
    setIsOpen
  ] = useState(false);

  return <>
    <Tooltip
      title={"Checklist conflitto d'interessi"}>
      <button
        className={'ant-btn'}
        style={{width: '100%'}}
        onClick={() => setIsOpen(true)}>
        <div style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
          <AuditOutlined style={{marginRight: '0.5rem'}}/>
          Conflitto
        </div>
      </button>
    </Tooltip>
    <ConflictOfInterestModal
      negotiation={negotiation}
      isOpen={isOpen}
      setIsOpen={(isNegotiationToUpdate) => {
        setIsOpen(false)
        if (isNegotiationToUpdate)
          updateNegotiation({})
      }}
    />
  </>
}