import {Col, Modal, Row} from "antd";
import {useEffect, useState} from "react";
import {TreeStructureModel} from "../../types/documents";

export default function PropertiesDocumentModal({
                                                    document,
                                                    options,
                                                    visible,
                                                    close,
                                                    onlyFiles
                                                }: {
                                                    document: any,
                                                    options: {label: string, value: string}[]
                                                    visible: boolean,
                                                    close: () => void,
                                                    onlyFiles: boolean
}) {
    const [dateCreation, setDateCreation] = useState<string>("")
    const [dateEdit, setDateEdit] = useState<string>("")
    const [folderName, setFolderName] = useState<string>("")

    useEffect(() => {
        const folder = options.find((el: any) => el.value === document.path)
        if (folder)
            setFolderName(folder.label)
    }, [document.path, options])

    useEffect(()=> {
        const dateM = new Date(document.dateEdit)
        const dateC = new Date(document.dateCreation)
        setDateEdit(dateM.toLocaleString())
        setDateCreation(dateC.toLocaleString())
    }, [document.dateEdit, document.dateCreation])

    return <Modal open={visible} onCancel={close} footer={false} title={"Proprietà documento"} width={700}>
        <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
            <Col span={10} style={{fontWeight: "bold"}}>Nome documento:</Col>
            <Col span={10}>{onlyFiles ? document.fileName : document.title}</Col>
        </Row>
        <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
            <Col span={10} style={{fontWeight: "bold"}}>Percorso:</Col>
            <Col span={10}>{folderName}</Col>
        </Row>
        <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
            <Col span={10} style={{fontWeight: "bold"}}>Data aggiunta:</Col>
            <Col span={10}>{dateCreation}</Col>
        </Row>
        <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
            <Col span={10} style={{fontWeight: "bold"}}>Aggiunto da:</Col>
            <Col span={10}>{document.userCreation}</Col>
        </Row>
        <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
            <Col span={10} style={{fontWeight: "bold"}}>Data ultima modifica:</Col>
            <Col span={10}>{dateEdit}</Col>
        </Row>
        <Row justify={"space-around"} align={"middle"} style={{marginBottom: "1em"}}>
            <Col span={10} style={{fontWeight: "bold"}}>Modificato da:</Col>
            <Col span={10}>{document.userEdit}</Col>
        </Row>
    </Modal>
}