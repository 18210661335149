import React, {useState} from "react";
import {EditOutlined} from "@ant-design/icons";
import {Button} from "antd";
import EditLobModal from "../../../../modals/EditLobModal";
import {NegotiationModel, UpdateNegotiationRequest} from "../../../../../types/negotiations";

const EditLobButton = (
  {negotiation, updateNegotiation}:
    {
      negotiation: NegotiationModel,
      updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
    }) => {

  const [
    isOpen,
    setIsOpen
  ] = useState(false);

  return (
    <>
      <EditLobModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        negotiation={negotiation}
        updateNegotiation={updateNegotiation}/>
      <Button
        type={'link'}
        size={'small'}
        icon={<EditOutlined/>}
        onClick={() => setIsOpen(true)}/>
    </>
  );
}

export default EditLobButton;