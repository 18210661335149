import {useLazyGetAtecoCodesQuery} from "../../redux/api/contractorsApiSlice";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import {
  ContractorModel,
  CreateCustomerModel
} from "../../types/contractors";
import {Button, Col, Divider, Form, Input, Row, Select} from "antd";
import {AntCurrencyFormatInput} from "../inputNumber";
import {DebounceSelect} from "../debounceSelect";
import {useCachedCountriesQuery} from "../../redux/api/countriesApiSlice";
import _ from 'lodash'

export default function ManualContractorForm({
                                               onSubmit,
                                               isSubmit,
                                               onClose,
                                               submitBtnLabel = 'Crea nuovo contraente',
                                               contractor
                                             }: {
  onSubmit?: (values: CreateCustomerModel) => void,
  isSubmit: boolean,
  onClose?: () => void,
  submitBtnLabel?: string,
  contractor?: Partial<ContractorModel>
}) {

  const [fetchAtecos, {isLoading: isAtecosLoading}] = useLazyGetAtecoCodesQuery()
  const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
  const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []
  const provinces = useSelector(selectOptions).provinces
  const [form] = Form.useForm()

  async function searchAtecos(text: string): Promise<{ label: string, value: string }[]> {
    try {
      const atecosSearchResult = await fetchAtecos({search: text}).unwrap()
      return atecosSearchResult.results.map(ateco => (
          {label: ateco.full_description || ateco.uuid, value: ateco.uuid}
      ))
    } catch (e: any) {
      console.error('Ateco search', e)
      return []
    }
  }

  const onCountryChange = () => {
    const {province, country} = form.getFieldsValue()
    if (country === 'IT' && province === 'EE') {
      form.setFieldValue('province', null)
    }
    if (country && country !== 'IT' && province !== 'EE') {
      form.setFieldValue('province', 'EE')
    }
  }

  const onProvinceChange = () => {
    const {province, country} = form.getFieldsValue()
    if (province === 'EE' && country === 'IT') {
      form.setFieldValue('country', null)
    }
    if (province && province !== 'EE' && country !== 'IT') {
      form.setFieldValue('country', 'IT')
    }
  }

  return <Form
      form={form}
      disabled={false}
      layout="vertical"
      onFinish={(values) => {
        if (onSubmit && values) {
          const parsedValues = _.omitBy(
              {
                ...values,
                activity: values.activity?.value || null,
              },
              _.isNil) as CreateCustomerModel
          onSubmit(parsedValues)
        }

      }}
      initialValues={{
        company_name: contractor?.name || null,
        piva: contractor?.vat_number || null,
        country: contractor?.country || 'IT',
        province: contractor?.province || null,
        year_income: contractor?.yearly_revenues || null,
        activity: contractor?.activity && contractor?.activity_full_description ? {
          label: contractor.activity_full_description,
          value: contractor.activity
        } : null
      }}
  >

    <Form.Item label="Nome contraente" name={'company_name'}
               rules={[{required: true, message: 'Il nome del contraente è obbligatorio'}]}>
      <Input/>
    </Form.Item>
    <Form.Item label="Partita IVA" name={'piva'} rules={[{required: true, message: 'La partita IVA è obbligatoria'}, {
      max: 16,
      message: 'La partita IVA è più lunga di 16 caratteri'
    }]}
               required>
      <Input maxLength={16}/>
    </Form.Item>
    <Row justify={'space-between'}>
      <Col xs={24} md={11}>
        <Form.Item label={"Indirizzo"} name={'address'}>
          <Input/>
        </Form.Item>
      </Col>
      <Col xs={24} md={11}>
        <Form.Item label={"Civico"} name={'street_number'}>
          <Input/>
        </Form.Item>
      </Col>
    </Row>
    <Row justify={'space-between'}>
      <Col xs={24} md={11}>
        <Form.Item label={"Città"} name={'city'}>
          <Input/>
        </Form.Item>
      </Col>
      <Col xs={24} md={11}>
        <Form.Item label={"Cap"} name={'postcode'}>
          <Input/>
        </Form.Item>
      </Col>
    </Row>
    <Row justify={'space-between'}>
      <Col xs={24} md={11}>
        <Form.Item label={"Nazione"} name={'country'} rules={[{required: true, message: 'La nazione è obbligatoria'}]}>
          <Select
              optionFilterProp={'label'}
              showSearch
              options={countries}
              loading={isLoadingCountries}
              virtual={false}
              onChange={onCountryChange}
          />
        </Form.Item>
      </Col>
      <Col xs={24} md={11}>
        <Form.Item label={"Provincia"} name={'province'}
                   rules={[{required: true, message: 'La provincia è obbligatoria'}]}>
          <Select
              virtual={false}
              options={provinces.map(el => ({value: el.value, key: el.value, label: el.text}))}
              showSearch
              filterOption={(input, option) => {
                return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
              }}
              onChange={onProvinceChange}
          />
        </Form.Item>
      </Col>
    </Row>
    <Form.Item label={"Fatturato"} name={'year_income'}>
      <AntCurrencyFormatInput/>
    </Form.Item>
    <Form.Item label="Descrizione attività" name={'activity'}
               rules={[{required: true, message: "L'attività è obbligatoria"}]}>
      <DebounceSelect
          virtual={false}
          showSearch
          placeholder="Comincia a digitare per cercare un attività.. (nome o ateco) "
          fetchOptions={searchAtecos}
          style={{width: '100%'}}
      />
    </Form.Item>
    <Row style={{marginBottom: '1.5rem'}}><p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span
        style={{color: '#ff4d4f'}}>*</span> sono obbligatori</p></Row>
    <Divider/>
    <Row justify={'space-between'}>
      <Col>{!!onClose && <Button type={"default"} onClick={() => onClose()}>Annulla</Button>}</Col>
      <Col>
        <Form.Item>
          <Button
              loading={isSubmit}
              disabled={isSubmit}
              htmlType={"submit"}
              type={'primary'}>{submitBtnLabel}</Button>
        </Form.Item>
      </Col>
    </Row>
  </Form>
}