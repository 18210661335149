import HighchartsReact from 'highcharts-react-official';
import CommonChart from "./CommonChart";
import {numeric} from "../../../utils/formatters";
import React from "react";
import {Options} from "highcharts";


const PortfolioHistogram = ({options, chartRef}: {options: Options, chartRef: React.RefObject<HighchartsReact.RefObject>}) => {
    return <>
        <CommonChart
            chartRef={chartRef}
            options={{
                xAxis: {
                    type: "category",
                    visible: true,
                    lineWidth: 0
                },
                yAxis: {
                    title: {
                        text: 'Numero trattative'
                    },
                    visible: false
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    pointFormatter: function () {
                        const value = numeric(this.y ? this.y : 0)
                        const symbol = this.color ? `<span style='color: ${this.color}'>●</span>` : ''
                        return `${symbol} <span style='font-weight: bolder'>${value}</span>`
                    },
                    useHTML: true

                },
                ...options
            }}
        />
    </>;
};

export default PortfolioHistogram