import { apiSlice } from "redux/api/baseApiSlice";
import { advancedSearchData } from "../../types/advancedSearchData";

export const advancedSearch = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAdvancedSearch: builder.query<advancedSearchData[], {instruction?: string}>({
            query: (param) => {
              return {
                url: '/negotiation/advancedsearch/',
                method: 'POST',
                body: { "text": param.instruction },
              };
            },
    }),
  }),
})
export const { useLazyGetAdvancedSearchQuery } = advancedSearch