import {States} from "../../../../../types";
import {EditNegotiationStatusModal} from "./state";
import React, {useState} from "react";
import {NegotiationModel, UpdateNegotiationRequest} from "../../../../../types/negotiations";
import {FormOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {ChecklistFormDialog} from "./checklist";

const EditNegotiationModalButton = ({
                                      negotiation,
                                      updateNegotiation,
                                      handleCreateStateTransition,
                                      isStateTransitionInProgress,
                                    }: {
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => Promise<NegotiationModel | undefined>
  handleCreateStateTransition: (data: {
    state_to: number,
    reason?: string,
    notes?: string
  }, newNegotiation?: NegotiationModel) => Promise<void>,
  isStateTransitionInProgress: boolean,
}) => {

  const [
    isChecklistOpen,
    setIsChecklistOpen
  ] = useState<boolean>(false);

  const [
    isEditNegotiationStatusModalOpen,
    setIsEditNegotiationStatusModalOpen
  ] = useState<boolean>(false);

  return (
    <>
      <ChecklistFormDialog
        isOpen={isChecklistOpen}
        onClose={(isNegotiationToUpdate) => {
          setIsChecklistOpen(false)
          if (isNegotiationToUpdate)
            updateNegotiation({})
        }}
        negotiation={negotiation}
        updateNegotiation={updateNegotiation}/>
      {/*show all states apart from referred (which has a dedicated button)*/}
      <EditNegotiationStatusModal
        handleCreateStateTransition={handleCreateStateTransition}
        isCreateStateTransitionLoading={isStateTransitionInProgress}
        availableStates={negotiation.available_state_transitions?.filter(state => state.state !== States.Referred)}
        isOpen={isEditNegotiationStatusModalOpen}
        negotiation={negotiation}
        onClose={() => setIsEditNegotiationStatusModalOpen(false)}
        openChecklist={()=>setIsChecklistOpen(true)}
        isAutoRenewal={negotiation.is_auto_renewal}
        currentState={negotiation.state}
        updateNegotiation={updateNegotiation}/>
      <Button
        block
        type='primary'
        icon={<FormOutlined/>}
        disabled={isStateTransitionInProgress}
        loading={isStateTransitionInProgress}
        onClick={() => setIsEditNegotiationStatusModalOpen(true)}>
        Modifica stato trattativa
      </Button>
    </>
  );
}

export default EditNegotiationModalButton;