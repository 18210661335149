import {FilterDropdownProps} from "antd/lib/table/interface";
import FilterContainer from "./FilterContainer";
import {Select, Spin} from "antd";
import {LabeledValue} from "antd/lib/select";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";
import {useLazyGetBankBranchAreaQuery} from "../../../redux/api/commercialDetailApiSlice";
import React, {useCallback, useEffect} from "react";
import {debounce} from "lodash";

export default function BankBranchAreaFilter(props: FilterDropdownProps) {
    const [getBankBranchArea, {data: bankBranchAreaData, isFetching: isBankBranchAreaFetching, isLoading: isBankBranchAreaLoading}] = useLazyGetBankBranchAreaQuery()
    const debounceFetcher = useCallback(debounce( (searchTerm) => getBankBranchArea({search: searchTerm}), 600 ), [])

    useEffect(()=> {
        getBankBranchArea({})
    }, [getBankBranchArea])

    // @ts-ignore
    // @ts-ignore
    return <FilterContainer {...props}>
        <Select
            labelInValue
            options={bankBranchAreaData?.results.map((el: any) => ({label: el.name, value: el.uuid})) || []}
            notFoundContent={ isBankBranchAreaLoading || isBankBranchAreaFetching ? <Spin size="small" /> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
            onSearch={debounceFetcher}
            mode={"multiple"}
            showSearch={true}
            placeholder={"Seleziona un area commerciale"}
            filterOption={false}
            style={{width: "20rem"}}
            virtual={false}
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.reverse().map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            // @ts-ignore
            value={props.selectedKeys}
        />
    </FilterContainer>
}