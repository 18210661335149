import {DeleteOutlined} from "@ant-design/icons";
import {Button, message} from "antd";
import React, {useEffect, useState} from "react";
import ConfirmCancelModal from "../../../../modals/ConfirmCancelModal";
import {useNavigate} from "react-router-dom";
import {useArchiveNegotiationMutation} from "../../../../../redux/api/negotiationsApiSlice";
import {NegotiationModel} from "../../../../../types/negotiations";

const DeleteNegotiationModalButton = ({negotiation}: { negotiation: NegotiationModel }) => {

  const navigate = useNavigate();

  const [
    isDeleteNegotiationModalOpen,
    setIsDeleteNegotiationModalOpen
  ] = useState<boolean>(false);
  const [
    errorMessage,
    setErrorMessage] = useState<string>('');


  const [archiveNegotiation] = useArchiveNegotiationMutation();

  useEffect(() => {
    if (isDeleteNegotiationModalOpen) {
      setErrorMessage('')
    }
  }, [isDeleteNegotiationModalOpen])


  return (
    <>
      <ConfirmCancelModal
        title={<p style={{color: "red"}}>Eliminazione trattativa</p>}
        open={isDeleteNegotiationModalOpen}
        okText={'Elimina'}
        okButtonProps={{danger: true}}
        onCancel={() => setIsDeleteNegotiationModalOpen(!isDeleteNegotiationModalOpen)}
        onOk={() => {
          archiveNegotiation({uuid: negotiation.uuid})
            .unwrap()
            .then((payload) => {
              message.success('Trattativa eliminata con successo.');
              navigate('negotiations')
            })
            .catch((error) => {
              message.error("L'eliminazione della trattativa non è andata a buon fine.")
              if (error.data?.message)
                setErrorMessage(error.data.message)
            })

        }}
      >
        <p>L'eliminazione di una trattativa comporta la perdita di tutti i suoi dati. Vuoi davvero eliminare la
          trattativa corrente?</p>
        {errorMessage && <p style={{color: 'red'}}>Error: {errorMessage}</p>}
      </ConfirmCancelModal>
      <Button
        style={{width: '100%'}}
        danger icon={<DeleteOutlined/>}
        onClick={() => setIsDeleteNegotiationModalOpen(!isDeleteNegotiationModalOpen)}>
        Elimina trattativa
      </Button>
    </>
  );
}

export default DeleteNegotiationModalButton;