import React, {useRef} from "react";
import {Card, Row, Space} from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {skipToken} from "@reduxjs/toolkit/query";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import {euro} from "../../../utils/formatters";
import {useBankBranchChartsParamsContext} from "../../../context/BankBranchChartsContext";
import {useGetBBBTotalNegotiationsQuery} from "../../../redux/api/chartsApiSlice";
import {TotalNegotiationsChartParams} from "../../../types/charts";
import BBBChartCardContainer from "../BBBChartCardContainer";
import {selectUser} from "../../../redux/features/userSlice";

export default function TotalBankBranchesPremiums() {
    const ref = useRef<HighchartsReact.RefObject>(null);

    const {params} = useBankBranchChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isCommonParamsOk = !!(params.budget_group && params.bank_branch_uuid && typeof uwYear === 'number')

    const {data, isFetching, isUninitialized} = useGetBBBTotalNegotiationsQuery(isCommonParamsOk ?
        {
        uw_year: uwYear,
        entity: 1,
        entity_uuid: params.bank_branch_uuid,
        budget_group: params.budget_group,
        calculation_type: 2,
        negotiations_type: 3,
    } : skipToken)
    const lastIndex = data?.history.length ? data?.history.length - 1 : undefined
    const previousYearsData = data && typeof lastIndex === 'number' ? data?.history.slice(0, lastIndex) : []
    const currentYearData = data && typeof lastIndex === 'number' ? data.history[lastIndex] : undefined
    const options = useSelector(selectOptions)

    const values: number[] = []
    const colors: string[] = []
    const categories: string[] = []
    data?.result.forEach(el => {
        const state = options.states.find(state => state.value === el.state)
        if (state) {
            values.push(el.amount)
            colors.push(state.color)
            categories.push(state.text)
        }
    })

    return <BBBChartCardContainer loading={isFetching || isUninitialized}>
        <Row justify={"space-between"}>
            <h2>Premi totali</h2>
            <Space>
                {previousYearsData.map(el => <div key={el.year}>
                    {el.year}: <strong>{euro(el.amount)}</strong>
                </div>)}
            </Space>
        </Row>
        {!!currentYearData && <Row justify={"end"}>
            <div style={{fontSize: "18px"}}>
                {currentYearData?.year}: <strong>{euro(currentYearData?.amount || 0)}</strong>
            </div>
        </Row>}
        <HighchartsReact
            highcharts={Highcharts}
            ref={ref}
            options={{
                chart: {
                    type: 'column',
                    height: "45%"
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ""
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                credits: undefined,
                xAxis: {
                    categories: categories,
                    crosshair: true,
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    }
                },
                tooltip: {
                    formatter: function (): string {
                        // @ts-ignore
                        const value = euro(this.y || 0)
                        // @ts-ignore
                        return `<span>${this.x}</span>: <span style='font-weight: bolder'>${value}</span>`
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        borderRadius: 5,
                        colorByPoint: true,
                        dataLabels: {
                            enabled: true,
                            formatter: function (): string {
                                // @ts-ignore
                                return euro(this.y || 0)
                            }
                        }
                    }
                },
                series: [
                    {
                        name: 'Premi',
                        data: values,
                        colors,
                    }
                ]
            }}
        />
    </BBBChartCardContainer>
}