import {Button, Col, Divider, Form, Row, Select, Upload, UploadFile} from "antd";
import ConfirmCancelModal from "components/modals/ConfirmCancelModal";
import {CloseCircleOutlined, UploadOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import DocListItem from "./DocListItem";

export type UploadDoc = UploadFile & { dirType: string }

export default function UploadDocumentModal({
                                              upload,
                                              visible,
                                              close,
                                              selectOptions,
                                            }: {
  upload: (files: UploadDoc[], realFiles: any) => void,
  visible: boolean,
  close: () => void,
  selectOptions: { label: string, value: string }[] | undefined
}) {

  const [form] = Form.useForm()
  const [isAddingDocumentButtonDisabled, setIsAddingDocumentButtonDisabled] = useState(true)
  const [isUploadingButtonDisabled, setIsUploadingButtonDisabled] = useState(true)
  const [directoryType, setDirectoryType] = useState<string | undefined>(undefined)
  const [docList, setDocList] = useState<UploadDoc[]>([]);
  const [realFiles, setRealFiles] = useState<File[]>([]);
  const [filesTooLong, setFilesTooLong] = useState<string[]>([])
  const maxFileSize = 50 * 1024 * 1024 // 50 MB

  function handleUpload(files: any) {
    const filesToUpload = files.filter((el: any) => el.size <= maxFileSize)
    let newFiles = filesToUpload.map((el: any) => el.originFileObj)
    setRealFiles([...realFiles, newFiles]);
  }

  const handleFormValuesChange = (changedValues: any) => {
    const fieldName = Object.keys(changedValues)[0];
    if (fieldName === "docType") {
      form.setFieldValue("dirType", undefined)
      form.resetFields(["dirType"])
    }
  }
  return <ConfirmCancelModal
    title={'Upload documenti'}
    open={visible}
    onOk={() => {
      upload(docList, realFiles)
      setDocList([])
      setRealFiles([])
      setIsAddingDocumentButtonDisabled(true)
      setIsUploadingButtonDisabled(true)
      setFilesTooLong([])
      form.resetFields()
      close()
    }}
    destroyOnClose
    width={700}
    okButtonProps={{htmlType: 'submit', disabled: isUploadingButtonDisabled || docList.length === 0}}
    okText={'Upload'}
    onCancel={() => {
      setDocList([])
      form.resetFields()
      setDirectoryType(undefined)
      setIsAddingDocumentButtonDisabled(true)

      setIsUploadingButtonDisabled(true)
      setFilesTooLong([])
      close()
    }}
  >
    {docList.length !== 0 &&
      <Row justify={"center"} align={"middle"} style={{marginBottom: "15px"}}><h3>{"Documenti da caricare:"} </h3>
      </Row>}
    <Form
      form={form}
      labelWrap={true}
      labelAlign={"left"}
      style={{alignItems: "space-between"}}
      onValuesChange={handleFormValuesChange}
      onFieldsChange={() => {
        setDirectoryType(form.getFieldValue('dirType'))
        setIsAddingDocumentButtonDisabled(!(form.isFieldsTouched(true)) || form.getFieldsError().some(({errors}) => errors.length))
      }}
    >
      {docList.length === 0 && <Row align='middle' justify='center'>
        <Col style={{width: '90%', marginTop: '1rem', marginBottom: '1rem'}}>
          <Form.Item
            labelCol={{md: 6}}
            name={'dirType'}
            label={'Seleziona cartella'}
          >
            <Select
              virtual={false}
              className={'custom-select-documents'}
              style={{width: '100%'}}
              options={selectOptions}
            />
          </Form.Item>
        </Col>
      </Row>}
      <Row align='middle' justify='center' style={{display: `${directoryType ? "" : "none"}`}}>
        <Col style={{width: '90%'}}>
          <Upload
            //image, .pdf, .doc, .docx, .docm, .zip, .xls, .xlsx, xlsm, .eml, .msg, .dwg, .odt, .ods firmed-mail
            accept={"image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-word.document.macroEnabled.12, application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12, message/rfc822, application/vnd.ms-outlook, application/x-msg, application/octet-stream, application/acad, application/vnd.oasis.opendocument.text, application/vnd.oasis.opendocument.spreadsheet, application/pkcs7-mime, application/x-pkcs7-mime"}
            className={'upload-doc'}
            disabled={isAddingDocumentButtonDisabled}
            multiple={true}
            onChange={(info) => {
              handleUpload(info.fileList);
            }}
            onRemove={file => {
              const index = docList.findIndex(el => el.uid === file.uid);
              const newFileList = docList.slice();
              newFileList.splice(index, 1);
              setDocList(newFileList);
            }}
            beforeUpload={(file, fileList) => {
              let _file = {
                ...file as object,
                name: file.name,
                fileName: file.name,
                dirType: directoryType
              } as unknown as UploadDoc;
              form.resetFields()
              setIsAddingDocumentButtonDisabled(true)
              const isBelowMaxSize = file.size <= maxFileSize;
              if (!isBelowMaxSize) {
                setFilesTooLong(prevState => {
                  return [...prevState, file.name]
                })
              } else
                setDocList(prevState => [...prevState, _file])
              return false
            }}
            fileList={docList}
            itemRender={(originNode, file, fileList, actions) => {
              const index = docList.findIndex(el => el.uid === file.uid);
              return <DocListItem selectOptions={selectOptions} file={file} index={index}
                                  disabledButton={isUploadingButtonDisabled}
                                  setDisabledButton={setIsUploadingButtonDisabled}
                                  fileList={fileList}
                                  remove={actions.remove} dirType={directoryType} setDocList={setDocList}/>
            }}
          >
            {docList.length === 0 && <Button
              style={{width: '100%', marginTop: "10px", border: "1px dashed"}}
              disabled={isAddingDocumentButtonDisabled}
              icon={<UploadOutlined/>}
            >
              Seleziona o trascina documenti
            </Button>}
          </Upload>
          {filesTooLong.length !== 0 && <div>
            <Divider/>
            <Row justify={"center"} style={{marginBottom: "15px"}}><h3>I seguenti documenti non verranno caricati</h3>
            </Row>
            {filesTooLong.map(el => {
              return <Row justify={"space-between"}>
                <Col span={12}>
                  <div className={"ellipsis-name"}>{el}</div>
                </Col>
                <Col span={12}>
                  <div className={"ellipsis-name"}><CloseCircleOutlined style={{color: "red", marginRight: "5px"}}/>Dimensione
                    massima superata
                  </div>
                </Col>
              </Row>
            })}
          </div>}
        </Col>
      </Row>
    </Form>

  </ConfirmCancelModal>
}