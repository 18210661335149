import React, {useEffect, useMemo, useState} from 'react';

import {Card, Col, Form, Input, message, Modal, Radio, Row, Select, Space, Tooltip, Typography} from 'antd';

import DatePicker from 'components/DatePicker'
import {CommonFormComponentProps} from "types/negotiations/components";
import {NegotiationUserModel} from "types/negotiations";
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";

import {InfoCircleOutlined, MailOutlined, WarningOutlined} from '@ant-design/icons';
import {selectNegotiationUsers} from "redux/features/negotiationUsersSlice";
import {useLazyGetNegotiationAttorneysQuery} from "redux/api/negotiationUsersApiSlice";
import {AntCurrencyFormatInput} from "components/inputNumber";
import {BrokerSelect} from "../selects";
import {checkStateCorrectForWarningPolicyFields, percentage} from "../../../utils/formatters";
import dayjs from "dayjs";
import WarningTitleButton from "../../buttons/WarningTitleButton";

const {Title, Paragraph} = Typography
const {Option} = Select

export const EffectiveDateInfo = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <InfoCircleOutlined onClick={showModal}/>
            <Modal title={'Date di decorrenza (polizza e pro-rata)'}
                   open={isModalOpen} onCancel={handleCancel} centered={true}
                   footer={null}>
                <Paragraph>
                    Nella scelta delle date di decorrenza della polizza e del pro-rata, tenere presente che:
                    <ul>
                        <li>La data di decorrenza della polizza non deve considerare il periodo di pro-rata;</li>
                        <li>La data di decorrenza del pro-rata è antecedente alla data di decorrenza della polizza;</li>
                        <li>La durata del pro-rata non può superare l'anno.</li>
                    </ul>
                    Esempio:
                    <ul>
                        <li>Data di decorrenza della polizza: {dayjs('2022-12-31').format('DD/MM/YYYY')};</li>
                        <li>Data di scadenza della polizza: {dayjs('2023-12-31').format('DD/MM/YYYY')};</li>
                        <li>Data di decorrenza del pro-rata: {dayjs('2022-11-30').format('DD/MM/YYYY')}.</li>
                    </ul>
                </Paragraph>
            </Modal>
        </>
    );
}

export const ProRataError = ({effectiveDate, prorataEffectiveDate}: {
    effectiveDate: dayjs.Dayjs | null | undefined,
    prorataEffectiveDate: dayjs.Dayjs | null | undefined
}) => {
    let text = ''
    if (!!effectiveDate && !!prorataEffectiveDate && (prorataEffectiveDate > effectiveDate)) {
        text = 'La decorrenza della pro-rata è successiva alla decorrenza della polizza'
    } else if (!!effectiveDate && !!prorataEffectiveDate && effectiveDate.diff(prorataEffectiveDate, 'year', true) > 1) {
        text = 'La durata della pro-rata è superiore a un anno'
    }
    return <span style={{color: 'red'}}>{text}</span>
}

export default function SubmissionForm({
                                           forwaredRef,
                                           formInstance,
                                           negotiation,
                                           onFormValuesChange,
                                           setCalculateField,
                                           disabled,
                                           saveData,
                                       }: CommonFormComponentProps) {

    const [getAttorneys, {isLoading: isAttorneysLoading}] = useLazyGetNegotiationAttorneysQuery()
    const [uwAttorneys, setUwAttorneys] = useState<NegotiationUserModel[]>([])
    const [disabledPolicyFields, setDisabledPolicyFields] = useState({
        policy_effective_date: true,
        policy_expiring_date: true,
        prorata_effective_date: true,
        quoted_premium: true,
        isa_share_perc: true,
        has_prorata: true,
    })

    const options = useSelector(selectOptions)
    const userProfiles = useSelector(selectNegotiationUsers)

    const watchIsia = Form.useWatch('is_isia', formInstance);
    const watchUw = Form.useWatch('underwriter', formInstance)
    const watchProrata = Form.useWatch('has_prorata', formInstance)
    const watchEffectiveDate = Form.useWatch('policy_effective_date', formInstance)
    const watchProrataEffectiveDate = Form.useWatch('prorata_effective_date', formInstance)
    const isStateCorrectForWarningPolicyFields = checkStateCorrectForWarningPolicyFields(negotiation.state)

    useEffect(() => {
        async function fetchAttorneys({negotiation_id}: { negotiation_id: string }) {
            try {
                const attorneys = await getAttorneys({negotiation_id}).unwrap()
                setUwAttorneys(attorneys)
            } catch (e: any) {
                message.error('Impossibile recuperare le procure')
                console.error('Fetch attroneys', e)
                setUwAttorneys([])
            }
        }

        if (negotiation.uuid) {
            if (uwAttorneys.length === 0) {
                fetchAttorneys({negotiation_id: negotiation.uuid})
            }
        } else
            setUwAttorneys([])

        // if underwriter changes => update attorneys
    }, [getAttorneys, negotiation.uuid, negotiation.underwriter, uwAttorneys.length])

    useEffect(() => {
        if (watchProrata === false) {
            formInstance.resetFields(['prorata_isa_share', 'prorata_effective_date'])
        }
    }, [formInstance, watchProrata])

    const uwAttorneysChoices = useMemo(() => {

        const choices = uwAttorneys.map(uw => <Option key={uw.uuid} value={uw.uuid}>{uw.user_full_name}</Option>)
        const isUwAlreadyPresentInTheChoices = negotiation.attorney_from === null || uwAttorneys.some(el => el.uuid === negotiation.attorney_from?.uuid)
        // if the uw associated to the negotiation is no more active, add the option to the select
        if (!isUwAlreadyPresentInTheChoices) {
            choices.push(<Select.Option key={negotiation.attorney_from?.uuid}
                                        value={negotiation.attorney_from?.uuid}>{negotiation.attorney_from?.user_full_name}</Select.Option>)
        }
        return <>{choices}</>
    }, [negotiation.attorney_from, uwAttorneys])

    return (
        <Card ref={forwaredRef} id="submission" bordered={false} style={{
            // padding: '24px',
            borderRadius: '12px',
            width: '100%'
        }}>
            <Form
                form={formInstance}
                disabled={disabled}
                name='submission'
                layout="vertical"
                requiredMark={false}
                onValuesChange={onFormValuesChange}
            >
                <Row style={{marginBottom: '24px'}}>
                    <Col>
                        <Title level={3}>
                            <MailOutlined/>
                        </Title>
                    </Col>
                    <Col style={{marginLeft: '12px'}}>
                        <Title level={3}>Submission</Title>
                    </Col>
                </Row>

                {/* HIDDEN FORM ITEM */}
                <Form.Item name={'is_calculated'} hidden>
                    <Input disabled/>
                </Form.Item>
                {/* HIDDEN FORM ITEM */}
                <Form.Item label="LOB" name='lob' hidden={true}>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona una LOB"}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {options.lobs.map(lob => <Option key={lob.uuid} value={lob.uuid}>{lob.name}</Option>)}
                    </Select>
                </Form.Item>
                {/* HIDDEN FORM ITEM */}
                <Form.Item label='Underwriter' name='underwriter' hidden={true}>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona un underwriter"}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {negotiation.lob.underwriters.map(uw => <Option key={uw.uuid}
                                                                        value={uw.uuid}>{uw.user_full_name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label="Procura utilizzata" name='attorney_from'>
                    <Select
                        virtual={false}
                        disabled={!watchUw}
                        showSearch
                        placeholder={disabled ? "" : watchUw ? "Seleziona una procura" : "Seleziona prima un underwriter"}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {uwAttorneysChoices}
                    </Select>
                </Form.Item>

                <Form.Item label="ISIA" name={['is_isia']}>
                    {/* <Radio.Group onChange={onChange} value={value}> */}
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Segnalazione ISIA" name={['isia_type']} hidden={!watchIsia}>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona un tipo di segnalazione"}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {options.isiaTypes.map(type => <Option key={type.value}
                                                               value={type.value}>{type.text}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={watchIsia ? "Sale ISIA" : "Sale"} name={['isia_sale']}>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona un utente"}
                        optionFilterProp="children"
                        allowClear
                        filterOption={(input, option) =>
                            option?.children ? (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase()) : false
                        }
                    >
                        {userProfiles.filter(user => user.usertypes.find(type => type.code === 'SA' || type.code === 'AA')).map(user =>
                            <Option key={user.uuid} value={user.uuid}>{user.user_full_name}</Option>)}
                    </Select>
                </Form.Item>
                <BrokerSelect
                    fieldName="broker"
                    label='Broker'
                    disabled={disabled}
                    onCreation={(newBrokerId: string) => saveData({broker: newBrokerId})}
                    {...!!negotiation.broker && {selectedBroker: negotiation.broker}}
                />
                <Row align='bottom' justify='space-between'>
                    <Col xs={24} md={watchIsia ? 7 : 11}>
                        <Form.Item name='fee_broker_perc' label="Provvigioni broker">
                            <AntCurrencyFormatInput hasPlaceholder={!disabled} suffix={' %'} decimalScale={6}/>
                        </Form.Item>
                    </Col>
                    <Col xs={!watchIsia ? 0 : 24} md={!watchIsia ? 0 : 7}>
                        <Form.Item name='fee_agency_perc' label="Provvigioni agenzia" hidden={!watchIsia}>
                            <AntCurrencyFormatInput hasPlaceholder={!disabled} suffix={' %'}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={watchIsia ? 7 : 11}>
                        <Tooltip placement='top'
                                 title={formInstance.getFieldValue('is_calculated')?.fee_total_perc ? watchIsia ? ' Provvigioni broker + Provvigioni agenzia' : 'Provvigioni broker' : null}>
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.fee_total_perc !== curValues.is_calculated?.fee_total_perc}
                                style={{margin: 0, padding: 0}}>
                                {() => <Form.Item name='fee_total_perc' label="Provvigioni totali Diretto">
                                    <AntCurrencyFormatInput
                                        suffix={' %'}
                                        hasPlaceholder={!disabled}
                                        disabled={formInstance.getFieldValue('is_calculated')?.fee_total_perc}
                                        calculate={() => setCalculateField('fee_total_perc')}
                                        decimalScale={6}
                                    />
                                </Form.Item>}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    {((negotiation.contractor?.bank_reporting_type !== "NO") && negotiation.is_new_business) &&
                        <Col xs={24} md={watchIsia ? 7 : 11}>
                            <Form.Item name='fee_bank_perc' label="Provvigioni segnalazione banca">
                                <AntCurrencyFormatInput hasPlaceholder={!disabled} suffix={' %'}/>
                            </Form.Item>
                        </Col>}
                </Row>
                <Form.Item label="Account Executive Broker" name={['broker_account_executive']}>
                    <Input placeholder={disabled ? "" : "Account executive broker"}/>
                </Form.Item>
                <Form.Item label="Branch" name='broker_branch'>
                    <Select
                        virtual={false}
                        showSearch
                        placeholder={disabled ? "" : "Seleziona un broker branch"}
                        filterOption={(input, option) => {
                            return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
                        }}
                        options={options.provinces.map(province => ({
                            key: province.value,
                            value: province.value,
                            label: province.text
                        }))}
                    />
                </Form.Item>

                <Form.Item label="LPS" name='is_lps'>
                    <Radio.Group>
                        <Radio value={true}>Si</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>
                <Row align='bottom' justify='space-between'>
                    <Col xs={24} md={11}>
                        <Form.Item label="Data ricezione Submission" name='reception_date'>
                            <DatePicker
                                clearIcon={false}
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder={disabled ? "" : 'Seleziona una data'}
                                format={'DD/MM/YYYY'}/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item
                            name='policy_effective_date'
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={<Space>Data decorrenza{!!watchProrata && <EffectiveDateInfo/>}</Space>}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            policy_effective_date: !prevState.policy_effective_date
                                        }))}
                                        isEditing={!disabledPolicyFields.policy_effective_date}
                                    />
                                    :
                                    <Space>Data decorrenza{!!watchProrata && <EffectiveDateInfo/>}</Space>
                            }>
                            <DatePicker
                                clearIcon={false}
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder={disabled ? "" : 'Seleziona una data'}
                                format={'DD/MM/YYYY'}
                                disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.policy_effective_date}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item
                            name='policy_expiring_date'
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={"Data scadenza"}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            policy_expiring_date: !prevState.policy_expiring_date
                                        }))}
                                        isEditing={!disabledPolicyFields.policy_expiring_date}
                                    />
                                    : "Data scadenza"
                            }
                        >
                            <DatePicker
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder={disabled ? "" : 'Seleziona una data'}
                                format={'DD/MM/YYYY'}
                                disabled={!watchEffectiveDate || (isStateCorrectForWarningPolicyFields && disabledPolicyFields.policy_expiring_date)}
                                disabledDate={(current) => !!(!watchEffectiveDate || !current.isAfter(watchEffectiveDate, 'day'))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item label="UW Year" name='uw_year'>
                            <DatePicker style={{width: '100%'}} placeholder={disabled ? "" : "Seleziona un anno"}
                                        picker='year' disabled/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item
                            name='quoted_premium'
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={'Premio imponibile 100%'}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            quoted_premium: !prevState.quoted_premium
                                        }))}
                                        isEditing={!disabledPolicyFields.quoted_premium}
                                    />
                                    :
                                    'Premio imponibile 100%'
                            }
                        >
                            <AntCurrencyFormatInput
                                hasPlaceholder={!disabled}
                                disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.quoted_premium}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item
                            name='isa_share_perc'
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={'Quota ISA'}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            isa_share_perc: !prevState.isa_share_perc
                                        }))}
                                        isEditing={!disabledPolicyFields.isa_share_perc}
                                    />
                                    :
                                    'Quota ISA'
                            }
                        >
                            <AntCurrencyFormatInput
                                hasPlaceholder={!disabled}
                                disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.isa_share_perc}
                                suffix=" %"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Tooltip placement='top'
                                 title={formInstance.getFieldValue('is_calculated')?.isa_quoted_premium_share ? 'Premio imponibile 100% * Quota ISA' : null}>
                            <Form.Item
                                shouldUpdate={(prevValues, curValues) => prevValues.is_calculated?.isa_quoted_premium_share !== curValues.is_calculated?.isa_quoted_premium_share}
                                style={{margin: 0, padding: 0}}>
                                {() => <Form.Item
                                    name='isa_quoted_premium_share'
                                    label={formInstance.getFieldValue('is_calculated')?.isa_quoted_premium_share ?
                                        'Premio imponibile quota ISA' :
                                        <Tooltip
                                            title={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}>
                                            <Space>
                                                Premio imponibile quota ISA
                                                <WarningOutlined style={{color: 'red'}}/>
                                            </Space>
                                        </Tooltip>}
                                >
                                    <AntCurrencyFormatInput
                                        hasPlaceholder={!disabled}
                                        disabled={formInstance.getFieldValue('is_calculated')?.isa_quoted_premium_share}
                                        calculate={() => setCalculateField('isa_quoted_premium_share')}
                                    />
                                </Form.Item>}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row justify={'space-between'} wrap={true}>
                    <Col xs={24}>
                        <Form.Item
                            name={'has_prorata'}
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={'Pro-rata'}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            has_prorata: !prevState.has_prorata
                                        }))}
                                        isEditing={!disabledPolicyFields.has_prorata}
                                    />
                                    : "Pro-rata"
                            }
                        >
                            <Radio.Group
                                disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.has_prorata}>
                                <Radio value={true}>Si</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item
                            name='prorata_effective_date'
                            label={
                                isStateCorrectForWarningPolicyFields && !disabled ?
                                    <WarningTitleButton
                                        title={<>Decorrenza pro-rata<EffectiveDateInfo/></>}
                                        warningText={"Attenzione! La modifica di questo campo può compromettere l'allineamento con i dati di PASS"}
                                        onConfirm={() => setDisabledPolicyFields(prevState => ({
                                            ...prevState,
                                            prorata_effective_date: !prevState.prorata_effective_date
                                        }))}
                                        isEditing={!disabledPolicyFields.prorata_effective_date}
                                    />
                                    :
                                    <Space>Decorrenza pro-rata<EffectiveDateInfo/></Space>
                            }
                            hidden={!watchProrata}
                            extra={<ProRataError
                                effectiveDate={watchEffectiveDate}
                                prorataEffectiveDate={watchProrataEffectiveDate}
                            />}
                        >
                            <DatePicker
                                clearIcon={false}
                                defaultPickerValue={undefined}
                                defaultValue={undefined}
                                style={{width: '100%'}}
                                placeholder='Seleziona una data'
                                format={'DD/MM/YYYY'}
                                disabledDate={(current) => watchEffectiveDate ?
                                    (current.startOf('day') >= watchEffectiveDate.startOf('day') ||
                                        watchEffectiveDate.startOf('day').diff(current, 'year', true) > 1)
                                    : true}
                                disabled={isStateCorrectForWarningPolicyFields && disabledPolicyFields.prorata_effective_date}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item name={'prorata_expiring_date'} label="Scadenza pro-rata" hidden={!watchProrata}>
                            <DatePicker
                                disabled
                                style={{width: '100%'}}
                                placeholder=''
                                format={'DD/MM/YYYY'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item name='prorata_uw_year' label="UW Year pro-rata" hidden={!watchProrata}>
                            <DatePicker
                                placeholder={''}
                                disabled
                                style={{width: '100%'}}
                                picker='year'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Form.Item label="Premio pro-rata nostra quota" name='prorata_isa_share' hidden={!watchProrata}>
                            <AntCurrencyFormatInput/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={11}>
                        <Tooltip placement='top'
                                 title={`Pro-rata ns. quota / Quota ISA${negotiation.isa_share_perc ? '(' + percentage(negotiation.isa_share_perc) + ')' : ""}`}>
                            <Form.Item label="Pro-rata 100%" hidden={!watchProrata} name={'prorata_total'}>
                                <AntCurrencyFormatInput disabled/>
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}