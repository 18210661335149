import PrettyPrintJson from "../PrettyPrintJson";
import {Button, Modal} from "antd";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {useState} from "react";
import {canSeeCoverageConfirmationDataModel} from "../../utils/permission";
import {useSelector} from "react-redux";
import {selectUser} from "../../redux/features/userSlice";
import {useGetCoverageConfirmationDataModelQuery} from "../../redux/api/coverageConfirmationApiSlice";

export default function ShowCoverageConfirmationJson({negotiationUuid}: { negotiationUuid?: string }) {

    const user = useSelector(selectUser)
    const isVisible = user && user.usertypes.length && canSeeCoverageConfirmationDataModel(user.usertypes.map(el => el.code))
    const [isOpen, setIsOpen] = useState(false)
    const {data, isFetching, isUninitialized} = useGetCoverageConfirmationDataModelQuery(isVisible && negotiationUuid && isOpen ? {uuid: negotiationUuid} : skipToken)

    return <>
        {isVisible && <><Modal
            footer={false}
            title={false}
            open={isOpen}
            onCancel={() => setIsOpen(false)}
            width={'95vw'}
        >
            <PrettyPrintJson
                data={data?.data_sent}
                loading={isFetching || isUninitialized}
            />
        </Modal>
            <Button
                onClick={() => setIsOpen(true)}
                type={'link'}
                style={{padding: 0}}>
                Mostra il data model...
            </Button>
        </>}
    </>

}