import React, { useState} from "react";
import {BrokerModel} from "../../types/broker";
import {Button, Col, Modal, Row, Typography} from "antd";
import {EditOutlined} from "@ant-design/icons";
import EditBrokerForm from "./EditBrokerForm";
import BrokerAgreements from "./BrokerAgreements";
import MeetingNotes from "./MeetingNotes";

const {Title} = Typography

export default function BrokerItem({broker, updateBroker}: { broker: BrokerModel, updateBroker: (name: string | null, relationship_quality: number | null)=> void}) {
    const {name, uuid} = broker
    const [isBrokerModalOpen, setIsBrokerModalOpen] = useState<boolean>(false)
    const [isMeetingNotesModalOpen, setIsMeetingNotesModalOpen] = useState<boolean>(false)
    const [isAgreementsModalOpen, setIsAgreementsModalOpen] = useState<boolean>(false)


    const handleSaveClick = (values: any) => {
        updateBroker(values.brokerName, values.relationshipQuality)
        setIsBrokerModalOpen(false)
    }

    return <div>
        <Row justify={'space-between'} style={{width: '100%'}}>
            <Col style={{paddingTop: '0.3rem', marginLeft: '0.3rem'}}><Title level={5}>{name}</Title></Col>
            <Col>
                <Button
                    type={'link'}
                    size={'middle'}
                    onClick={() => setIsMeetingNotesModalOpen(true)}>
                    Meeting
                </Button>
                <Button
                    type={'link'}
                    size={'middle'}
                    onClick={() => setIsAgreementsModalOpen(true)}>
                    Convenzioni
                </Button>
                <Button
                    type={'link'}
                    size={'middle'}
                    onClick={() => setIsBrokerModalOpen(true)}
                    icon={<EditOutlined/>}
                />
            </Col>
        </Row>
        <Modal open={isBrokerModalOpen} destroyOnClose footer={false} title={"Modifica broker"}
               onCancel={() => setIsBrokerModalOpen(false)}>
            <EditBrokerForm broker={broker} onsubmit={handleSaveClick} onClose={() => setIsBrokerModalOpen(false)}/>
        </Modal>
        <Modal width={600} open={isMeetingNotesModalOpen} destroyOnClose footer={false} title={"Meeting Notes con: " + name}
               onCancel={() => setIsMeetingNotesModalOpen(false)}>
            <MeetingNotes broker={uuid}/>
        </Modal>
        <Modal width={600} open={isAgreementsModalOpen} destroyOnClose footer={false} title={"Convenzioni con: " + name}
               onCancel={() => setIsAgreementsModalOpen(false)}>
            <BrokerAgreements broker={uuid}/>
        </Modal>
    </div>
}