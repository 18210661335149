import {Button, Form, FormProps, Select} from "antd";
import Input from "antd/lib/input/Input";
import React from "react";
import {useCachedCountriesQuery} from "../../redux/api/countriesApiSlice";

export const COUNTRY = 'country'
export const NAME = 'name'

export type FormType = { [COUNTRY]: string, [NAME]: string }

export default function AutoPilotFormRevisited({inputPlaceholder = "Nome contraente", formProps}: {
    formProps?: FormProps,
    inputPlaceholder?: string
}) {

    const {data: fetchedCountries, isLoading: isLoadingCountries} = useCachedCountriesQuery()
    const countries = fetchedCountries?.map(c => ({label: c.label, value: c.code})) || []

    return <>
        <p
            style={{fontStyle: 'italic'}}>
            Inserire i dati per caricare una lista di candidati, premere il pulsante "Avvia ricerca" e infine
            selezionare il candidato.
        </p>
        <Form
            <FormType>
            layout={'vertical'}
            style={{marginTop: '1rem'}}
            initialValues={{
                [COUNTRY]: 'IT',
            }}
            {...formProps}
        >
            <Form.Item name={COUNTRY} label={'Paese'} rules={[{required: true, message: "Campo obbligatorio"}]}>
                <Select
                    optionFilterProp={'label'}
                    showSearch
                    options={countries}
                    loading={isLoadingCountries}
                    virtual={false}
                />
            </Form.Item>
            <Form.Item name={NAME} label={'Ragione sociale / P.IVA'} rules={[
                {required: true, message: "Campo obbligatorio"},
                {
                    validator: (_, value) =>
                        !value || value.trim().length >= 3 ? Promise.resolve() : Promise.reject(new Error("Minimo 3 caratteri"))
                }
            ]}>
                <Input disabled={formProps?.disabled} placeholder={inputPlaceholder}/>
            </Form.Item>
            <Form.Item style={{textAlign: 'right'}}>
                <Button
                    type={'primary'}
                    htmlType={'submit'}
                >
                    Avvia ricerca</Button>
            </Form.Item>
        </Form>
    </>
}