import SubmissionsTrend from "../SubmissionsTrend";
import React from "react";
import {useBankBranchChartsParamsContext} from "../../../context/BankBranchChartsContext";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetBBBSubmissionTrendQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BankBranchSubmissionsTrend() {

    const {params} = useBankBranchChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year

    const areParamsOk = !!(params.bank_branch_uuid && typeof uwYear === 'number' && params.budget_group)
    const {data, isFetching, isUninitialized} = useGetBBBSubmissionTrendQuery(areParamsOk ? {
        entity: 1,
        entity_uuid: params.bank_branch_uuid,
        budget_group: params.budget_group,
        uw_year: uwYear,
    } : skipToken)

    return <SubmissionsTrend data={data} loading={isUninitialized || isFetching}/>
}