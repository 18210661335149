import {Breadcrumb, Button, Card, Col, Divider, Input, List, message, Modal, Pagination, Row, Spin} from "antd";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {CreateContractorModel} from "../../types/contractors";
import {
  useLazyGetContractorsQuery,
  useUpdateContractorMutation
} from "../../redux/api/contractorsApiSlice";
import {useNavigate} from "react-router-dom";
import ContractorsItem from "../../components/settings/ContractorsItem";
import {debounce} from "lodash";
import {LeftOutlined, PlusOutlined} from "@ant-design/icons";
import ContractorCreation from "../../components/contractorCustomer/ContractorCreation";

const pageSize: number = 22;

export default function ContractorsSettings() {

  const [fetchContractors, {
    data: contractorsData,
    isLoading: isContractorLoading,
    isError: isContractorError,
    isFetching: isContractorFetching
  }] = useLazyGetContractorsQuery()
  const [updateContractorRequest] = useUpdateContractorMutation()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debounceSetSearchTerm = debounce((value: string) => setSearchTerm(value), 600)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isOneItemModifing, setIsOneItemModifing] = useState({status: false, key: ""})

  const navigate = useNavigate()

  function fetchContractorsData(_searchTerm?: string, _page?: number, _pageSize?: number) {
    try {
      fetchContractors({search: _searchTerm, page_size: _pageSize, page: _page}).unwrap()
    } catch (e: any) {
      message.error('Impossibile scaricare i contraenti')
      console.error('fetchContractorsData', e)
    }
  }

  useEffect(() => {
    searchTerm.length ? fetchContractorsData(searchTerm, currentPage, pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
  }, [currentPage])

  useEffect(() => {
    currentPage === 1 ?
        searchTerm.length ? fetchContractorsData(searchTerm, currentPage, pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
        : setCurrentPage(1)
  }, [searchTerm])

  useEffect(() => {
    //polling if one email is in progress job_status
    let timer: NodeJS.Timeout;
    if (contractorsData?.results.some(contractor => contractor.is_autopilot_pending)) {
      timer = setInterval(() => currentPage === 1 ?
          searchTerm.length ? fetchContractorsData(searchTerm, currentPage, pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
          : setCurrentPage(1), 5000)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [contractorsData?.results, currentPage, fetchContractorsData, searchTerm])

  const updateContractor = useCallback((uuid: string, data: Partial<CreateContractorModel>) => {
    updateContractorRequest({uuid, data})
        .unwrap()
        .then(() => {
          searchTerm.length ? fetchContractorsData(searchTerm, currentPage, pageSize) : fetchContractorsData(undefined, currentPage, pageSize)
          message.success("Contraente aggiornato correttamente")
        })
        .catch((error) => {
          message.error('Qualcosa è andato storto durante l\'aggiornamento del contraente')
          console.error("contractor not updated", error)
        })
  }, [updateContractorRequest, searchTerm, currentPage])

  return <Row justify={'space-evenly'}>
    <Col xs={24} sm={22} lg={23}>
      <div style={{paddingTop: '40px', paddingBottom: '70px', backgroundColor: 'transparent'}}>
        <Card bodyStyle={{minHeight: '83vh', display: 'flex', flexDirection: 'column'}}
              title={<Breadcrumb>
                <Breadcrumb.Item onClick={() => navigate('/settings')}><span
                    style={{cursor: "pointer"}}><LeftOutlined/> Dati comuni</span></Breadcrumb.Item>
                <Breadcrumb.Item>Contraenti</Breadcrumb.Item>
              </Breadcrumb>
              }

        >
          <Row justify={'space-between'} style={{marginBottom: '1rem'}}>
            <Col style={{width: '20rem'}}><Input.Search placeholder={'Filtra per nome...'} enterButton
                                                        defaultValue={""}
                                                        onChange={(e) => debounceSetSearchTerm(e.target.value.trim())}
            /></Col>
            <Col><Button icon={<PlusOutlined/>} type={"primary"} onClick={() => setIsModalOpen(true)}>Crea
              contraente</Button></Col>
          </Row>
          <Divider/>
          <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
            {isContractorLoading ? <Spin/> :
                <List
                    style={{marginBottom: '5px'}}
                    grid={{column: 2, gutter: 8, xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
                    loading={!contractorsData || isContractorLoading || isContractorFetching}
                    dataSource={contractorsData?.results}
                    renderItem={item => <List.Item
                        style={{borderBottom: '1px solid rgba(0, 0, 0, 0.06)', paddingBottom: '0.1rem', backgroundColor: !item?.customer_uuid ? '#ffc7c78c' : 'transparent'}}>
                      <Spin tip={'Acquisizione dati in corso...'} spinning={!!item?.is_autopilot_pending}>
                        <ContractorsItem
                            contractor={item}
                            updateContractors={(values: CreateContractorModel) => updateContractor(item.uuid, values)}
                            onAlignContractor={(c) => fetchContractorsData(searchTerm, currentPage, pageSize)}
                            isOneItemModifing={isOneItemModifing}
                            setIsOneItemModifing={setIsOneItemModifing}/>
                      </Spin>
                    </List.Item>
                    }
                />}
            {contractorsData?.count ?
                <Row justify={'end'}><Pagination
                    simple
                    total={contractorsData.count}
                    current={currentPage}
                    pageSize={pageSize}
                    onChange={page => {
                      setIsOneItemModifing({status: false, key: ""})
                      setCurrentPage(page)
                    }}
                /></Row> : ""}</div>
        </Card>
        {/*conditional rendering for reset modal*/}
        {isModalOpen && <ContractorCreation
            isFund={false}
            isVisible={isModalOpen}
            searchTerm={''}
            setIsVisible={(value) => setIsModalOpen(value)}
            onCreation={(c) => fetchContractorsData(searchTerm, currentPage, pageSize)}/>}
      </div>
    </Col>
  </Row>
}

