import React, {Fragment, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    Col,
    Input,
    message,
    Pagination,
    Popconfirm,
    Row,
    Space,
    Table,
    TablePaginationConfig,
    Tag,
    Tooltip
} from "antd";
import {DeleteOutlined, Loading3QuartersOutlined,} from '@ant-design/icons';
import {ColumnsType} from "antd/es/table";
import {GetPendingEmailsParams, PendingEmailsModel} from "../../../types/pendingEmails";
import dayjs from "dayjs";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setTablesPreferences} from "redux/features/userSlice";
import {useSetUserTablesPreferencesMutation} from "redux/api/userPreferencesApiSlice";
import {useDeletePendingEmailMutation, useLazyGetPendingEmailsQuery} from "../../../redux/api/pendingEmailsApiSlice";
import {useLazyGetNegotiationUsersQuery} from "../../../redux/api/negotiationUsersApiSlice";
import {useNavigate} from "react-router-dom";
import {TableFilter, TableSorter, Views} from "../../../types";
import {SorterResult} from "antd/lib/table/interface";
import _ from "lodash";
import {FilterConfirmProps} from "antd/es/table/interface";
import {ColumnType} from "antd/lib/table";
import {
    DEFAULT_CURRENT_PAGE_SIZE_TABLE,
    DEFAULT_CURRENT_PAGE_TABLE,
    DELAY_TIMER_SEARCH_FILTER,
    endAndStartTimerFilter,
    getColumnDateProps,
    getStandardFilter
} from "./columnsUtils";
import FilterContainer from "./FilterContainer";
import {getContrastYIQ} from "../../../utils/formatters";
import {selectOptions} from "../../../redux/features/optionsSlice";

export default function PendingEmailTable() {

    const [fetchEmails, {
        data: pendingEmails,
        isLoading: isPendingEmailsLoading,
        isFetching: isPendingEmailsFetching,
        isError: isPendingEmailsError,
        error: pendingEmailsError
    }] = useLazyGetPendingEmailsQuery()
    const [triggerNegotiationUsers, {
        data: negotiationUsersData,
        isLoading: isNegotiationUsersLoading,
        isUninitialized: isNegotiationUsersUnitialized
    }] = useLazyGetNegotiationUsersQuery()
    const [deletePendingEmail, {isLoading: isDeleteInProgress}] = useDeletePendingEmailMutation()

    const user = useSelector(selectUser)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [updatePreferences] = useSetUserTablesPreferencesMutation()
    const filterPreferences: TableFilter[] = useMemo(() => user.preferences.tables[Views[user.preferences.current_view]].filters || [], [user.preferences.current_view, user.preferences.tables])
    const sorterPreferences: TableSorter[] = useMemo(() => user.preferences.tables[Views[user.preferences.current_view]].sorters || [], [user.preferences.current_view, user.preferences.tables])
    const pagePreferences = user.preferences.tables[Views[user.preferences.current_view]].page || DEFAULT_CURRENT_PAGE_TABLE
    const pageSizePreferences = user.preferences.tables[Views[user.preferences.current_view]].page_size || DEFAULT_CURRENT_PAGE_SIZE_TABLE
    const options = useSelector(selectOptions)

    const [searchText, setSearchText] = useState('');
    const searchInput = useRef(null);
    const [fetchEmailsParams, setFetchEmailsParams] = useState<GetPendingEmailsParams>({})
    const [columns, setColumns] = useState<ColumnsType<PendingEmailsModel>>([])

    useEffect(() => {
        try {
            triggerNegotiationUsers({page: 0, usertypes: "UW,UWJ,UWS,LBM,CM"}).unwrap()
        } catch (e: any) {
            message.error("Errore nel caricamento dei sottoscrittori")
            console.error("Errore", e)
        }
    }, [triggerNegotiationUsers])

    useEffect(() => {
        const error = pendingEmailsError as { status: number, data: { detail: string } }
        if (error?.status === 404 && error?.data?.detail === 'Invalid page.') {
            message.error('Errore nello scaricamento')
            dispatch(setTablesPreferences({[Views[user.preferences.current_view]]: {page: DEFAULT_CURRENT_PAGE_TABLE}}))
        }
    }, [dispatch, pendingEmailsError, user.preferences.current_view])


    useEffect(() => {
        let option: any = {page: pagePreferences, page_size: pageSizePreferences}
        if (filterPreferences) {
            for (let i in filterPreferences) {
                switch (filterPreferences[i].key as string) {
                    case ('email_from'):
                        const senders = (): string => {
                            let _senders: string = ""
                            filterPreferences[i].range.forEach((el, index) => {
                                index === 0 ? _senders = el as string : _senders = _senders + "," + el
                            })
                            return _senders
                        }
                        option = {...option, email_from_user_profile: senders()}
                        break
                    case ('email_subject'):
                        option = {...option, search: filterPreferences[i].range[0]}
                        break
                    case ('email_forwarding_date'):
                        option = {
                            ...option,
                            email_forwarding_date_from: filterPreferences[i].range[0],
                            email_forwarding_date_to: filterPreferences[i].range[1] + " 23:59:59"
                        }
                        break
                    case ('lob'):
                        option = {...option, lob: filterPreferences[i].range.join(',')}
                        break
                    case ('job_status'):
                        const status = (): string => {
                            let _status: string = ""
                            filterPreferences[i].range.forEach((el, index) => {
                                index === 0 ? _status = el as string : _status = _status + "," + el
                            })
                            return _status
                        }
                        option = {...option, job_status: status()}
                        break
                }
            }
        }
        if (sorterPreferences) {
            const orderingValues: string[] = []
            for (let i in sorterPreferences) {
                if (sorterPreferences[i].type === "ascend")
                    orderingValues.push(sorterPreferences[i].key)
                else
                    orderingValues.push(`-${sorterPreferences[i].key}`)
            }
            option = {...option, ordering: orderingValues.join(',')}
        }
        try {
            setFetchEmailsParams(option)
            fetchEmails(option).unwrap()
        } catch (e: any) {
            message.error('Impossibile scaricare i dati')
            console.error('fetchPendingEmail', e)
        }
    }, [filterPreferences, sorterPreferences, fetchEmails, pagePreferences, pageSizePreferences])

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const getColumnSearchProps = useCallback((): ColumnType<PendingEmailsModel> => ({
        filterDropdown: (props) => (
            <FilterContainer {...props}>
                <Input
                    ref={searchInput}
                    placeholder={`Cerca per parola`}
                    value={props.selectedKeys[0]}
                    onChange={(e) => {
                        props.setSelectedKeys(e.target.value ? [e.target.value] : [])
                        endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
                    }}
                    onPressEnter={() => handleSearch(props.selectedKeys as string[], props.confirm)}
                    style={{
                        width: '20rem',
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
            </FilterContainer>
        ),
    }), [])

    useEffect(() => {
        let updatedCols: ColumnsType<PendingEmailsModel> = [
            {
                title: 'Mittente',
                dataIndex: 'email_from',
                key: 'email_from',
                sorter: {multiple: 0},
                filteredValue: [],
                ...getStandardFilter(negotiationUsersData?.map(el => ({
                    text: el.user_full_name,
                    value: el.uuid
                })) || []),
            },
            {
                title: 'Oggetto della mail',
                dataIndex: 'email_subject',
                key: 'email_subject',
                ellipsis: {
                    showTitle: false,
                },
                sorter: {multiple: 0},
                filteredValue: [],
                ...getColumnSearchProps(),
                render: address => (
                    <Tooltip placement="topLeft" title={address}>
                        <p style={{textOverflow: 'ellipsis', maxWidth: '20rem', overflow: 'hidden'}}> {address} </p>
                    </Tooltip>
                ),
            },
            {
                title: 'Data ricezione',
                dataIndex: 'email_forwarding_date',
                key: 'email_forwarding_date',
                sorter: {multiple: 0},
                filteredValue: [],
                ...getColumnDateProps('email_forwarding_date'),
                render: (value: Date) => {
                    return dayjs(value).format("DD-MM-YYYY")
                }
            },
            {
                title: 'LOB',
                dataIndex: 'lob',
                key: 'lob',
                sorter: {multiple: 0},
                filteredValue: [],
                render: (lob: PendingEmailsModel['lob']) => {
                    if (lob) {
                        return <Tag
                            style={{
                                borderRadius: '100px',
                                width: '7rem',
                                height: '1.25rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0 0.5rem',
                                color: getContrastYIQ(lob.color)
                            }}
                            color={lob.color}
                        >
                            {lob.name}
                        </Tag>
                    }
                },
                ...getStandardFilter([{
                    text: 'Nessuna lob',
                    value: 'no_data'
                }, ...options.lobs.map(el => ({text: el.name, value: el.uuid}))])
            },
            {
                title: 'Stato',
                dataIndex: ['job_status'],
                key: 'job_status',
                ...getStandardFilter(
                    [{text: 'In lavorazione', value: 'P'},
                        {text: 'Completato', value: 'D'},
                        {text: 'Errore', value: 'E'}
                    ]),
                sorter: {multiple: 0},
                filteredValue: [],
                render: (text, record) => {
                    switch (record.job_status) {
                        case('P'):
                            return <div style={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center'}}><Tag
                                style={{width: '5.5rem', textAlign: 'center'}} color={"yellow"}>In
                                lavorazione</Tag><Loading3QuartersOutlined spin/></div>
                        case('D'):
                            return <Tag style={{width: '5.5rem', textAlign: 'center'}} color={"green"}>Completato</Tag>
                        case('E'):
                            return <Tag style={{width: '5.5rem', textAlign: 'center'}} color={"red"}>Errore</Tag>
                    }
                }
            },
            {
                title: 'Azioni',
                key: 'actions',
                width: '12rem',
                render: (_, record) => {
                    return record.job_status === 'E' && (
                        <span
                            onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                title={"Eliminare il processo RORE?"}
                                onConfirm={async () => {
                                    try {
                                        await deletePendingEmail({pending_email_uuid: record.uuid}).unwrap()
                                        message.success('Processo RORE eliminato')
                                        const updatedParams = {...fetchEmailsParams, page: 1}
                                        setFetchEmailsParams(updatedParams)
                                        fetchEmails(updatedParams)
                                    } catch {
                                        message.error("Errore nell'eliminazione del processo RORE")
                                        console.log('error')
                                    }
                                }}
                            >
                            <Button icon={<DeleteOutlined/>} type='ghost'/>
                        </Popconfirm>
                    </span>)
                }
            }
        ]
        if (filterPreferences.length) {
            for (let i = 0; i < filterPreferences.length; i++) {
                const col = updatedCols.find(el => el.key === filterPreferences[i].key)
                if (col) {
                    col.filteredValue = filterPreferences[i].range as string[]
                }
            }
        }
        if (sorterPreferences.length) {
            for (let i = 0; i < sorterPreferences.length; i++) {
                const col = updatedCols.find(el => el.key === sorterPreferences[i].key)
                if (col) {
                    col.sortOrder = sorterPreferences[i].type
                }
            }
        }

        setColumns(updatedCols)
    }, [filterPreferences, getColumnSearchProps, navigate, negotiationUsersData, sorterPreferences])

    useEffect(() => {
        //polling if one email is in progress job_status
        let timer: NodeJS.Timeout;
        if (pendingEmails?.results.some(email => email.job_status === 'P')) {
            timer = setInterval(() => fetchEmails(fetchEmailsParams), 5000)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [fetchEmails, fetchEmailsParams, pendingEmails?.results])

    return <div style={{marginTop: "1rem", cursor: 'default'}}>
        <Table<PendingEmailsModel> loading={isPendingEmailsLoading || isPendingEmailsFetching || isDeleteInProgress}
                                   pagination={false}
                                   scroll={{x: true}}
                                   locale={{
                                       filterConfirm: 'Applica filtro',
                                       filterReset: 'Reset filtro',
                                   }}
                                   onChange={(pag, fil, sor: SorterResult<any> | SorterResult<any>[], extra) => {
                                       switch (extra.action) {
                                           case "filter":
                                               let f: TableFilter[] = []
                                               for (let key of Object.keys(fil))
                                                   fil[key] ? f.push({
                                                       key: key,
                                                       range: fil[key] as string[] | number[]
                                                   }) : f.push({key: key, range: []})
                                               const filters = f.filter(el => el.range.length > 0)
                                               if (!_.isEqual(filters, filterPreferences)) {
                                                   dispatch(setTablesPreferences({
                                                       [Views[user.preferences.current_view]]: {
                                                           filters,
                                                           page: 1
                                                       }
                                                   }))
                                                   updatePreferences({[Views[user.preferences.current_view]]: {filters: f.filter(el => el.range.length > 0)}})
                                               }
                                               break
                                           case "sort":
                                               let s: TableSorter[] = []
                                               if (Array.isArray(sor)) {
                                                   s = sor.map(el => ({
                                                       key: el.columnKey as string,
                                                       type: el.order as 'ascend' | 'descend'
                                                   }))
                                               } else {
                                                   if (sor.order === undefined)
                                                       s = []
                                                   else {
                                                       s = [{
                                                           key: sor.columnKey as string,
                                                           type: sor.order as 'ascend' | 'descend'
                                                       }]
                                                   }

                                               }
                                               dispatch(setTablesPreferences({[Views[user.preferences.current_view]]: {sorters: s}}))
                                               updatePreferences({[Views[user.preferences.current_view]]: {sorters: s}})
                                               break
                                       }
                                   }}
                                   columns={columns}
                                   dataSource={pendingEmails?.results.map(el => ({key: el.uuid, ...el}))}
                                   onRow={(record) => {
                                       return {
                                           onClick: () => {
                                               if (record.negotiation) {
                                                   navigate(`/negotiations/${record.negotiation}`)
                                               }
                                           },
                                           style: {
                                               cursor: record.negotiation ? 'pointer' : ""
                                           }
                                       };
                                   }}
        />
        {pendingEmails?.count ?
            <Row justify={'end'} style={{marginTop: '1rem'}}><Pagination
                total={pendingEmails.count}
                showTotal={(total, range) => `${range[0]}-${range[1]} di ${total} email`}
                current={pagePreferences}
                showSizeChanger={true}
                pageSize={pageSizePreferences}
                onChange={(page, pageSize) => {
                    dispatch(setTablesPreferences({
                        [Views[user.preferences.current_view]]: {
                            page,
                            page_size: pageSize
                        }
                    }))
                }}
            /></Row> : ""}
    </div>
}
