import React, {Dispatch, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Card, Col, Input, InputRef, Row, Select, Space, Table, TableColumnType, TablePaginationConfig} from "antd";
import {useNavigate} from "react-router-dom";
import {useLazyGetBankBranchesListTableQuery} from "../../../redux/api/chartsApiSlice";
import {ITALY} from "../broker/BrokersTable";
import {ColumnsType} from "antd/lib/table";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {euro, numeric} from "../../../utils/formatters";
import {BankBranchesListTableResponse, LabelValue} from "../../../types/charts";
import {extendedBound, States} from "../../../types";
import {useSelector} from "react-redux";
import {selectOptions} from "../../../redux/features/optionsSlice";
import FilterContainer from "../../tables/negotiationsTables/FilterContainer";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "../../tables/negotiationsTables/columnsUtils";
import {useBankBranchesChartsParamsContext} from "../../../context/BankBranchesChartsContext";
import {selectUser} from "../../../redux/features/userSlice";

const pageSize = 6

type SearchObj = {
    bank_branch: string,
    bank_sales_management: string,
    bank_branch_area: string,
    city: string,
    bank_branch_type: string,
    sales: string,
}

export function BranchTable({
                                zoneOptions,
                                selectedOption,
                                setSelectedOption,
                            }: {
    zoneOptions: LabelValue[],
    selectedOption: string,
    setSelectedOption: Dispatch<string>,
}) {
    const navigate = useNavigate()
    const {params} = useBankBranchesChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = typeof uwYear === 'number'

    const [currentState, setCurrentState] = useState<number | string>(-1)
    const [currentPage, setCurrentPage] = useState(1)

    const [fetchData, {
        data,
        isFetching,
    }] = useLazyGetBankBranchesListTableQuery()
    const loading = isFetching

    const searchInput = useRef<InputRef>(null);
    const [search, setSearch] = useState<SearchObj>({
        bank_branch: "",
        bank_sales_management: "",
        bank_branch_area: "",
        city: "",
        bank_branch_type: "",
        sales: "",
    })

    const getData = useCallback((page = 1) => {
        if (areParamsOk) {
            setCurrentPage(page)
            fetchData({
                budget_group: params.budget_group,
                uw_year: uwYear,
                ...selectedOption !== ITALY && {provinces: selectedOption},
                ...currentState !== -1 && {state: currentState},
                page_size: pageSize,
                page,
                ...Object.entries(search).reduce(
                    (result, [key, value]) => value ?
                        {
                            ...result,
                            [["bank_branch", "bank_branch_type", "bank_branch_area", "bank_sales_management"].includes(key) ? `${key}_name` : key]: value
                        } :
                        result,
                    {})
            })
        }
    }, [areParamsOk, currentState, fetchData, params.budget_group, search, selectedOption, uwYear])

    useEffect(() => {
        getData()
    }, [getData]);

    const options = useSelector(selectOptions)
    const states = [
        {value: -1, text: "Tutti gli stati"},
        ...options.states.filter(el =>
            (el.value === States.Working) ||
            (el.value === States.Quoted) ||
            (el.value === States.Bound) ||
            (el.value === States.Declined) ||
            (el.value === States.NotTakenUp))]
        .map(el => ({
            value: el.value === States.Bound ? extendedBound.map(el => el.toString()).toString() : el.value,
            label: el.text
        }))

    const cardTitle = useMemo(() => {
        return <Row justify={"space-between"} align={"middle"} gutter={[16, 16]}>
            <Col flex={1}>
                <Space direction={"vertical"}>
                    <p>
                        Totale
                        trattative: <strong>{loading ? '...' : typeof data?.amount === 'number' && numeric(data?.amount)}</strong>
                    </p>
                    <p>
                        Totale
                        premi: <strong>{loading ? '...' : typeof data?.premiums === 'number' && euro(data?.premiums)}</strong>
                    </p>
                </Space>
            </Col>
            <Col>
                <Space wrap>
                    Filtra per:
                    <Select
                        value={selectedOption}
                        onChange={(v) => setSelectedOption(v)}
                        options={zoneOptions}
                        style={{width: "200px"}}
                        showSearch={true}
                        virtual={false}
                        optionFilterProp={"label"}
                    />
                    <Select
                        onSelect={(v: string | number) => setCurrentState(v)}
                        value={currentState}
                        options={states}
                        style={{width: "200px"}}/>
                </Space>
            </Col>
        </Row>
    }, [currentState, data?.amount, data?.premiums, loading, selectedOption, setSelectedOption, states, zoneOptions])

    const pagination: TablePaginationConfig = useMemo(() => {
        return {
            size: "small",
            total: data?.count,
            showSizeChanger: false,
            pageSize,
            current: currentPage,
            onChange: (page) => {
                getData(page)
            }
        }
    }, [data?.count, getData, currentPage])

    const handleSearch = useCallback((
        value: string,
        dataIndex: keyof SearchObj,
    ) => {

        setSearch(prevState => ({
            ...prevState,
            [dataIndex]: value
        }));
    }, [])

    const getColumnSearchProps = useCallback((dataIndex: keyof SearchObj): TableColumnType<BankBranchesListTableResponse> => ({
        filteredValue: search[dataIndex] ? [search[dataIndex]] : [],
        filterDropdown: (props) => (
            <FilterContainer
                {...props}
                clearFilters={() => {
                    setSearch(prevState => ({...prevState, [dataIndex]: ""}))
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Cerca per parola`}
                    value={props.selectedKeys[0]}
                    onChange={(e) => {
                        props.setSelectedKeys(e.target.value ? [e.target.value] : [])
                        endAndStartTimerFilter(() => {
                            handleSearch(e.target.value, dataIndex)
                        }, DELAY_TIMER_SEARCH_FILTER)
                    }}
                    onPressEnter={() => setSearch(prevState => ({
                        ...prevState,
                        [dataIndex]: props.selectedKeys[0]
                    }))}
                    style={{
                        width: '20rem',
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
            </FilterContainer>
        )
    }), [handleSearch, search])

    const columns: ColumnsType<BankBranchesListTableResponse> = useMemo(() => [
        {
            title: "Filiale",
            key: "bank_branch",
            dataIndex: ["bank_branch", "label"],
            width: 150,
            ...getColumnSearchProps("bank_branch"),
        },
        {
            title: "Dir. Commerciale",
            key: "bank_sales_management",
            dataIndex: ["bank_sales_management", "label"],
            width: 150,
            ...getColumnSearchProps("bank_sales_management")
        },
        {
            title: "Area",
            key: "bank_branch_area",
            dataIndex: ["bank_branch_area", "label"],
            width: 150,
            ...getColumnSearchProps("bank_branch_area")
        },
        {
            title: "Comune",
            key: 'city',
            dataIndex: 'city',
            width: 150,
            ...getColumnSearchProps("city")
        },
        {
            title: "Sales",
            key: "sales",
            dataIndex: ["sales", "label"],
            width: 150,
            ...getColumnSearchProps("sales")
        },
        {
            title: "Tipologia",
            key: "bank_branch_type",
            dataIndex: ["bank_branch_type", "label"],
            width: 150,
            ...getColumnSearchProps("bank_branch_type")
        },
        {
            title: <div style={{textAlign: 'right'}}>Trattative</div>,
            dataIndex: "negotiations_count",
            key: "negotiations_count",
            width: 150,
            align: 'right',
            render: (v: number) => numeric(v)
        },
        {
            title: <div style={{textAlign: 'right'}}>Totale Premi</div>,
            width: 150,
            dataIndex: "negotiations_premiums",
            key: "negotiations_premiums",
            align: 'right',
            render: (v: number) => euro(v),
        },
        {
            title: "Andamento",
            dataIndex: "growing_trend",
            width: 150,
            render: (trend: boolean) => trend ?
                <ArrowUpOutlined style={{fontSize: "20px", color: "green", transform: "rotate(45deg)"}}/> :
                <ArrowDownOutlined style={{fontSize: "20px", color: "red", transform: "rotate(-45deg)"}}/>
        },
    ], [getColumnSearchProps])

    const onRow = useCallback((record: BankBranchesListTableResponse) => {
        return {
            onClick: () => {
                navigate(record.bank_branch.value)
            },
            style: {
                cursor: 'pointer'
            }
        }
    }, [navigate])

    return <Card title={cardTitle} style={{height: "100%"}}>
        <div style={{overflowX: "scroll"}}>
            <Table
                <BankBranchesListTableResponse>
                columns={columns}
                tableLayout={"fixed"}
                loading={loading}
                dataSource={data?.results}
                pagination={pagination}
                onRow={onRow}
                rowKey={record => record.bank_branch.value}
            />
        </div>
    </Card>
}