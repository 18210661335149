import DatePicker from "../DatePicker";
import dayjs from "dayjs";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setChartUWYear} from "../../redux/features/userSlice";
import {useSetChartsParamsMutation} from "../../redux/api/userPreferencesApiSlice";

export default function UwYearDatePicker() {
    const dispatch = useDispatch()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const [updateChartsParams] = useSetChartsParamsMutation()

    return <>
        <label
            htmlFor={'datepicker'}
            style={{
                marginRight: '6px',
                fontSize: '14px',
                fontWeight: 'normal'
            }}
        >
            UW Year:
        </label>
        <DatePicker.YearPicker
            clearIcon={false}
            value={dayjs(uwYear?.toString())}
            onSelect={(value) => {
                dispatch(setChartUWYear(value.year()))
                updateChartsParams({uw_year: value.year()})
            }}
            style={{width: '7.5rem'}}
            bordered={true}
            name={'datepicker'}
        />
    </>
}