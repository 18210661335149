import {Button, Card, Col, Divider, List, Row} from "antd";
import {useNavigate} from "react-router-dom";
import React from "react";
import Title from "antd/lib/typography/Title";

export default function Settings() {

    const data = [
        {key: 'brokers', label: 'Brokers'},
        {key: 'reinsurerbrokers', label: 'Brokers Riassicurativi'},
        {key: 'contractors', label: 'Contraenti'},
        {key: 'delegatedinsurers', label: 'Delegatarie'},
        {key: 'reinsurers', label: 'Riassicuratori'},
        {key: 'branches', label: "Filiali"}
    ]
    const navigate = useNavigate()


    return <Row justify={'space-evenly'}>
        <Col xs={24} sm={22} lg={23}>
            <div style={{paddingTop: '40px', paddingBottom: '70px', backgroundColor: 'transparent'}}>
                <Row style={{marginBottom: '0.7rem', marginLeft: '2rem'}}><Col style={{marginLeft: '1rem'}}><Title level={3} style={{marginBottom: 0, marginLeft: '20rem'}}>Dati
                    comuni alle trattative</Title></Col></Row>
                <Col><Card style={{height: '100%', flexWrap: 'wrap'}}>
                    <div style={{color: 'black', fontSize: '14px', fontWeight: 'bold'}}>Qui è possibile creare o modificare i dati comuni alle trattative</div>
                    <Divider/>
                    <List
                        bordered
                        dataSource={data}
                        renderItem={item => <List.Item>
                            <Button
                                type="link"
                                onClick={() => navigate(`/settings/${item.key}`)}>
                                {item.label}
                            </Button>
                        </List.Item>
                        }
                    />
                </Card></Col>
            </div>
        </Col>
    </Row>
}

