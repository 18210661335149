import {Survey as SurveyReact} from "survey-react-ui";
import {
  Question,
  QuestionRadiogroupModel
} from "survey-react";
import {CustomWidgetCollection, Model} from "survey-core";
import {message, Modal, Skeleton} from "antd";
import React, {useCallback, useEffect, useState} from "react";
import {
  useLazyCreateChecklistQuery,
  useLazyGetChecklistQuery,
  useUpdateChecklistMutation
} from "../../../../../redux/api/checklistApiSlice";
import {debounce} from "lodash";
import {NegotiationModel} from "../../../../../types/negotiations";
import {CustomRadioGroup} from "./checklist";
import survey_theme from "../../../../../assets/survey_theme.json";

export function ConflictOfInterestModal({
                                          isOpen,
                                          setIsOpen,
                                          negotiation
                                        }: {
  isOpen: boolean,
  setIsOpen: (isNegotiationToUpdate: boolean) => void,
  negotiation: NegotiationModel
}) {

  const [survey, setSurvey] = useState(new Model())
  const [isChecklistCreated, setIsChecklistCreated] = useState(false)

  const [updateChecklist] = useUpdateChecklistMutation()
  const debounceUpdate = useCallback(debounce(({
                                                 negotiation,
                                                 checklist,
                                                 data,
                                                 visible_questions,
                                               }: {
    negotiation: string,
    checklist: string,
    data: object,
    visible_questions: { [key: string]: boolean }
  }) => updateChecklist({
    negotiation,
    checklist,
    data,
    visible_questions
  }), 500), [])
  const debounceMessage = useCallback(debounce((success: boolean) => success ? message.success('Checklist aggiornata') : message.error('Checklist non aggiornata'), 500), [])
  const [getChecklist, {
    data: getChecklistData,
    isLoading: getChecklistIsLoading,
    isFetching: getChecklistIsFetching
  }] = useLazyGetChecklistQuery()

  const [createChecklist, {
    data: createChecklistData,
    isLoading: createChecklistIsLoading,
    isFetching: createChecklistIsFetching
  }] = useLazyCreateChecklistQuery()

  useEffect(() => {
    if (isOpen) {
      const cintChecklistExist = negotiation.checklist_answers.find(c => c.checklist_type === 'CINT')
      if (cintChecklistExist) {
        getChecklist({negotiation: negotiation.uuid, checklist: cintChecklistExist.uuid})
      } else {
        createChecklist({negotiation: negotiation.uuid, checklist_type: 'CINT'})
          .unwrap()
          .then(res => {
            setIsChecklistCreated(true)
          })
          .catch()
      }
    }
  }, [createChecklist, getChecklist, isOpen, negotiation.checklist_answers, negotiation.uuid])

  useEffect(() => {
    // set jsonSchema
    const surveyData = getChecklistData || createChecklistData;

    if (surveyData?.jsonSchema && surveyData?.data) {
      const newSurvey = new Model(surveyData?.jsonSchema)
      newSurvey.data = {...newSurvey.data, ...surveyData.data}
      if (createChecklistData) {
        // when the checklist is created defaultValues are passed
        const questions = newSurvey.getAllQuestions().reduce((prev, q) => ({...prev, [q.name]: q.isVisible}), {})
        updateChecklist({
          negotiation: negotiation.uuid,
          checklist: surveyData.uuid,
          data: newSurvey.data,
          visible_questions: questions
        })
      }
      newSurvey.onValueChanged.add((sender) => {
        const questions = sender.getAllQuestions().reduce((prev, q) => ({...prev, [q.name]: q.isVisible}), {})
        debounceUpdate({
          negotiation: negotiation.uuid,
          checklist: surveyData.uuid,
          data: sender.data,
          visible_questions: questions
        })
          ?.unwrap()
          .then(() => debounceMessage(true))
          .catch(() => debounceMessage(false))
      });
      newSurvey.focusFirstQuestionAutomatic = false;
      newSurvey.applyTheme({cssVariables: survey_theme.cssVariables, isPanelless: survey_theme.isPanelless});
      newSurvey.setCss(
        {
          container: "ant-form ant-form-vertical",
          row: "ant-row ant-form-item",
          question: {
            header: "ant-col ant-form-item-label ant-form-item-required",
            title: "ant-form ant-form-item-label question-title",
            content: "ant-col ant-form-item-control",
            description: "ant-form-item-explain",
            hasError: "ant-form-item-explain-error",
          },
          text: {
            root: "ant-input",
          },
          comment: {
            root: "ant-input ant-input-textarea ant-input-textarea-in-form-item",
          },
        }
      )
      setSurvey(newSurvey)
    }

  }, [createChecklistData, debounceMessage, debounceUpdate, getChecklistData, negotiation.uuid, updateChecklist])

  useEffect(() => {
    // add custom widget
    CustomWidgetCollection.Instance.addCustomWidget({
      name: 'customRadioGroup',
      title: 'custom radio group',
      render: (question: Question) => <CustomRadioGroup question={question as QuestionRadiogroupModel}/>,
      isFit: (question: Question) => question.getType() === 'radiogroup',
    }, "customRadioGroup")
  }, [])

  return <>
    <Modal
      className='checklist-modal'
      title="Conflitto d'interessi"
      centered
      open={isOpen}
      onOk={() => {
        setIsOpen(isChecklistCreated)
        setIsChecklistCreated(false)
      }}
      onCancel={() => {
        setIsOpen(isChecklistCreated)
        setIsChecklistCreated(false)
      }}
      footer={null}
      destroyOnClose={true}
    >
      {!(getChecklistIsLoading || getChecklistIsFetching || createChecklistIsLoading || createChecklistIsFetching) &&
        <SurveyReact model={survey}/>}
      {(getChecklistIsLoading || getChecklistIsFetching || createChecklistIsLoading || createChecklistIsFetching) &&
        <Skeleton paragraph={{rows: 20}}/>}
    </Modal>
  </>
}