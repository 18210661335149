import {Popconfirm, Space} from "antd";
import {WarningOutlined, EditOutlined} from "@ant-design/icons";
import {ReactNode} from "react";

export default function WarningTitleButton({
                                             title,
                                             warningText,
                                             onConfirm,
                                             isEditing
                                           }: { title: string | ReactNode, warningText: string, onConfirm: () => void, isEditing: boolean }) {
  return !isEditing ?
      <Space style={{cursor: 'pointer'}}>
        {title}
        <Popconfirm
            title={warningText}
            onConfirm={onConfirm}
            showCancel={false}
            style={{cursor: 'pointer'}}
        >
          <EditOutlined/>
        </Popconfirm>
      </Space>
      :
      <Space style={{cursor: 'pointer'}}>
        {title}
        <WarningOutlined onClick={onConfirm} style={{color: 'red'}}/>
      </Space>
}