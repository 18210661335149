import {Button, Modal, Typography} from 'antd';
import React, {useState} from 'react';
import {InfoCircleOutlined, } from "@ant-design/icons";

const { Paragraph} = Typography;

export default function InfoModal(props: {title: React.ReactNode, description : React.ReactNode}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary"
                    icon={<InfoCircleOutlined
                        style={{fontSize: '18px', color: '#818A94'}}/>}
                    onClick={showModal}
                    style={{
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                        boxShadow: "none",
                        cursor: 'pointer'
                    }}/>
            <Modal title={props.title} open={isModalOpen} onCancel={handleCancel} centered={true}
                   footer={null}>
                <Paragraph>{props.description}</Paragraph>
            </Modal>
        </>
    );
};