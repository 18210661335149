import MapChart from "../MapChart";
import {ChartEntity, ZoneType} from "../../../types/charts";
import BrokersTable, {ITALY} from "../broker/BrokersTable";
import React, {useCallback, useMemo, useState} from "react";
import {useBrokersChartsParamsContext} from "../../../context/BrokersChartsContext";
import {useGetBBBNegotiationsByZoneQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Col} from "antd";

export default function BrokersTableMap() {

    const [zoneType, setZoneType] = useState<ZoneType>(ZoneType.REGION)
    const [selectedOption, setSelectedOption] = useState<string>(ITALY)
    const {params} = useBrokersChartsParamsContext()
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const areParamsOk = typeof uwYear === 'number'

    const {
        data,
        isFetching,
        isUninitialized
    } = useGetBBBNegotiationsByZoneQuery(areParamsOk ? {
        budget_group: params.budget_group,
        entity: ChartEntity.BROKER,
        uw_year: uwYear,
        zone_type: zoneType
    } : skipToken)
    const options = useMemo(() => [
            {
                label: "Tutta Italia",
                value: ITALY
            },
            {
                label: "Estero",
                value: "EE"
            },
            ...(data || []).map
            (el => ({value: el.zone.value, label: el.zone.label}))
                .sort((optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()))],
        [data])

    const handleZoneType = useCallback((v: ZoneType) => {
        setZoneType(v)
        if (selectedOption !== ITALY) {
            setSelectedOption(ITALY)
        }
    }, [selectedOption])

    return <>
        <Col xs={24} xl={8}>
            <MapChart
                data={data}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                loadingData={isFetching || isUninitialized}
                zoneType={zoneType}
                setZoneType={handleZoneType}
                legendHeight={450}
                chartHeight={500}
            />
        </Col>
        <Col xs={24} xl={16}>
            <BrokersTable
                options={options || []}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
            />
        </Col>
    </>
}