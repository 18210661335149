import { Divider} from "antd";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {selectOptions} from "redux/features/optionsSlice";
import {percentage} from "utils/formatters";
import {useLazyGetNegotiationTotalChartQuery} from "../../../redux/api/chartsApiSlice";
import ChartContainer from "./ChartContainer";
import {Typography} from 'antd';
import {selectUser} from "../../../redux/features/userSlice";

const {Title} = Typography;

const NegotiationTotal = () => {

    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetNegotiationTotalChartQuery()
    const options = useSelector(selectOptions)

    const description = 'Il grafico mostra il conteggio delle trattative (new business) suddivise per ogni stato.'

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    const title = 'Totale trattative new business'
    return <ChartContainer title={title} loading={isLoading || isFetching} description={description}>
        <Title level={3}>{data?.total_number ? data.total_number : undefined}</Title>
        <Divider style={{margin: '0.5rem 0'}}/>
        <dl className={'statistic-card__list'}>
            {data?.total_number !== 0 ? data?.states_shares.map(el => <div key={el.state}>
                <dt>{options.states.find(state => state.value === el.state)?.text}</dt>
                <dd style={{fontWeight: 'bold'}}>{percentage(+el.percentage || 0)}</dd>
            </div>) : 'Nessuna Trattativa'}
        </dl>
    </ChartContainer>

}
export default NegotiationTotal