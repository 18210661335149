import {
    useGetNegotiationAdditionalInsuredQuery,
    useUpdateAdditionalInsuredMutation
} from "../../redux/api/additionalInsured";
import {skipToken} from "@reduxjs/toolkit/dist/query";
import {Button, Col, List, message, Modal, Popconfirm, Row, Space} from "antd";
import React, {useCallback, useRef, useState} from "react";
import ConnectAdditionalInsuredAndNegotiation from "./ConnectAdditionalInsuredAndNegotiation";
import {euro} from "../../utils/formatters";
import {DeleteOutlined, FileAddOutlined, FileTwoTone} from "@ant-design/icons";
import {NegotiationAdditionalInsured} from "../../types/additionalInsureds";
import TextArea, {TextAreaRef} from "antd/lib/input/TextArea";

const PAGE_SIZE = 5


const RemoveAdditionalInsured = ({negotiationUuid, additionalInsured, disabled}: {
    negotiationUuid?: string,
    additionalInsured: NegotiationAdditionalInsured
    disabled?: boolean
}) => {

    const [update] = useUpdateAdditionalInsuredMutation()
    const handleRemove = useCallback(async () => {

        try {
            if (negotiationUuid) {
                await update({
                    negotiationUuid,
                    additionalInsuredUuid: additionalInsured.uuid,
                    data: {
                        negotiations: additionalInsured.negotiations.filter(el => el !== negotiationUuid)
                    }
                }).unwrap()
                message.success("Assicurato addizionale rimosso con successo")
            }
        } catch {
            message.error("Errore nella rimozione dell'assicurato addizionale")
        }
    }, [additionalInsured.negotiations, additionalInsured.uuid, negotiationUuid, update])

    return <Popconfirm
        title={"Rimuovere l'assicurato addizionale?"}
        onConfirm={handleRemove}
        disabled={disabled}
    >
        <Button
            type={'text'}
            danger
            icon={<DeleteOutlined/>}
            disabled={disabled}
        />
    </Popconfirm>
}

const AdditionalInsuredNote = ({negotiationUuid, additionalInsured, disabled}: {
    negotiationUuid?: string,
    additionalInsured: NegotiationAdditionalInsured
    disabled?: boolean
}) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [update] = useUpdateAdditionalInsuredMutation()
    const textAreaRef = useRef<TextAreaRef>(null)

    const handleOk = useCallback(async () => {
        try {
            if (negotiationUuid && textAreaRef) {
                await update({
                    negotiationUuid,
                    additionalInsuredUuid: additionalInsured.uuid,
                    data: {
                        note: textAreaRef.current?.resizableTextArea?.textArea.value
                    }
                }).unwrap()
                message.success("Nota aggiunta con successo")
            }
        } catch {
            message.error("Errore nell'aggiunta della nota'")
        } finally {
            setIsModalOpen(false)
        }
    }, [additionalInsured.uuid, negotiationUuid, update])


    return <>
        <Modal
            destroyOnClose
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            onOk={handleOk}
            title={"Nota assicurato addizionale"}
        >
            <TextArea
                ref={textAreaRef}
                defaultValue={additionalInsured.note ?? ""}
                placeholder={"Inserisci una nota relativa all'assicurato addizionale..."}
                rows={5}
            />
        </Modal>
        <Button
            disabled={disabled}
            icon={additionalInsured.note ? <FileTwoTone /> : <FileAddOutlined/>}
            type={'text'}
            onClick={() => setIsModalOpen(true)}
        />
    </>
}
export default function NegotiationAdditionalInsuredList({negotiationUuid, disabled}: {
    negotiationUuid?: string,
    disabled?: boolean
}) {

    const [currentPage, setCurrentPage] = useState(1)
    const {data, isUninitialized, isFetching} = useGetNegotiationAdditionalInsuredQuery(negotiationUuid ? {
        negotiationUuid,
        page: currentPage,
        page_size: PAGE_SIZE
    } : skipToken)

    return <List
        header={<Row justify={'end'}>
            <Col>
            </Col>
            <Col>
                <ConnectAdditionalInsuredAndNegotiation negotiationUuid={negotiationUuid} disabled={disabled}/>
            </Col>
        </Row>}
        loading={isFetching || isUninitialized}
        dataSource={data?.results}
        pagination={(data?.count ?? 0) > 0 && {
            total: data?.count,
            current: currentPage,
            pageSize: PAGE_SIZE,
            onChange: (page) => setCurrentPage(page)
        }}
        renderItem={(item, idx) => (
            <List.Item
                actions={[<Space>
                    {/*EDIT BUTTON*/}
                    <AdditionalInsuredNote
                        negotiationUuid={negotiationUuid}
                        additionalInsured={item}
                        disabled={disabled}
                    />
                    {/*DELETE BUTTON*/}
                    <RemoveAdditionalInsured
                        negotiationUuid={negotiationUuid}
                        additionalInsured={item}
                        disabled={disabled}/>
                </Space>
                ]}
            >
                <List.Item.Meta
                    title={<Row justify={'space-between'}>
                        <Col>
                            {item.name}
                        </Col>
                        <Col>
                            {item.vat_number ?? item.fiscal_code ?? ''}
                        </Col>
                    </Row>}
                    description={<>
                        {item.address ?? item.address} {item.street_number ?? item.street_number}
                        {item.address || item.street_number ? <br/> : ""}
                        {!!item.city && item.city} {!!item.province && '(' + item.province + ')'}
                        {item.city || item.province ? <br/> : ""}
                        Fatturato: {item.yearly_revenues !== null ? euro(+item.yearly_revenues) : "n.d."}
                        <br/>
                        Numero dipendenti: {item.number_of_employees ?? "n.d."}
                    </>}
                />
            </List.Item>

        )}
    />
}