import {CopyOutlined} from "@ant-design/icons";
import {Button, message, Skeleton, Space, Tooltip, Typography} from "antd";

export default function PrettyPrintJson({data, loading}: { data?: any, loading?: boolean }) {

    const jsonString = JSON.stringify(data, null, 2)

    return <div>
        <Space align={'center'}>
            <Typography.Title level={3}>
                Data model
            </Typography.Title>
            <Tooltip
                title={'Copia il data model'}
            >
                <Button
                type={'text'}
                icon={<CopyOutlined/>}
                onClick={() => {
                    message.success('Data model copiato')
                    void navigator.clipboard.writeText(jsonString);
                }}
            />
            </Tooltip>
        </Space>
        {loading
            ? <Skeleton paragraph={{rows: 20}}/>
            : <pre>{jsonString}</pre>}
    </div>
}