import React, {useEffect, useState} from 'react';

import {Button, Col, Divider, Form, Input, Modal, Radio, Row, Select, Space, Spin, Typography} from 'antd';
import {CalendarOutlined, CheckOutlined, WarningOutlined} from '@ant-design/icons';
import {
  AvailableStateTransitionModel,
  NegotiationModel,
  NegotiationTransitionHistory,
  UpdateNegotiationRequest,
} from 'types/negotiations';
import {euro, stateToColors} from 'utils/formatters';
import dayjs from 'dayjs';
import {useSelector} from 'react-redux';
import {selectOptions} from 'redux/features/optionsSlice';
import {States} from "../../../../../types";
import {StatusIndicator} from '../utils';
import {useForm} from 'antd/lib/form/Form';
import {useGetNegotiationReferringUsersQuery} from "../../../../../redux/api/negotiationsApiSlice";
import {ConflictOfInterestModal} from "./ConflictOfInterestModal";

const {Title, Paragraph} = Typography


export function RestoreNegotiationStatus({
                                           //currentState,
                                           revertStates,
                                           isOpen,
                                           onClose,
                                           handleCreateStateTransition,
                                           isCreateStateTransitionLoading
                                         }: {
  revertStates: AvailableStateTransitionModel[],
  //currentState: number,
  isOpen: boolean,
  onClose: () => void,
  handleCreateStateTransition: (data: { state_to: number, reason?: string, notes?: string }) => void,
  isCreateStateTransitionLoading: boolean
}) {

  const [form] = useForm()
  const {states} = useSelector(selectOptions)
  const [selectedState, setSelectedState] = useState<AvailableStateTransitionModel | undefined>(undefined)
  const watchStateTo = Form.useWatch('state_to', form)

  useEffect(() => {
    if (watchStateTo)
      setSelectedState(revertStates.find(state => watchStateTo === state.state))
  }, [revertStates, watchStateTo])

  function createStateTransition() {
    if (selectedState) {
      const values = form.getFieldsValue()
      handleCreateStateTransition(values)
      onClose()
    }
  }

  return (
    <>
      <Modal
        title={"Ripristina stato"}
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={
          <Row
            align={'middle'}
            justify={'space-between'}>
            <Col>
              <Button onClick={onClose}>Annulla</Button>
            </Col>
            <Col>
              <Button disabled={!revertStates.length}
                      type={'primary'}
                      loading={isCreateStateTransitionLoading}
                      onClick={createStateTransition}>
                {isCreateStateTransitionLoading ? 'Ripristino in corso' : 'Ripristina stato'}
              </Button>
            </Col>
          </Row>
        }
      >

        {revertStates.length ? (
          <>
            <Form
              form={form}
              layout={"vertical"}
              requiredMark={true}
              preserve={false}
              initialValues={{...(revertStates.length === 1 ? {state_to: revertStates[0].state} : {})}}
            >
              <Form.Item
                label={"Stato trattativa"}
                name={'state_to'}
                required={true}>
                {
                  <Radio.Group>
                    {revertStates.map((state, idx) => {
                      const enhancedState = states.find(completeState => completeState.value === state.state)
                      return (
                        <Radio
                          key={idx}
                          value={enhancedState?.value}>
                          {enhancedState?.text}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                }
              </Form.Item>

              {!!selectedState && !!selectedState.isReasonMandatory.length && (
                <Form.Item
                  label={"Motivazione"}
                  name={'reason'}
                  required={true}>
                  <Select
                    options={selectedState.isReasonMandatory.map((reason, idx) => ({
                      label: reason.text,
                      value: reason.value,
                      key: idx
                    }))}/>
                </Form.Item>)}
              <Form.Item
                label={"Note"}
                name={'notes'}
                required={!!selectedState?.isNotesMandatory}>
                <Input.TextArea placeholder="Note"/>
              </Form.Item>
            </Form>
            <p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span
              style={{color: '#ff4d4f'}}>*</span> sono obbligatori</p>
          </>) : (
          <p>Non sono possibili cambiamenti di stato</p>
        )
        }
      </Modal>
    </>
  );
}

export function MoveNegotiationToStatus({
                                          state,
                                          isOpen,
                                          onClose,
                                          handleCreateStateTransition,
                                          isCreateStateTransitionLoading
                                        }: {
  state: number,
  isOpen: boolean,
  onClose: () => void,
  handleCreateStateTransition: (data: { state_to: number, reason?: string, notes?: string }) => void,
  isCreateStateTransitionLoading: boolean
}) {

  function createStateTransition() {
    handleCreateStateTransition({state_to: state})
    onClose()
  }

  return (
    <>
      <Modal
        title="Modifica stato trattativa"
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={<Row align='middle' justify='space-between'>
          <Col><Button onClick={onClose}>Annulla</Button></Col>
          <Col><Button type='primary' onClick={createStateTransition}>{isCreateStateTransitionLoading ?
            <Spin size='small'/> : 'Conferma'}</Button></Col>
        </Row>}
      >
        <Row>
          <Col span={24}><Paragraph>La trattativa passerà allo stato di</Paragraph></Col>
          <Col span={24} style={{marginTop: '12px'}}><StatusIndicator state={state}/> </Col>
        </Row>
      </Modal>

    </>
  );
}

export function EditReferredModal({
                                    isOpen,
                                    onClose,
                                    handleCreateStateTransition,
                                    isCreateStateTransitionLoading,
                                    negotiationId,
                                  }: {
  isOpen: boolean,
  onClose: () => void,
  handleCreateStateTransition: (data: { state_to: number, notes: string, responsible_user: string }) => void,
  isCreateStateTransitionLoading: boolean,
  negotiationId: string
}) {

  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [referralForm] = useForm()
  const {data: referringUsers} = useGetNegotiationReferringUsersQuery(negotiationId)

  const handleSubmit = () => {
    const values: { notes: string, responsibleUser: string } = referralForm.getFieldsValue()
    handleCreateStateTransition({
      state_to: States.Referred,
      notes: values.notes,
      responsible_user: values.responsibleUser
    })
    referralForm.resetFields()
    onClose()
  }

  const onValuesChange = () => {

    referralForm.validateFields().then(
      (values) => {
        let isFieldsRequiredOk = true
        for (const key in values || isFieldsRequiredOk) {
          if (!values[key]) {
            isFieldsRequiredOk = false
          }
        }
        setDisabledSubmit(!isFieldsRequiredOk)
      }
    )
  }

  return <Modal
    title="Richiedi referral"
    centered
    open={isOpen}
    onOk={onClose}
    onCancel={onClose}
    footer={
      <Row align='middle' justify='space-between'>
        <Col><Button onClick={onClose}>Annulla</Button></Col>
        <Col>
          <Button type='primary' disabled={disabledSubmit}
                  onClick={handleSubmit}>{isCreateStateTransitionLoading ?
            <Spin size='small'/> : 'Conferma'}
          </Button>
        </Col>
      </Row>}>
    <Form
      form={referralForm}
      layout="vertical"
      requiredMark={true}
      onValuesChange={onValuesChange}
      preserve={false}
    >
      <Space direction='vertical' size='small' style={{width: '100%'}}>
        <Form.Item label="Responsabile" name='responsibleUser' required>
          <Select options={referringUsers ? referringUsers.map(ref => ({
            label: ref.user_full_name,
            value: ref.uuid
          })) : []}/>
        </Form.Item>
        <Form.Item label="Note" name='notes' required>
          <Input.TextArea placeholder="Note"/>
        </Form.Item>
      </Space>
    </Form>
    <p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span
      style={{color: '#ff4d4f'}}>*</span> sono obbligatori</p>
  </Modal>
}

interface FormNegotiationEditStatus {
  state_to: number,
  reason?: string | undefined,
  notes?: string | undefined
}

export function EditNegotiationStatusModal({
                                             availableStates,
                                             currentState,
                                             isOpen,
                                             onClose,
                                             handleCreateStateTransition,
                                             isCreateStateTransitionLoading,
                                             openChecklist,
                                             isAutoRenewal,
                                             negotiation,
                                             updateNegotiation
                                           }: {
  availableStates: AvailableStateTransitionModel[],
  currentState: number,
  isOpen: boolean,
  onClose: () => void,
  handleCreateStateTransition: (data: { state_to: number, reason?: string, notes?: string }) => void,
  isCreateStateTransitionLoading: boolean,
  openChecklist: () => void,
  isAutoRenewal: boolean | null,
  negotiation: NegotiationModel,
  updateNegotiation: (data: Partial<UpdateNegotiationRequest>) => void
}) {

  const {states} = useSelector(selectOptions)

  const [form] = Form.useForm<{ state_to: number, reason?: string, notes?: string }>()
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [selectedState, setSelectedState] = useState<AvailableStateTransitionModel | undefined>(undefined)
  const [isChecklistConfirmOk, setIsChecklistConfirmOk] = useState<boolean>(false)
  const [isConflictOfInterestOk, setIsConflictOfInterestOk] = useState<boolean>(false)
  const [isConflictOfInterestModalOpen, setIsConflictOfInterestModalOpen] = useState<boolean>(false)
  const [isChecklistConfirmNeeded, setIsChecklistConfirmNeeded] = useState<boolean>(false)
  const watchStateTo = Form.useWatch('state_to', form)

  function createStateTransition() {
    const values = form.getFieldsValue()
    if (isAutoRenewal === false && currentState === States.InRinnovo && values.state_to === States.DaRinnovare)
      values.reason = 'TBR-2'
    handleCreateStateTransition(values)
    onClose()

  }

  function handleOpenChecklist() {
    openChecklist()
    setIsChecklistConfirmOk(true)
  }

  const onValuesChange = (changedValues: Partial<FormNegotiationEditStatus>, values: FormNegotiationEditStatus) => {
    const hasErrors = form.getFieldsError().some(({errors}) => errors.length);
    const chosenState = availableStates.find(el => el.state === values.state_to)
    let allFieldsOk = false;
    if (currentState === States.InRinnovo && chosenState?.state === States.DaRinnovare) {
      const isReasonNotRequired = isAutoRenewal === false
      const isReasonsProvided = values.reason !== undefined
      allFieldsOk = chosenState !== undefined && (isReasonNotRequired || isReasonsProvided)
    } else {
      const isNotesNotRequiredOrNotesAreGiven = (chosenState && (!chosenState.isNotesMandatory || (values.notes?.length))) as boolean
      const isReasonNotRequiredOrReasonIsSelected = (chosenState && (!chosenState.isReasonMandatory.length || values.reason)) as boolean
      allFieldsOk = isNotesNotRequiredOrNotesAreGiven && isReasonNotRequiredOrReasonIsSelected
    }
    if (values.state_to === States.Bound) {
      allFieldsOk = allFieldsOk && isConflictOfInterestOk && isChecklistConfirmOk
    }
    setDisabledSubmit(hasErrors || !allFieldsOk);
  }

  useEffect(() => {
    if (watchStateTo)
      setSelectedState(availableStates.find(state => watchStateTo === state.state))
  }, [availableStates, watchStateTo])

  useEffect(() => {
    setIsChecklistConfirmNeeded(selectedState?.state === States.Bound)
  }, [selectedState?.state])

  useEffect(() => {
    if (isChecklistConfirmNeeded) {
      setDisabledSubmit(prevState => prevState && !(!isChecklistConfirmNeeded || (isChecklistConfirmNeeded && isChecklistConfirmOk && isConflictOfInterestOk)))
    }
  }, [isChecklistConfirmNeeded, isChecklistConfirmOk, isConflictOfInterestOk])


  return (
    <>
      <Modal
        title="Modifica stato trattativa"
        centered
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        footer={
          <Row align='middle' justify='space-between'>
            <Col><Button onClick={onClose}>Annulla</Button></Col>
            <Col><Button type='primary' disabled={disabledSubmit}
                         onClick={createStateTransition}>{isCreateStateTransitionLoading ?
              <Spin size='small'/> : 'Conferma'}
            </Button>
            </Col>
          </Row>}
      >
        <Form<FormNegotiationEditStatus>
          form={form}
          layout="vertical"
          requiredMark={true}
          onValuesChange={onValuesChange}
          preserve={false}
          initialValues={{
            ...(availableStates.length === 1 ? {state_to: availableStates[0].state} : {})
          }}
        >
          <Space direction='vertical' size='small' style={{width: '100%'}}>
            <Form.Item label="Stato trattativa" name='state_to' required>
              {/* <Radio.Group onChange={onChange} value={value}> */}
              {availableStates.length === 1 ?
                <Radio.Group value={states[availableStates[0].state].value}>
                  <Radio key={0}
                         value={states[availableStates[0].state].value}>
                    {states[availableStates[0].state].text}
                  </Radio>
                </Radio.Group>
                : <Radio.Group>{availableStates.map((state, idx) => {
                  const enhancedState = states.find(completeState => completeState.value === state.state)
                  return <Radio key={idx}
                                value={enhancedState?.value}>{enhancedState?.text}</Radio>
                })}</Radio.Group>}
            </Form.Item>
            {!!((selectedState && selectedState.isReasonMandatory.length && currentState !== States.InRinnovo) || (selectedState && selectedState.isReasonMandatory.length && (isAutoRenewal === null || isAutoRenewal === true) && currentState === States.InRinnovo)) &&
              <Form.Item
                label={currentState === States.InRinnovo ? "Come rinnovare la trattativa" : "Motivazione"}
                name='reason' required>
                <Select>
                  {selectedState.isReasonMandatory.map((reason, idx) => <Select.Option key={idx}
                                                                                       value={reason.value}>{reason.text}</Select.Option>)}
                </Select>
              </Form.Item>
            }
            <Form.Item label="Note" name='notes'
                       required={!!selectedState?.isNotesMandatory}>
              <Input.TextArea placeholder="Note"/>
            </Form.Item>
          </Space>
        </Form>
        <Row gutter={[16, 16]} align={'middle'}>
          <Col xs={24}>
            <p style={{fontStyle: 'italic'}}>I campi contrassegnati da <span style={{color: '#ff4d4f'}}>*</span> sono
              obbligatori</p>
          </Col>

          {isChecklistConfirmNeeded && <Col flex={1}><Paragraph
            type={isChecklistConfirmOk ? 'success' : 'warning'}>{isChecklistConfirmOk ?
            <Space><CheckOutlined/>Checklist assuntiva visionata</Space> :
            <Space><WarningOutlined/>Checklist assuntiva da visionare</Space>} </Paragraph></Col>}
          {isChecklistConfirmNeeded && <Col>
            <Button onClick={handleOpenChecklist}>Apri</Button>
          </Col>}

          {isChecklistConfirmNeeded && <Col flex={1}><Paragraph
            type={isConflictOfInterestOk ? 'success' : 'warning'}>{isConflictOfInterestOk ?
            <Space><CheckOutlined/>Checklist conflitto d'interessi visionato</Space> :
            <Space><WarningOutlined/>Checklist conflitto d'interessi da visionare</Space>} </Paragraph></Col>}
          {isChecklistConfirmNeeded && <Col>
            <ConflictOfInterestModal
              negotiation={negotiation}
              isOpen={isConflictOfInterestModalOpen}
              setIsOpen={(isNegotiationToUpdate) => {
                setIsConflictOfInterestModalOpen(false)
                setIsConflictOfInterestOk(true)
                if (isNegotiationToUpdate)
                  updateNegotiation({})
              }}/>
            <Button onClick={() => setIsConflictOfInterestModalOpen(true)}>Apri</Button>
          </Col>}
        </Row>

      </Modal>

    </>
  );
}

export function AssignNegotiationModal({
                                         negotiation,
                                         isOpen,
                                         onClose,
                                         updateNegotiationUnderwriter,
                                         handleCreateStateTransition
                                       }: {
  negotiation: NegotiationModel,
  isOpen: boolean,
  onClose: () => void,
  updateNegotiationUnderwriter: (underwriterId: string) => Promise<NegotiationModel | undefined>
  handleCreateStateTransition: ({
                                  state_to,
                                  reason
                                }: {
    state_to: number,
    reason?: string
  }, newNegotiation?: NegotiationModel) => Promise<void>
}) {

  const [
    selectedUnderwriter,
    setSelectedUnderwriter
  ] = useState<string | undefined>(undefined)

  const {
    contractor,
    broker,
    quoted_premium,
    policy_expiring_date,
    policy_effective_date,
    lob} = negotiation;


  async function handleAssignNegotiation(underwriterId: string) {
    try {
      const newNegotiation = await updateNegotiationUnderwriter(underwriterId)
      if (newNegotiation) {
        await handleCreateStateTransition({state_to: States.Assegnata}, newNegotiation)
      } else {
        console.error('Trouble setting the uw')
      }
    } catch (e: any) {
      console.error('Trouble setting the uw')

    } finally {
      setSelectedUnderwriter(undefined)
      onClose()
    }

  }

  return (
    <Modal
      title="Assegna trattativa"
      centered
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Row
            align='middle'
            justify='start'
            gutter={4}>
            <Col>
              <Paragraph>Nome contraente:</Paragraph>
            </Col>
            <Col>
              <Title level={5}>{contractor?.name || 'Non presente'}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            align='middle'
            justify='start'
            gutter={4}>
            <Col>
              <Paragraph>Broker:</Paragraph>
            </Col>
            <Col>
              <Title level={5}>{broker?.name}</Title>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row align='middle' justify='start' gutter={4}>
            <Col><Paragraph>Premio:</Paragraph></Col>
            <Col><Title
              level={5}>{`${typeof quoted_premium === 'number' ? `${euro(quoted_premium)}` : 'Non presente'}`} </Title></Col>
          </Row>
        </Col>
        <Col span={24}>
          <Space size={'large'}>
            <Paragraph type='secondary'>
              Decorrenza: {policy_effective_date ? dayjs(policy_effective_date).format('DD/MM/YYYY') : 'Non presente'}
            </Paragraph>
            <Paragraph type='secondary'>
              Scadenza: {policy_expiring_date ? dayjs(policy_expiring_date).format('DD/MM/YYYY') : 'Non presente'}
            </Paragraph>
          </Space>
        </Col>
      </Row>
      <Row
        gutter={[0, 16]}
        style={{marginTop: '32px'}}>
        {lob?.underwriters.length ?
          <>
            <Col span={24}>
              <Title level={5}>Scegli underwriter</Title>
            </Col>
            {lob?.underwriters.map((uw, idx) => {
              return (
                <Col key={idx} span={24}>
                  <Row align='middle' justify='space-between'>
                    <Col>{uw?.user_full_name}</Col>
                    <Col>
                      <Button
                        style={{width: '10rem'}}
                        loading={selectedUnderwriter === uw?.uuid}
                        type='primary'
                        disabled={!!selectedUnderwriter}
                        onClick={() => {
                          if (uw.uuid) {
                            setSelectedUnderwriter(uw.uuid);
                            handleAssignNegotiation(uw.uuid)
                          }
                        }}>
                        Assegna trattativa
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )
            })}
          </>
          : <Col span={24}>
            <Title level={5} type='warning'> Nessun underwriter disponibile per questa LOB</Title>
          </Col>}
      </Row>
    </Modal>
  );
}

export function HistoryModal({
                               isOpen,
                               onClose,
                               negotiationStatusHistory
                             }: {
  isOpen: boolean,
  onClose: () => void,
  negotiationStatusHistory: NegotiationTransitionHistory[]
}) {

  const negotiationStatusHistoryCopy = [...negotiationStatusHistory]
  negotiationStatusHistoryCopy.reverse()

  return (
    <Modal
      title="Storico della trattativa"
      centered
      open={isOpen}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
    >

      <Row>

        {negotiationStatusHistoryCopy.map((negotiationStatus, idx) => {
            const state = negotiationStatus.state_to ? stateToColors[negotiationStatus.state_to] : undefined

            return (
              <Col key={idx} span={24}>
                <Row align='middle' justify='space-between'>
                  <Col>
                    <Row justify='start' align='middle' gutter={8}>
                      <Col style={{
                        width: '6px',
                        height: '6px',
                        backgroundColor: `var(${state?.color}, black)`,
                        borderRadius: '50px',
                        padding: 0
                      }}>

                      </Col>
                      <Col>
                        <Paragraph>{state?.label}</Paragraph>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Row gutter={8}>
                      <Col>
                        <CalendarOutlined/>
                      </Col>
                      <Col>
                        <Paragraph>{dayjs(negotiationStatus.date_creation).format('DD/MM/YYYY HH:mm')}</Paragraph>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div style={{marginLeft: '11px'}}>
                  {negotiationStatus.reason_text && <Row style={{marginTop: '0.1rem'}}>
                    <Paragraph style={{fontSize: '12px'}}> <span
                      style={{fontWeight: 'bold'}}>Motivazioni:</span> {negotiationStatus.reason_text}
                    </Paragraph>
                  </Row>}
                  {negotiationStatus.notes && <Row>
                    <Paragraph style={{fontSize: '12px'}}> <span
                      style={{fontWeight: 'bold'}}>Commento:</span> {negotiationStatus.notes}
                    </Paragraph>
                  </Row>}
                  {negotiationStatus.responsible_user && <Row>
                    <Paragraph style={{fontSize: '12px'}}> <span
                      style={{fontWeight: 'bold'}}>Referral:</span> {negotiationStatus.responsible_user}
                    </Paragraph>
                  </Row>}
                  {negotiationStatus.user_creation && <Row>
                    <Paragraph style={{fontSize: '12px'}}> <span
                      style={{fontWeight: 'bold'}}>Autore:</span> {negotiationStatus.user_creation}
                    </Paragraph>
                  </Row>}
                </div>
                <Divider/>
              </Col>
            )
          }
        )}

      </Row>


    </Modal>
  );
}