import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { OptionsModel } from 'types/options'



const optionsSlice = createSlice({
    name: 'options',
    initialState: { atecori: [], isiaTypes: [], cancellationDays: [], ppw_days: [], states: [], installmentTypes: [], provinces: [], reinsuranceTypes: [], lobs: [], underwriters: [], policy_types: [], waiting_reasons: [], firstDelegated: null } as OptionsModel,
    reducers: {
        setOptions: (state, action: PayloadAction<OptionsModel>) => {
            return action.payload
            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        }
    }
})

export const { setOptions, } = optionsSlice.actions
export default optionsSlice.reducer

export const selectOptions = (state: RootState) => state.options