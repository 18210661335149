import {Col, Divider, Row, Space,} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {euro} from "utils/formatters";
import HighchartsReact from "highcharts-react-official";
import ChartContainer from "./ChartContainer";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import {
    useLazyGetGapToPlanChartQuery,
} from "../../../redux/api/chartsApiSlice";
import {selectUser} from "../../../redux/features/userSlice";

export function Description() {
    return <>
        Il grafico mostra la percentuale dei premi in Bound rispetto il budget prefissato.
        <dl>
            <dt>Premi per raggiungimento plan:</dt>
            <dd>rappresenta il budget totale della LOB analizzata.</dd>
            <dt>Premi raggiunti:</dt>
            <dd>rappresenta il totale dei premi BOUND fino ad oggi, dato dalla somma di:
                <ul>
                    <li>Premi new business</li>
                    <li>Premi rinnovati chiusi con successo</li>
                    <li>Premi di regolazione</li>
                </ul>
            </dd>
            <dd>rappresenta il totale dei premi di regolazione delle trattative con premio di regolazione.</dd>
        </dl>
    </>
}

export default function GapToPlan(props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) {
    const title = "Premi rispetto gap to plan"
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetGapToPlanChartQuery()
    const [percentageToDisplay, setPercentageToDisplay] = useState<string>('')

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setPercentageToDisplay(data.percentage ? (data?.percentage * 100).toFixed(2) : '0')
        }
    }, [data])

    return <ChartContainer
        title={title}
        loading={isLoading || isFetching}
        description={<Description/>}>
        <BarPercentageChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },
                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [Number(percentageToDisplay) > 100 ? 0 : 100 - Number(percentageToDisplay)],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: Array.from({length: 1}, () => Number(percentageToDisplay)),
                        color: '#13C2C2',
                        dataLabels: {
                            enabled: true,
                            format: `{y:.2f}%`
                        },
                    },
                ]
            }}/>
        <Divider style={{marginTop: 0, marginBottom: '12px'}}/>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi per raggiungimento plan:</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.goal === 'number' ? euro(data.goal) : euro(0)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi raggiunti:</Col>
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.amount === 'number' ? euro(data.amount) : euro(0)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi new business</Col>
            <Col
                style={{fontWeight: 'bolder'}}>{typeof data?.amount_new_business === 'number' ? euro(data.amount_new_business) : euro(0)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'}>
            <Col>Premi rinnovati chiusi con successo:</Col>
            <Col
                style={{fontWeight: 'bolder'}}>{typeof data?.amount_renews === 'number' ? euro(data.amount_renews) : euro(0)}</Col>
        </Row>
        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            <Col>Premi di regolazione:</Col>
            <Col
                style={{fontWeight: 'bolder'}}>{typeof data?.regulation === 'number' ? euro(data.regulation) : euro(0)}</Col>
        </Row>

        <Row justify={'space-between'} wrap={false} align={'bottom'} >
            {data?.regulation_premium_factor ?
                <Col>Stima premi di regolazione {`(${data.regulation_premium_factor})`}:</Col>
                :
                <Col>Stima premi di regolazione:</Col>
            }
            <Col style={{fontWeight: 'bolder'}}>{typeof data?.average_regulation === 'number' ? euro(data.average_regulation) : euro(0)}</Col>
        </Row>
    </ChartContainer>
}