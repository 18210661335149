import {Input} from "antd";
import ConfirmCancelModal from "components/modals/ConfirmCancelModal";
import {useEffect, useState} from "react";
import {DataNode} from "antd/lib/tree";

export default function RenameDocumentModal({
                                                document,
                                                rename,
                                                visible,
                                                close,
                                                onlyFilesMode
                                            }: {
                                                document: any,
                                                rename: (fileName: string, uuid: string) => void,
                                                visible: boolean, close: () => void,
                                                onlyFilesMode: boolean
}) {

    const [input, setInput] = useState<string>("")
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (document.title !== input)
            onlyFilesMode ? setInput(document.fileName ? document.fileName as string : "") : setInput(document.title ? document.title as string : "")
    }, [document.title])

    return <ConfirmCancelModal
        title={'Rinomina documento'}
        open={visible}
        onOk={() => {
            if (!input.trim()) {
                setIsError(true)
            } else if ((onlyFilesMode ? document.fileName : document.title) === input) {
                close()
            } else {
                rename(input, document.key as string)
                close()
            }
        }}
        okText={'Rinomina'}
        onCancel={() => {
            setIsError(false)
            setInput(onlyFilesMode ? document.fileName : document.title as string)
            close()}
        }
    >
        <p style={{marginBottom: "5px"}}>Nome documento</p>
        <Input
            type={'text'}
            value={input}
            status={isError ? 'error' : ""}
            onChange={e => {
                if (isError) {
                    setIsError(false)
                }
                setInput(e.target.value)
            }}
        />
        {isError && <p style={{color: "red"}}>Il nome del documento non può essere vuoto</p>}
    </ConfirmCancelModal>
}