import {Button, Col, Form, Popconfirm, Row, Modal, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import {AntCurrencyFormatInput} from "../inputNumber";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import { useEffect, useState} from "react";
import {
    useLazyGetAppendixQuery,
    useCreateAppendixMutation,
    useDeleteAppendixMutation,
    useUpdateAppendixMutation,
    Appendix
} from "../../redux/api/appendixApiSlice";
import _, {debounce} from "lodash";
import {percentage} from "../../utils/formatters";

export default function AppendixModal({
                                          isVisible,
                                          setIsVisible,
                                          fee,
                                          negotiation,
                                      }: { isVisible: boolean, setIsVisible: (param: boolean) => void, fee: number, negotiation: string }) {

    const [getAppendix, {data: appendix, isLoading: isLoadingAppendix}] = useLazyGetAppendixQuery()
    const [updateAppendix, {data: updatedAppendix, isSuccess: isUpdateSuccess, isError: isUpdateError}] = useUpdateAppendixMutation()
    const [deleteAppendix] = useDeleteAppendixMutation()
    const [createAppendix] = useCreateAppendixMutation()
    const [appendixList, setAppendixList] = useState<Appendix[]>([])
    const [debounceCalls, setDebounceCalls] = useState<{uuid: string, call: any}[]>([])

    useEffect(() => {
        getAppendix({negotiation})
    }, [getAppendix, negotiation])

    useEffect(() => {
        if (appendix) {
            setAppendixList(appendix.map(el => ({...el, isa_amount_share: +el.isa_amount_share})))
            setDebounceCalls(appendix.map(el => ({uuid: el.uuid, call: debounce(updateAppendix, 800) })))
        }
    }, [appendix, updateAppendix])

    useEffect(() => {
        if (updatedAppendix) {
            setAppendixList(prevList => {
                const idx = prevList.findIndex(el => el.uuid === updatedAppendix.uuid)
                if (idx !== -1) {
                    const copiedList = _.cloneDeep(prevList)
                    copiedList.splice(idx, 1, updatedAppendix)
                    return copiedList

                } else {
                    message.error("Errore nell'aggiornamento dell'appendice")
                    return prevList
                }
            })
        }
        if (isUpdateSuccess) {
            message.success('Appendice aggiornata')
        }
        if (isUpdateError) {
            message.error("Errore nell'aggiornamento dell'appendice")
        }
    }, [isUpdateError, isUpdateSuccess, updatedAppendix])

    return <Modal
        title={'Appendici a titolo oneroso (nostra quota)'}
        open={isVisible}
        onCancel={() => setIsVisible(false)}
        onOk={() => setIsVisible(false)}
        width={1000}
        footer={<Row justify={'end'}>
            <Col>
                <Button
                    onClick={async () => {
                        try {
                            const newAppendix = await createAppendix({
                                negotiation,
                                description: '',
                                isa_amount_share: 0
                            }).unwrap()
                            message.success('Appendice creata')
                            setAppendixList(prevState => [...prevState, newAppendix])
                            setDebounceCalls(prevState => [...prevState, {uuid: newAppendix.uuid, call: debounce(updateAppendix, 800) } ])
                        } catch {
                            message.error("Errore nella creazione dell'appendice")
                            console.log('error')
                        }
                    }}
                    icon={<PlusOutlined/>}>
                    Aggiungi appendice
                </Button>
            </Col>
        </Row>}
    >
        {appendixList.length === 0 && <p>Nessuna appendice presente.</p>}
        {!!appendixList.length && <>
          <Row justify={'end'} style={{marginBottom: '1rem'}}>
            <Col>Quota ISA: <span style={{fontWeight: 'bold'}}>{percentage(fee)}</span></Col></Row>
          <Row gutter={16}>
            <Col span={11}>Descrizione</Col>
            <Col span={6}>Premio nostra quota</Col>
            <Col span={6}>Premio 100%</Col>
          </Row></>}

        {appendixList.map(appendix => (
            <Form
                name={`appendix_${appendix.uuid}`}
                key={appendix.uuid}
                onValuesChange={(changedValues, allValues) => {
                    const x = debounceCalls.find(el => el.uuid === appendix.uuid)
                    if (x) {
                        x.call({
                            negotiation,
                            appendix: appendix.uuid,
                            description: allValues.description,
                            isa_amount_share: allValues.isa_amount_share || 0
                        })
                    }
                }}
                initialValues={appendix}
            >
                <Row style={{margin: '0.5rem 0'}} align={'middle'} gutter={16} wrap={false}>
                    <Col span={11}><Form.Item
                        noStyle
                        name={'description'}
                    >
                        <TextArea autoSize={true}/>
                    </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            noStyle
                            name={'isa_amount_share'}
                        >
                            <AntCurrencyFormatInput/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item noStyle shouldUpdate={(prevValues, curValues) => {
                            return prevValues.isa_amount_share !== curValues.isa_amount_share
                        }}>
                            {(form) => {
                                return <AntCurrencyFormatInput
                                    readOnly
                                    disabled
                                    value={form.getFieldValue('isa_amount_share') / (fee)}/>
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={1}>
                        <Popconfirm
                            title={"Eliminare l'appendice?"}
                            onConfirm={async () => {
                                try {
                                    await deleteAppendix({appendix: appendix.uuid, negotiation}).unwrap()
                                    message.success('Appendice eliminata')
                                    setAppendixList(prevState => prevState.filter(currentAppendix => currentAppendix.uuid !== appendix.uuid))
                                    setDebounceCalls(prevState => prevState.filter(el => el.uuid === appendix.uuid ))
                                } catch {
                                    message.error("Errore nell'eliminazione dell'appendice")
                                    console.log('error')
                                }
                            }}
                        >
                            <Button icon={<DeleteOutlined/>} type='ghost'/>
                        </Popconfirm>
                    </Col>
                </Row>
            </Form>
        ))}
    </Modal>
}