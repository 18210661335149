import { apiSlice } from "redux/api/baseApiSlice";
import {
    ContractorCandidateModel,
    ContractorModel,
    CreateContractorModel, CreateCustomerModel,
    GetContractorsModel
} from "types/contractors";
import { GetAtecosModel } from "types/negotiations";


export const contractorsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContractors: builder.query<GetContractorsModel, { search?: string, page?: number, page_size?: number, paginate?: boolean }>({
            query: (args) => {
                return {
                    url: '/negotiation/contractors/',
                    //   params: { start, end },
                    params: args,
                };
            },
        }),
        getContractorsCandidates: builder.query<ContractorCandidateModel[], { country: string, q: string }>({
            query: (args) => {
                return {
                    url: '/negotiation/contractors/search/',
                    params: args,
                };
            },
        }),
        createCustomer: builder.mutation<ContractorModel, CreateCustomerModel>({
            query: (args) => {
                return {
                    url: '/negotiation/contractors/',
                    method: 'POST',
                    body: args
                };
            },
        }),
        alignCustomer: builder.mutation<ContractorModel, (ContractorCandidateModel | CreateCustomerModel) & {contractor_uuid: string}>({
            query: (args) => {
                const {contractor_uuid, ...customerData} = args
                return {
                    url: `/negotiation/contractors/${contractor_uuid}/align_customer/`,
                    method: 'POST',
                    body: customerData
                };
            },
        }),
        updateContractor: builder.mutation<ContractorModel, { uuid: string, data: Partial<CreateContractorModel> }>({
            query: ({ uuid, data }) => {
                return {
                    url: `/negotiation/contractors/${uuid}/`,
                    method: 'PATCH',
                    //   params: { start, end },
                    body: { ...data }
                };
            },
        }),
        getAtecoCodes: builder.query<GetAtecosModel, { page?: number, page_size?: number, search?: string }>({
            query: (data) => {
                return {
                    url: `/negotiation/ateco`,
                    method: 'GET',
                    params: data,
                };
            },
        }),
        getEmissionRatingOptions: builder.query<{value: string, text: string}[], void>({
            query: () => {
                return {
                    url: `/negotiation/emissions_rating_options`,
                    method: 'GET',
                };
            },
        })
    })
})

export const { useLazyGetContractorsQuery, useLazyGetContractorsCandidatesQuery, useCreateCustomerMutation, useAlignCustomerMutation, useUpdateContractorMutation, useLazyGetAtecoCodesQuery, useLazyGetEmissionRatingOptionsQuery, useGetEmissionRatingOptionsQuery } = contractorsApiSlice
