import {apiSlice} from "redux/api/baseApiSlice";
import {
    BelatedReports, BelatedReportsOptions, BelatedReportsResponse
} from "types/negotiations";
import dayjs from "dayjs";
import handleDownloadFile from "../../utils/handleDownloadFile";

export const belatedHistoryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBelatedReports: builder.query<BelatedReportsResponse, { page: number, page_size: number, base_report_type?: string, reference_date_from?: string, reference_date_to?: string, date_creation_from?: string, date_creation_to?: string, ordering?: string}>({
            query: (args) => {
                //const { page, page_size } = args;
                return {url: `/negotiation/belated_emitted_reports/`, params: args}
            }
        }),
        getBelatedReportsOptions: builder.query<BelatedReportsOptions[], void>({
            query: () => `/negotiation/belated_emitted_report_options`
        }),
        getBelatedReportsDownload: builder.query<Blob, { uuid: string, type: string }>({
            query: ({uuid, type}) => {
                return {
                    url: `/negotiation/belated_emitted_reports/${uuid}/download/?type=${type}`,
                    responseHandler: (response) => handleDownloadFile(response),
                    cache: "no-cache",
                }
            },

        }),
        createBelatedReports: builder.mutation<BelatedReports[], {"reference_date"?: string}>({
            query: (data) => {
                return {
                    url: `/negotiation/belated_emitted_reports/`,
                    method: 'POST',
                    body: {...data}
                };
            },
        }),
        getTitleExtractionDownload: builder.query<Blob, { date_from: string, date_to: string }>({
            query: (params) => {
                return {
                    url: `/negotiation/belated_emitted/download/`,
                    params: params,
                    responseHandler: (response) => handleDownloadFile(response),
                    cache: "no-cache",
                }
            },

        }),
    })
})

export const {
    useLazyGetBelatedReportsQuery,
    useLazyGetBelatedReportsOptionsQuery,
    useLazyGetBelatedReportsDownloadQuery,
    useCreateBelatedReportsMutation,
    useLazyGetTitleExtractionDownloadQuery
} = belatedHistoryApiSlice
