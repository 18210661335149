import React from "react";
import {LabeledValue} from "antd/lib/select";
import FilterContainer from "./FilterContainer";
import {FilterDropdownProps} from "antd/lib/table/interface";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";
import ReinsurerMultiSelect from "../../select/MultiSelect/ReinsurerMultiSelect";

export default function ReinsurerFilter(props: FilterDropdownProps) {

    return <FilterContainer {...props}>
        <ReinsurerMultiSelect
            style={{width: '20rem'}}
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            value={props.selectedKeys}
        />
        </FilterContainer>

}