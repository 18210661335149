import React, {useEffect, useState} from "react";
import {Modal, Table} from "antd";
import {useGetAgreementsQuery} from "../../../redux/api/brokersApiSlice";
import {ColumnsType} from "antd/lib/table";
import {AgreementsModel} from "../../../types/broker";


const columns: ColumnsType<AgreementsModel> = [
    {
        title: "Nome",
        dataIndex: "name",
        width: "25%"
    },
    {
        title: "LOB",
        dataIndex: ['lob', 'label'],
        width: "25%"
    },
    {
        title: "Commissioni",
        dataIndex: "commissions",
        width: "20%",
        align: 'right',
        render: (text: number) => text + "%",
    },
    {
        title: "Wordind dedicato",
        dataIndex: "dedicated_wording",
        render: (text: boolean) => text ? "SI" : "NO",
        width: "25%",
    }
]

export default function ActiveAgreements({broker}: { broker: string }) {

    const {data: agreementsData} = useGetAgreementsQuery({broker})
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const agreementDescription = agreementsData?.results.map((el) => `${el.name} (${el.lob?.label})`, "").join(', ')


    return <div style={{fontSize: "16px", padding: "0 8px 8px 8px"}}>
        Convenzioni attive: <strong>{agreementDescription}</strong>
        <span
            style={{
                fontSize: "13px",
                color: "#1890FF",
                marginLeft: "5px",
                cursor: "pointer"}}
              onClick={() => setIsModalOpen(true)}>
            vedi tutto
        </span>
        <Modal title="Convenzioni attive" open={isModalOpen} footer={false} onCancel={() => setIsModalOpen(false)}>
            <Table
                <AgreementsModel>
                dataSource={agreementsData?.results}
                columns={columns}
                pagination={false}
                rowKey={'uuid'}
            />
        </Modal>
    </div>
}