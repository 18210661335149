import React, {useEffect, useState} from "react";
import {BelatedEmittedREVISITED, NegotiationModel} from "../../../../../types/negotiations";
import dayjs from "dayjs";
import _ from "lodash";
import {AntCurrencyFormatInput} from "../../../../inputNumber";
import {Button, Checkbox, Col, Input, message, Row, Space, Table, Tag, Typography} from "antd";
import DatePicker from '../../../../DatePicker'
import {useAlignBelatedEmittedWithDataMutation} from "../../../../../redux/api/negotiationBelatedEmittedApiSlice";

export default function BelatedEmittedTableAfterAlignment({belatedEmitted, negotiation, onConfirm}: {
  belatedEmitted: BelatedEmittedREVISITED[],
  negotiation: NegotiationModel,
  onConfirm: () => void
}) {
  const [belatedEmittedManualAligned, setBelatedEmittedManualAligned] = useState<BelatedEmittedREVISITED[]>([])
  const [alignWithData, {isLoading}] = useAlignBelatedEmittedWithDataMutation()

  const {Title} = Typography;

  const unmountDataAreNotCorrectlySet = belatedEmittedManualAligned.some(el => {
    return el.is_unmounted && (!el.unmounting_date || _.isNil(el.installment_premium_actual) || !el.certificate_number)
  })
  const totalTitles = belatedEmittedManualAligned.length
  const unmountedTitles = belatedEmittedManualAligned.reduce((pv, curr) => pv + (curr.is_unmounted ? 1 : 0), 0)
  const updateBelatedEmitted = (belatedEmittedUuid: string, data: Partial<BelatedEmittedREVISITED>) => {
    setBelatedEmittedManualAligned(prevState => {
      return prevState.map(be => {

        const residualPremiumAmount = +((be.installment_premium - (be?.installment_premium_actual || 0)) < 0 ? 0 : (be.installment_premium - (be?.installment_premium_actual || 0))).toFixed(2)
        const residualCommissionAmount = +(residualPremiumAmount * (negotiation.fee_total_perc || 0)).toFixed(2)

        if (be.uuid === belatedEmittedUuid) {
          return {
            ...be,
            ...data,
            installment_premium_residual: residualPremiumAmount,
            installment_commission_residual: residualCommissionAmount
          }
        } else {
          return be
        }
      })
    })
  }

  useEffect(() => {
    setBelatedEmittedManualAligned(belatedEmitted)
  }, [belatedEmitted])

  return <>
    <Row justify={'space-between'} align={'middle'}>
      <Col>
        <Title level={5}>DOPO</Title>
      </Col>
      <Col>
        <span style={{fontStyle: 'italic'}}>Smontati {unmountedTitles} titoli su {totalTitles}</span>
      </Col>
    </Row>
    <Table
        <BelatedEmittedREVISITED>
        dataSource={belatedEmittedManualAligned}
        pagination={false}
        rowKey={'uuid'}
        scroll={{x: true}}
        columns={[
          {
            key: 'installment_class',
            dataIndex: 'installment_class',
            title: 'Titolo',
            align: 'right',
            render: (value, _, idx) => {
              return <Space>
                {value === 'PRT' && <Tag color={'gold'}>Pro-rata</Tag>}
                {idx + 1}
              </Space>
            }
          },
          {
            key: 'installment_effective_date',
            dataIndex: 'installment_effective_date',
            title: 'Decorrenza',
            align: 'right',
            render: (value, record) => {
              if (value) {
                return dayjs(value).format('DD/MM/YYYY')
              }
            }
          },
          {
            key: 'installment_expiring_date',
            dataIndex: 'installment_expiring_date',
            title: 'Scadenza',
            align: 'right',
            render: value => {
              if (value) {
                return dayjs(value).format('DD/MM/YYYY')
              }
            }
          },
          {
            key: 'installment_premium',
            dataIndex: 'installment_premium',
            title: 'Importo premio',
            align: 'right',
            render: value => {
              if (!_.isNil(value)) {
                return <AntCurrencyFormatInput displayType='text'
                                               value={value}/>
              }
            }
          },
          {
            key: 'installment_commission',
            dataIndex: 'installment_commission',
            title: 'Provvigioni',
            align: 'right',
            render: value => {
              if (!_.isNil(value)) {
                return <AntCurrencyFormatInput displayType='text'
                                               value={value}/>
              }
            }
          },
          {
            key: 'is_unmounted',
            dataIndex: 'is_unmounted',
            title: 'Smontato',
            align: 'right',
            render: (value, record) => {
              return <Checkbox
                  checked={value}
                  onChange={e => {
                    const updatedValue = e.target.checked
                    if (updatedValue) {
                      updateBelatedEmitted(record.uuid, {is_unmounted: true})
                    } else {
                      updateBelatedEmitted(record.uuid, {
                        is_unmounted: false,
                        unmounting_date: null,
                        installment_premium_actual: 0,
                        certificate_number: null
                      })
                    }
                  }}
              />
            }
          },
          {
            key: 'unmounting_date',
            dataIndex: 'unmounting_date',
            title: 'Data caricamento',
            align: 'right',
            render: (value, record) => {
              if (record.is_unmounted) {
                return <DatePicker
                    value={value ? dayjs(value) : null}
                    format={'DD/MM/YYYY'}
                    allowClear={false}
                    disabled={!record.is_unmounted}
                    placeholder={''}
                    onChange={value => {
                      if (value) {
                        updateBelatedEmitted(record.uuid, {unmounting_date: value.format('YYYY-MM-DD') + 'T00:00:00Z'})
                      }
                    }}
                />
              }
            }
          },
          {
            key: 'installment_premium_actual',
            dataIndex: 'installment_premium_actual',
            title: 'Importo premio effettivo',
            align: 'right',
            render: (value, record) => {
              if (record.is_unmounted) {
                return <AntCurrencyFormatInput
                    style={{textAlign: 'right'}}
                    value={value}
                    disabled={!record.is_unmounted}
                    onChange={e => updateBelatedEmitted(record.uuid, {installment_premium_actual: typeof e === 'number' ? e : 0})}
                />
              }
            }
          },
          {
            key: 'certificate_number',
            dataIndex: 'certificate_number',
            title: 'Numero certificato',
            align: 'right',
            render: (value, record) => {
              if (record.is_unmounted) {
                return <Input
                    style={{textAlign: 'right'}}
                    value={value}
                    disabled={!record.is_unmounted}
                    onChange={e => {
                      const updatedValue = e.target.value
                      updateBelatedEmitted(record.uuid, {certificate_number: updatedValue || ""})
                    }}
                />
              }
            }
          },
        ]}
    />
    <Row justify={'space-between'} align={'bottom'}>
      <Col>{unmountDataAreNotCorrectlySet && <p style={{color: 'red'}}>Per confermare l'allineamento è necessario inserire i dati dello smontamento</p>}</Col>
      <Col>
        <Button
            style={{marginTop: '2rem'}}
            disabled={unmountDataAreNotCorrectlySet || isLoading}
            loading={isLoading}
            onClick={async () => {
              try {
                await alignWithData({negotiationId: negotiation.uuid, data: belatedEmittedManualAligned}).unwrap()
                onConfirm()
                message.success('Allineamento avvenuto con successo')
              } catch {
                message.error("Errore nel processo di allineamento")
              }
            }}
        >
          Conferma
        </Button>
      </Col>
    </Row>
  </>
}