import {FilterDropdownProps} from "antd/lib/table/interface";
import {useLazyGetBankReportingOptionsQuery} from "../../../redux/api/commercialDetailApiSlice";
import {useEffect, useState} from "react";
import FilterContainer from "./FilterContainer";
import {Select} from "antd";
import {LabeledValue} from "antd/lib/select";
import {DELAY_TIMER_SEARCH_FILTER, endAndStartTimerFilter} from "./columnsUtils";

export default function BankReportingFilter(props: FilterDropdownProps) {
    const [getBankBranch, {data: bankBranchData}] = useLazyGetBankReportingOptionsQuery()
    const [options, setOptions] = useState<{label: string, value: string}[]>([])

    useEffect(()=> {
        getBankBranch(null)
    }, [getBankBranch])

    useEffect(()=> {
        if (bankBranchData)
            setOptions(bankBranchData.map(el => ({label: el.text, value: el.value})))
    }, [bankBranchData])
    // @ts-ignore
    // @ts-ignore
    return <FilterContainer {...props}>
        <Select
            labelInValue
            options={options}
            mode={"multiple"}
            showSearch={true}
            placeholder={"Seleziona segnalazione banca"}
            filterOption={(input, option) => {
                return !!(option && option.label && (option.label).toLowerCase().includes(input.toLowerCase())) || !!(option && option.value && (option.value as string).toLowerCase() === (input.toLowerCase()))
            }}
            style={{width: "20rem"}}
            virtual={false}
            onChange={(values: LabeledValue[]) => {
                // @ts-ignore
                props.setSelectedKeys(values.reverse().map(el => ({label: el.label, value: el.value})))
                endAndStartTimerFilter(props.confirm, DELAY_TIMER_SEARCH_FILTER)
            }}
            // @ts-ignore
            value={props.selectedKeys}
        />
    </FilterContainer>
}