import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Col, Pagination, Progress, Row, Table, Tag} from "antd";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";
import {extendedBound, States} from "../../types";
import {euro, getContrastYIQ, numeric} from "../../utils/formatters";
import {DISPLAY_NONE, NegotiationsDetailsTable} from "../../types/bankBranchesCharts";
import {ColumnsType} from "antd/lib/table";
import {useLazyGetBBBNegotiationDetailsQuery} from "../../redux/api/chartsApiSlice";
import {CheckOutlined} from "@ant-design/icons";
import {ChartEntity} from "../../types/charts";
import BBBChartCardContainer from "./BBBChartCardContainer";
import {ALL} from "../../context/BrokerChartsContext";

const pageSize = 6
export default function NegotiationsDetail({uuid, filterKey, uw_year, budget_group, broker_branch}: {
    uuid: string,
    filterKey: 'bank_branch' | 'broker',
    uw_year: number,
    budget_group: string,
    broker_branch?: string
}) {
    const options = useSelector(selectOptions)
    const [selectedState, setSelectedState] = useState<States | -1>(-1)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

    const [fetch, {
        data,
        isFetching,
        isUninitialized,
    }] = useLazyGetBBBNegotiationDetailsQuery()

    const getData = useCallback((page = 1) => {
        setCurrentPage(page)
        fetch({
            entity: filterKey === 'broker' ? ChartEntity.BROKER : ChartEntity.BANK_BRANCH,
            entity_uuid: uuid,
            uw_year,
            budget_group,
            page,
            page_size: pageSize,
            ...(!!broker_branch && broker_branch !== ALL) && {broker_branch},
            ...selectedState === -1 ? {} : {state: selectedState === States.Bound ? extendedBound.map(el => el.toString()).toString() : selectedState}
        })
    }, [broker_branch, budget_group, fetch, filterKey, selectedState, uuid, uw_year])

    useEffect(() => {
        getData()
    }, [getData]);

    let total = data?.count
    const loading = isFetching || isUninitialized
    const negotiations: NegotiationsDetailsTable[] = data?.results.map(el => ({
        ...el,
        counter: el.negotiations.length,
        key: el.contractor.value
    })) || []

    const states: { value: string | number; text: string; color: string }[] = [
        {value: -1, text: "Tutti gli stati", color: "white"},
        ...options.states.filter(el => (el.value === States.Working) || (el.value === States.Quoted) || (el.value === States.Bound) || (el.value === States.Declined) || (el.value === States.NotTakenUp))]

    const columns: ColumnsType<NegotiationsDetailsTable> = useMemo(() => {
            return [
                {
                    title: "Contraente",
                    key: "contractor",
                    width: 150,
                    render: (v, record) => {
                        if (record.contractor !== DISPLAY_NONE) {
                            return record.contractor.label
                        }
                    },
                },
                {
                    title: <div style={{textAlign: 'right'}}>Trattative</div>,
                    key: "counter",
                    dataIndex: "counter",
                    align: 'right',
                    width: 100,
                    render: (v) => {
                        if (v !== DISPLAY_NONE) {
                            return numeric(v)
                        }
                    }
                },
                {
                    title: <div style={{textAlign: 'right'}}>Totale Premi</div>,
                    key: "extended_premium",
                    align: 'right',
                    width: 150,
                    render: (v, record) => euro(record.negotiations.reduce((result, curr) => result + curr.extended_premium, 0))
                },
                {
                    title: "LOB",
                    dataIndex: 'lob',
                    key: 'lob',
                    width: 150,
                    render: (_, record) => {
                        const foundLob = options.lobs.find(el => record.negotiations.length === 1 && el.uuid === record.negotiations[0].lob)
                        if (foundLob) {
                            return <Tag
                                style={{
                                    borderRadius: '100px',
                                    width: '7rem',
                                    height: '1.25rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '0 0.5rem',
                                    color: getContrastYIQ(foundLob.color)
                                }}
                                color={foundLob.color}
                            >
                                {foundLob.name}
                            </Tag>
                        } else {
                            return 'Varie'
                        }
                    },
                },
                {
                    title: "Status",
                    dataIndex: 'state',
                    key: 'state',
                    width: 150,
                    render: (_, record) => {
                        const state = options.states.find(el => record.negotiations.length === 1 && el.value === record.negotiations[0].state)
                        if (state) {
                            return <Tag
                                color={state.color}
                                style={{
                                    borderRadius: '100px',
                                    width: '7rem',
                                    height: '1.25rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '0 0.5rem',
                                    color: getContrastYIQ(state.color)
                                }}
                            >
                                {state.text}
                            </Tag>
                        } else {
                            return 'Vari'
                        }
                    }
                },
                {
                    title: "Indice di penetrazione",
                    dataIndex: 'penetration_index',
                    key: 'penetration_index',
                    width: 100,
                    render: (v, record) => {
                        if (record.penetration_index !== DISPLAY_NONE && record.penetration_total !== DISPLAY_NONE) {
                            return <>
                                <div>{record.penetration_index} / {record.penetration_total}</div>
                                <Progress
                                    showInfo={false}
                                    steps={record.penetration_total}
                                    percent={(record.penetration_index / record.penetration_total) * 100}
                                />
                            </>
                        } else {
                            return record.negotiations.length === 1 && extendedBound.includes(record.negotiations[0].state) ?
                                <CheckOutlined style={{color: "#52c41a", fontSize: '1.5rem'}}/> : undefined
                        }
                    },
                }
            ]
        }
        ,
        [options.lobs, options.states]
    )

    return <BBBChartCardContainer>
        <h2>Dettaglio trattative</h2>
        <Row style={{marginTop: "1rem"}}>
            <Col xs={24} lg={6}>
                <Row justify={"center"}>
                    {states && states.map(el =>
                        <div
                            key={el.value}
                            className={"negotiations-state-button"}
                            onClick={() => {
                                setSelectedState(el.value as number)
                            }}
                            style={{backgroundColor: selectedState === el.value ? "#B3E7FF" : undefined}}>
                            <span className={"negotiation-state-circle"}
                                  style={{backgroundColor: el.color}}></span>{el.text}
                        </div>)}
                </Row>
            </Col>
            <Col xs={24} lg={18}>
                <div style={{overflowX: "scroll"}}>
                    <Table
                        <NegotiationsDetailsTable>
                        loading={loading}
                        columns={columns}
                        tableLayout={"fixed"}
                        dataSource={negotiations.map(currentContractor => ({
                            ...currentContractor,
                            counter: currentContractor.negotiations.length,
                            children: currentContractor.negotiations.length > 1 ? currentContractor.negotiations.map(currNegotiation => ({
                                key: currNegotiation.uuid,
                                counter: DISPLAY_NONE,
                                contractor: DISPLAY_NONE,
                                negotiations: [currNegotiation],
                                penetration_index: DISPLAY_NONE,
                                penetration_total: DISPLAY_NONE,
                            })) : null
                        }))}
                        pagination={false}
                        expandable={{
                            rowExpandable: record => record.negotiations.length > 1,
                            expandedRowKeys: expandedRowKeys,
                            onExpand: (expanded, record) => {
                                if (!expanded) {
                                    setExpandedRowKeys([])
                                } else {
                                    setExpandedRowKeys([record.key])
                                }
                            },
                        }}
                    />
                </div>
                {!!total && <Row justify={"end"} style={{marginTop: "1rem"}}>
                    <Pagination
                        disabled={loading}
                        total={total}
                        pageSize={pageSize}
                        current={currentPage}
                        onChange={page => getData(page)}
                        simple={true}
                    />
                </Row>}
            </Col>
        </Row>
    </BBBChartCardContainer>
}