import React, {useState} from "react";
import {RestoreNegotiationStatus} from "./state";
import {HistoryOutlined} from "@ant-design/icons";
import {Button} from "antd";
import {NegotiationModel} from "../../../../../types/negotiations";

const RestoreTransitionButton = ({negotiation, handleStateTransition, isTransitionInProgress = false}: {
  negotiation: NegotiationModel,
  handleStateTransition: (data: {
    state_to: number,
    reason?: string | undefined,
    notes?: string | undefined
  }) => void,
  isTransitionInProgress?: boolean
}) => {
  const [
    isRestoreStateModalOpen,
    setIsRestoreStateModalOpen
  ] = useState<boolean>(false);
  return (
    <>
      <RestoreNegotiationStatus
        handleCreateStateTransition={handleStateTransition}
        isCreateStateTransitionLoading={isTransitionInProgress}
        revertStates={negotiation.revert_state_transition}
        isOpen={isRestoreStateModalOpen}
        onClose={() => setIsRestoreStateModalOpen(false)}/>
      <Button
        icon={<HistoryOutlined/>}
        disabled={!negotiation.revert_state_transition.length}
        onClick={() => setIsRestoreStateModalOpen(true)}
        type={'text'}/>
    </>
  );
}

export default RestoreTransitionButton;