import React, {useCallback, useState} from "react";
import ContractorCandidatesList from "./ContractorCandidatesList";
import {Button, message,} from "antd";
import {
  useAlignCustomerMutation,
  useLazyGetContractorsCandidatesQuery
} from "../../redux/api/contractorsApiSlice";
import {ContractorCandidateModel, ContractorModel, CreateCustomerModel} from "../../types/contractors";
import ManualContractorForm from "./ManualContractorForm";
import AutoPilotForm from "./AutoPilotForm";

export default function ConnectContractorAndCustomer({
                                                       isFund,
                                                       contractor,
                                                       updateContractor,
                                                     }: { isFund: boolean, updateContractor: (contractor: ContractorModel) => void, contractor: ContractorModel }) {
  const [getCandidates, {
    data: candidates,
    isFetching: isFetchingCandidates,
    isLoading: isLoadingCandidates,
    isUninitialized: isUninitializedGetCandidates
  }] = useLazyGetContractorsCandidatesQuery()
  const [alignCustomer, {isLoading: isAlignCustomerLoading, error: errorAlignCustomer}] = useAlignCustomerMutation()
  const [isManualInsertion, setIsManualInsertion] = useState(false)

  const onSelectedCandidate = useCallback((candidate: ContractorCandidateModel | CreateCustomerModel) => {
    return new Promise(async () => {
          try {
            // align contractor
            const updatedContractor = await alignCustomer({...candidate, contractor_uuid: contractor.uuid}).unwrap()
            message.success('Dati del contraente recuperati correttamente')

            // update contractor
            updateContractor(updatedContractor)
          } catch (err: any) {
            if (err?.data?.error) {
              message.error(err?.data?.error)
            } else {
              message.error('Errore nel recupero dei dati del contraente')
            }
          }
        }
    )
  }, [alignCustomer, contractor.uuid, updateContractor])

  return (
      <>
        {!isManualInsertion && <>
            <AutoPilotForm country={contractor.country} searchTerm={contractor.vat_number || contractor.name || undefined} disabled={isFetchingCandidates} handleFinish={(values) => getCandidates({country: values.country, q: values.name})}/>
          {!!candidates?.length &&
              <p style={{color: 'red', fontStyle: 'italic'}}>Attenzione, selezionando uno dei contraenti seguenti
                  verranno
                  sovrascritti i dati del contraente. L'azione è irreversibile</p>}
            <ContractorCandidatesList
                contractorsCandidates={candidates || []}
                isLoading={isLoadingCandidates || isFetchingCandidates || isAlignCustomerLoading}
                isUninitialized={isUninitializedGetCandidates}
                btnLabel={"Seleziona"}
                disabled={false}
                onSelectedCandidate={onSelectedCandidate}
            />
        </>}
        {isManualInsertion && (
            <ManualContractorForm
                isSubmit={isAlignCustomerLoading}
                onSubmit={async (values) => onSelectedCandidate(values)}
                submitBtnLabel={'Conferma'}
                contractor={contractor}
            />
        )}
        <Button
            disabled={isFetchingCandidates || isLoadingCandidates}
            type={'link'}
            style={{padding: 0, margin: '1rem 0'}}
            onClick={() => setIsManualInsertion(prevState => !prevState)}
        >
          {isManualInsertion ? "Passa all'inserimento automatico" : "Non trovi quello che stai cercando? Passa all'inserimento manuale"}
        </Button>

      </>
  )
}