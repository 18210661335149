import {States} from "./index";

export type DISPLAY_NONE_TYPE = 'DISPLAY_NONE'
export const DISPLAY_NONE = 'DISPLAY_NONE'

export type NegotiationsDetailsTable = {
    "key": string,
    "contractor": {
        "value": string,
        "label": string
    } | DISPLAY_NONE_TYPE,
    "counter": DISPLAY_NONE_TYPE | number,
    "negotiations": {
            "uuid": string,
            "lob": string,
            "state": States,
            "extended_premium": number
        }[],
    "penetration_index": number | DISPLAY_NONE_TYPE,
    "penetration_total": number | DISPLAY_NONE_TYPE
}