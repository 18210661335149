import {Col, Input, InputProps, Row} from "antd";
import {FunctionComponent, useMemo} from "react";

import NumberFormat, {NumberFormatProps, NumberFormatValues} from 'react-number-format';
import CalculateButton from "./buttons/CalculateButton";

export function isPercentageAllowed(values: NumberFormatValues): boolean {
    const floatValue = values.floatValue
    if (!floatValue || (floatValue >= 0 && floatValue <= 100))
        return true
    else
        return false
}

export const AntCurrencyFormatInput: FunctionComponent<NumberFormatProps<InputProps> & { onChange?: (x: number | undefined) => void, calculate?: () => void, hasPlaceholder?: boolean }> =
    ({
         onChange,
         calculate,
         hasPlaceholder = true,
         ...props
     }) => {
        const customNumberFormat = useMemo(() => {
            return <NumberFormat
                allowNegative={false}
                suffix={' €'}
                thousandSeparator={'.'}
                decimalSeparator=','
                customInput={Input}
                decimalScale={props.decimalScale !== undefined ? props.decimalScale : 2}
                fixedDecimalScale={true}
                style={{whiteSpace: 'nowrap', ...props.style}}
                {...(props.suffix?.includes('%') && {isAllowed: isPercentageAllowed})}
                {...(hasPlaceholder ? (props.suffix?.includes('%') ? {placeholder: "0.00 %"} : {placeholder: "0.00 €"}) : {})}
                {...props}
                // in order to works with antd (because the onChange of NumberFormat passes the formatted string)
                //https://ant.design/components/form/#components-form-demo-customized-form-controls

                onValueChange={(value) => {
                    onChange?.(value.floatValue || 0)
                }}
                // onChange={(e: any) => console.log(e)}
            />
        }, [hasPlaceholder, onChange, props])

        return !calculate ?
            customNumberFormat :
            <Row wrap={false}>
                <Col flex={1}>{customNumberFormat}</Col>
                <Col>
                    <CalculateButton calculated={props.disabled as boolean} calculate={calculate}/>
                </Col>
            </Row>
    }
