import {Collapse, Table, Tag} from "antd";
import {BelatedEmittedModel} from "../../../../types/negotiations";
import {ColumnsType} from "antd/lib/table";
import dayjs from "dayjs";
import {euro} from "../../../../utils/formatters";
import _ from 'lodash';

export default function CanceledPolicyOverview({belatedEmittedList}: {belatedEmittedList: BelatedEmittedModel[]}) {

    const COLUMNS:  ColumnsType<BelatedEmittedModel>= [
        {
            key: 'installment_effective_date',
            dataIndex: 'installment_effective_date',
            title: 'Decorrenza',
            render: (value: Date) => {
                return value ? <div style={{width: '5rem'}}>{dayjs(value).format("DD-MM-YYYY")}</div> : ""
            }
        },
        {
            key: 'installment_expiring_date',
            dataIndex: 'installment_expiring_date',
            title: 'Scadenza',
            render: (value: Date) => {
                return value ? <div style={{width: '5rem'}}>{dayjs(value).format("DD-MM-YYYY")}</div> : ""
            }
        },
        {
            key: 'installment_premium',
            dataIndex: 'installment_premium',
            title: 'Importo premio',
            render: (value: number) => {
                return !_.isNil(value) ? <div style={{width: '5rem'}}>{euro(value)}</div> : ""
            }
        },
        {
            key: 'installment_commission',
            dataIndex: 'installment_commission',
            title: 'Provvigioni',
            render: (value: number) => {
                return !_.isNil(value) ? <div style={{width: '5rem'}}>{euro(value)}</div> : ""
            }
        },
        {
            key: 'unmounting_date',
            dataIndex: 'unmounting_date',
            title: 'Data caricamento',
            render: (value: Date) => {
                return value ? <div style={{width: '5rem'}}>{dayjs(value).format("DD-MM-YYYY")}</div> : ""
            }
        },
        {
            key: 'installment_premium_actual',
            dataIndex: 'installment_premium_actual',
            title: 'Importo premio effettivo',
            render: (value: number) => {
                return !_.isNil(value) ? <div style={{width: '5rem'}}>{euro(value)}</div> : ""
            }
        },
        {
            key: 'certificate_number',
            dataIndex: 'certificate_number',
            title: 'Numero certificato',
        }
    ]

    return <>
        <Collapse ghost>
            <Collapse.Panel key={0} header={<p style={{fontWeight: 'bold'}}>La polizza è stata cancellata</p>}>
                <Table
                    <BelatedEmittedModel>
                    style={{margin: '1rem 0'}}
                    title={() => <span style={{fontWeight: 'bold'}}>Dettaglio dei titoli</span>}
                    dataSource={belatedEmittedList}
                    columns={COLUMNS}
                    rowKey={'uuid'}
                    pagination={false}
                    scroll={{x: true}}
                    tableLayout={'fixed'}
                />
            </Collapse.Panel>
        </Collapse>
    </>
}